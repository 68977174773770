import { IonLabel, IonPage } from "@ionic/react";
import { connect, useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { FeatureGroup, MapContainer, Marker, Popup, useMap,GeoJSON, LayersControl } from "react-leaflet";
import { LayersControlMap, PlayControl, PlayControlFloat, SegmentZoomControl } from "./LeafletLayers";
import { use } from "i18next";
import { getPlayAction, getPlaySize, getRouteId, getRouteIndex, getRouteList, getRouteSelected, getTrackWhilePlay, setPlayAction, setRouteIndex  } from "../store/mapSlice";
import { Polyline } from "react-leaflet";
import { DivIcon, Icon } from "leaflet";
import { getOS, getSearchSelect } from "../store/appSlice";
import "leaflet/dist/leaflet.css";
import "leaflet-rotatedmarker"
import './leaflet.css' 
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw'; 
import 'react-leaflet-markercluster/dist/styles.min.css';
import { SwipBack } from "../components/AppComponent";
import moment from "moment";
import { pinStop, pinend, pinstart, pinstop } from "./PublicMarker";
import L from "leaflet";
import { getStorage } from "../actions";


const speedPlay=[
    {
      speed: 1000,
      text: "-2x",
    },
    {
      speed: 800,
      text: "-1x",
    },
    {
      speed: 600,
      text: "01x",
    },
    {
      speed: 400,
      text: "02x",
    },
    {
      speed: 200,
      text: "03x",
    },
  ] 
 
let count = 0
// let interval: string | number | NodeJS.Timeout | undefined 
const PastRouteMap =({...props}:any)=>{
    const [speed,setSpeed] = useState ({name:"4x",val:200})
    const [zoom,setZoom] = useState(12)
    const [latitude,setLatitude] = useState(13.98804)
    const [longitude,setLongitude] = useState(100.32456) 
    const [markerCount,setMarkerCount] = useState(0)
    const [interval,setinterval] = useState(0)
    const routeIndex :any= useSelector(getRouteIndex)
    const routeSelected :any= useSelector(getRouteSelected)
    const routeid :any= useSelector(getRouteId) 
    const route :any= useSelector(getRouteSelected)
    
    const dispatch = useDispatch()

    const play=(e:any)=>{  
        if(routeIndex < routeSelected?.list.length-1){ 
            let interval:any = setInterval(()=>{  
                count+=1 
                dispatch(setRouteIndex(count)) 
            },speed.val)
            setinterval(interval)
            dispatch(setPlayAction("playing"))
        }else{
            stop(null) 
            count=0
            dispatch(setRouteIndex(0))
        }
    }
    const pause=(e:any)=>{
        if(routeIndex<15){
            console.log("please wait ")
        }else{
            console.log("click pause ",e)
            dispatch(setPlayAction("pause"))
            // dispatch(setViewRouteSelect(false) )
            clearInterval(interval) 
        }
    }
    const stop=(e:any)=>{
        if(routeIndex<15){
            console.log("please wait ")
        }else{
            console.log("click stop ",e)
            count=0
            // dispatch(setViewRouteSelect(false) )
            dispatch(setPlayAction("stop")) 
            dispatch(setRouteIndex(0)) 
            clearInterval(interval)
        }
    }

    useEffect(()=>{
        setMarkerCount(routeIndex)
        if(markerCount >= routeSelected?.list?.length-1){
            stop(null) 
        } 
    },[routeIndex ,route, markerCount,zoom])

    useEffect(()=>{
       return(()=>{
          dispatch(setRouteIndex(0)) 
       })
    },[])
 
 
    return(<div  style={{position:"relative",width:'99%'}}> 
        {routeid && <PlayControl speed={speed} setSpeed={(e:any)=>{return setSpeed(e)}} play={(e:any)=>{return  play(e)}} pauseAction={(e:any)=>{return  pause(e)}} stopAction={(e:any)=>{return  stop(e)}}/>} 
        <SegmentZoomControl zoom={zoom} action={(e:any)=>{setZoom(e)}}/>
        <MapContainer
                center={[latitude , longitude]} 
                zoomControl={false}  maxZoom={18}
                className="markercluster-map"
                id="map-history"
                zoom={zoom} 
                scrollWheelZoom={true} 
                ref={(mapRef)=>{
                    mapRef?.invalidateSize()
                    mapRef?.on("zoom",(e)=>{
                        setZoom(e.target._zoom) 
                    })
                    mapRef?.on("zoomend",(e:any)=>{  
                        setZoom(e.target._zoom) 
                    })
                }}
        >
            <LayersControlMap />
            {route&& <RoutesSearch zoom={zoom} />  }
            <MarkerPlay index={routeIndex} stop={(e:any)=>{stop(e)}}  /> 
      </MapContainer>
    </div>)
}
export default PastRouteMap;

const RoutesSearch=({zoom}:any)=>{
    const routeList  :any= useSelector(getRouteList)
    const routeid :any= useSelector(getRouteId)
    const [matchRoute,setMatchRoute] = useState(0)
    const [markStop,setMarkStop] = useState()
    const playAction :any= useSelector(getPlayAction)
    const map = useMap() 
    
    useEffect(()=>{ 
        map.setZoom(zoom)
        if(routeid && playAction === "stop"){
           const findRoute =  routeList.findIndex((e:any)=> e._id === routeid           )
      
          // map.fitBounds(routeList[findRoute].route)
           setMatchRoute(findRoute)
        }
        if(routeList.length > 0){
            markerStop() 
        }
    },[routeList,routeid,map,zoom,playAction])

    const markerStop=async()=>{
        let markerstop:any[] = []
        const current:any = routeList.find((dev:any)=> dev._id === routeid) 
        console.log("current ", current)
         

        markerstop = await current.list?.map((dev:any,index:any)=>{
            let nxtInt = index +1
            if(index < current.list.length - 1 ){ 
                if( dev.status !== current.list[nxtInt].status ){
                    if(dev.status === 23 ){ return dev }
                }
            } 
        })  
        markerstop = markerstop.filter((dev:any)=> dev!==undefined)  
        await parseJsonToGeoJson(markerstop)
        // if(markerstop.length >20){
        //     for (var i = 0; i < markerstop.length; i++) {
        //         markerstop.splice(i + 1, 1);
        //     } 
        // }
    }

    const parseJsonToGeoJson=async(markerstop:any)=>{
        let mygeojson:any = {type: "FeatureCollection", features: []}
        markerstop.map((point:any)=>{ 
          let coordinate = [parseFloat(point.longitude), parseFloat(point.latitude)];
          let properties = point;       
          let feature:any = {"type": "Feature", "geometry": {"type": "Point", "coordinates": coordinate}, "properties": properties}
          //@ts-ignore
          mygeojson.features.push(feature);
        }) 
        
       setMarkStop(mygeojson)   
    }

    function createMarker(feature:any, latlng:any) {
        let mark = L.marker(latlng ,{ icon : pinstop })
       
        return mark
      }

    return(<FeatureGroup>
         {markStop &&
            <LayersControl  position={'bottomright'} >
             <LayersControl.Overlay checked={true} name="MarkerStop" >
                <GeoJSON  //@ts-ignore
                    id = {'markstop'} 
                    data={markStop}
                    filled= {true} 
                    pointRadiusMinPixels= {2} 
                    pointRadiusScale= {2000} 
                    getPointRadius={ (f:any) => 11 - f.properties.scalerank }
                    getFillColor={ [200, 0, 80, 180]}  
                    pointToLayer={ createMarker  } 
                />  
               </LayersControl.Overlay>
            </LayersControl> 
        }

        {
            routeList.map((e:any,index:any)=>
                <div key={index}>  { e.show && 
                        <Polyline positions={e.route}  >
                            <Popup>
                                <IonLabel>{e._id}</IonLabel><br/>
                                <IonLabel>{e.name}</IonLabel> 
                            </Popup>
                            {zoom> 17&& <>  
                            </>}
                           {e.list.length >0 &&<Marker icon={pinstart} position={[e.list[0].latitude,e.list[0].longitude]} />}
                           {e.list.length >2 &&<Marker icon={pinend} position={[e.list[e.list.length-1].latitude,e.list[e.list.length-1].longitude]} />}
                       </Polyline>
                    } 
                </div>
                
            )
        }
    </FeatureGroup>)
}

const MarkerPlay=({index,stop}:any)=>{ 
    const routeSelected :any= useSelector(getRouteSelected)
   // const routeIndex :any= useSelector(getRouteIndex)
    const playSize :any= useSelector(getPlaySize)
    const trackPlay :any= useSelector(getTrackWhilePlay)
    const os :any= useSelector(getOS)
    const map = useMap()  
    useEffect(()=>{ 
        map.invalidateSize()  
        console.log(`routeSelected.route${index} `, routeSelected?.list[index])
        if(trackPlay && routeSelected){
            // map.setView(routeSelected.route[index])
        }
        if(index >= playSize-1){
            return stop(null)
        }
    },[ routeSelected,trackPlay])

    return(<>
    {os === "andoid" || os== "ios" && <SwipBack />}
    {routeSelected && index <routeSelected.route.length && 
        <FeatureGroup> 
            {/* <Marker
                position={routeSelected.route[index]} 
                icon={routeSelected.typeId !== null && routeSelected.typeId !== undefined ?
                    new Icon({ 
                        iconUrl: `../assets/types/type-${routeSelected.typeId}.png`,
                        iconSize: [60, 60],
                        rotationOrigin: "center",
                        iconAnchor: [25, 70],
                        popupAnchor: [0, 0],
                        tooltipAnchor: [0, 0],
                    }): new Icon({ 
                        iconUrl: `../assets/types/type-1.png`,
                        iconSize: [60, 60],
                        rotationOrigin: "center",
                        iconAnchor: [30, 70],
                        popupAnchor: [0, 0],
                        tooltipAnchor: [0, 0],
                    })
                }
                ref={markerRef=>{
                    markerRef?.openPopup()
                }}
            > 
             <Popup 
               offset={[0,-60]} 
               autoPan={true}
               className="popup-marker-play"
             >
                <div className="innner">
                    <h6 style={{margin:'0px'}}><IonLabel >{routeSelected?.name}</IonLabel></h6><br/>
                    <IonLabel>{routeSelected.list[index]?.address}</IonLabel><br/>
                    <IonLabel>{routeSelected.list[index]?.speed} Km/h | </IonLabel>
                    <IonLabel>{moment(routeSelected.list[index]?.event_stamp).format("HH:mm DD/MM/YY")} </IonLabel><br/>
                    <IonLabel>{routeSelected.list[index]?.driver_id} {routeSelected.list[index]?.driver_message} </IonLabel>
                </div>
             </Popup>
            </Marker>   */}
        {/* <Marker   
          ref={(markRf:any)=>{
            markRf?.setRotationOrigin("center")
            markRf?.setRotationAngle(routeSelected.list[index]?.heading )
          }}
          icon={ new DivIcon({
            className: `marker-status-divicon ${ 
              routeSelected.list[index]?.status===7? 'marker-move': 
              routeSelected.list[index]?.status===23?'marker-stop': 
              routeSelected.list[index]?.status===24?'marker-idle':'marker-offline'
            }`,
            iconSize: [20, 20], 
            iconAnchor: [5, 78],  
          })} 
          position={[Number(routeSelected.list[index]?.latitude), Number(routeSelected.list[index]?.longitude)]} 
             
        /> */}

    </FeatureGroup>
  }
 </>)
}