import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSignin } from '../store/appSlice';
import { index } from '../actions';

// ระยะเวลาที่จะทำการ logout (หน่วยเป็นมิลลิวินาที)
let IDLE_TIMEOUT = 7200000; // 2hr
// const MAX_TIMEOUT = 7200000 ;
const IdleTimerComponent: React.FC = () => {
  const [isIdle, setIsIdle] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout |any | null>(null);
 
  const [MAX_TIMEOUT,setMaXTimeOut] = useState(7200000) ;
  const dispatch = useDispatch()

  function millisecondsToTime(ms:any) {
    // Calculate hours, minutes, and seconds
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);

    // Pad with leading zeros if necessary
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
  // ฟังก์ชันรีเซ็ตตัวจับเวลาถอยหลังเมื่อมีการเคลื่อนไหว
  const resetTimer = () => { 
    let el = document.getElementById("timeoute")
    if(IDLE_TIMEOUT < MAX_TIMEOUT){
        IDLE_TIMEOUT = IDLE_TIMEOUT + 100 
        // if(el){el.innerHTML = `${(IDLE_TIMEOUT/1000).toFixed(0)}  sec`}
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if(window.location.pathname.indexOf("groupmonitor") < 0 && window.location.pathname.indexOf("grouproutehistory") < 0 ){
      timeoutRef.current = setInterval(() => {
        setIsIdle(true);
        IDLE_TIMEOUT = IDLE_TIMEOUT -1000;  
        if(el){el.innerHTML = millisecondsToTime(IDLE_TIMEOUT) }// `${(IDLE_TIMEOUT/1000).toFixed(0)}  sec`}
        if(IDLE_TIMEOUT <= 0){
          // clearInterval(timeoutRef?.current)
          handleLogout();
        }
      }, 1000);
    }
  };

  const logOut=async()=>{   
    localStorage.removeItem("isSignin")
    localStorage.removeItem("token") 
    dispatch(setSignin(false)) 
  } 

  // ฟังก์ชันที่จะทำการ logout
  const handleLogout = () => { 
    console.log('ผู้ใช้ไม่ได้ใช้งานแล้ว ทำการ logout');
    alert('ผู้ใช้ไม่ได้ใช้งานแล้ว ทำการ logout')
    logOut() 
  };

  useEffect(() => {
    // ตรวจสอบเมื่อผู้ใช้ไม่มีการเคลื่อนไหว
     const getITimer=async ()=>{
      const result = await index()  
      if(result && result?.idleTimer){
        IDLE_TIMEOUT = result?.idleTimer
        setMaXTimeOut(result?.idleTimer)
      }else{ 
        IDLE_TIMEOUT = 7200000
        setMaXTimeOut(7200000)
      }
     }
     getITimer()
     
   
  }, [isIdle ,window.location.pathname]);

  useEffect(() => {
    // เพิ่ม event listeners เพื่อตรวจจับกิจกรรมของผู้ใช้
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keypress', resetTimer);
    window.addEventListener('scroll', resetTimer);

    // ตั้งค่าเริ่มต้นตัวจับเวลาครั้งแรก
    resetTimer();

    // ลบ event listeners เมื่อ component ถูก unmount
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keypress', resetTimer);
      window.removeEventListener('scroll', resetTimer);
    };
  }, []);

  return <div id='timeoute' style={{}} > </div>
};

export default IdleTimerComponent;
