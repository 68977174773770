import i18next from 'i18next';
import React, { Component } from 'react'; 
// import {lmc} from "./longdomap.markercluster"
 
export let longdo;
export let map;
export let markercluster;
 
export class LongdoMap extends Component {

  constructor(props) {
    super(props);
    this.mapCallback = this.mapCallback.bind(this);
  }

  mapCallback() {
    longdo = window.longdo 
    map = new window.longdo.Map({
      placeholder: document.getElementById(this.props.id),
      language: i18next.language , 
      // ui: longdo.UiComponent.None,
    }); 
    // markercluster = new lmc.MarkerCluster(map,
    //   {
    //     minClusterSize:2,
    //   });
  }

  componentDidMount() {
    const existingScript = document.getElementById('longdoMapScript');
    const callback = this.props.callback
    console.log(" mapKey ",this.props.mapKey)
    if (!existingScript) {
      try{
        const script = document.createElement('script');
        script.src = `https://longdomap.attg.cc/mmmap/`
        // script.src = `https://api.longdo.com/map3/?key=${this.props.mapKey}` 
        // script.src = `https://api.longdo.com/map/?key=${this.props.mapKey}` //`https://api.longdo.com/map3/?key=${this.props.mapKey}`; //
        script.id = 'longdoMapScript';
        document.body.appendChild(script);
        script.onload = () => {
          this.mapCallback();
          if (callback) callback();
        };
      }catch(err){
        console.log("err ",err)
      }
      
    }

    if (existingScript) this.mapCallback();
    if (existingScript && callback) callback();
  }

  render() {
    return (
        <div id={this.props.id} style={{width:'100%',height:'100%'}}>
       
        </div>
    );
  }

}