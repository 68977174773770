import { createSlice } from "@reduxjs/toolkit"; 
import { setStorage } from "../actions";
import { RootState } from "./store";

const initialState = {  
  notifyEnable: true,
  voiceEnable: true ,
  notifyVoice: "Horn Car",
  soundsNotify : [
        { id:1  , name:"Beep Alarm Clock" ,url:"../../assets/sounds/beep-alarm-clock.wav" },
        { id:2  , name:"Beep Alarm Digital" ,url:"../../assets/sounds/beep-alarm-digital-clock.wav" },
        { id:3  , name:"Beep Alarm Warning" ,url:"../../assets/sounds/beep-alarm-warning.wav" },
        { id:4  , name:"Bell Casino" ,url:"../../assets/sounds/bell-casino-reward.wav" },
        { id:5  , name:"Bell School Calling" ,url:"../../assets/sounds/bell-school-calling.wav" },
        { id:6  , name:"Bell" ,url:"../../assets/sounds/bell.wav" },
        { id:7  , name:"Horn Circus Clown" ,url:"../../assets/sounds/horn-at-circus-clown.wav" },
        { id:8  , name:"Horn Small Car" ,url:"../../assets/sounds/horn-small-car.wav" },
        { id:9  , name:"Horn Car" ,url:"../../assets/sounds/horn_car.mp3" },
        { id:10 , name:"Siren Ambulance" ,url:"../../assets/sounds/siren-ambulance-us.wav" },
        { id:11 , name:"Siren Police(US)" ,url:"../../assets/sounds/siren-police-us.wav" },
        { id:12 , name:"Siren Police" ,url:"../../assets/sounds/siren-police.wav" },
        { id:13 , name:"Siren Slot Machine" ,url:"../../assets/sounds/siren-slot-machine-win.wav" },
    ],
  devicesAdmin:[],
  devicesAdminBackup:[],
  usersAdmin:[],
};

export const settingSlice = createSlice({
  name: "settingState",
  initialState,
  reducers: { 
    setNotifyEnable:  (state, action) => {
      state.notifyEnable = action.payload; 
      setStorage("notifyEnable",state.notifyEnable )
    } ,
    setVoiceEnable:  (state, action) => {
      state.voiceEnable = action.payload;
      setStorage("voiceEnable",state.voiceEnable )
    } ,
    setNotifyVoice:  (state, action) => {
      state.notifyVoice = action.payload;
      setStorage("notifyVoice",state.notifyVoice )
    } ,
    setDevicesAdmin:  (state, action) => {
      state.devicesAdmin = action.payload; 
    } ,
    setDevicesAdminBackup:  (state, action) => {
      state.devicesAdminBackup = action.payload; 
    } ,
    setUsersAdmin:  (state, action) => {
      state.usersAdmin = action.payload; 
    } ,
  },
});

// Action creators are generated for each case reducer function
export const { 
  setNotifyEnable,
  setVoiceEnable,
  setNotifyVoice,
  setDevicesAdmin,
  setDevicesAdminBackup,
  setUsersAdmin
} = settingSlice.actions;
 
export const getNotifyEnable= (state:RootState) => state.settingState.notifyEnable;
export const getVoiceEnable= (state:RootState) => state.settingState.voiceEnable;  
export const getNotifyVoice= (state:RootState) => state.settingState.notifyVoice; 
export const getSoundNotify= (state:RootState) => state.settingState.soundsNotify 
export const getDevicesAdmin= (state:RootState) => state.settingState.devicesAdmin; 
export const getDevicesAdminBackup= (state:RootState) => state.settingState.devicesAdminBackup; 
export const getUsersAdmin= (state:RootState) => state.settingState.usersAdmin; 

export default settingSlice.reducer;
