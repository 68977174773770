import React, { useRef, useState } from 'react';  
import { allDevices,  deviceByGroup,  getNotifyMessage,  getNotifyUnreadMessage, getStorage, notifications, updateReadMessageNotify } from '../actions';
import { useDispatch, useSelector} from 'react-redux';  
import { getNotifierMessages, setBackupDevices, setDeviceGroup, setDevices, setNotifierMessage, setUnreadMsg } from '../store/appSlice';
import { getPreviewDevice, getTrackRealtime, setDeviceBound, setPreviewDevice, setTrackRealtime } from '../store/mapSlice'; 
import moment from 'moment';
import {  getNotify, setNotify } from '../store/menuSlice'; 
import { useToaster } from 'react-hot-toast/headless'; 
import { getNotifyEnable, getNotifyVoice, getSoundNotify, getVoiceEnable } from '../store/settingSlice';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css' 
import { IonIcon } from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';

export default function LoadingProgress({updatetime , mapref}:any) {
  //const classes = useStyles();
  const dispatch = useDispatch() 
  var [completed, setCompleted] = React.useState(0); 
  const [limit,setLimit] = useState(0)
  const trackRealtime  :any= useSelector(getTrackRealtime)
  const preview :any= useSelector(getPreviewDevice)
  const { toasts, handlers } = useToaster()
  const voiceEnable  :any= useSelector(getVoiceEnable) 
  const soundsNoti :any= useSelector(getSoundNotify) 
  const notify:any = useSelector(getNotify) 
  const [unread,setUnread] = useState<any[]>([])
  const alarm  =  useSelector(getNotifyVoice) 
  

  let intervalRef:any = useRef(); 
  let indexMsg = -1

  const boundingDevices=async(list:any)=>{
    let bound:any = []
    await list.filter((device:any)=>device.event_id !== null).map((device:any)=>
      bound = [...bound , [parseFloat(device.latitude),parseFloat(device.longitude)] ]
    )
    dispatch(setDeviceBound(bound))
  }
  
  const isLastNoti=(time:any)=>{
    let diff = new Date().getTime() - time
    if(diff > 60000){
      return false
    }else{
      return true
    }
  }

 const noticode=(code:any)=>{
  //'success' | 'danger' | 'info' | 'default' | 'warning'
    if(code  === -6) { return "warning"; }
    else if(code  === -7) {  return "warning";  }
    else if(code  === 6)  { return "danger"; } 
    else if(code  === 1)  { return "info"; }
    else if(code  === 2)  { return "info"; }
    else if(code  === 25) { return "warning"; }
    else if(code  === 26) { return "info";  }else{
      return "info"
    }
  }
  

  async function requestPermission(e:any,index:any,size:any) {   
    const voiceEn = await   getStorage("voiceEnable"  )
    const notiEn = await   getStorage("notifyEnable"  )
    const typemsg :any= await noticode(e?.notiferCode) 
    
    if( notiEn  ){ 
      try{   
        let notiAlarm = soundsNoti.filter((s:any)=> s.name.indexOf(e?.sound ? e?.sound : alarm) > -1 ) 
        if( voiceEn  && notiAlarm.length > 0 ){ 
          var audio = new Audio( notiAlarm[0]?.url ); 
          audio.play();
          setTimeout(()=>{audio.pause()},2500) 
        }
        // if(indexMsg === index && index <size){ 

        Store.addNotification({
          title:  ` ${e?.title} `,
          message: e?.tambon ==="" && e?.province ==="" ? e?.message.slice(0,50) : `${e.title} ${e?.geoName }  ${e?.tambon}   ${e?.amphur}   ${e?.province}` ,
          type: typemsg ,
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        }); 
        indexMsg = indexMsg+1 
      // }
      }catch(err){
        console.log(err)
      }
    }
  }
  //alarm

  const getNotifyMess=async()=>{ 
       
    let mbegin = moment().subtract(5,"minutes").format("YYYY-MM-DD HH:mm:ss")
    let mend = moment().format("YYYY-MM-DD HH:mm:ss") 
    let begin = new Date(mbegin).getTime()
    let end =  new Date(mend).getTime() 
    let message = await notifications({begin,end})

    
      message = message.splice(0,6) 
    
      try{ 
        let  pushedId: any[] = []
        if(message.length < 10){ 
         let intervalNoti = setInterval(async ()=>{
            if(indexMsg < message.length ){
              indexMsg += 1 
              requestPermission(message[indexMsg],indexMsg ,message.length  )
             
              pushedId.push( message[indexMsg]?.id)
            }else{
              clearInterval(intervalNoti)
             const body = {ids: pushedId}
              
             await updateReadMessageNotify(body)
              // pushedMessage
            }
          },3000)
        } 
        if(notify?.message){
          let joindmessage :any =  notify?.message
          await message.map((e:any)=>{
            joindmessage = [...joindmessage , e]
          })
          dispatch(setNotifierMessage(joindmessage)) 
          dispatch(setNotify({
            update:  new Date().getTime(),
            message: joindmessage
          }))  
        }else{
          dispatch(setNotifierMessage(message)) 
          dispatch(setNotify({
            update:  new Date().getTime(),
            message: message
          }))  
        } 
      
      }catch(err){
        console.log("err ", err)
      } 
   
  } 
  
  const fetchDevice=async()=>{
    const devicelist:any[] = await  allDevices({})  
    const result =  await devicelist.map((e:any)=> {
        return {  ...e,...{show : true} ,}
    })  
    dispatch(setDevices(result))
    dispatch(setBackupDevices(result))
    boundingDevices(result)

    let deviceGroup = await deviceByGroup({})   
    if(deviceGroup.status === 200){
        let groupsdcheck =await deviceGroup?.devices.map((group:any)=>{
            const dcheck = group.devices.map((device:any)=>{
                return { ...device , ...{show : true} }
            })
            return {...group , ...{devices:dcheck , show : true}}
        }) 
        dispatch(setDeviceGroup(groupsdcheck)) 
    } 

    if(preview.active){
        const lstUpdate = devicelist.find((el)=> el.device_id === preview.device.device_id)
        dispatch(setPreviewDevice({active:true,device:lstUpdate}))
        mapref.setView([lstUpdate.latitude , lstUpdate.longitude ])
    }
    if(trackRealtime.length>0){
        let updateTrackRealtime = trackRealtime
        let updateTrack={
            device_id:0 ,
            name:"" ,
            line: {
                isDash: true,
                dashArray: [5, 10], //null
                color: "", 
            },
            route:[[0,0]]
        }
        try{
          await devicelist.filter((device:any) => device.event_id !== null).map(async(device:any) =>  
              await trackRealtime.map((track:any) =>{
                  if(device ){ 
                      if(device?.device_id === track?.device_id){
                          let trackRoute = track?.route
                          if(trackRoute[trackRoute.length-1][0]!==parseFloat(device.latitude) && trackRoute[trackRoute.length-1][1]!==parseFloat(device.longitude) ){
                              let tractRoute:any[] = [...trackRoute , [parseFloat(device.latitude),parseFloat(device.longitude)] ]
                              if(track){
                                updateTrack.device_id = track?.device_id
                                updateTrack.route = tractRoute

                                updateTrack.line = track?.line
                                updateTrack.name = track?.name 

                                updateTrackRealtime = updateTrackRealtime.filter((track:any) => track.device_id !== device.device_id)

                                updateTrackRealtime = [...updateTrackRealtime, updateTrack]
                                dispatch(setTrackRealtime(updateTrackRealtime))
                              }
                            }else{
                            console.log("location didn't change.")
                          }
                        }else{
                          
                      }
                  }
              })

          ) 
        }catch(err){
           console.log("update track err ", err)
        } 
       
    }
  } 

  React.useEffect(() => {
    function progress() {
      setCompleted((oldCompleted) => {  
        if (oldCompleted === 100) {  
          fetchDevice()  
          getNotifyMess()
          return 0; 
        }
        var diff = Math.random() *3.6;
        setLimit(oldCompleted + diff)
        if (oldCompleted + diff > 90 && oldCompleted + diff < 99) {
             updatetime(new Date().getTime())
         }
        return Math.min(oldCompleted + diff, 100);
      });
    }

    var timer = setInterval(progress, 1000);

    if(limit>=600000){
      clearInterval(timer);
      setLimit(0)
      console.log("clearlimit interval")
      setTimeout(()=>{
        timer = setInterval(progress, 1000);
      },500) 
    } 
    return () => {
        clearInterval(intervalRef);
      clearInterval(timer);
    };
  }, [notify ,voiceEnable]);

  return (
    <div className='loading-progress' style={{width:"100%",display:"flex"}} >
      <div className='set-center' style={{width:"100%"}}> 
        <div className='wrap'  >
          <div  className='loading' style={{
            width: completed +"%", 
            height:"100%",
            // backgroundColor:"rgb(19, 73, 133)",
            transitionDuration:"300ms",
            borderTopRightRadius:"25px",
            borderBottomRightRadius:"2px"
            }}></div>
        </div>
      </div> 
      {toasts
        .filter((toast) => toast.visible)
        .map((toast) => (
          <div key={toast.id} {...toast.ariaProps}>
            {toast.message?.toString()}
          </div>
        ))}
    </div>
  );
} 