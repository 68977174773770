import { configureStore } from "@reduxjs/toolkit";
import appstateReducer from "./appSlice";
import mapStateReducer from "./mapSlice";
import dashboardReducer from "./dashboardSlice";
import filterReducer from "./filterSlice";
import reportReducer from "./reportSlice";
import menuReducer from "./menuSlice";
import settingReducer from "./settingSlice"
import mdvrReducer from "./mdvrSlice"

export const store = configureStore({
  reducer: {
    appstate: appstateReducer,
    mapstate: mapStateReducer,
    dashboardState: dashboardReducer,  
    filteredState: filterReducer,
    reportState: reportReducer ,
    menuState: menuReducer,
    settingState: settingReducer,
    mdvrReducer: mdvrReducer
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch