import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonListHeader,IonModal,IonPage, IonRow, IonSpinner, IonText, IonTextarea, IonTitle, IonToggle,   IonToolbar, useIonToast} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { getDarkMode, getIsGroupDevice, getMenuOpen, getProfile, getSearchSelect, getShowLicebse, setBackupDevices, setDefaultMap, setDevices, setIsGroupDevice, setLoading, setProfile, setShowLicense } from '../store/appSlice';
import { AuthCheck } from './Home';
import { DefaultMap, IconVehicleType, Languages, LineKey,  NotifySetting } from './settings/SettingComponents'; 
import { allDevices, devicesAll, getDevices,   getStorage, getUserProfile, setDeviceName, setGroupDevices, setMarkerName, setStorage, toastFail, toastSuccess, userSetting } from '../actions';
import { useTranslation } from 'react-i18next';
import { chevronForward, chevronBackOutline, menu, chevronBack, closeCircle } from 'ionicons/icons';
import { MapGroupDevices, MapShowName } from '../leaflet/SettingsMap';
import { MenuContent } from '../components/Menu';
import NavBar from '../components/NavBar';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import moment from 'moment';
import { t } from 'i18next';
import { useHistory } from 'react-router';
import '../theme/style.css';
import './css/Setting.css'
import { DropDownDevice } from '../components/AppComponent';
import { ModalEditDevice } from './HomeScreen';
import { setPreviewDevice } from '../store/mapSlice';

export  const Settings: React.FC = () => { 
  const dispatch = useDispatch()
  const dark :any= useSelector(getDarkMode) // [dark,setDark] = useState(false)
  const menuOpen :any= useSelector(getMenuOpen)
  const profile :any= useSelector(getProfile)
  const [rightContent,setContent] = useState(false) 
  const [deviceSetting,setDeviceSetting] = useState(false)
  const [admin,setAdmin] = useState(false)
  const [modal,setModal] = useState(false)
  const [deviceId,setDeviceId] = useState(0)
  const history = useHistory()
 
  useEffect(()=>{
    const getsignin=async()=>{
      let signin = await getStorage("sigin")
      console.log("signin", signin)
      if(signin?.username === "admin"){ 
        setAdmin(true)
      }
    } 
    dispatch(setLoading(false))  
  },[])


  return (
    <IonPage> 
    {/* <IonHeader color='transparent' className='ion-no-border  '   > 
      <IonToolbar style={{display:"flex"}} color='transparent' mode='ios' className='ion-no-padding'>  
          <NavBar />   
          <IonButtons slot="start"  className='ion-hide-md-up  '  >
             <IonButton size='small' onClick={()=>{history.goBack()}}> <IonIcon icon={chevronBackOutline} />  <IonText >Back</IonText> </IonButton>
          </IonButtons>
          <IonTitle style={{fontWeight:"bold",fontSize:"1.3em",marginLeft:"1rem" }} >{'ตั้งค่า'}</IonTitle>
      </IonToolbar>
    </IonHeader> */}
    <IonContent fullscreen className='ion-padding' style={{paddingBottom:"2rem"}}>  
      <IonRow>
          {(menuOpen)&&<IonCol sizeLg='3' sizeXl='2' className='ion-hide-xl-down'  >
             <div className={`main-side-menu ${menuOpen && "slide-right"}`} style={{height:'90vh' }} > 
             <MenuContent profile={profile} /></div>
          </IonCol>} 
        <IonCol >
        <IonGrid> 
          <IonRow className='ion-justify-content-center' > 
            <IonCol sizeXs="12" sizeSm="12" sizeMd='8' sizeLg={rightContent ?'2.5':'5'}> 
                <IonLabel  className='ion-hide-md-down'  style={{fontWeight:"bold",fontSize:"1.3em",marginLeft:"1rem"}}>{'ตั้งค่า'}</IonLabel>
                <br/>
                <IonListHeader >
                  <IonLabel  className='ion-no-margin' style={{fontSize:"1.1em"}} >ตั้งค่าโปรไฟล์</IonLabel>
                </IonListHeader>
                <IonList  className='group-settings'>  
                  <SettingProfile />
                </IonList>

                <IonListHeader >
                  <IonLabel  className='ion-no-margin' style={{fontSize:"1.1em"}} >ตั้งค่าการแสดงผล</IonLabel>
                </IonListHeader>
                <IonList className='group-settings' > 
                  <Languages  />
                  <SettingDeviceName2 />
                  {/* <IonItem lines='none' mode='ios' color={"transparent"} >
                    <IonLabel>Devices Name</IonLabel>
                  </IonItem>  */}

                  <DefaultMap />
                  <SettingGroupDevices />
                  <SettingShowName />
                </IonList>

                <IonListHeader >
                    <IonLabel  className='ion-no-margin' style={{fontSize:"1.1em"}} >ตั้งค่าการแจ้งเตือน</IonLabel>
                 </IonListHeader>
                <IonList  className='group-settings' >  
                  <NotifySetting />
                  <LineKey />
                </IonList>

                <IonListHeader>
                    <IonLabel  className='ion-no-margin' style={{fontSize:"1.1em"}}>การให้คะแนนคนขับ</IonLabel>
                  </IonListHeader>
                <IonList  className='group-settings'>  
                   <IonItem mode='ios' lines='none' color={"transparent"} >
                     <IonLabel>กำหนดเวลาจอดติดเครื่องยนต์</IonLabel>
                   </IonItem>
                   <IonItem mode='ios' lines='none' color={"transparent"}>
                     <IonLabel>กำหนดความเร็วที่ยอมรับได้</IonLabel>
                   </IonItem>
                </IonList>

                <IonListHeader>
                    <IonLabel  className='ion-no-margin' style={{fontSize:"1.1em"}}>ตั้งค่าอุปกรณ์</IonLabel>
                </IonListHeader>
                <IonList className='group-settings'>  
                  <IconVehicleType />
                  {admin &&  <IonItem lines='none' mode='ios' color={"transparent"} onClick={()=>{ setContent(true);setDeviceSetting(true) }}>
                    ตั้งค่าอื่นๆ ของอุปกรณ์
                  </IonItem>}
                </IonList> 
            </IonCol>{
            rightContent && <IonCol>
              <div style={{width:"100%",height:"70vh",transitionDuration:"1s"}} >
                 {deviceSetting && 
                 <DeviceSettings 
                  setContent={(e:any)=>setContent(e)} 
                  setDeviceSetting={(e:any)=>setDeviceSetting(e)}  
                 /> }
              </div>
            </IonCol>
            }

          </IonRow>
        </IonGrid> 
        </IonCol>
      </IonRow> 
      </IonContent>  
    </IonPage>
  );
};


const SettingProfile=()=>{
  const [modal,setModal] = useState(false) 
  const dispatch = useDispatch()
  const [username,setUsername] = useState("")
  const [password,setPassword] = useState("")
  const [account,setAccount] = useState("")
  const [name , setName ] = useState("")
  const [lastname , setLastName] = useState("")
  const [email , setEmaiil] = useState("")

  const [uProfile , setUProfile] = useState({
    "account": "phuket",
    "userName": "noom2",
    "name": "สุทธิพงศ์",
    "email": "suttipong@andaman-tracking.com",
    "timeZone": "Asia/Bangkok",
    "isMatch": true,
    "lastName": "ประทุม",
    "showName": true,
    "password": null,
    "lineId": "andamangps"
})

  
  useEffect(()=>{ 
    const getpro=async()=>{ 
      const pro = await getUserProfile({}) 
      setUProfile(pro)
      dispatch(setProfile(pro))
     
      setAccount(pro?.account)
      setUsername(pro?.userName)
      setName(pro?.name)
      setLastName(pro?.lastName)
      setEmaiil(pro?.setEmaiil) 
      setPassword(pro?.password)
    }
    getpro()
   },[])

  return( <>
    <IonItem mode='ios' lines='none' color={"transparent"} onClick={()=>{setModal(true)}} >
      <IonLabel> ตั้งค่าโปรไฟล์</IonLabel>
    </IonItem>
    <IonModal isOpen={modal} onIonModalDidDismiss={()=>{setModal(false)}} mode='ios' >
      <IonContent className='ion-padding' > 
      
        <IonGrid className='ion-padding' >
          <IonRow>
            <IonCol size='12' className='set-center' style={{justifyContent:"space-between",flexDirection:"row"}} >
              <IonLabel style={{fontWeight:"bold" , fontSize:"1.3rem"}} > ตั้งค่าโปรไฟล์ </IonLabel>
              <IonButton fill='clear' color={"primary"} onClick={()=>{setModal(false)}} > <IonIcon icon={closeCircle} /> </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>  
            <IonCol size='6' >
            <div className='input' >
              <IonItem mode='ios' lines='none' color={'transparent'} > 
                  <IonInput mode='ios'   placeholder="Username" value={username} onIonChange={(e)=>{setUsername(e.detail.value!)}}></IonInput> 
              </IonItem>
            </div> 
            </IonCol>
            <IonCol size='6' >
            <div className='input' >
              <IonItem mode='ios' lines='none' color={'transparent'} > 
                  <IonInput mode='ios'   placeholder="Password" value={password} type='password'  onIonChange={(e)=>{setUsername(e.detail.value!)}}></IonInput> 
              </IonItem>
            </div> 
            </IonCol>
            <IonCol size='6' >
            <div className='input' >
              <IonItem mode='ios' lines='none' color={'transparent'} > 
                  <IonInput mode='ios'   placeholder="Name" value={name} onIonChange={(e)=>{setName(e.detail.value!)}}></IonInput> 
              </IonItem>
            </div>  
            </IonCol>
            <IonCol size='6' > 
              <div className='input' >
                <IonItem mode='ios' lines='none' color={'transparent'} > 
                    <IonInput mode='ios'  placeholder="Last Name" value={lastname} onIonChange={(e)=>{setLastName(e.detail.value!)}}></IonInput> 
                </IonItem>
              </div>  
            </IonCol>
            <IonCol size='6' >
              <div className='input' >
                <IonItem mode='ios' lines='none' color={'transparent'} > 
                    <IonInput mode='ios'  placeholder="Email" value={email} type="email" onIonChange={(e)=>{setEmaiil(e.detail.value!)}}></IonInput> 
                </IonItem>
              </div>  
            </IonCol>
            <IonCol size='12' className='ion-text-right' > <br/>
               <IonButton mode='ios' color={"primary"} >  <IonLabel>Update</IonLabel>  </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  </> )
}

interface TableDevices {
  headers: {
     col   : String 
     label : String
     id :   String
  }[]
  rows:{
    cols:{
      col   : String 
      label : String
      id :   String
    }[]
  }[]
}

const DeviceSettings=({setContent,setDeviceSetting}:any)=>{
  const [tables,setTableDevices] = useState<TableDevices>()
  const [devices,setDevices] = useState<any>()
  const [deviceEdit,setDeviceEdit] = useState<any>()
  const [modal,setModal] = useState(false)
  const [loading,setLoading] = useState(false)

  const [name,setName] = useState<any>("")
  const [deviceId,setDeviceId] = useState<any>(0)
  const [phoneNumber,setPhoneNumber] = useState<any>("")
  const [odometer,setOdometer] = useState<any>(0.0)
  const [expiration,setExpiration] = useState<any>(0.0)
  const [note , setNote] = useState<any>("") 
  const dispatch = useDispatch()
 

  useEffect(()=>{
    const getdevices=async()=>{
      setLoading(true)
      let device  = await allDevices({lang:"th"}) 
      setDevices(device)
      console.log("device table ",device)
      let table:TableDevices = await tableDevices(device)
      setTableDevices(table)
      console.log("table ", table)
      setLoading(false)
    }
    getdevices()
  },[])

  const editDevice=async(id:any)=>{ 
    let editing =await devices.find((e:any)=> e.deviceId === id)
    dispatch(setPreviewDevice({active:true , device: editing}))
    // console.log("editing ",editing)
    // setDeviceEdit(editing)
    // setName(editing?.name)
    // setDeviceId(editing?.deviceId)
    // setPhoneNumber(editing?.phoneNumber) 
    // setOdometer(editing?.odometer)
    // setExpiration(editing?.expire)
    // setNote(editing?.note)

    setTimeout(()=>{
      setModal(true)
    },500)
  }

  const saveEdit=()=>{  
    let body ={
      name: name ,
      deviceId: deviceId ,
      phoneNumber: phoneNumber ,
      odometer: odometer ,
      expiration: expiration ,
      note: note
    } 
    console.log("body save device ", body)
  }
  
 

  return(
  <IonGrid className='ion-margin' style={{fontSize:".8em" }} >
    <IonRow>
      <IonCol size='12' >
        <IonButton fill='clear'  mode='ios' onClick={()=>{ setContent(false);setDeviceSetting(false)}}>  
            <IonIcon icon={chevronBackOutline} />
            <IonLabel>ตั้งค่าอุปกรณ์</IonLabel> 
          </IonButton>
      </IonCol>
      <IonCol size='12' >
        { loading && <IonItem lines='none' className='ion-text-center' >
           <IonLabel >
               <IonSpinner name={"crescent"} /> &nbsp; {t(['loading'])}
           </IonLabel>
        </IonItem>}
        <div className='table' style={{width:"90%",marginLeft:"5%"}} > 
          <IonRow style={{backgroundColor:"#f2f2f2",borderRadius:"5px"}} > {
              tables?.headers.map((e:any, index:any)=> <IonCol key={index} size={e.col} className='set-center' > {e.label} </IonCol>)
          }</IonRow>
          <div style={{ height:"65vh" , overflowY:"scroll"}}>
          {
            tables?.rows.map((row:any,index:any)=>
            <IonRow key={index}  style={{backgroundColor: index%2 ? "#fdfdfd" : "#FFF",borderRadius:"5px"}} >
              {
                row?.cols.map((col:any , i:any)=> 
                <IonCol key={i} size={col.col} >  
                   {
                    col.id === "edit" ? <IonButton mode='ios' fill='clear' onClick={(e)=>{editDevice(col.deviceId)}} >
                      <FontAwesomeIcon icon={faPenToSquare}/>
                    </IonButton> :  col.label 
                   }
                </IonCol>
                )
              }
            </IonRow>
            )
          } 
          </div>
        </div>
      </IonCol>
    </IonRow>
    <ModalEditDevice openedit={modal}  setOpenEdit={(e:any)=>setModal(e)} />
    {/* <IonModal mode='ios' isOpen={modal} onIonModalDidDismiss={()=>{setModal(false)}} >
      <IonContent className='ion-padding' >
          <IonRow>
            <IonCol size='12' >
              <IonTitle> แก้ไขอุปกรณ์ </IonTitle>
            </IonCol>
          </IonRow> <br/>
          <IonRow style={{marginTop:'1rem'}}>
            <IonCol size="6" >
              <div className="input" >
                <IonInput mode="ios" placeholder="Device Name" value={name} onIonChange={(e)=>{setName(e.detail.value!)}} ></IonInput>
              </div>    
            </IonCol> 
            <IonCol size="6" >
                <div className="input" >
                   <IonInput mode="ios" placeholder="Device ID" type='number' value={deviceId} onIonChange={(e)=>{setDeviceId(e.detail.value!)}} ></IonInput>
                </div>    
            </IonCol>
            <IonCol size="6" >
                <div className="input" >
                   <IonInput mode="ios" placeholder="Phone Number" value={phoneNumber} onIonChange={(e)=>{setPhoneNumber(e.detail.value)}} ></IonInput>
                </div>    
            </IonCol>
            <IonCol size="6" >
                <div className="input" >
                   <IonInput mode="ios" placeholder="Odometer" value={odometer} onIonChange={(e)=>{setOdometer(e.detail.value!)}} ></IonInput>
                </div>    
            </IonCol>
            <IonCol size="6" >
                <div className="input" >
                   <IonInput mode="ios" placeholder="Driver name"  ></IonInput>
                </div>    
            </IonCol>
            <IonCol size="6" >
                <div className="input" >
                   <IonInput mode="ios" placeholder="Expiration Date" value={expiration} onIonChange={(e)=>{setExpiration(e.detail.value)}}  ></IonInput>
                </div>    
            </IonCol><IonCol size="12" >
                <div className="input" >
                    <IonTextarea mode="ios" placeholder="Note" style={{height:"5rem"}} value={note} onIonChange={(e)=>{setNote(e.detail.event!)}}  ></IonTextarea> 
                </div>    
            </IonCol>
            <IonCol size="12" > 
            </IonCol>
            </IonRow> 
            <IonRow>
              <IonCol size="12" className="ion-text-right" >
                  <IonButton size="small" color={"primary"} onClick={()=>{saveEdit()}}>
                      <IonLabel>Save</IonLabel>
                  </IonButton>
              </IonCol>
            </IonRow>
      </IonContent>
    </IonModal> */}
  </IonGrid>
  )
}

export const tableDevices=(table:any)=>{
  const headers = [
    { col:".5" ,label:"#" ,id:"id"},
    { col:"1" ,label: "แบรนด์" ,id:"brand"},
    { col:"1" ,label: "คัทซ๊" ,id:"chasis"},
    { col:"1.5" ,label: "หมดอายุ" ,id:"expire"},
    { col:"2.5" ,label: "ชื่อ" ,id:"name"},
    { col:"1.5" ,label: "ชื่อ2" ,id:"name2"},
    { col:"1" ,label: "หมายเลขโทรศัพท์" ,id:"phoneNumber"},
    { col:"1.5" ,label: "ประเภท" ,id:"type"},
    { col:"1" ,label: "เลขทะเบียน" ,id:"vehicleLicense"}, 
    { col:".5",label: "" ,id:"edit"}
  ]
  let rows = table.map((e:any,index:any)=>{
    return {
      cols: [
        { col:".5" ,  label: index+1 ,id:"id"},
        { col:"1" ,   label: e.brand  ,id:"brand"},
        { col:"1",  label: e.chasis  ,id:"chasis"},
        { col:"1.5" , label: e.expire? moment(e.expire).format("DD/MM/YYYY") :"-",id:"expire"},
        { col:"2.5" ,   label: e.name  ,id:"name"},
        { col:"1.5" , label: e.name2  ,id:"name2"},
        { col:"1" , label: e.phoneNumber  ,id:"phoneNumber"},
        { col:"1.5" ,   label: e.type  ,id:"type"},
        { col:"1" , label: e.vehicleLicense  ,id:"vehicleLicense"}, 
        { col:".5",label: "" ,id:"edit" , deviceId: e.deviceId}
      ]
    }
  })

  return {rows , headers}
}
 

const SettingGroupDevices=()=>{
  const { t, i18n } = useTranslation()
  const modalSetName = useRef<HTMLIonModalElement>(null);
  const [openShowname,setShowname] = useState(false)
  const gropdevice :any= useSelector(getIsGroupDevice)
  const [checked,setChecked] = useState(false)
  const dispatch = useDispatch()
  const [toast ] = useIonToast()

  const updateGroupDevice=async(isGroup:any)=>{  
    const res = await setGroupDevices({groupDevice: isGroup })
    if(res){
      toast({
          message: 'Update success!!',
          ...toastSuccess
      });
         
      setTimeout(async()=>{
        const settings = await userSetting()    
        console.log("setting ",settings)
        dispatch(setIsGroupDevice(settings.groupDevice))  
      },500)
      
  }else{
      toast({
        message: 'อัพเดตข้อมูลไม่สำเร็จ !!',
        ...toastFail
      });
  }   
  }

  useEffect(()=>{ setChecked(gropdevice) },[gropdevice,checked])
  const changeCheckToggle=(e:any)=>{
    console.log('e ',e)
    // setChecked(e)
    updateGroupDevice(e)
    setStorage('groupdevice',e)
  }

  return(<>
    <IonItem className='sub' color={"transparent"} lines='none' mode='ios' onClick={()=>{setShowname(true)}} >
      <IonLabel>จัดกลุ่มอุปกรณ์</IonLabel> 
      <IonIcon icon={chevronForward} color='medium' slot='end' />
    </IonItem>

    <IonModal id='setting-show-name'  ref={modalSetName} isOpen={openShowname} mode="ios"  initialBreakpoint={1}  onDidDismiss={()=>{setShowname(false)}}>
      <IonHeader mode='md' className='ion-no-border'>
        <IonToolbar mode='md'  className='ion-padding' onClick={()=>{setShowname(false)}}>
           <IonLabel color={'secondary'} className='toolbar-title' ><IonIcon icon={chevronBackOutline} />&nbsp;  จัดกลุ่มอุปกรณ์ </IonLabel> 
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonRow>
          <IonCol size='4.5' className='set-center' >
            <IonLabel>ไม่จัดกลุ่ม</IonLabel>
          </IonCol>
          <IonCol size='3' className='ion-text-center' >
            <IonToggle color={'secondary'} className='show-name-toggle' checked={gropdevice} onIonChange={(e)=>{changeCheckToggle(e.detail.checked)}}  >  </IonToggle>
          </IonCol>
          <IonCol size='4.5' className='set-center' >
            <IonLabel>จัดกลุ่ม</IonLabel>
          </IonCol>
        </IonRow>
        <IonRow  className='ion-justify-content-center'>
          <IonCol size='11' >
            <div style={{width:'100%',height:'10vh'}} >
             <MapGroupDevices />
            </div>
          </IonCol>
        </IonRow><br/>
        
      </IonContent>
    </IonModal>
  </>)
}



const SettingShowName=()=>{
  const { t, i18n } = useTranslation()
  const modalSetName = useRef<HTMLIonModalElement>(null);
  const [openShowname,setShowname] = useState(false)
  const showname :any= useSelector(getShowLicebse)
  const [checked,setChecked] = useState(false)
  const dispatch = useDispatch()
  const [toast] = useIonToast()

  const updateShowName=async(e:any)=>{
    const res = await setMarkerName({show: e?'show':'hide'})  
    if(res){
        toast({
            message: 'Update success!!',
            ...toastSuccess
        });
          
        setTimeout(async()=>{
          const settings = await userSetting()    
          console.log("setting ",settings)
          dispatch(setIsGroupDevice(settings.groupDevice)) 
          // setStorage('defaultTile',settings.defaultMap)
        },500)
        
      dispatch(setShowLicense(e))
      setStorage('showname',e)
    }else{
      toast({
        message: 'อัพเดตข้อมูลไม่สำเร็จ !!',
        duration: 3000,
        position: 'top',
        color:'warning'
      });
     }  
  }

  useEffect(()=>{ setChecked(showname) },[showname,checked])
  const changeCheckToggle=(e:any)=>{
    console.log('e ',e)
    // setChecked(e)
    updateShowName(e)
  }

  return(<>
    <IonItem className='sub' lines='none' color={"transparent"} mode='ios' onClick={()=>{setShowname(true)}} >
      <IonLabel>{t('settingShowName')}</IonLabel> 
      <IonIcon icon={chevronForward} color='medium' slot='end' />
    </IonItem>

    <IonModal id='setting-show-name'  ref={modalSetName} isOpen={openShowname} mode="ios"  initialBreakpoint={0.5}  onDidDismiss={()=>{setShowname(false)}}>
      <IonHeader mode='md' className='ion-no-border'>
        <IonToolbar mode='md'  className='ion-padding' onClick={()=>{setShowname(false)}}>
           <IonLabel color={'secondary'} className='toolbar-title' ><IonIcon icon={chevronBackOutline} />&nbsp; {t('settingShowName')}</IonLabel> 
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonRow className='ion-justify-content-center'>
          <IonCol size='11' >
             <MapShowName height={'12rem'} />
          </IonCol>
        </IonRow><br/>
        <IonRow>
          <IonCol size='4.5' className='set-center' >
            <IonLabel>ไม่แสดง</IonLabel>
          </IonCol>
          <IonCol size='3' className='ion-text-center' >
            <IonToggle color={'secondary'} className='show-name-toggle' checked={showname} onIonChange={(e)=>{changeCheckToggle(e.detail.checked)}}  >  </IonToggle>
          </IonCol>
          <IonCol size='4.5' className='set-center' >
            <IonLabel>แสดง</IonLabel>
          </IonCol>
        </IonRow>
        
      </IonContent>
    </IonModal>
  </>)
}
 


interface SelectOption {
  device_id:number 
  value: number 
  label: string
}
const SettingDeviceName2=()=>{
  const { t, i18n } = useTranslation()
  const [modal,setModal] = useState(false)
  const [name,setName] = useState("")
  const select:any = useSelector(getSearchSelect) 
  const dispatch = useDispatch()
  const [toast] = useIonToast(); 
  const [vehihle,setVehicle] = useState({device_id:0 ,value: 0,name:"Select"}) 
  const [optionsBack,setOptionBackup] = useState<SelectOption[]>([]) 

  const updateDeviceName=async()=>{
    let domain = await getStorage( 'domain');
    if(vehihle.value > 0){  
      const res = await setDeviceName({name2:name ,deviceId: vehihle.device_id}) 
      if(res){ 
          toast({
            message: 'Update device name success!!',
            ...toastSuccess
          });
          let result = await allDevices({}) //devicesAll({user_id: parseInt(user_id) }) 
          await dispatch(setDevices(result))
          await dispatch(setBackupDevices(result)) 
       }else{
        toast({
          message: 'อัพเดตข้อมูลไม่สำเร็จ !!',
          ...toastFail
        });
       }  
    }else{
      toast({
        message: 'กรุณาเลือกอุปกรณ์ !!',
        duration: 3000,
        position: 'top',
        color:'warning'
      });
    } 
  }

  useEffect(()=>{ 
    const getdevices=async()=>{
      let result = await allDevices({}) //devicesAll({user_id: parseInt(user_id) })
      console.log("result ",result)
      await dispatch(setDevices(result))
      await dispatch(setBackupDevices(result)) 
      let options = result.map((e:any)=> {return {value: e.device_id ,label: e.name, ...e } }) 
      setOptionBackup(options)
  }
  if(optionsBack.length <= 0){ getdevices() }

    if(select && select?.name2){ 
      setName(select?.name2)
    }else if(select && !select?.name2){
      setName(select.label)
    }else{
      setName("")
    }
    var url = new URL(window.location.href);
    var settingname = url.searchParams.get("settingname"); 
    if(settingname){
      setModal(true)
    }
  },[])

  return( <>
      <IonItem className='sub' lines='none' color={"transparent"} mode='ios'  onClick={()=>{setModal(true)}} > 
        <IonLabel color='dark'> ตั้งค่าชื่ออุปกรณ์ </IonLabel> 
        <IonIcon color='medium' icon={chevronForward} style={{fontSize: '1em'}} />
      </IonItem>
      <IonModal isOpen={modal} mode='ios' onDidDismiss={() => setModal(false)}  >
       
        <IonContent className='ion-padding' color={"light"} >
        <IonHeader className='ion-no-border'>
           <IonToolbar color={"transparent"} > 
              <IonTitle color={"dark"} slot='start'> ตั้งค่าชื่ออุปกรณ์ </IonTitle> 
              <IonButton size='small' color={'secondary'} slot='end' onClick={()=>{setModal(false)}} > <IonIcon icon={closeCircle}  /> </IonButton>  
           </IonToolbar> 
        </IonHeader>
          <IonGrid>
            <IonRow> 
              <IonCol size='12'>
                  {/* <ListDevices />  */}
                  <DropDownDevice  
                    vehihle={vehihle}  
                    setVehicle={(e:any)=>{setVehicle(e);  setName(e?.name2 ? e?.name2 :"")   }}
                    optionsBack={optionsBack} 
                  /> 
              </IonCol>
              <IonCol size='12'>
                <div className='input' >
                  <IonInput color={"transparent"} value={name} placeholder="Device second name" 
                  onIonInput={(e)=>{ setName(e.detail.value!)}}
                  onIonChange={(e)=>{ setName(e.detail.value!)}}></IonInput>  
                </div>
              </IonCol> 
              <IonCol size='12'><br/>
                <IonButton expand='block' mode="ios" onClick={()=>{updateDeviceName()}} > {t("update")} </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    </>
  )
}