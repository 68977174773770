import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonPage, IonRippleEffect, IonRow, IonSegment, IonSegmentButton } from "@ionic/react"
import SetRouteMap from "../leaflet/SetRouteMap"; 
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { addCircle, addCircleOutline, chevronBackOutline, chevronForward, list, pencilOutline, trashBin, trashBinOutline, trashSharp } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { getRouteSet, getRouteDraw, setRouteDraw, setRouteSet } from "../store/mapSlice";
import { addRouteSet, allRoutesSet, calDistance, deleteSetRoute, editSetRoute } from "../actions";
import { PopupSubmenu } from "../components/Menu";
import { getMenuOpen } from "../store/appSlice";
import toast, { Toaster, resolveValue } from 'react-hot-toast';
import "./css/SuggestRoute.css"
import { Map as MapType} from "leaflet";
import { t } from "i18next";
import {Swiper as SwiperType}  from "swiper";
import NavBar from "../components/NavBar";

interface RouteSetType {
    id : Number
    name : String
    acceptDistance :Number
    allDistance : Number
    route : Number[][]
    
}

const SetRoute: React.FC =()=>{
 const [segmant,setSegment] = useState("list")
 const [swiper,setSwiper] = useState<SwiperType|null>(null)
 const [routes,setRoutes] = useState<RouteSetType[]>([])
 const [accept,setAccept] = useState(200)
 const [distance,setDistance] = useState(0)
 const routedraw :any= useSelector(getRouteDraw)
 const [name,setName] = useState("")
 const [distanceall,setDistanceAll] = useState(0)
 const routeset :any= useSelector(getRouteSet)
 const dispatch = useDispatch()
 const menuOpen :any= useSelector(getMenuOpen) 
 const [mapref , setMapRef] = useState<MapType|null>(null)
 const [loading,setLoading] = useState(false)
 
 const getroute=async()=>{
    let res = await allRoutesSet()
    console.log("allRoutesSet res ",res)
    setRoutes(res)
}

 useEffect(()=>{  
    getroute()

    return(()=>{
        // dispatch(setRouteSet(null))
        setName("") 
        setAccept(200)
        setDistanceAll(0) 
        swiper?.slideTo(0)
        setSegment("list")
    })
 },[])

 const selectedList=(route:RouteSetType|any)=>{
    dispatch(setRouteSet(route))
    setName(route?.name) 
    setAccept(route?.acceptDistance)
    setDistanceAll(route?.allDistance) 
 }
 
 const changeSegmant=(value:any)=>{
    setSegment(value)
    if(value ==="list"){ 
        swiper?.slideTo(0)
        console.log("swiper ",swiper)
    }else if(value ==="add"){  
        swiper?.slideTo(1)
        console.log("swiper ",swiper)
    }else{ 
       swiper?.slideTo(2)
       if(routeset === null){
        toast.error(`กรุณาเลือกเส้่นทางเพื่อแก้ไข`,{id:"no-routeset-fail"})
        setTimeout(()=>{ swiper?.slideTo(0) ;  setSegment("list") },1000)
       }
       console.log("swiper ",swiper) 
    }
 }

 const addWithLatLon=(val:any)=>{ 
    let update:any[]  = routedraw
    mapref?.setView([ val?.lat,val?.lon] , 17)
    if(routedraw.length > 0){ 
        let lstDraw = routedraw[routedraw.length-1] 
       
        let cord1 = lstDraw
        let cord2 = {   lat : val?.lat,   lng : val?.lon  }
       let lastDistane =   calDistance(cord1 ,cord2)
       console.log("lastDistane ",lastDistane)
       let km = Math.round(lastDistane  * 1609.344); 
 
      if(km >= Number(accept)){
        toast.error(` ${km} เมตร เกินระยะทาง ${accept} เมตรที่ยอมรับได้ `,{id:"accept-fail"})
      }else{   
        update = [...update ,{lat: val?.lat , lng:val?.lon} ]
        dispatch(setRouteDraw(update))
      } 
     }else{
        update = [...update ,{lat: val?.lat , lng:val?.lon} ]
        dispatch(setRouteDraw(update))
     }
    }

    const addNewRouteSet=async()=>{
        setLoading(true)
        let routepointstr =""
        let routepoints:any[] = []
        let routepointsorder:any[] = []
        console.log("addNewRouteSet routedraw",routedraw)
        await routedraw.map((e:{lat:any,lng:any}, index:any)=>{
            routepointstr += `${e.lat},${e.lng}#`
            routepoints = [...routepoints , [e.lat,e.lng]]
            routepointsorder = [...routepointsorder , {lat:e.lat ,lon:e.lng ,order: index+1 }]
        })

        const body= {  
            name: name , 
            acceptDistance: accept , //meter 
            allDistance: Number(distanceall), //km 
            route:routepointsorder,
            routeStr:routepointstr, 
        }
        console.log("addNewRouteSet body ",body)
        const res = await addRouteSet(body)
        if(res.result){
          dispatch(setRouteDraw([]))  
          toast.success(`เพิ่มเส้นทาง ${name} สำเร็จ !!!`);
          changeSegmant("list")
          getroute()
          setLoading(false)
        }else{
           toast.error(`เพิ่มเส้นทาง ${name} ไม่สำเร็จ !!!`);
           setLoading(false)
        }
    }

    const editUpdateRoute=async()=>{
        setLoading(true) 

        const body= {  
            id: routeset?.id ,
            name: routeset?.name , 
            acceptDistance: routeset?.acceptDistance , //meter 
            allDistance: routeset?.allDistance, //km 
            route:routeset?.route ,
            routeStr:'', 
        }
        console.log("addNewRouteSet body ",body)
       let res = await editSetRoute(body)
       if(res.result){
            dispatch(setRouteDraw([]))  
            dispatch(setRouteSet(null))  
            toast.success(`แก้ไขเส้นทาง ${routeset?.name} สำเร็จ !!!`);
            changeSegmant("list")
            getroute()
            setLoading(false)
       }else{
        toast.error(`แก้ไขเส้นทาง ${routeset?.name} ไม่สำเร็จ !!!`);
        setLoading(false)
       }
    setLoading(false)
    }

    const deleteroute=async(body:any)=>{
        setLoading(true)
        let res = await deleteSetRoute(body)
        if(res){
            toast.success(`ลบเส้นทาง ${body?.name} สำเร็จ !!!`);
            getroute()
            setLoading(false)
        }else{
            toast.error(`ลบเส้นทาง ${body?.name} ไม่สำเร็จ !!!`);
            setLoading(false)
           }
    }

 return(<IonPage>  
    <IonContent fullscreen   className="contents"> 
        <IonLoading isOpen={loading} message={t("loading").toString()} onIonLoadingWillDismiss={()=>{setLoading(false)}} />
            <IonGrid>
                <IonRow  >
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="3" style={{padding:'.5rem'}} >
                        <div className="set-route-container" >
                            <IonHeader mode="ios" className="ion-no-border" style={{borderBottom:'1px solid #DDD'}}>
                                <h2 style={{marginBottom:'0px'}}><IonLabel><strong>กำหนดเส้นทางการเดินทาง</strong></IonLabel></h2>
                                <small>วาดเส้นทางการเดินทาง</small>
                                <br/><br/> 

                                <IonSegment value={segmant} style={{maxWidth:'100%'}} onIonChange={(e)=>{changeSegmant(e.detail.value)}} >
                                    <IonSegmentButton value="list" className="set-center-row" style={{padding: ".3rem",}}> 
                                        <IonIcon icon={list} style={{fontSize:"1.5em"}} />
                                        <IonLabel>รายการเส้นทาง</IonLabel>
                                    </IonSegmentButton> 
                                    <IonSegmentButton value="add" className="set-center-row" style={{padding: ".3rem",}}> 
                                       <IonIcon icon={addCircleOutline} style={{fontSize:"1.5em"}} />
                                        <IonLabel>เพิ่มเส้นทาง</IonLabel>
                                    </IonSegmentButton> 
                                    <IonSegmentButton value="edit" className="set-center-row" style={{padding: ".3rem",}}> 
                                        <IonIcon icon={pencilOutline} style={{fontSize:"1.5em"}} />
                                        <IonLabel>แก้ไขเส้นทาง</IonLabel>
                                    </IonSegmentButton> 
                                </IonSegment><br/>  
                            </IonHeader><br/> 
                            <div className="content" >
                            <Swiper  
                                    draggable={false} 
                                    onSlideChange={(e) => console.log('slide change',e)}
                                    onSwiper={(swiper:any) => {setSwiper(swiper);console.log(swiper)}}
                                    allowTouchMove={false} 
                                >
                                    <SwiperSlide>
                                        <div  >
                                            {routes.map((route:any,index:any)=>
                                                <RoutesList 
                                                route={route} key={index} 
                                                select={(e:any)=>selectedList(e)} 
                                                setSegment={(e:any)=>{setSegment(e)}}
                                                deleteroute={(e:any)=>{ deleteroute(route)}}
                                                />
                                            )}
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <AddRoute 
                                            routeName={""} 
                                            acceptDistance={accept}  
                                            setRouteName={(e:any)=>{return setName(e)}} 
                                            setAcceptDis={(e:any)=>{return setAccept(e)}} 
                                            addWithLatLon={(e:any)=>{addWithLatLon(e)}} 
                                            setDistanceAll={(e:any)=>{ setDistanceAll(e)}} 
                                            distanceAll={distance} 
                                            addNewRouteSet={()=>{addNewRouteSet()}}
                                        />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <EditRoute  route={routeset} 
                                        setAcceptDis={(e:any)=>{return setAccept(e)}}  
                                        setRouteName={(e:any)=>{return setName(e)}} 
                                        setDistanceAll={(e:any)=>{return setDistanceAll(e)}}
                                        setSegment={(e:any)=>{setSegment(e)}} 
                                        editUpdateRoute={()=>{editUpdateRoute()}}
                                        />
                                    </SwiperSlide>
                            </Swiper>
                            </div>
                        </div>
                    </IonCol>
                    <IonCol  sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="9" >
                        <SetRouteMap 
                            isAdd={segmant==="add"?true:false}  
                            accept={accept} 
                            cratemapref={(mapref:any)=>{console.log("cratemapref ",mapref);setMapRef(mapref)}}
                        />
                    </IonCol>
                </IonRow>
            </IonGrid> 
        </IonContent>


        <PopupSubmenu />  
    </IonPage>)
}
export default SetRoute;
 

const RoutesList=({route,select,setSegment,deleteroute}:any)=>{
    const swiper = useSwiper()

    const routenameicon=(name:any)=>{
        return name[0]
    }
    const selectRoute=()=>{
       return select(route)
    }

    return(  
        <div  className="wrapper routes-list" >
            <div className=" ion-activatable rounded-rectangle">
            <IonRow >
                <IonCol size="3" className="set-center" style={{padding:'.5rem', justifyContent:"flex-start"}}>
                    <div className="icon-route-name set-center"  >{routenameicon(route.name)}</div> 
                    <IonButton  size="small" mode="ios"  color={"danger"} style={{marginTop:"-18%"}} onClick={()=>{deleteroute()}} > 
                      <IonIcon  icon={trashSharp}   />
                    </IonButton>
                </IonCol>
                <IonCol size="7" >
                    <IonLabel><strong>{route.name}</strong></IonLabel> <br/>
                    <IonLabel ><small>ระยะทาง {(route.allDistance/1000).toFixed(2)} ก.ม. </small></IonLabel>
                </IonCol> 
                <IonCol size="2" className="set-center" >
                    <IonButton size="small" mode="ios" fill="clear"  onClick={()=>{swiper.slideTo(2) ; selectRoute(); setSegment("edit") }} > 
                       <IonIcon  icon={chevronForward} color="medium" />
                    </IonButton> 
                </IonCol>
                
            </IonRow> 
            {/* <IonRippleEffect></IonRippleEffect> */}
            </div>
       </div>
    )
}

const AddRoute=({routeName,acceptDistance,distanceAll,setAcceptDis ,addWithLatLon,addNewRouteSet,setRouteName,setDistanceAll}:any)=>{ 
    const routeDraw :any= useSelector(getRouteDraw)
    const [name,setName] = useState(routeName)
    const [accept,setAccept] = useState(acceptDistance)
    const [distance,setDistance] = useState(distanceAll)
    const [lat,setLat] = useState(null)
    const [lon,setLon] = useState(null)

    const clearall=()=>{
        setName("")
        setAccept(200)
        setDistance(0)
        setDistanceAll(0)
    }

    useEffect(()=>{
        const calRouteDiatnce=async()=>{
            let distanceall = 0
            await routeDraw.map((el:any,index:any)=>{
                if(index < routeDraw.length -1){
                    distanceall += calDistance(el,routeDraw[index+1]) 
                } 
            }) 
            setDistance((distanceall * 1609.344).toFixed(2))
            setDistanceAll((distanceall * 1609.344).toFixed(2))
        }
        calRouteDiatnce()
    },[routeDraw])

    return(<div className="add-route-content" >
        <IonGrid>
            <IonRow>
                <IonCol size="12" >
                    <IonLabel><small>ชื่อเส้นทาง</small></IonLabel>
                    <div className="input"  >
                        <IonInput mode="ios" value={name} type="text" placeholder="ชื่อเส้นทาง" onIonChange={(e)=>{setName(e.detail.value);setRouteName(e.detail.value);}} ></IonInput>
                    </div>
                </IonCol>
                <IonCol size="12" >
                    <IonLabel><small>ระยะทางที่ยอมรับได้</small></IonLabel>
                    <div className="input" style={{position:'relative'}} >
                        <IonInput mode="ios" value={accept} type="number" placeholder="ระยะทางที่ยอมรับได้" 
                          onIonInput={(e)=>{setAccept(e.detail.value); return setAcceptDis(e.detail.value)}}
                        ></IonInput>
                        <div  className="input-unit set-center" >
                            <IonLabel>เมตร</IonLabel>
                        </div>
                    </div>
                </IonCol>
                <IonCol size="12" >
                    <IonLabel><small>ระยะทางที่วัดได้</small></IonLabel>
                    <div className="input"  style={{position:'relative'}}  >
                        <IonInput mode="ios" value={distance} type="number" disabled placeholder="ระยะทางที่วัดได้" ></IonInput>
                        <div  className="input-unit set-center" >
                            <IonLabel>เมตร</IonLabel>
                        </div>
                    </div>
                </IonCol>

                <IonCol size="12" >
                    <IonLabel><small>เพิ่มจุดถัดไปด้วย Lat , Lon</small></IonLabel>
                    <IonRow>
                        <IonCol size="11" > 
                            <div className="input set-center-row"     >
                                <IonInput mode="ios" value={lat} type="number"   placeholder="Lat" onIonChange={(e:any)=>{setLat(e.detail.value!)}}></IonInput> 
                                <div  className="input-unit small  set-center" >
                                    <IonLabel><small>Lat</small></IonLabel>
                                </div>
                            </div> 
                            <div className="input set-center-row"     >
                                <IonInput mode="ios" value={lon} type="number"   placeholder="Lon" onIonChange={(e:any)=>{setLon(e.detail.value!)}} ></IonInput> 
                                <div  className="input-unit small set-center" >
                                    <IonLabel><small>Lon</small></IonLabel>
                                </div>
                            </div>
                        </IonCol>  
                        <IonCol size="1" className="set-center" > 
                            <IonButton size="small" mode="ios" fill="clear" onClick={()=>{ addWithLatLon({lat:lat ,lon:lon}); setLat(null);setLon(null) }} > <IonIcon icon={addCircle} /> </IonButton>
                        </IonCol>
                    </IonRow>
                </IonCol>
                <IonCol size="12" >
                    <IonButton mode="ios" onClick={()=>{clearall(); return addNewRouteSet()}} >
                        <IonLabel>Add</IonLabel>
                    </IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    </div>)
}


const EditRoute=({route,setRouteName,setAcceptDis,setDistanceAll,setSegment,editUpdateRoute}:any)=>{  
    const [name,setName] = useState(route?.name)
    const [accept,setAccept] = useState(route?.radius)
    const [distance,setDistance] = useState(route?.distance) 
    const routeset :any= useSelector(getRouteSet)
    const swiper = useSwiper()
    const dispatch = useDispatch()
   

    useEffect(()=>{
        if(routeset){ 
            setName(routeset?.name)
            setAccept(routeset?.acceptDistance)
            setDistance(routeset?.allDistance)
        } 
        return(()=>{
            clearseslectroute()
        })
    },[routeset])
    const updatename=(name:any)=>{
        let update = routeset
        update = {...update, ...{name:name}}
        dispatch(setRouteSet(update))
    }
    const updateaccept=(accept:any)=>{
        let update = routeset
        update = {...update, ...{acceptDistance:Number(accept)}}
        dispatch(setRouteSet(update))
    }

    const clearseslectroute=()=>{
        setName("")
        setAccept("")
        setDistance("")
    }
 
    return(<div className="add-route-content" >
        <IonGrid>
            <IonRow>
                <IonCol size="12" >  
                    <IonButton fill="clear" color={"dark"} size="small" mode="ios" onClick={()=>{swiper.slideTo(0); dispatch(setRouteSet(null));return setSegment("list"); }} >
                        <IonIcon icon={chevronBackOutline} />
                        <IonLabel>Back</IonLabel>
                    </IonButton>
                </IonCol>
                <IonCol size="12" >
                    <IonLabel><small>ชื่อเส้นทาง</small></IonLabel>
                    <div className="input"  >
                        <IonInput mode="ios" value={name} type="text" placeholder="ชื่อเส้นทาง" 
                          onIonInput={(e)=>{setName(e.detail.value);updatename(e.detail.value);return setRouteName(e.detail.value)}}
                        ></IonInput>
                    </div>
                </IonCol>
                <IonCol size="12" >
                    <IonLabel><small>ระยะทางที่ยอมรับได้</small></IonLabel>
                    <div className="input" style={{position:'relative'}} >
                        <IonInput mode="ios" value={accept} type="number" placeholder="ระยะทางที่ยอมรับได้" 
                          onIonInput={(e)=>{setAccept(e.detail.value);updateaccept(e.detail.value); return setAcceptDis(e.detail.value)}}
                        ></IonInput>
                        <div  className="input-unit set-center" >
                            <IonLabel>เมตร</IonLabel>
                        </div>
                    </div>
                </IonCol>
                <IonCol size="12" >
                    <IonLabel><small>ระยะทางที่วัดได้</small></IonLabel>
                    <div className="input"  style={{position:'relative'}}  >
                        <IonInput mode="ios" value={distance} type="number" disabled placeholder="ระยะทางที่วัดได้"   ></IonInput>
                        <div  className="input-unit set-center" >
                            <IonLabel>เมตร</IonLabel>
                        </div>
                    </div>
                </IonCol>
                <IonCol size="12" >
                    <IonButton mode="ios" onClick={()=>{clearseslectroute();editUpdateRoute()}} >
                        <IonLabel>Update</IonLabel>
                    </IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    </div>)
} 