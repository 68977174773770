import { faGasPump, faPhone, faRoute, faRuler } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButton, IonCardSubtitle, IonCardTitle,   IonCol, IonContent, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonModal, IonPopover, IonRadio, IonRadioGroup, IonRippleEffect, IonRow, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonText, useIonAlert, useIonToast } from "@ionic/react"
import { shareSocialOutline, chatbubbleOutline, videocamOutline, terminalOutline, closeCircle,   trashBin, location, locationOutline, settingsOutline, logoFacebook, logoTwitter, logoTwitch, lockClosed, lockOpen, callOutline, chatboxEllipses, chatboxEllipsesOutline, navigateCircleOutline, navigateOutline, compassOutline, copyOutline, ellipseSharp, addCircleOutline, addCircle, cloudUploadOutline, trashBinOutline, removeCircleOutline } from "ionicons/icons"
import { useEffect, useRef, useState } from "react"
import { IconRouteToday } from "./AppComponent" 
import { allDevices, byIdType, cancleShutdown, commandSend, cutengine, deviceIconId, distanceCalulate, getStorage,   iconType,   setDelDeviceImage,   setDeviceIcon, setDeviceImage, setGroupDevices, settingDetailById, toastFail, toastSuccess, userSetting } from "../actions"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import {  getDeviceModal, getDeviceModalOpt, getDeviceSelect, getNotifierMessages, getSearchSelect,   setBackupDevices,   setDevice, setDeviceModal, setDeviceModalOpt, setDevices, setIsGroupDevice, setSharePop } from "../store/appSlice"


import "./css/CardDevice.css"
import { getMessage, getNavigation, getPhoneNumber, getQuickTrack, getShare } from "../store/filterSlice"
import { useSwiper } from "swiper/react"
import axios from "axios"
import i18next, { t } from "i18next"
import { useHistory } from "react-router"
import { Tabs, TabList, Tab, TabPanel } from "react-tabs"
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { PreveiewDevice } from "../pages/schema"
import { getPreviewDevice, setPreviewDevice } from "../store/mapSlice"
import toast, { Toaster, resolveValue } from 'react-hot-toast';

export const OptionDialog=({device}:any)=>{ 
    const history = useHistory()
    
    const share :any= useSelector(getShare)
    const message :any= useSelector(getMessage)
    const quickTrack :any= useSelector(getQuickTrack)
    const phoneNumber :any= useSelector(getPhoneNumber)
    const canNavigation :any= useSelector(getNavigation)
    const notiMessage:any[] = useSelector(getNotifierMessages);
    const swiper = useSwiper() 
    const dispatch = useDispatch()
    const [messages ,setMessages] = useState<any[]>([])
    const urlPattern = /https?:\/\/[^\s/$.?#].[^\s]*/gi;
 
    useEffect(()=>{ 
        messageDevice()
    },[])

    const setIsOpen=(target:boolean)=>{
        dispatch(setDevice(device))
        dispatch(setDeviceModal(target))
    } 

    const navigationToDevice=(latitude:any,longitude:any)=>{
        window.open(
         "https://www.google.com/maps/dir/?api=1&destination="+latitude+","+longitude , '_blank'
        )
    }
 
    const openDialog=(option:any)=>{ 
        dispatch(setDeviceModalOpt(option))
        dispatch(setDevice(device))
        setTimeout(()=>{
          dispatch(setDeviceModal(true))
        },100)
      }
    const copyurl=async ()=>{
        const profile:any = await getStorage("profile")
        const shareurl = profile?.url_realtime + btoa(device?.device_id) 
        navigator.clipboard.writeText( shareurl);
    }
      
    const messageDevice=()=>{ 
        if(notiMessage && notiMessage.length >0){
            let asdevice = notiMessage.filter((e:any)=> e.message.indexOf(device?.name) > -1 )
            setMessages(asdevice) 
        }
    }
    return( 
       <IonCol size="8"  >  
          <IonRow>
            {share.card &&<IonCol   className="set-center">
                <IonLabel className="tooltip"  >   
                    <IonButton size="small" fill="clear" color={"secondary"} onClick={()=>{copyurl()}} >
                        <IonIcon icon={copyOutline}  className="icon-opt" />
                    </IonButton>  <span className="tooltiptext"> 
                     คัดลอกลิงค์  
                    </span>
                </IonLabel>  
             </IonCol>}

             <IonCol   className="set-center" >
                <IonLabel className="tooltip"  >   
                {messages.length > 0 && 
                <IonIcon style={{fontSize:".7em",position:"absolute",right:"2px",zIndex:9999,top:"10px"}} icon={ellipseSharp} color="danger" />}
                    <IonIcon 
                        icon={chatboxEllipsesOutline}  
                        onClick={()=>{openDialog("message");setIsOpen(true);}}  
                        className="icon-opt" style={{fontSize:"1.4em" }} 
                    /> <span className="tooltiptext"> 
                    ข้อความจากอุปกรณ์  
                   </span>
                </IonLabel>
             </IonCol> 

             {device.canCutEngin===1&&<IonCol   className="set-center"> 
                <IonIcon 
                    icon={terminalOutline}  
                    color={device.deviceisCutEngin===1?"danger":"success"}
                    onClick={()=>{openDialog("cmd");setIsOpen(true);}}  
                    className="icon-opt" style={{fontSize:"1.4em" }}
                />  
             </IonCol>}
             <IonCol   className="set-center" > 
               <IonLabel className="tooltip"  >   
                    <IonLabel  className="icon-opt"  > <FontAwesomeIcon icon={faGasPump} /></IonLabel>
                    <span className="tooltiptext"> 
                        {device?.fuel_liters}  
                   </span>
               </IonLabel>
             </IonCol> 

             {phoneNumber.card && device.phone_number && device?.phone_number != "-" && <IonCol   className="set-center" >
                <IonLabel className="tooltip">  
                    {device.phone_number.length>0 &&
                    <IonIcon 
                    icon={callOutline}  
                    className="icon-opt" 
                    style={{fontSize:"1.35em" }} 
                    onClick={()=>{ window.open("tel:"+device.phone_number,"_self")}}
                    />
                    } 
                    <div className="tooltiptext">{device.phone_number}</div>
                </IonLabel> 
             </IonCol>}
            <IonCol size="1.5"  className="set-center " >
                <IonLabel className="tooltip"  >   
                    <IonIcon icon={navigateOutline}  
                        onClick={()=> {navigationToDevice(device.latitude,device.longitude)}}
                        className="icon-opt" 
                        style={{fontSize:"1.5em" }}
                    />
                      <div className="tooltiptext"> นำทางไปยังรถ </div>
                </IonLabel>
             </IonCol> 
             <IonCol size="1.5" className="set-center"  > 
              
             </IonCol>
             {/* <IonCol size="1.5" className="set-center"  > 
                <DestinationDevice deviceid={device.device_id} vehicle={device.name} lat={device.latitude} lng={device.longitude} />
             </IonCol>   */}
             <IonCol   className="set-center" >
                <IonLabel className="tooltip"  >   
                    <IconRouteToday  device={device} swiper={swiper} ischip={false}/> 
                    <div className="tooltiptext"> เส้นทางวันนี้ </div>
                </IonLabel>
             </IonCol>  

          </IonRow> 
      </IonCol> 
    )
}

const DestinationDevice=({deviceid,vehicle,lat,lng}:any)=>{
    const [lattitude,setLattitude] = useState("")
    const [longitude,setLongitude] = useState("")
    
    useEffect(()=>{

    },[])

    const searchRoute=()=>{
        let link =  `https://www.google.com/maps/dir/?api=1&origin=${lat},${lng}&destination=${lattitude },${longitude}`  
        window.open(link,"_blank")
    }
 

    return(
    <IonLabel className="icon-opt" id={'triger-'+deviceid} > 
        {/* <FontAwesomeIcon icon={faRoute} />  */}
        <IonIcon icon={compassOutline} style={{fontSize:"1.2em"}}  />  
        <IonPopover mode="ios" trigger={'triger-'+deviceid} >
            <IonContent className="ion-padding" >
                <strong className="ion-margin-bottom">{vehicle}</strong>


                <div className="input"  >
                    <IonInput mode="ios" value={lattitude} onIonChange={(e)=>{ setLattitude(e.detail.value!) }} onIonInput={(e)=>{ setLattitude(e.detail.value!) }} placeholder="Lattitude" ></IonInput>
                </div>
                <div className="input" >
                    <IonInput mode="ios" value={longitude} placeholder="Longitude"  onIonChange={(e)=>{ setLongitude(e.detail.value!) }} onIonInput={(e)=>{ setLongitude(e.detail.value!) }}  ></IonInput>
                </div>

                <IonButton mode="ios" expand="block"  onClick={()=>{searchRoute()}} >  
                    <IonLabel > ค้นหา </IonLabel>     
                </IonButton>
               
            </IonContent>
        </IonPopover> 
   </IonLabel> 
    )
}

export const DialogDevice=()=>{
    const modal = useRef<HTMLIonModalElement>(null);
    const isOpen  :any= useSelector(getDeviceModal)
    const option  :any= useSelector(getDeviceModalOpt)  
    const device :any= useSelector(getDeviceSelect)
    const [tabs,setTabs] = useState(0)

    const dispatch = useDispatch()
    const dismiss=()=> { 
        setIsOpen(false)  
    }
    const setIsOpen=(target:boolean)=>{
        dispatch(setDeviceModal(target))
    }
    const setOption=(opt:string)=>{
        dispatch(setDeviceModalOpt(opt))
    }

    useEffect(()=>{  
        if(option === "message"){
            setTabs(0)
        }else{ setTabs(1) }
    },[option,isOpen])

    return(
        <>
        {device!==null  ?   
        <IonModal id="option-modal" onDidDismiss={()=>setIsOpen(false)} isOpen={isOpen} ref={modal} >
        <IonContent>
        <IonIcon icon={closeCircle} style={{cursor:"pointer",position:'absolute',top:'1rem',right:'1rem',zIndex:9999}} color='primary' onClick={(e)=>dismiss()}/>
        <div className="wrapper">
          <IonRow>
              <IonCol size="10" >
                  <IonCardTitle className="title">Device Options</IonCardTitle>
                  <IonCardSubtitle className="sub-title"> {device?.name}</IonCardSubtitle>
              </IonCol> 
          </IonRow> <br/>
          
        <IonRow>
            <IonCol size="12" >
            <Tabs className={"device-modal-tabs"} selectedIndex={tabs}  onSelect={(index) => setTabs(index)} >
                <TabList>
                    <Tab> ข้อความวันนี้ </Tab>
                    {device?.canCutEngin && <Tab> คำสั่ง </Tab> }
                </TabList>
            <TabPanel> 
                <MessageContent device={device} />
            </TabPanel>
            {device?.canCutEngin && <TabPanel> 
               <CMDContent deviceId={device.device_id} stsEngine={device.status_engin} vehicle={device?.name} />
            </TabPanel> }
            </Tabs> 

            </IonCol>
        </IonRow>
 
        </div>
        </IonContent>
      </IonModal>:<></>
       }
      </>
    )
}


const MessageContent=({device}:any)=>{
    const notiMessage:any[] = useSelector(getNotifierMessages);
    const [messages ,setMessages] = useState<any[]>([])
    const urlPattern = /https?:\/\/[^\s/$.?#].[^\s]*/gi;
    const messageDevice=()=>{
        if(notiMessage && notiMessage.length >0){
            console.log("notiMessage ", notiMessage)
            let asdevice = notiMessage.filter((e:any)=> e.message.indexOf(device?.name) > -1 )
            setMessages(asdevice)
            console.log("asdevice ", asdevice)
        }
    }
    useEffect(()=>{
        console.log("MessageContent ", device)
        messageDevice()
    },[])

    function extractUrls(text:any) {
        const urlPattern = /https?:\/\/[^\s/$.?#].[^\s]*/gi;
        const matches = text.match(urlPattern); 
          return  <small  className="ion-text-wrap"> &nbsp;&nbsp;&nbsp;&nbsp;{matches? text.replace(matches,''):text} {matches&& <a target="_blank" href={matches}>View on Google Map</a>}</small>
    }
 

    return(
        <div className="message-content">
            {/* <DateRange/>
            <IonRow className="ion-justify-content-end" > 
                <IonCol sizeSm="12" sizeMd="2" sizeXl="3" > <IonButton mode="ios" size="small">Search</IonButton> </IonCol>
            </IonRow>  */} 
            <div className="messages-list"  >
                <IonList >
                    {
                    messages.map((e:any,index)=>
                    <IonItem key={index}> 
                        <IonLabel >
                            <IonLabel color={"dark"} className="set-center-row"  >
                                <IonText><strong>{e.title}</strong></IonText> 
                            </IonLabel>  
                            {extractUrls(e.message)}
                        </IonLabel>
                        <IonIcon icon={locationOutline} slot='end'/>
                    </IonItem>
                    )}
                    
                </IonList>
            </div>
        </div>
    )
}

const CMDContent=({deviceId,stsEngine,vehicle}:any)=>{ 
    const [engineSts,setEngineSts] = useState("resume") 
    const [alert] = useIonAlert();
    const [toast] = useIonToast();
    const dispatch = useDispatch()
     
    useEffect(()=>{ 
        if(stsEngine===1){
            setEngineSts("stop")
        }else{
            setEngineSts("resume") 
        }
     },[])

     const sendShutDown=async(pass:any)=>{
        const passLocal = getStorage("password") 
        if(pass !== passLocal){
            toast({
                message: 'รหัสผ่านไม่ถูกต้อง !',
                duration: 3000,
                position: "top",
                color:"warning"
            })
        }else{
            const userId = getStorage("userId") 
            let res = await cutengine({ 
                password: pass,
                userId: userId , 
                language: i18next.language,  
                deviceId: deviceId,
            })
            if(res){
                toast({
                    message: 'ส่งคำสั่งสำเร็จ ใช้เวลาสักครู่เพื่อประมวลผลคำสั่ง...', 
                    ...toastSuccess
                })  
            }else{
                toast({
                    message: 'ส่งคำสั่งไม่สำเร็จ',
                    ...toastFail
                })  
            }
        }
    }

     const sendCmd=()=>{
        if(engineSts === "stop"){
            alert({  
                header:  "Shutdown Engine"  ,
                subHeader: vehicle,
                mode:'ios' ,
                inputs:[{
                    name: 'password',
                    type: 'password',
                    placeholder: t("signinPassword")
                }],
                buttons:[{
                        text: t("cancel"),
                        role: 'cancel',
                        cssClass: 'secondary', 
                    },
                    {
                        text: t("ok"),
                        handler: (data:any) => { 
                            sendShutDown(data.password) 
                    }
                }] 
            })
        }else{
            sendCancleShutDown()
        } 
    }
    const sendCancleShutDown=async()=>{  
        const userID = await getStorage("userId")
        const result = await cancleShutdown({userID,device_id:deviceId})
		 
        if(result){ 
            toast({
                message: 'ยกเลิกคำสั่งสำเร็จ !!',
                ...toastSuccess
            })
			let devicelist = await  allDevices({}) 
            await dispatch(setDevices(devicelist))
            await dispatch(setBackupDevices(devicelist))   
		}else{
            toast({
                message: 'ยกเลิกคำ ไม่สั่งสำเร็จ !!',
                ...toastFail
            })
        }
    }

    return(
        <div className="cdm-content"> 
            <IonRow> 
                <IonCol size="12" >
                  <IonLabel color={"dark"}><u style={{marginLeft:".5rem"}}>Command Type </u></IonLabel> <br/>
                </IonCol>
                <IonCol size="6" className="set-center-row" style={{justifyContent: 'flex-start',alignItems: 'center',}} >
                    <div className='input set-center' style={{borderRadius:'10px',width:'100%' ,maxWidth:"15rem" }}> 
                    <IonSegment mode="ios" value={engineSts} onIonChange={(e:any)=>{setEngineSts(e.detail.value)}} >
                        <IonSegmentButton value="stop">
                          <IonLabel style={{flexDirection:'row'}}><IonIcon icon={lockClosed} color="danger" /> Stop</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="resume">
                          <IonLabel style={{flexDirection:'row'}}><IonIcon icon={lockOpen} color="success" />  Resume</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                    </div> &nbsp;
                    <IonButton mode="ios" size="small"  >Send</IonButton>
                </IonCol>
                {/* <IonCol size="2" className="set-center" > <IonButton mode="ios" size="small"  >Send</IonButton>  </IonCol> */}

            </IonRow><br/> 
           <CommnadTable device_id={deviceId} />
        </div>
    )
}


const CommnadTable=({device_id}:any)=>{
    const [table,setTable] = useState([])

    const getTableCmd=async()=>{
        const list:any = await commandSend({device_id}) 
        setTable(list)
    }

    const typeformat=(label:any)=>{
        if(label.toLowerCase().search("resume") >-1){
            return "Resume"
        }else{
            return "Stop"
        }
    }
    const dateformat=(long:any)=>{   
        let formatdate = moment.unix(long/1000).format("DD/MM/YYYY HH:mm") 
        return formatdate
    }

    useEffect(()=>{ 
        getTableCmd()
    },[])

    return(
        <IonGrid className="cmd-table" >
            <IonRow className="cmd-column">
                <IonCol size="2.5" className="ion-text-center"> <IonLabel>type</IonLabel>  </IonCol>
                <IonCol size="3.5"  className="ion-text-center">   <IonLabel>Date</IonLabel>  </IonCol>
                <IonCol size="4.5"  className="ion-text-center">   <IonLabel>Send</IonLabel></IonCol>
                <IonCol size="1.5" className="ion-text-center">  <IonLabel></IonLabel>      </IonCol>
            </IonRow>
            {
                table.map((e:any,index:any)=>
                <IonRow className={`cmd-row ${index%2==0?'white':'tint'}`} key={index} >
                    <IonCol size="2.5" > <IonLabel>{typeformat(e.type)}</IonLabel>  </IonCol>
                    <IonCol size="3.5"  className="ion-text-center">   <IonLabel>{dateformat(e.date)}</IonLabel>  </IonCol>
                    <IonCol size="4.5"  className="ion-text-center">   <IonLabel>{e.send.toString()}</IonLabel></IonCol>
                    <IonCol size="1.5" className="set-center">  
                        <IonIcon icon={trashBin} color="danger" style={{fontSize:'1.5em'}}/>      
                    </IonCol>
                </IonRow>
                )
            }
        </IonGrid>
    )
}

const MDVRContent=()=>{

    return(
        <div className="mdvr-content">MDVR</div>
    )
}
export const SettingIcon=(props:any)=>{
    const [select,setSelect] = useState(props.default)
    const selected :any= useSelector(getSearchSelect)
    const [imgUpload,setImgUpload] = useState<any|null>(null)
    const [imgFileUpload,setImgFileUpload] = useState<any|null>(null)
    const preview:PreveiewDevice |any= useSelector(getPreviewDevice)   
    const dispatch = useDispatch()
     
    useEffect(()=>{    
        setDefault() 
        if(preview?.active && preview?.device?.device_id ===  props?.deviceId){
            if(preview?.device?.image){ setImgUpload(preview?.device?.image)}
        }
    },[select,selected,props])

    const setDefault=()=>{
        if(props.default){
            return props.setIcon(props.default)
        }
    }

    const changeSelection=async(id:any)=>{ 
        setSelect(id) 
        return props.setIcon(id)
    }
 
    const toBase64 = (file:any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    function getBase64Size(base64String:any){ 
        const byteLength = (base64String.length * 3) / 4 - (base64String[base64String.length - 1] === '=' ? 1 : 0) - (base64String[base64String.length - 2] === '=' ? 1 : 0);
      
        let size;
        if (byteLength < 1024) {
            size = `${byteLength} bytes`;
            console.log("size ",size);
            return true
        } else if (byteLength < 1024 * 1024) {
            size = `${(byteLength / 1024).toFixed(2)} KB`;
            console.log("size ",size);
            if((byteLength / 1024) > 200){
                return false
            }else{
                return true
            } 
        } else {
            size = `${(byteLength / (1024 * 1024)).toFixed(2)} MB`;
            console.log("size ",size);
            return false
        }
    }
    
    const handleAddBanner = async ({ target: { files } }:any) => {
        const loadedImage = files[0]; 
 
            const base64:any = await toBase64(loadedImage)
            console.log("base64 ",base64);
            if(   getBase64Size(base64 )){
                setImgUpload(base64)
                setImgFileUpload(loadedImage)  
            }else{
                alert(" File are too large !")
            }
       
         
               
    };
    const uploadimg=async()=>{
        const profile = await getStorage("profile") 
        const body ={  
             id:null,
             deviceId:  props?.deviceId ,
             userId: profile?.id,
             img: imgUpload
        }
        const res =await setDeviceImage(body)
        console.log("uploadimg res ", res)
        fetchDevice(props?.deviceId)
        toast.success(`อัพโหลดรูปภาพสำเร็จ !!`);
    }
    const fetchDevice=async(id:any)=>{
        const devices = await allDevices({}) //devicesAll({user_id: parseInt(user_id) }) 
        await dispatch(setDevices(devices))
        await dispatch(setBackupDevices(devices))   
        let view = devices.find((e:any)=> e.device_id == id)
        dispatch(setPreviewDevice({active:true, device:view})) 
    }
    
    const deleteimg=async()=>{
        const profile = await getStorage("profile") 
        const body ={  
             id:null,
             deviceId:  props?.deviceId ,
             userId: profile?.id,
             img: imgUpload
        }
        const res =await setDelDeviceImage(body)
        setImgUpload(null)
        fetchDevice(props?.deviceId)
        toast.success(`ลบรูปภาพสำเร็จ !!`);
    }
    
    return(
    <div>
        <div  > 
            <IonRow> 
                <IonCol className="ion-no-padding" size="9">
                <IonItem> <IonLabel>ICON</IonLabel> </IonItem>
                    <IonRadioGroup value={select} onIonChange={(e)=>{ changeSelection(e.detail.value )}} >
                        <IonRow> 
                        { iconType.map((icon,index)=>
                            <IonCol key={index}  sizeXs="4" sizeSm="4" sizeMd="4" sizeLg="3" sizeXl="2"  > 
                            <IonImg src={icon.icon_path} style={{width:'100%'}}  alt=' ' />
                            <IonRadio className="cartype-checkbox" value={icon.icon_type_id} ></IonRadio>
                            </IonCol>
                        )}  
                        </IonRow>
                    </IonRadioGroup> 
                </IonCol>
                <IonCol size="3" className="ion-no-padding" >
                    <IonItem> <IonLabel>Upload</IonLabel> </IonItem>
                     <div className="set-center" >
                        <div className="set-center-row" style={{justifyContent:"flex-start"}} >
                            {
                                <label className="circle-file-upload">
                                <input id="file-upload" type="file" onChange={handleAddBanner} /> 
                                <IonText>Choose your image</IonText>
                                </label> 
                            }
                        </div>
                        <div style={{border:"1px solid #ebe8e8" ,width:"4.5rem", height:"4.5rem" ,borderRadius:"50%"} } className="set-center" > 
                                {imgUpload ? <img  src={imgUpload} />  : <IonText><small>Preview</small></IonText> }
                    </div>
                    <div className="set-center-row" >
                        {imgUpload && <button  className="circle-file-upload" onClick={()=>{uploadimg()}} disabled={imgUpload === preview?.device?.image ? true :false} > <IonIcon icon={cloudUploadOutline} color="secondary" /> </button> }
                        {imgUpload && <button  className="circle-file-upload" onClick={()=>{deleteimg()}} > <IonIcon icon={trashBinOutline} color="danger" /> </button> }
                        {imgUpload && imgUpload !== preview?.device?.image && <button  className="circle-file-upload" onClick={()=>{setImgUpload(null)}} > <IonIcon icon={removeCircleOutline} color="primary" /> </button> }
                    </div>
                   </div>
                </IonCol>
               
            </IonRow>
           
        </div>
    </div>
    )
}

const SettingsContent=({deviceId,iconType}:any)=>{
    const [petro,setPetro] = useState(1)
    const [settings,setSetting] = useState({deviceIconId:0,  ConsumptionIdle:0, fuelConsumptionMove:0, pttType:1})
    const [icon,setIcon] = useState(iconType)
    const [consumptionIdle,setConsumptionIdle] = useState(0)
    const [consumptionMove,setConsumptionMove] = useState(0) 
    const [present] = useIonToast();
    const dispatch = useDispatch()

    const petroleums= [
        {value:1  ,label:"GASSOLINE-95"},
        {value:2 ,label:"GASSOHOL-91"},
        {value:4 ,label:"GASSOHOL-95"},
        {value:4 ,label:"GASSOHOL-E20"},
        {value:5 ,label:"GASSOHOL-E85"},
        {value:6 ,label:"DIESEL"},
        {value:7 ,label:"HYFORCE PREMIUM DESEL"},
        {value:8 ,label:"NVG"},
        {value: null ,label:"Not Specified"},
    ]
    const getSetting=async()=>{
        const server = await getStorage("server")
        const token = await getStorage("token")
        let res = await settingDetailById({deviceId: deviceId,token:token, server:server })
        setIcon(parseInt(res.deviceIconId))
        setConsumptionIdle(res.ConsumptionIdle)
        setConsumptionMove(res.fuelConsumptionMove) 
        setPetro(res.pttType)
    } 
    useEffect(()=>{ 
        // getSetting()
    },[])

    const updateGroupDevice=async(isGroup:any)=>{  
        const res = await setGroupDevices({groupDevice: isGroup })
        if(res){
            present({
              message: 'Update success!!',
              ...toastSuccess
          });
             
          setTimeout(async()=>{
            const settings = await userSetting()    
            console.log("setting ",settings)
            dispatch(setIsGroupDevice(settings.groupDevice)) 
            // setStorage('defaultTile',settings.defaultMap)
          },500)
          
      }else{
        present({
            message: 'อัพเดตข้อมูลไม่สำเร็จ !!',
            ...toastFail
          });
      }   
      }

    const changeSelection=async(value:any,feild:string)=>{ 
        const res = await byIdType({deviceId: deviceId,value:value, feild:feild})
        console.log("res ",res)
        if(res.result){
            getSetting()
            present({
                message: 'Update '+feild+' succes !',
                duration: 3000,
                position: 'top',
                color:"success"
            });
        }else{
            present({
                message: 'Update '+feild+' Fail !',
                duration: 3000,
                position: 'top',
                color:"danger"
            });
        } 
    }

    const updateDeviceTypeId=async()=>{ 
          const res = await setDeviceIcon({
              deviceId: deviceId ,
              deviceIconId: icon
          })
          if(res){
              present({
                  message: 'Update device icon type succes !',
                  ...toastSuccess
              });
          }else{
              present({
                  message: 'Update device icon type Fail !',
                  ...toastFail
              });
          }
          console.log("changeSelection ",res)
      
    }

    return(
        <div className="mdvr-content">
            <div className="ion-padding"> 
           
            <IonRow>
                <IonCol size="6"  >
                    <SettingIcon deviceId={deviceId}  default={iconType} setIcon={(id:any)=>setIcon(id)} />
                    <IonRow>
                        <IonCol size="12" className="ion-text-right"> 
                           <IonButton mode="ios" size="small"  onClick={()=>{updateDeviceTypeId()}} >Save</IonButton>
                        </IonCol>
                    </IonRow>
                </IonCol>
                <IonCol size="6" className="ion-text-left" >
                    {/* <SettingIcon deviceId={props.deviceId} default={props?.deviceIconId?props?.deviceIconId:1} /> */}
                    <IonItem> <IonLabel>Consumption rate</IonLabel> </IonItem><br/>
                    <div className="ion-padding" >
                    <IonRow>
                        <IonCol size="12" > 
                            <IonLabel className="" style={{fontSize:".9em" }} >Consumption rate while driving</IonLabel> 
                            <div className="input" style={{marginTop:".5rem",position:'relative'}}>
                                <IonInput type="number" value={consumptionIdle} onIonChange={(e:any)=>{setConsumptionIdle(e.detail.value!) }}></IonInput>
                                <IonButton mode="ios" size="small" 
                                    onClick={()=>{changeSelection(consumptionIdle,'ConsumptionIdle')}}
                                    style={{position:'absolute',top:'.2rem',right:'.2rem',zIndex:999}} 
                                > Save </IonButton>
                            </div> 
                            <IonLabel className="" style={{fontSize:".9em" }} >Engine power consumption</IonLabel> 
                            <div className="input" style={{marginTop:".5rem",position:'relative'}}>
                                <IonInput type="number" value={consumptionMove} onIonChange={(e:any)=>{setConsumptionMove(e.detail.value!) }}></IonInput>
                                <IonButton mode="ios" size="small" 
                                    onClick={()=>{changeSelection(consumptionMove,'fuelConsumptionMove')}}
                                    style={{position:'absolute',top:'.2rem',right:'.2rem',zIndex:999}} 
                                > Save </IonButton>
                            </div> 
                        </IonCol>
                        <IonCol size=".5" ></IonCol>
                        <IonCol size="12" >
                            <div className="input ion-padding">
                                <IonRow>
                                    <IonCol size="12" >
                                        <IonLabel>Select petroleum</IonLabel>
                                    </IonCol>
                                    <IonCol size="12"  >
                                    <IonRadioGroup value={petro}  > 
                                        <IonRow>
                                            {
                                                petroleums.map((petro:any,index) =>
                                                <IonCol key={index} className="petroleum-choice set-center" style={{flexDirection:"row",justifyContent:"flex-start"}}>
                                                    <IonRadio mode="ios"  color="primary" value={petro.value}></IonRadio> &nbsp;
                                                    <IonLabel style={{fontSize:'.7em',cursor:'pointer'}} onClick={()=>{changeSelection(petro.value,'pttType')}}>{petro.label}</IonLabel>
                                                    <IonRippleEffect></IonRippleEffect>
                                                </IonCol> 
                                            ) }
                                        
                                        </IonRow>
                                    </IonRadioGroup>
                                    
                                    </IonCol>
                                </IonRow>
                            </div>
                        </IonCol>
                    </IonRow>
                    </div>
                </IonCol>
            </IonRow>

            
           </div>
        </div>
    )
} 