import { faKeyboard } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonMenuButton, IonPage, IonPopover, IonRange, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import { addCircle, close, ellipseOutline, shapesOutline, trash } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import { addGeometry, allGeometry, deleteGeometry, geofenceAll,  getGeozone,  getStorage, toastSuccess } from '../actions';
import GeofencesMap from '../leaflet/GeofencesMap';
import { getLoading, getMenuOpen, getOS, setLoading } from '../store/appSlice';
import { AuthCheck } from './Home';
import { getAddGeo, getCircle, getGeoType, getGeoZones, getPointGeozone, getPolygon, getRadius, setAddGeofence, setCircle, setFindPoint, setFocusZone, setGeofenceType, setGeozones, setPointGeozone, setPolygon, setRadius } from '../store/mapSlice';
import Draggable from 'react-draggable';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { SelectPopover, SwipBack } from '../components/AppComponent';
import { MenuContent } from '../components/Menu';
import '../theme/style.css';
import "./css/Geofences.css"

const Geofences: React.FC = () => { 
  const loading :any= useSelector(getLoading)
  const dispatch = useDispatch()
  const os :any= useSelector(getOS)
  const addgeo :any= useSelector(getAddGeo)
  const menuOpen :any= useSelector(getMenuOpen) 
  const [geoType,setGeoType] = useState("polygon") 
  const geozones :any= useSelector(getGeoZones)
  

  const getZone=async()=>{ 
    let user = await getStorage("profile")
    let user_id= user.id 

    let zones = await getGeozone() //allGeometry()//geofenceAll({user_id: parseInt(user_id) }) 
    console.log("zones ",zones)
    let newzones:any = [] 
    
    zones.map((zone:any,index:any)=>{
      let points = zone.position?.map((el:any)=> {return [el.lat,el.lon]})
      newzones[index] = {...zone,...{points:points}}
    }) 
    dispatch(setGeozones(newzones))
  }

  useEffect(()=>{ 
    dispatch(setLoading(false)) 
 //  getZone()
    
  },[loading ,geozones])

  

  return (
    <IonPage>
    {/* <AuthCheck />   */}
      <IonContent fullscreen >
        <IonRow> 
          <IonCol  > 
                <IonGrid  style={{padding:"0px"}} className='ion-hide-md-down' >
                  <IonRow  style={{padding:"0px"}}>
                    <IonCol sizeSm='6' sizeLg="4" sizeXl='3'> 
                      <GeofencesContent geozones={geozones} geoType={geoType} setGeoType={(e:any)=>{setGeoType(e)}} /> 
                    </IonCol>
                    <IonCol sizeXs='12' sizeSm='6' sizeLg="8" sizeXl='9' >
                      <GeofencesMap geoType={geoType} /> 
                    </IonCol>
                  </IonRow>
                </IonGrid> 
 
                <Swiper 
                  className='mobile-mode ion-hide-md-up'
                  style={{height:"fit-content"}} 
                  onSlideChange={(e: any) => console.log('slide change',e)}
                  onSwiper={(swiper: any) => console.log(swiper)} 
                >
                  <SwiperSlide style={{background:"#FFF"}}>
                    <GeofencesContent  geozones={geozones}  geoType={geoType} setGeoType={(e:any)=>{setGeoType(e)}}  />
                  </SwiperSlide>
                  <SwiperSlide style={{background:"#FFF"}}>
                    <SwipBack />
                    <GeofencesMap geoType={geoType} />
                  </SwiperSlide>
                </Swiper> 
                
          </IonCol>
        </IonRow>
        
      </IonContent>
    </IonPage>
  );
};

export default Geofences;

const PointOfInterest=()=>{

  return(<div style={{width:"100%",minHeight:'5rem'}}>
    PointOfInterest
  </div>)
}
const DangerZone=()=>{

  return(<div  style={{width:"100%",minHeight:'5rem'}}>
    DangerZone
  </div>)
}

const GeofencesContent=({geozones ,geoType,setGeoType}:any)=>{
  // const geozones :any= useSelector(getGeoZones)
  const addgeo :any= useSelector(getAddGeo)
  const dispatch = useDispatch()
  const type :any= useSelector(getGeoType) 
  const [name,setName] = useState("")
  const polygon :any= useSelector(getPolygon)
  const circle :any= useSelector(getCircle)
  const os :any= useSelector(getOS)
  const swiper = useSwiper()
  const [toast] = useIonToast();
  const [swiperRef,setSwiperRef] = useState(null)
  const [zonetype,setZoneType] = useState("geozone")
  const radius :any= useSelector(getRadius)
  
  //  const getZone=async()=>{
  //     let zones = await getGeozone() // allGeometry()  
  //     let newzones:any = [] 
  //     console.log("zones ",zones)
  //     zones.map((zone:any,index:any)=>{
  //       if(zone?.position){ 
  //         let points = zone?.position.map((el:any)=> {return [el.lat,el.lon]})
  //         newzones[index] = {...zone,...{points:points}}
  //       }
  //     }) 
  //     dispatch(setGeozones(newzones))
  //   }

  const saveGeofences=async(type:any)=>{
      let geometryArr:any = []
      if(type=== "polygon"){
        console.log("polygon ",polygon)
        polygon.forEach((el:any) => {
          geometryArr = geometryArr.concat(el)
        }); 
      }else{
        console.log("circle ",circle)
        circle.forEach((el:any) => {
          geometryArr = geometryArr.concat(el)
        });
      } 
    console.log("geometryArr ",geometryArr)
    const result = await addGeometry({ 
      locationName: name ,
      geometry:   JSON.stringify(geometryArr)   ,  
      iconId : 0 ,
    })
    if(result.status===1){
      toast({
        ...toastSuccess,
        message:"Add geofences success."
      })
      dispatch(setCircle([]))
      dispatch(setPolygon([]))
      setName("")
      // getZone()
    } 
  }

  const changeZone=(e:any)=>{
    console.log("changeZone ",e)
    if(e?.position){
      let updatelist  = e 
      updatelist = e?.position.map((obj:any) => { 
        let icon =  obj.icon !== null? obj.icon:0
        if (obj.geoId === e.id) { 
          return { ...obj ,...{show: e.show }, ...{icon:icon}}
        }else{
          return {...obj,...{icon:icon} }
        }
      })
      let newlist =   updatelist
      dispatch(setGeozones(newlist))
      dispatch(setFocusZone(e.id))
       if(os==="android"||os==="ios"){
          swiper.slideNext()
       }
    }
    // if(geozones){
    // let zones:any[] = geozones
    //     let updatelist  = zones
    //     let select 
    //     let update
    //     let index = 0 
    //     select = zones.find((e:any)=> e._id === id)
    //     index = zones.findIndex((e:any)=> e._id === id)
    //     update  =  {...select,...{show:show}} 
    //     updatelist = zones.map( obj => { 
    //         let icon =  obj.icon !== null? obj.icon:0
    //         if (obj.geoId === id) { 
    //             return     { ...obj ,...{show: show }, ...{icon:icon}}
    //          }else{
    //             return {...obj,...{icon:icon} }
    //          }
    //     })  
    //     let newlist =   updatelist
 
    //    dispatch(setGeozones(newlist))
    //    dispatch(setFocusZone(id))
    //    if(os==="android"||os==="ios"){
    //       swiper.slideNext()
    //    }
      
    //   }
  }
  const setAddGeo=()=>{
    dispatch( setAddGeofence(!addgeo))
  }
  const deleteZone=async(id:any)=>{ 
      const result = await deleteGeometry({geoId:id})
      if(result){
        toast({
          ...toastSuccess,
          message:"Delete geofences success."
        }) 
        // getZone()
      }
   }

   const changeZoneType=(val:any)=>{
    dispatch(setGeofenceType(val));
    dispatch(setPointGeozone([]))
   }

   const changeSegment=(val:any)=>{
    setZoneType(val)
    if(val==="geozone"){
      //@ts-ignore
      swiperRef?.slideTo(0)
    }else if(val==="poi"){
      //@ts-ignore
      swiperRef?.slideTo(1)
    }else if(val==="dangerzone"){
      //@ts-ignore
      swiperRef?.slideTo(2)
    }
   }
 
   useEffect(()=>{
    console.log("type ",type) 
   },[])

  return(
    <div style={{padding:"1rem"}} className="geofences-content" >
      <IonRow>
        <IonCol size='10' className="set-center" style={{flexDirection:"row",justifyContent:'flex-start' }} > 
            <IonLabel className='title'>
             {zonetype =="geozone"? "Geofences":
             zonetype =="poi"? "Point Of Interest": "Danger zone"
             }    
            </IonLabel> 
            <IonButton color="primary" fill='clear' size='small' onClick={()=>setAddGeo()} > <IonIcon icon={addCircle} /> </IonButton> 
           <InputLocation />
        </IonCol>  
        <IonCol size='12' >
            <IonSegment value={zonetype} className='geozone-segment' mode='ios' onIonChange={(e)=>{changeSegment(e.detail.value)}}>
              <IonSegmentButton value="geozone">
                <IonLabel>Geozones</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="poi">
                <IonLabel>Point of interest</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="dangerzone">
                <IonLabel>Danger zone</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonCol> 
      </IonRow>

      
    {  addgeo ?<IonRow style={{marginBottom:'1rem'}} className='add-geofences' >
        <IonCol size='10' style={{paddingTop:"0",paddingBottom:"0"}} > 
          <div className='input' > 
            <IonInput mode='ios' placeholder="Name" value={name} onIonInput={(e:any)=>{setName(e.detail.value)}}></IonInput> 
         </div>
        </IonCol>
        <IonCol size='2' className='set-center' style={{paddingTop:"0",paddingBottom:"0"}}> 
          <IonButton size="small" fill="clear" color={"dark"}  onClick={()=>{return setGeoType(geoType==="polygon"?"circle":"polygon") }}>
              {geoType === "circle" ? <IonIcon icon={ellipseOutline} /> : <IonIcon icon={shapesOutline} />}
          </IonButton> 
        </IonCol>
        {geoType === "circle" && 
        <IonCol size='12' >
           <IonRow>
            <IonCol size='10'>
              <IonRange labelPlacement="start" label="Radius" mode='ios' value={radius}  min={1} max={100}
              onIonInput={(e)=>{dispatch(setRadius(e.detail.value))}}
              onIonChange={(e)=>{dispatch(setRadius(e.detail.value))}}></IonRange>
            </IonCol>
            <IonCol size='2' className='set-center' >
              {radius/100}
            </IonCol>
          </IonRow>
        </IonCol>
        } 
        <IonCol size='8' style={{paddingTop:"0"}} >
          <IonButton size='small'  mode='ios' onClick={()=>{dispatch(setPointGeozone([]))}} ><IonLabel style={{fontSize:"small"}}>Cancle</IonLabel></IonButton>
          <IonButton size='small'  mode='ios' onClick={()=>{dispatch(setPointGeozone([]))}} ><IonLabel style={{fontSize:"small"}}>Clear</IonLabel></IonButton> 
        </IonCol>
        <IonCol size='4' style={{paddingTop:"0"}} className="ion-text-right" >
          <IonButton size='small' mode='ios' color={"secondary"} 
            onClick={()=>{saveGeofences(geoType)}}
          >
            <IonLabel style={{fontSize:"small"}}>Save</IonLabel>
          </IonButton>
        </IonCol>
      </IonRow>:<></>
      }  

      
      <Swiper  
        style={{height:"fit-content"}} 
        onSlideChange={(e: any) => console.log('slide change',e)}
        onSwiper={(swiper: any) =>{ setSwiperRef(swiper)} } 
        draggable={false}
        allowTouchMove={false}
      >
        <SwiperSlide  color='light'>  
           <IonRow> 
            <IonCol size='12' >
              <div className='geozones-list' >
                <IonList>
                  {
                    geozones.map((zone:any,index:any)=>
                    <GeozoneList key={index} zone={zone} changeZone={(e:any)=>{return changeZone(e)}} deleteZone={(e:any)=>{deleteZone(e)}}/> 
                    )
                  }  
                </IonList>
              </div> 
            </IonCol>
           </IonRow>
        </SwiperSlide>
        <SwiperSlide  color='light'> 
            <PointOfInterest />
        </SwiperSlide>
        <SwiperSlide  color='light'>
            <DangerZone />
        </SwiperSlide>
      </Swiper>
     
    </div>
  )
}

export const InputLocation=({findLatLng}:any)=>{
  const [showInput,setShowInput] = useState(false)
  const [lattitude,setLattitude] = useState("")
  const [longitude,setLongitude] = useState("")
  const dispatch = useDispatch() 
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  const findLocation=()=>{ 
      dispatch(setFindPoint({lat:lattitude,lng:longitude}))
      setShowInput(false) 
  }

  return(<> 
      <IonButton color="secondary" fill='clear' size='small' onClick={openPopover}  >
      Enter &nbsp; <FontAwesomeIcon icon={faKeyboard} style={{fontSize:"large"}} /> 
      </IonButton>
      <IonPopover className='popover-find-location' mode='ios' ref={popover} isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
        <IonContent class="ion-padding">
          <div className="title-toolbar" style={{marginBottom:'.5rem' }}    > 
            <IonRow>
              <IonCol size="10" > <IonLabel style={{fontSize:"small"}}>คันหาด้วย ละติจูด / ลองติจูด</IonLabel>  </IonCol>
              <IonCol size="2"  className="set-center">  <IonIcon icon={close}  onClick={()=>{setPopoverOpen(false)}}  /> </IonCol>
            </IonRow> 
            <IonRow  style={{justifyContent:"center",marginTop:".5rem"}}>
              <IonCol size='11' style={{padding:'0px'}} >
                <div className='input' ><IonInput value={lattitude} placeholder='Lattitude' onIonChange={(e)=>{setLattitude(e.detail.value!)}} ></IonInput></div> 
                <div className='input' ><IonInput value={longitude} placeholder='Longitude'  onIonChange={(e)=>{setLongitude(e.detail.value!)}} ></IonInput></div>
              </IonCol>
              <IonCol size='11' className='ion-text-right' >
                {/* <IonButton mode='ios' onClick={()=>{findLocation()}}><IonLabel>Yes</IonLabel></IonButton>
                <IonButton mode='ios' onClick={()=>{setShowInput(false)}} ><IonLabel>No</IonLabel></IonButton> */}
                <IonButton mode='ios' fill='clear' size='small' onClick={()=>{setShowInput(false)}} ><IonLabel>ยกเลิก</IonLabel></IonButton>
                <IonButton mode='ios'  size='small' onClick={()=>{return findLatLng({lat:lattitude,lng:longitude}) }} ><IonLabel>ค้นหา</IonLabel></IonButton>
              </IonCol>
            </IonRow>
          </div>
        </IonContent>
      </IonPopover>
      {/* {
        showInput?  
        <div className='input-location' style={{}}>
          <div className="title-toolbar" style={{marginBottom:'.5rem'}}    > 
            <IonRow>
              <IonCol size="10" > <IonLabel >Fine  with Latt and Long</IonLabel>  </IonCol>
              <IonCol size="2"  className="set-center">  <IonIcon icon={close}  onClick={()=>{setShowInput(false)}}  /> </IonCol>
            </IonRow> 
          </div>
          <IonRow  style={{justifyContent:"center",marginTop:".5rem"}}>
            <IonCol size='11' style={{padding:'0px'}} >
              <div className='input' ><IonInput value={lattitude} placeholder='Lattitude' onIonChange={(e)=>{setLattitude(e.detail.value!)}} ></IonInput></div> 
              <div className='input' ><IonInput value={longitude} placeholder='Longitude'  onIonChange={(e)=>{setLongitude(e.detail.value!)}} ></IonInput></div>
            </IonCol>
            <IonCol size='12' className='ion-text-right' >
              <IonButton mode='ios' onClick={()=>{findLocation()}}><IonLabel>Yes</IonLabel></IonButton>
              <IonButton mode='ios' onClick={()=>{setShowInput(false)}} ><IonLabel>No</IonLabel></IonButton>
            </IonCol>
          </IonRow>
        </div> :<></>
      } */}
    </>
  )
}


 const GeozoneList=({zone,changeZone,deleteZone}:any)=>{
  
  return(<>
    <IonRow key={zone.geoId} id={`geozone-${zone.geoId}`} onContextMenu={()=>{}}>
    <IonCol size='2' className='set-center' style={{padding:"0"}} >
      <IonCheckbox mode='ios'  aria-label=""  checked={zone.show} onIonChange={(e)=>{return changeZone({show:e.detail.checked, ...e})}}></IonCheckbox>
    </IonCol> 
    <IonCol size='8' style={{padding:"0"}} >
      <IonItem lines='none' color={"light"} button  onClick={()=>{return changeZone({show:true,...zone}); }} > 
        <IonLabel color={"dark"} >{zone.name}</IonLabel> 
      </IonItem>
    </IonCol>
    <IonCol size='2' style={{padding:"0"}}>
      <IonButton disabled={!zone.canDelete} fill='clear' color="medium" onClick={()=>{
        return deleteZone(zone.geoId)
      }} > 
        <IonIcon icon={trash} /> 
      </IonButton>
    </IonCol>
  </IonRow>  
 </>)
}