import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonCol, IonRow, IonImg, IonIcon, IonLabel, IonChip, IonNavLink, IonInput, IonItem, IonCheckbox, IonButton } from "@ionic/react"
import { batteryChargingOutline, batteryDeadOutline, batteryHalfOutline, caretForwardCircleOutline, chatbubbleOutline, chevronBack, chevronForward, ellipse, flameOutline, idCard, idCardOutline, informationCircle, key, locateOutline, location, locationOutline, pin, shareSocialOutline, speedometerOutline, terminalOutline, thermometerOutline, videocamOutline, warning, wifiOutline } from "ionicons/icons"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome" 
import {  faCircleCheck, faCircleXmark, faRoad,  } from '@fortawesome/free-solid-svg-icons' 
import { useDispatch, useSelector } from "react-redux"; 
import { SlideNextButton } from "./DevicesContainer"; 
import { ChipFuel, ChipGPS, ChipGSM, ChipPTO, ChipSpeed, ChipStreetView, ChipTemp, DeviceIcon, DeviceName, DeviceStatusNameTime, DeviceUpdateTime, MileInput } from "./IconStatus";
import { OptionDialog } from "./OptionDialogs";
import { getPreviewDevice, getTrackRealtime, setTrackRealtime } from "../store/mapSlice"
import  config from "../api.config.json"
import { getAddress, getExpireDate, getName, getRatePoint, getRealtimeTrack, getSensorBattery, getSensorCardName, getSensorCardNumber, getSensorFuel, getSensorGPS, getSensorGSM, getSensorMile, getSensorSpeed, getSensorTemperture, getStatusName, getUpdateTime } from "../store/filterSlice"
import { IconRouteToday } from "./AppComponent"
import { useSwiper } from "swiper/react" 
import * as Moment from "moment-timezone"
import { extendMoment } from 'moment-range'
import "./css/CardDevice.css"
import { GPSState } from "./ListDevice"
import Tooltips from "./Tooltip"
import { t } from "i18next"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const moment = extendMoment(Moment);
moment.locale("th")
  
export const CardDevice  =({device,modal , select} :any)=>{ 
    const showName :any= useSelector(getName)
    const status :any= useSelector(getStatusName)
    const updateTime :any= useSelector(getUpdateTime)
    const speed :any= useSelector(getSensorSpeed)
    const battery :any= useSelector(getSensorBattery)
    const fuel :any= useSelector(getSensorFuel)
    const gps :any= useSelector(getSensorGPS)
    const gsm :any= useSelector(getSensorGSM)
    const temp :any= useSelector(getSensorTemperture)
    const address :any= useSelector(getAddress)
    const expiredDate :any= useSelector(getExpireDate)
    const cardNumber :any= useSelector(getSensorCardNumber)
    const cardName :any= useSelector(getSensorCardName)
    const miledge :any= useSelector(getSensorMile)
    const realtimeTrack :any= useSelector(getRealtimeTrack)
    const ratePoint :any= useSelector(getRatePoint)
    const swiper = useSwiper()

    useEffect(()=>{
 
    },[])

    const isExpired=(expire_date:any)=>{
        const start =  moment(new Date()).add(543,"year") ;
        const end =moment(expire_date); 
        const r1 = moment.range(start.tz('Asia/Bangkok'), end.tz('Asia/Bangkok'));  
        var diff = r1.diff('minute') ; 
      
        return diff > 0 ? false : true
    } 

    return( <>
    { device.event_id !==null ? 
     <IonCard className="card" mode="ios">
        {   expiredDate.card &&
            <IonChip   className="chip float"  >
                { isExpired(device.expiration_date)?
                    <IonLabel aria-label="icon-expired" color={"danger"}><FontAwesomeIcon icon={faCircleXmark} /></IonLabel>:
                    <IonLabel aria-label="icon-actived" color={"success"}><FontAwesomeIcon icon={faCircleCheck} /></IonLabel>
                }
            <IonLabel aria-label={isExpired(device.expiration_date)?"expired":"actived"} className="expire-date"> &nbsp;{moment(device.expiration_date).format("DD MMMM YYYY")} </IonLabel>
            </IonChip>
        }
        <IonCardHeader> 
            <IonRow>
                {realtimeTrack.card && 
                <IonCol size="1" className="set-center" >
                    <CheckTrackRealtime device={device} />
                </IonCol> }
                <DeviceIcon deviceIconId={device.deviceIconId} statusName={device?.status_name} status={device.status} heading={device.heading}  online={device.online} employee={device.employee} /> 
                <IonCol  style={{paddingBottom:'0'}}>
                    {showName.card && <DeviceName size="1em" deviceid={device?.device_id} name={device?.name2 ? device?.name2 : device?.name} update={device.event_stamp} showRatePonit={ratePoint.card} />  } 
                    {status.card && <DeviceStatusNameTime online={device.online} status={device.status} statusName={device.status_name} statusTime={device.status_time} />}
                    <br/>
                    {updateTime.card && <small>{device.event_stamp}</small>}
                </IonCol> 
                <IonCol size='1'className="set-center" >  
                   {device?.event_id > 1  && <SlideNextButton  device={device} modal={modal} select={(e:any)=>{modal?.setCurrentBreakpoint(0.8);  return select(e) }} />  } 
                 
                </IonCol>
                
                <IonCol size="12"> 
                    <ChipStreetView device={device} />
                    {speed.card && <ChipSpeed speed={device.speed}/> }
                    {fuel.card && <ChipFuel fuel_liters={device.fuel_liters}/> }
                    {battery.card && <IonChip  className="chip stack" >
                        <IonIcon
                        icon={
                            device.fld_sensorHigh === null?batteryDeadOutline:
                            device.fld_sensorHigh === "1" ?batteryHalfOutline:batteryChargingOutline } 
                        color={ 
                        device.fld_sensorHigh === null?"medium":
                        device.fld_sensorHigh ==="1" ? "warning":"success"}></IonIcon>
                        <IonLabel>{device.fld_sensorHigh}</IonLabel>
                    </IonChip>}
                   {gsm.card &&  <ChipGSM fld_signalStrength={device.fld_signalStrength} />}
                   {gps.card &&  <ChipGPS satellites={device.satellites} />  }
                   {device.door_status!==null ? <ChipPTO closeOpenSensor={device.door_status}/> :<></>}
                   {temp.card &&  <ChipTemp temperature={device.temperature}/> }
                </IonCol>
            </IonRow> 
        </IonCardHeader>
  
        <IonCardContent> 
            <IonRow> 
               {  device?.odoCheckToday &&  <IonCol size="12" >
                   <IonLabel>{device?.odoToday? device?.odoToday:"-"}</IonLabel>
                 </IonCol> 
                }
                <IonCol size="12"  style={{paddingTop:'0'}}>
                    {address.card &&<IonLabel>{device.address} </IonLabel> }
                </IonCol> 
                {cardNumber.card && device.fld_driverID &&<IonCol className="set-center" size="1.5" >
                  <IonIcon color="success" icon={idCard} style={{fontSize:'1.5em'}} /> 
                </IonCol>}
                {cardNumber.card && <IonCol size="10"  style={{paddingTop:'0'}}> 
                    <IonLabel>  {device.fld_driverID} {cardName.card ? device.fld_driverMessage :''}</IonLabel>
                </IonCol> }
                <IonCol size="12"  style={{paddingTop:'0',paddingLeft:"0"}}>
                    {miledge.card && <MileInput mile={device.mile} car={device} /> }
                </IonCol> 
            </IonRow>
            <IonRow>
                <OptionDialog device={device} /> 
            </IonRow>
        </IonCardContent> 
   </IonCard>:<></>} 
   </>
    )
}
 
export const LDevice=({device,select,swiper,changeCheck}:any)=>{
    const showName :any= true//useSelector(getName)
    const status :any= true//useSelector(getStatusName)
    const updateTime :any= true//useSelector(getUpdateTime) 
    const speed :any= true//useSelector(getSensorSpeed)
    const preview:any = useSelector(getPreviewDevice)

    const twoDForm=(odo:any)=>{
        if(odo){
            return Number(odo.toFixed(2))
        }else{
            return 0
        }
    }
    return(
        <div    
         className={`ldevice  ${
            device?.online === 0 ? 'bg-offline': 
            device?.speedLimit && device?.speed > device?.speedLimit && device?.status  === 7 ? 'bg-overspeed' :
            !device?.speedLimit && device?.speed >  90 && device?.status  === 7 ? 'bg-overspeed' :
            device?.status === 7 ? 'bg-move' :
            device?.status === 24 ?'bg-idle ':
            device?.status === 23 ?'bg-stop':'bg-offline'
        }`}> 
        <IonRow> 
            <IonCol size=" .5" className="set-center ion-padding " >  
              <IonCheckbox  checked={device?.show} onIonChange={(e:any)=>{changeCheck(e.detail.checked)}} ></IonCheckbox>
            </IonCol>
            <IonCol size="1 " onClick={()=>{ select(device)}}>   
                <div style={{position:"relative"}} >
                    {device?.ignition && <IonIcon icon={key} color="warning" style={{position:"absolute",right:"0px",bottom:"-.3rem"}} />}
                    {device?.image}
                    { device?.image ? 
                       <IonImg  src={device?.image} alt="icon-type"  /> :
                     !device?.image && device.deviceIconId ?  
                       <LazyLoadImage  src={`../../assets/types/${device.deviceIconId}.png`} alt="icon-type"  effect="blur"/> 
                        :
                        <LazyLoadImage  src={`../../assets/types/1.png`}  alt="icon-type-1" effect="blur"/>
                    } 

                </div>
            </IonCol>
            <IonCol   size="5"  style={{paddingBottom:'0'}} className="cursor-pointer" onClick={()=>{ select(device)}}>
                {showName  && <DeviceName size=".9em" deviceid={device?.device_id} name={device?.name2 ? device?.name2 : device?.name} update={device.event_stamp} showRatePonit={false} />  } 
                {/* {status  && <DeviceStatusNameTime online={device.online} status={device.status} statusName={device.status_name} statusTime={device.status_time} />} */}
                   
                {updateTime  && <small>เวลาข้อมูล {device.event_stamp}</small>}
            </IonCol> 
            <IonCol size="1" className="set-center" >
            {  device.fld_driverID &&   
                   <IonIcon color="success" icon={idCard}  />    
            }
            </IonCol>
            <IonCol size="1" className="set-center" >
              <IconRouteToday device={device} swiper={swiper} ischip={false}/>  
            </IonCol>
            {/* <IonCol    className="set-center "  > 
                <IonLabel className="tooltip"  > 
                    {
                        (device.speedLimit && device?.speed > device.speedLimit && device.status===7) ||
                        (!device.speedLimit && device?.speed > 90 && device.status===7) &&
                        <IonIcon icon={warning} color="danger" className="pulse-icon"  />
                    } {device?.speed} 
                    <span className="tooltiptext"> 
                    ความเร็ว {device?.speed}
                    </span>
                </IonLabel>  
              
            </IonCol> */}
            <IonCol size="1"   className="set-center ">
                <IonLabel className="tooltip"  >
                    <small>{device?.fuel_liters}</small>
                    <span className="tooltiptext"> 
                      น้ำมัน {device?.fuel_liters}
                    </span>
                </IonLabel>
            </IonCol>
             <IonCol  size="1" className="set-center" > 
                <GPSState deviceId={device?.device_id} gps={device.fld_sensorHigh} gsm={device.fld_signalStrength} /> 
            </IonCol>  
            <IonCol size="1" className="set-center" >
                <IonButton size="small" fill="clear" color={"dark"} onClick={()=>{ select(device)}}>
                    <IonIcon icon={caretForwardCircleOutline} />
                </IonButton>
            </IonCol>
        </IonRow>
         <div className="content-hidden" area-hiden={preview?.device && preview?.device?.device_id === device?.device_id ? "fixed" : "auto"} >
         
            <IonRow className="ion-justify-content-center" > 
                <IonCol size="10" >
                {  device?.odoCheckToday &&   <IonLabel> <FontAwesomeIcon icon={faRoad} /> {t("distance_today")} {device?.odoToday? device?.odoToday.toFixed(2):"-"} {t('kmUnit')}</IonLabel>  }
                    &nbsp;
                {  <IonLabel onClick={()=>{ select(device)}} className="cursor-pointer"> <IonIcon icon={location} /> {device.address} </IonLabel> }
                </IonCol> 
                {  device.fld_driverID &&<IonCol className="ion-text-left" size="10" >
                    <IonIcon color="success" icon={idCard} style={{fontSize:'1.5em'}} />  
                    <IonLabel> {  device.fld_driverMessage }  {device.fld_driverID} </IonLabel>
                </IonCol> }
            </IonRow>
            <IonRow>
                <IonCol size="10" className="ion-no-padding">
                    <IonRow> 
                        <OptionDialog device={device} /> 
                    </IonRow> 
                </IonCol> 
                <IonCol size="2" className="ion-no-padding">
                    { <MileInput mile={twoDForm(  device?.mile)} car={device} input={false} /> }
                    {/* {device.mile} */}
                </IonCol> 
            </IonRow>
         </div>
        </div>
    )
}

export const CheckTrackRealtime=({device}:any)=>{
    const trackRealtime:any []  = useSelector(getTrackRealtime)
    const [check,setCheck] = useState(false)
    const dispatch = useDispatch()

    const isTracking=async()=>{ 
        if(trackRealtime.length > 0){
            let found = await trackRealtime.find((track:any)=> track.device_id === device.device_id )
           
            if(found!==undefined && found !== null){
                setCheck(true)
            }else{ setCheck(false) }
        }else{
            setCheck(false)
        }
    }

    const checkToTrack=async(check:any)=>{
        console.log("check ", check)
        if(check){
            const checkAlready =await trackRealtime.find((track:any)=> track.device_id===device.device_id )
            if(!checkAlready){
                const tracking = {
                    device_id: device.device_id ,
                    name: device.name ,
                    line: {
                        isDash: false,
                        dashArray:null, //[5, 10] 
                        color: config.color, 
                    },
                    route:[[parseFloat(device.latitude),parseFloat(device.longitude)]]
                }
                let arrays:any[] = []
                arrays = trackRealtime
                console.log("arrays ",arrays)
                const addDeviceTrack = [...arrays, tracking]
                dispatch(setTrackRealtime(addDeviceTrack))
                isTracking()
            }else{
                console.log("device "+device.name+" is already tracking. ")
            }
        }else{
            const removeDeviceTrack =await trackRealtime.filter((track:any)=> track.device_id!==device.device_id )
            await dispatch(setTrackRealtime(removeDeviceTrack))
            setCheck(false)
        }
       
    }

    useEffect(()=>{
        isTracking()
    },[trackRealtime,check])

    return( <IonCheckbox checked={check} aria-label="" onIonChange={(e)=>{checkToTrack(e.detail.checked)}} ></IonCheckbox>)
}
 
