import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from "./store";

const initialState = {
  os:'windows',
  isSignin: false,
  darkMode: false ,
  openMenu: false ,
  sharePop: { ishow: false, id: 0 },
  showname:false,
  token: null,
  defaultMap: "OpenStreetMap",
  isGroupDevice:false,
  fcmToken:null,
  googleEnable:false,
  profile:{
    first_name: "Name",
    t_name: "Last Name" ,
    urlid: 0 ,
    las_realtime: ""
  },
  subMenu: null,

  loading: false,
  listTemplate:'card',
  dateStart: moment().format("YYYY-MM-DDT00:00"),
  dateEnd: moment().format("YYYY-MM-DDT23:59"),
  devices: [],
  backupDevices: [],
  deviceSelect: null,
  filterDevices: "all",
  windowWidth: 480,
  modalDevices: false,
  currentDevice: { name: "", id: 0 },
  keyword: "",
  filtered: [],
  searchSelect: null,
  modalDashboard: false,
  modalDashTable: null,
  deviceModal:false,
  deviceModalOpt:'message',
  deviceParams: null,
  notifierMessage: [] ,
  unreadMsg:[],
  deviceGroup: [],
  deviceGroupBackup: []
};

export const appSlice = createSlice({
  name: "appstate",
  initialState,
  reducers: {
    setOS: (state, action) => {
      state.os = action.payload;
    },
    setFCMToken: (state, action) => {
      state.fcmToken = action.payload;
    },
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
      document.body.classList.toggle('dark', action.payload);
    },
    setMenuOpen:(state,action)=>{
      state.openMenu = action.payload;
    },
    setSubMenu:(state,action)=>{
      state.subMenu = action.payload;
    },
    setShowLicense: (state, action) => {
      state.showname = action.payload;
    },
    setIsGroupDevice: (state, action) => {
      state.isGroupDevice = action.payload;
    },
    setListTemplate: (state, action) => {
      state.listTemplate = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setSharePop: (state, action) => {
      state.sharePop = action.payload;
    },
    setSearchSelect: (state, action) => {
      state.searchSelect = action.payload;
    },
    setDevices: (state, action) => {
      state.devices = action.payload;
    },
    setDateStart: (state, action) => {
      state.dateStart = action.payload;
    },
    setDateEnd: (state, action) => {
      state.dateEnd = action.payload;
    },
    setBackupDevices: (state, action) => {
      state.backupDevices = action.payload;
    },
    setDevice: (state, action) => {
      state.deviceSelect = action.payload;
    },
    setSignin: (state, action) => {
      state.isSignin = action.payload;
      if(!action.payload){
        setTimeout(()=>{ 
          window.location.pathname = 'signin'
        },50)
      }
    },
    setWindowWidth: (state, action) => {
      state.windowWidth = action.payload;
    },
    setModalDevice: (state, action) => {
      console.log("action.payload ", action.payload);
      if (action.payload !== state.modalDevices) {
        state.modalDevices = action.payload;
      }
    },
    setCurrentDevice: (state, action) => {
      state.currentDevice = action.payload;
    },
    setDefaultMap: (state, action) => {
      state.defaultMap = action.payload;
    },
    setFiltered: (state, action) => {
      state.filtered = action.payload;
    },
    setModalDashboard: (state, action) => {
      state.modalDashboard = action.payload;
    },
    setModalDashTable: (state, action) => {
      state.modalDashTable = action.payload;
    },
    setDeviceModal: (state, action) => {
      if(action.payload != state.deviceModal){
      state.deviceModal = action.payload;}
    },
    setDeviceModalOpt: (state, action) => {
      state.deviceModalOpt = action.payload;
    },
    setGoogleEnable: (state, action) => {
      state.googleEnable = action.payload;
    },
    setDeviceParams: (state, action) => {
      state.deviceParams = action.payload;
    },
    setFilterDevices: (state, action) => {
      let sts = action.payload;
      state.filterDevices = action.payload;
      if (state.filterDevices === "offline") {
        state.devices = state.backupDevices.filter(
          (device:any) => device.online === 0
        );
      } else if (state.filterDevices === "all") {
        state.devices = state.backupDevices;
      } else if (state.filterDevices === "move") {
        state.devices = state.backupDevices.filter(
          (device:any) => device.status === 7 && device.online === 1
        );
      } else if (state.filterDevices === "idle") {
        state.devices = state.backupDevices.filter(
          (device:any) => device.status === 24 && device.online === 1
        );
      } else if (state.filterDevices === "stop") {
        state.devices = state.backupDevices.filter(
          (device:any) => device.status === 23 && device.online === 1
        );
      } else {
        state.devices = state.backupDevices;
      }
    },
    setNotifierMessage: (state, action) => {
      state.notifierMessage = action.payload ;
    },
    setUnreadMsg:(state, action) => {
      state.unreadMsg = action.payload ;
    },
    setDeviceGroup:(state, action) => {
      state.deviceGroup = action.payload ;
    },
    setDeviceGroupBackup:(state, action) => {
      state.deviceGroupBackup = action.payload ;
    },
    
  },
});

// Action creators are generated for each case reducer function
export const {
  setOS,
  setFCMToken,
  setToken,
  setDarkMode,
  setMenuOpen,
  setShowLicense,
  setIsGroupDevice,setSubMenu,
  setListTemplate,
  setProfile,
  setSharePop,
  setDevices,
  setDevice,
  setSignin,
  setBackupDevices,
  setFilterDevices,
  setWindowWidth,
  setModalDevice,
  setDefaultMap,
  setFiltered,
  setCurrentDevice,
  setDateStart,
  setDateEnd,
  setLoading,
  setKeyword,
  setSearchSelect,
  setModalDashboard,
  setModalDashTable,
  setDeviceModal,
  setDeviceModalOpt,
  setGoogleEnable,
  setDeviceParams,
  setNotifierMessage,
  setUnreadMsg,
  setDeviceGroup,
  setDeviceGroupBackup
} = appSlice.actions;

export const getOS = (state:RootState) => state.appstate.os
export const getToken = (state:RootState) => state.appstate.token;
export const getFCMToken = (state:RootState) => state.appstate.fcmToken;
export const getMenuOpen = (state:RootState) => state.appstate.openMenu;
export const getSubMenu = (state:RootState) => state.appstate.subMenu;
export const getShowLicebse = (state:RootState) => state.appstate.showname;
export const getIsGroupDevice = (state:RootState) => state.appstate.isGroupDevice;
export const getProfile = (state:RootState) => state.appstate.profile;
export const getLoading = (state:RootState) => state.appstate.loading;
export const getlistTemplate= (state:RootState) => state.appstate.listTemplate;
export const getDevices = (state:RootState) => state.appstate.devices;
export const getBackup = (state:RootState) => state.appstate.backupDevices;
export const getDeviceSelect = (state:RootState) => state.appstate.deviceSelect;
export const getIsSignin = (state:RootState) => state.appstate.isSignin;
export const getFilter = (state:RootState) => state.appstate.filterDevices;
export const getWindowWidth = (state:RootState) => state.appstate.windowWidth;
export const getModalDevices = (state:RootState) => state.appstate.modalDevices;
export const getFilterJson = (state:RootState) => state.appstate.filtered;
export const getDefaultMap = (state:RootState) => state.appstate.defaultMap;
export const getCurrentDevice = (state:RootState) => state.appstate.currentDevice;
export const getDateStart = (state:RootState) => state.appstate.dateStart;
export const getDateEnd = (state:RootState) => state.appstate.dateEnd;
export const getSharePop = (state:RootState) => state.appstate.sharePop;
export const getKeyword = (state:RootState) => state.appstate.keyword;
export const getSearchSelect = (state:RootState) => state.appstate.searchSelect;
export const getModalDashboard = (state:RootState) => state.appstate.modalDashboard;
export const getModalDashTable = (state:RootState) => state.appstate.modalDashTable;
export const getDeviceModal = (state:RootState) => state.appstate.deviceModal;
export const getDeviceModalOpt = (state:RootState) => state.appstate.deviceModalOpt;
export const getDarkMode= (state:RootState) => state.appstate.darkMode;
export const getGooggleEnable= (state:RootState) => state.appstate.googleEnable;
export const getDeviceParams= (state:RootState) => state.appstate.deviceParams;
export const getNotifierMessages = (state:RootState) => state.appstate.notifierMessage
export const getUnReadMsg = (state:RootState) => state.appstate.unreadMsg
export const getDeviceGroup = (state:RootState) => state.appstate.deviceGroup
export const getDeviceGroupBackup = (state:RootState) => state.appstate.deviceGroupBackup

export default appSlice.reducer;
