import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from "./store";

const initialState = {
  isSignin: false,
  fastTrack: null,
  passRoute: null,
  routeID: "",
  controlPosition: { x: 0, y: 0 },
  routeLists: [],
  routeSelected:null,
  deviceBound:[],
  indexRoute: 0,
  speedPlay: 1000,
  playSize: 0,
  playAction:'stop',
  isMeasure: false,
  lastPoint: { lat: 0, lng: 0 },
  measure_distance: "",
  measure_area: "",
  measurement: [],
  geozones: [],
  geozonesBackup: [],
  focusZone: null,
  findPoint: null,
  addGeofence: false, 
  startDraw: false,
  geofenceType: {value: "polygon", label:"Polygon"},
  pointsGeozone: [],
  geoIcon: { id: 0, name: "Pin" },
  historyMapPosition: { lat: 14.340027, lng: 100.586026 },
  previewDevice:{active:false , device: null} ,
  trackRealtime:[] ,
  routeIndex:0,
  passRouteIndex:0,
  polygon:[],
  circle:[],
  radius: 0.5 ,
  quickTrackList:[],
  trackWhilePlay:false ,
  viewRouteSelect:false,
  streetView:false ,
  focusMarker: null , 
  streetViewPosition:null,
  openStreetView:false,
  routeDraw:[],
  routeSet: null,
  passrouteInterval: null ,
  mapRouting: {
    start: null ,
    end: null 
  },
  mapRoute: null ,
  mapDark: false ,
  passrouteList: []
};

export const appSlice = createSlice({
  name: "mapstate",
  initialState,
  reducers: {
    setSignin: (state, action) => {
      state.isSignin = action.payload;
    },
    setFastTrack: (state, action) => {
      state.fastTrack = action.payload;
    },
    setPassRouteIndex: (state, action) => {
      state.passRouteIndex = action.payload;
      console.log("setPassRouteIndex   ",state.passRouteIndex)
    },
    setPassRoute: (state, action) => {
      state.passRoute = action.payload;
    },
    setRouteID: (state, action) => {
      state.routeID = action.payload;
    },
    setControlPosition: (state, action) => {
      state.controlPosition = action.payload;
    },
    setRouteList: (state, action) => {
      console.log("action.payload ", action.payload);
      state.routeLists = action.payload;
    },
    setRouteSelected: (state, action) => { 
      state.routeSelected = action.payload;
    },
    setPlaySize: (state, action) => {
      state.playSize = action.payload;
    },
    setPlayAction: (state, action) => {
      state.playAction = action.payload;
    }, 
    setIndexRoute: (state, action) => {
      state.indexRoute = action.payload;
    },
    setSpeedPlay: (state, action) => {
      state.speedPlay = action.payload;
    },
    setIsMeasure: (state, action) => {
      state.isMeasure = action.payload;
    },
    setLastPoint: (state, action) => {
      state.lastPoint = action.payload;
    },
    setMeasureDistance: (state, action) => {
      state.measure_distance = action.payload;
    },
    setMeasureArea: (state, action) => {
      state.measure_area = action.payload;
    },
    setMeasurement: (state, action) => {
      state.measurement = action.payload;
    },
    setGeozones: (state, action) => {
      state.geozones = action.payload;
    },
    setGeozonesBackup: (state, action) => {
      state.geozonesBackup = action.payload;
    }, 
    setFocusZone: (state, action) => {
      state.focusZone = action.payload;
    },
    setFindPoint: (state, action) => {
      state.findPoint = action.payload;
    },
    setStartDraw: (state, action) => {
      state.startDraw = action.payload;
    },
    setAddGeofence: (state, action) => {
      state.addGeofence = action.payload;
    },
    setGeofenceType: (state, action) => {
      state.geofenceType = action.payload;
    },
    setPointGeozone: (state, action) => {
      state.pointsGeozone = action.payload;
    },
    setGeoIcon: (state, action) => {
      state.geoIcon = action.payload;
    },
    setHistoryMapPosition: (state, action) => {
      state.historyMapPosition = action.payload;
    },
    setPreviewDevice: (state, action) => {
      state.previewDevice = action.payload;
    },
    setTrackRealtime: (state, action) => {
      state.trackRealtime = action.payload;
    },
    setDeviceBound: (state, action) => {
      state.deviceBound = action.payload;
    }, 
    setRouteIndex: (state, action) => {
      state.routeIndex = action.payload; 
    }, 
    setTrackWhilePlay:(state, action) => {
      state.trackWhilePlay = action.payload;
    }, 
    setPolygon:(state, action) => {
      state.polygon = action.payload;
    }, 
    setCircle:(state, action) => {
      state.circle = action.payload;
    }, 
    setRadius:(state, action) => {
      state.radius = action.payload;
    }, 
    setQuickTrackList:(state, action) => {
      state.quickTrackList= action.payload;
    },  
    setStreetView:(state, action) => {
      state.streetView= action.payload;
    },
    setFocusMarker:(state, action) => {
      state.focusMarker= action.payload;
    },
    setStreetViewPosition:(state, action) => {
      state.streetViewPosition= action.payload;
    },
    setOpenStreetView:(state, action) => {
      state.openStreetView= action.payload;
    },
    setRouteDraw:(state, action) => {
      state.routeDraw = action.payload;
    },
    setRouteSet:(state, action) => {
      console.log("setRouteSet payload  " , action.payload)
      state.routeSet = action.payload;
    },
    setPassRouteInterval:(state, action) => {
      state.passrouteInterval = action.payload;
    },
    setMapRouting: (state, action)=>{
      state.mapRouting = action.payload
    },
    setMapRoute: (state, action)=>{
      state.mapRoute= action.payload
    },
    setMapDark: (state, action)=>{
      state.mapDark= action.payload
      if(action.payload){
        document.body.classList.add( "map-dark" )
      }else{ 
        document.body.classList.remove( "map-dark" )
      }
    },
    setPassrouteList: (state, action)=>{
      state.passrouteList = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSignin,
  setFastTrack,
  setPassRoute,
  setRouteID,
  setControlPosition,
  setRouteList,
  setRouteSelected,
  setPlaySize,
  setPlayAction ,
  setIndexRoute,
  setSpeedPlay,
  setIsMeasure,
  setLastPoint,
  setMeasureDistance,
  setMeasureArea,
  setMeasurement,
  setFocusZone,
  setFindPoint,
  setStartDraw,
  setAddGeofence,
  setGeofenceType,
  setPointGeozone,
  setGeoIcon,
  setHistoryMapPosition,
  setPreviewDevice ,
  setTrackRealtime ,
  setDeviceBound,
  setRouteIndex,
  setTrackWhilePlay,
  setPolygon ,
  setCircle, 
  setQuickTrackList,
  setStreetView,
  setFocusMarker,
  setStreetViewPosition,
  setOpenStreetView,
  setRouteDraw,setRouteSet,
  setRadius,
  setPassRouteIndex,
  setPassRouteInterval,
  setMapRouting,
  setMapRoute ,
  setGeozones,
  setGeozonesBackup,
  setMapDark,
  setPassrouteList
} = appSlice.actions;

export const getIsSignin = (state:RootState) => state.mapstate.isSignin;
export const getFastTrack = (state:RootState) => state.mapstate.fastTrack;
export const getPassRoute = (state:RootState) => state.mapstate.passRoute;
export const getPassRouteIndex = (state:RootState) => state.mapstate.passRouteIndex;
export const getRouteId = (state:RootState) => state.mapstate.routeID;
export const getControlPosition = (state:RootState) => state.mapstate.controlPosition;
export const getRouteList = (state:RootState) => state.mapstate.routeLists;
export const getRouteSelected = (state:RootState) => state.mapstate.routeSelected;
export const getPlaySize = (state:RootState) => state.mapstate.playSize;
export const getPlayAction = (state:RootState) => state.mapstate.playAction;
export const getIndexRoute = (state:RootState) => state.mapstate.indexRoute;
export const getSpeedPlay = (state:RootState) => state.mapstate.speedPlay;
export const getIsMeasure = (state:RootState) => state.mapstate.isMeasure;
export const getLastPoint = (state:RootState) => state.mapstate.lastPoint;
export const getMeasureDistance = (state:RootState) => state.mapstate.measure_distance;
export const getMeasureArea = (state:RootState) => state.mapstate.measure_area;
export const getMeasurement = (state:RootState) => state.mapstate.measurement;
export const getGeoZones = (state:RootState) => state.mapstate.geozones;
export const getGeoZonesBackup = (state:RootState) => state.mapstate.geozonesBackup;
export const getFocusZone = (state:RootState) => state.mapstate.focusZone;
export const getFindPoint = (state:RootState) => state.mapstate.findPoint;
export const getStartDraw = (state:RootState) => state.mapstate.startDraw;
export const getAddGeo = (state:RootState) => state.mapstate.addGeofence;
export const getGeoType = (state:RootState) => state.mapstate.geofenceType;
export const getPointGeozone = (state:RootState) => state.mapstate.pointsGeozone;
export const getGeoIcon = (state:RootState) => state.mapstate.geoIcon;
export const getHisMapPosit = (state:RootState) => state.mapstate.historyMapPosition;
export const getPreviewDevice= (state:RootState) => state.mapstate.previewDevice;
export const getTrackRealtime= (state:RootState) => state.mapstate.trackRealtime;
export const getDeviceBound= (state:RootState) => state.mapstate.deviceBound;
export const getRouteIndex= (state:RootState) => state.mapstate.routeIndex;
export const getTrackWhilePlay= (state:RootState) => state.mapstate.trackWhilePlay;
export const getPolygon= (state:RootState) => state.mapstate.polygon;
export const getCircle= (state:RootState) => state.mapstate.circle;
export const getViewRourtSelect= (state:RootState) => state.mapstate.viewRouteSelect;
export const getQuickTrackList= (state:RootState) => state.mapstate.quickTrackList;
export const getStreetView= (state:RootState) => state.mapstate.streetView
export const getFocusMarker= (state:RootState) => state.mapstate.focusMarker
export const getStreetViewPosition= (state:RootState) => state.mapstate.streetViewPosition
export const getOpenStreetView= (state:RootState) => state.mapstate.openStreetView
export const getRouteDraw= (state:RootState) => state.mapstate.routeDraw
export const getRouteSet= (state:RootState) => state.mapstate.routeSet
export const getRadius= (state:RootState) => state.mapstate.radius
export const getPassRouteInterval = (state:RootState) => state.mapstate.passrouteInterval
export const getMapRouting = (state:RootState) => state.mapstate.mapRouting 
export const getMapRoute = (state:RootState) => state.mapstate.mapRoute 
export const getMapDark = (state:RootState) => state.mapstate.mapDark
export const getPassrouteList = (state:RootState) => state.mapstate.passrouteList
 

export default appSlice.reducer;
