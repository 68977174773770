import { createSlice } from "@reduxjs/toolkit"; 
import { RootState } from "./store";
const initialState = {
  os:'windows', 
  travelSum: null
};

export const reportSlice = createSlice({
  name: "reportstate",
  initialState,
  reducers: {
    setOS: (state, action) => {
      state.os = action.payload;
    }, 
    setTravelSum: (state, action) => {
        state.travelSum = action.payload;
    }, 
  },
});

// Action creators are generated for each case reducer function
export const {
  setOS, 
  setTravelSum
} = reportSlice.actions;

export const getOS = (state:RootState) => state.reportState.os; 
export const getTravelSum = (state:RootState) => state.reportState.travelSum
 

export default reportSlice.reducer;
