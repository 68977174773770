import { faCircleCheck, faCircleXmark, faGasPump, faRoute, faSatelliteDish } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonBadge, IonButton, IonCard, IonChip, IonCol, IonGrid, IonIcon, IonImg, IonLabel, IonPopover, IonReorder, IonRow, IonText, useIonToast } from "@ionic/react"
import { arrowForward, arrowForwardCircle, batteryChargingOutline, batteryDeadOutline, batteryHalfOutline, caretDown, caretDownCircle, caretUpCircle, chatboxEllipsesOutline, checkmarkCircle, chevronForward, ellipse, filterOutline, lockClosed, navigateCircleOutline, notifications, notificationsOutline, shareSocial, shareSocialOutline, wifi } from "ionicons/icons"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDateEnd, getDateStart, getDevices, setDevice, setDeviceModal, setDeviceModalOpt, setDevices, setSharePop } from "../store/appSlice"
import './css/ListDevice.css' 
import { SlideNextButton } from "./DevicesContainer"
import { ChipSpeed, ChipFuel, ChipGSM, ChipGPS, ChipPTO, ChipTemp, DeviceName, ChipStreetView, DeviceIcon, ChipCutEngine } from "./IconStatus"
import { useSwiper } from "swiper/react"
import { setFastTrack, setPreviewDevice } from "../store/mapSlice"
import { CheckTrackRealtime } from "./CardDevice" 
import { getStorage, historyRoute, toastFail } from "../actions"
import { getName, getStatusName, getUpdateTime, getSensorSpeed, getSensorBattery, getSensorFuel, getSensorGPS, getSensorGSM, getSensorTemperture, getAddress, getExpireDate, getSensorCardNumber, getSensorCardName, getSensorMile, getRealtimeTrack, getSensorPTO, getQuickTrack, getShare, getNavigation, getRatePoint, getIconFilter, getSensorCutengine, getStreetview, getNotification } from "../store/filterSlice"
import { Map, map } from "leaflet"
import { Device } from "../pages/schema"


import * as Moment from "moment-timezone"
import { extendMoment } from 'moment-range'
import "./css/CardDevice.css"
import { t } from "i18next"
import { IconRouteToday } from "./AppComponent"

const moment = extendMoment(Moment);
moment.locale("th")
  

interface ListDevice{
    status: String
    mapref: Map | null
    devices: any[] | Device[]  
}
const ListDevice =({status,mapref ,devices}:any)=>{
    // const devices :any= useSelector(getDevices)
    const dispatch = useDispatch()
  
    return(
    <div>
        <br/>
        <IonGrid className="ion-no-padding" >
            {
                status === "all" ? <>
                    { devices.map((el:any,index:any)=>
                        <Devices device={el} key={index}  select={()=>{mapref?.setView([el?.latitude , el?.longitude])}} />
                    )}
                </>:<> 
                { devices.filter((device:any)=>
                    status === "offline" ? device.online === 0 :
                    status === "move" ? device.status === 7 :
                    status === "idle" ? device.status === 24 :  device.status === 23 
                    ).map((device:any,index:any)=>
                    <Devices device={device} key={index}  select={()=>{mapref?.setView([device?.latitude , device?.longitude])}}   /> 
                 )}  
                </>
            }
            
        </IonGrid><br/><br/><br/>
    </div>
    )
}
export default ListDevice

const MotionState=(props:any)=>{
    return(
        <div className="status-pulse-name">
            <IonImg src={
             props.online === 0?"../../assets/status/sts_offline.png" :
             props.status === 7?"../../assets/status/sts_move.png" :
             props.status === 23?"../../assets/status/sts_stop.png" :
             props.status === 24?"../../assets/status/sts_idle.png" : 
             "../../assets/status/sts_offline.png"
            } />
            <IonLabel className={
                props.online === 0?"offline" :
                props.status === 7?"move" :
                props.status === 23?"stop" :
                props.status === 24?"idle" : "offline"
            } 
            >{props.online === 0 ? 'OFFLINE': props.statusName}</IonLabel>
        </div>
    )
}
export const GPSState=(props:any)=>{ 
    const sattle = props.gps 
    const gsm = props.gsm
    const showGSM :any= useSelector(getSensorGSM)
    const showGPS :any= useSelector(getSensorGPS)
    const [show,setShow] = useState(false)

    return(<> 
        <div id={props?.deviceId+"-sensor-popover"} className="status-gps-state tooltip" style={{alignItems:'center'}} onMouseOver={()=>{ setShow(true)}}>
            <IonLabel className="set-center-row">
                <IonText color={sattle > 0 && sattle < 10 ? "danger" :   sattle >= 10 && sattle < 20 ?"warning" : "success"}> 
                    <FontAwesomeIcon icon={faSatelliteDish}  /> 
                </IonText> &nbsp;
                <IonIcon  color={gsm > 0 && gsm < 10 ? "danger" :   gsm >= 10 && gsm < 20 ?"warning" : "success"} icon={wifi}/>   
            </IonLabel>
            <span className="tooltiptext"> 
                    <IonLabel>GPS Signal : {sattle}</IonLabel><br/>
                    <IonLabel>GSM Signal : {gsm}</IonLabel> 
            </span>
        </div>  

        {/* <IonPopover mode="ios" isOpen={show}  trigger={props?.deviceId+"-sensor-popover"}  
        dismissOnSelect={true} onMouseLeave={()=>{ setShow(false)}}
        >
        <div className="ion-padding" >
            <IonLabel>GPS Signal : {sattle}</IonLabel><br/>
            <IonLabel>GSM Signal : {gsm}</IonLabel>
        </div>
        </IonPopover> */}
    </>
    )
}

const ListDev=({device}:any)=>{  
    const [sensor,showSensor] = useState(true)
    const dateStart :any= useSelector(getDateStart) 
    const dateEnd :any= useSelector(getDateEnd)

    const showName :any= useSelector(getName) 
    const address :any= useSelector(getAddress) 
    const speed :any= useSelector(getSensorSpeed)
    const battery :any= useSelector(getSensorBattery)
    const fuel :any= useSelector(getSensorFuel) 
    const temp :any= useSelector(getSensorTemperture) 
    const shareDevice :any= useSelector(getShare)
    const quicktTack :any= useSelector(getQuickTrack)
    const pto :any= useSelector(getSensorPTO)
    const realtimeTrack :any= useSelector(getRealtimeTrack)
    const canNavigation :any= useSelector(getNavigation)
    const ratePoint :any= useSelector(getRatePoint)

    const swiper = useSwiper();
    const [toast] = useIonToast();
    
    const dispatch= useDispatch()
  
    const selectDevice=()=>{
        console.log("device ",device)
      dispatch(setPreviewDevice({active:true, device: device}))
      swiper.slideNext()  
    }

    // const fastTrack=async()=>{ 
    //     let deviceId =  device?.device_id
    //     let date = moment(dateStart).format("DD/MM/YYYY")
    //     let dateend = moment(dateEnd).format("DD/MM/YYYY")
    //     let start = moment(dateStart).format("YYYY-MM-DD 00:00:00")   
    //     let end = moment(dateEnd).format("YYYY-MM-DD HH:mm:ss") 
    //     let route = await historyRoute({deviceId,date,dateend,start,end}) 
        
    //      if(route.list.length > 0){
    //         let routeBound:any = []
    //         route.list.forEach((list:any)=>{
    //             routeBound =[...routeBound,[list.latitude,list.longitude]]
    //         }) 
    //         let tarck={   route:route ,list:route.list ,   deviceIcon: 1,  bound: route?.bound  }
    //         dispatch(setFastTrack(tarck))
    //     }else{
    //         toast({...toastFail,...{
    //             message:"Not Found History Route in "+device?.name
    //         }})
    //     }
    // }
    const setPopShow=(e:any)=>{
        dispatch(setSharePop(e))
    }
    const navigationToDevice=(latitude:any,longitude:any)=>{
        window.open(
         "https://www.google.com/maps/dir/?api=1&destination="+latitude+","+longitude , '_blank'
        )
    }

    return(
        <div className="list-dev-wrapper" >
           
            <IonIcon 
                icon={!sensor?caretDownCircle:caretUpCircle} 
                color={!sensor?'primary':'secondary'}  className='cursor-pointer' 
                onClick={()=>{showSensor(!sensor)}}
                style={{position:"absolute",bottom:'-.2rem',right:"-.2rem",zIndex:999}}
            />
            <IonRow className="list-dev">
                <IonCol size="1" className='set-center' >
                    {realtimeTrack.list && <CheckTrackRealtime device={device} /> } 
                </IonCol>
                <IonCol size="4" className='set-center' style={{alignItems:'flex-start'}} > 
                    {showName.list && <DeviceName size=".8em" name={device?.name2 ? device?.name2 : device?.name} update={device.event_stamp}  showRatePonit={ratePoint.list} />  }
                </IonCol>
                <IonCol size="2.5" className='set-center'  >
                   <MotionState status={device.status} statusName={device.status_name} online={device.online} deviceId={device?.device_id}/>
                </IonCol>
                <IonCol size="1.5" className='set-center' >
                    {fuel.list && <IonLabel >
                        <small> {device.fuel_liters} </small>
                    </IonLabel> }
                </IonCol>
                <IonCol size="1.5"  className='set-center'> 
                    <GPSState deviceId={device?.device_id} gps={device.fld_sensorHigh} gsm={device.fld_signalStrength} /> 
                </IonCol>
                <IonCol size="1.5"  className='set-center'>  
                    <div className="preview-device next-btn set-center" 
                        onClick={()=>{selectDevice()}}
                    > 
                        <IonIcon icon={chevronForward} /> 
                    </div> 
                </IonCol>
                { device.canCutEngin  &&
                    <IonCol size='1.5'>
                    	 <IonIcon icon={lockClosed} color="danger" />
                    </IonCol> }

                {address?.list && 
                <IonCol size="12" > 
                    <IonLabel><small>{device?.address}</small></IonLabel>
                </IonCol>
                }
                

            </IonRow>
            <IonRow>
            </IonRow>
            {sensor?
            <IonRow style={{marginLeft:'1rem'}}>
            <IonCol size="12">  
                {speed.list && <ChipSpeed speed={device.speed}/>  } 
                {fuel.list && <ChipFuel fuel_liters={device.fuel_liters}/> }
                {battery.list && <IonChip  className="chip stack" >
                    <IonIcon 
                        icon={
                            device.fld_sensorHigh === null?batteryDeadOutline:
                            device.fld_sensorHigh === "1" ?batteryHalfOutline:batteryChargingOutline } 
                        color={ 
                        device.fld_sensorHigh === null?"medium":
                        device.fld_sensorHigh ==="1" ? "warning":"success"}></IonIcon>
                        <IonLabel>{device.fld_sensorHigh}</IonLabel>
                    </IonChip>
                }
                    {/* <ChipGSM fld_signalStrength={device.fld_signalStrength} />
                    <ChipGPS satellites={device.satellites} />  */}
                    {device.door_status && pto.list &&  <ChipPTO closeOpenSensor={device.door_status}/> }
                    {temp.list && <ChipTemp temperature={device.temperature}/> }
                   
                    {/* {quicktTack.list &&<IonChip  className="chip stack" onClick={()=>fastTrack()}>
                        <IonLabel className="icon-opt" color={"secondary"} > 
                            <FontAwesomeIcon icon={faRoute} />  
                        </IonLabel>
                    </IonChip>} */}

                    
                    {shareDevice.list &&  <IonChip  className="chip stack" onClick={()=>{setPopShow({ishow:true,id: device.device_id})}} >
                        <IonIcon id={"share-popover"+device.device_id} icon={shareSocial}  color="secondary" className="icon-opt" style={{fontSize:"1.3em"}} />
                    </IonChip>}

                    {canNavigation.list && <IonChip  className="chip stack"    onClick={()=> {navigationToDevice(device.latitude,device.longitude)}} >
                        <IonIcon icon={navigateCircleOutline}  
                            color="secondary"  
                            style={{fontSize:"1.6em" }}
                        />
                    </IonChip>}  
                    <ChipStreetView device={device} />
                </IonCol>
            </IonRow>: <></>
            }
        </div>
    )
}
interface DeviceList{
    device: Device
}
export const Devices=({device,select}:any)=>{
    const [sensor,showSensor] = useState(true)
    const dateStart :any= useSelector(getDateStart) 
    const dateEnd :any= useSelector(getDateEnd)
    const stsName :any= useSelector(getStatusName) 
    const iconShow :any= useSelector(getIconFilter) 
    const showName :any= useSelector(getName) 
    const address :any= useSelector(getAddress)
    const speed :any= useSelector(getSensorSpeed)
    const battery :any= useSelector(getSensorBattery)
    const fuel :any= useSelector(getSensorFuel) 
    const temp :any= useSelector(getSensorTemperture)  
    const pto :any= useSelector(getSensorPTO)
    const realtimeTrack :any= useSelector(getRealtimeTrack)
    const canNavigation :any= useSelector(getNavigation) 
    const expiredDate :any= useSelector(getExpireDate)
    const quicktTack:any= useSelector(getQuickTrack)
    const cutEngine:any= useSelector(getSensorCutengine)
    const streetView:any= useSelector(getStreetview)
    const notification:any= useSelector(getNotification)
    const swiper = useSwiper()
 
    const dispatch= useDispatch()
  
    const selectDevice=()=>{
        console.log("device ",device)
      dispatch(setPreviewDevice({active:true, device: device}))
    //   swiper.slideNext()  
        return select()
    }

    const setPopShow=(e:any)=>{
        dispatch(setSharePop(e))
    }
    const navigationToDevice=(latitude:any,longitude:any)=>{
        window.open(
         "https://www.google.com/maps/dir/?api=1&destination="+latitude+","+longitude , '_blank'
        )
    }

    const isExpired=(expire_date:any)=>{
        const start =  moment(new Date()).add(543,"year") ;
        const end =moment(expire_date); 
        const r1 = moment.range(start.tz('Asia/Bangkok'), end.tz('Asia/Bangkok'));  
        var diff = r1.diff('minute') ; 
      
        return diff > 0 ? false : true
    } 
    const openDialog=(option:any,device:any)=>{ 
        dispatch(setDeviceModalOpt(option))
        dispatch(setDevice(device))
        setTimeout(()=>{
          dispatch(setDeviceModal(true))
        },100)
    }
    const setIsOpen=(target:boolean)=>{
        dispatch(setDevice(device))
        dispatch(setDeviceModal(target))
    }

    useEffect(()=>{ },[address,stsName])

    return(
    <IonCard mode="md" className="ion-no-margin" style={{position:"relative", boxShadow:"none", marginBottom:".5rem",borderRadius:"0px",borderBottom:"1px solid #DDD",paddingBottom:".5rem"}}>  
        <IonGrid  className="ion-no-padding ion-padding-horizontal" >
            <IonRow style={{marginBottom:".5rem"}}> 
                {
                  iconShow?.list  &&
                <IonCol size="2.5" className="set-center ion-no-padding" style={{justifyContent:"flex-start"}}> 
                    { device?.deviceIconId!==null?  <IonImg  src={`../../assets/types/${device?.deviceIconId}.png`} alt="icon-type" style={{maxWidth:"2.7rem"}} />:
                        <IonImg  src={`../../assets/types/1.png`}  alt="icon-type-1" />
                    }
                    {/* <IonChip 
                        className="ion-text-center" mode="md" style={{marginTop:"-.7rem"}}
                        color={
                            device?.online===0 ? "medium" :
                            device?.status===7 ? "success":
                            device?.status===23? "danger" :
                            device?.status===24? "warning": "medium"
                        }
                    >
                        <IonLabel>  <small>{  
                                device.online === 0  ? t("home_status_offline").toUpperCase() : 
                                device.status === 7  ? t("home_segment_move").toUpperCase() : 
                                device.status === 24 ? t("home_status_idle").toUpperCase() : 
                                device.status === 23 ? t("home_status_stop").toUpperCase() : t("home_status_offline").toUpperCase()
                            }</small>
                        </IonLabel>
                    </IonChip> */}
                </IonCol>
                } 
                <IonCol   className="ion-no-padding" >  
                    <IonRow> 
                       {showName.list && <IonCol  className="set-center" style={{alignItems:"flex-start"}}>  
                           <IonLabel color={"dark"} > 
                                <span className="set-center-row"  >
                                    {realtimeTrack.list && <CheckTrackRealtime device={device} /> } 
                                    <IonText>&nbsp;{device?.name2 ? device?.name2 : device?.name} &nbsp;</IonText>
                                    {stsName.list &&  <div> 
                                        <IonBadge style={{fontSize:".7em"}}
                                                mode="md"
                                                color={device?.online===0 ? "medium" :
                                                    device?.status===7 ? "success":
                                                    device?.status===23? "danger" :
                                                    device?.status===24? "warning": "medium"
                                                } 
                                        > <small>{  
                                            device.online === 0  ? t("home_status_offline").toUpperCase() : 
                                            device.status === 7  ? t("home_segment_move").toUpperCase() : 
                                            device.status === 24 ? t("home_status_idle").toUpperCase() : 
                                            device.status === 23 ? t("home_status_stop").toUpperCase() : t("home_status_offline").toUpperCase()
                                        }</small> </IonBadge>
                                    </div>}
                                </span> 
                           </IonLabel>  
                        </IonCol>} 
                        {fuel.list &&<IonCol size="1.5" className="set-center">  
                           <div className="tooltip">   {device?.fuel_liters} 
                                <span className="tooltiptext"> เชื้อเพลิง </span>
                            </div>
                        </IonCol>}
                        <IonCol size="1.5" className="set-center" > 
                           <GPSState deviceId={device?.device_id} gps={device.fld_sensorHigh} gsm={device.fld_signalStrength} /> 
                        </IonCol> 
                        
                        {device?.event_id > 1  && <IonCol size="2" className="set-center ion-no-padding "> 
                            <div className="preview-device next-btn set-center" 
                                onClick={()=>{selectDevice()}}
                            >  <IonIcon icon={chevronForward} /> 
                            </div>  
                        </IonCol>}

                
                    </IonRow>
                </IonCol>
                
               <IonCol size="12"  className="ion-no-padding" >  
               {address.list && <IonLabel color={"dark"} style={{fontSize:".95em"}} > {device?.address}  </IonLabel> }
               </IonCol>
            </IonRow>
            <IonRow> 
                <IonCol size="9" className="ion-no-padding"  > 
                {speed.list && <ChipSpeed speed={device?.speed}/>  } 
                {fuel.list && <ChipFuel fuel_liters={device?.fuel_liters}/> }
                {battery.list && <IonChip  className="chip stack" >
                    <IonIcon 
                        icon={
                            device?.fld_sensorHigh === null?batteryDeadOutline:
                            device?.fld_sensorHigh === "1" ?batteryHalfOutline:batteryChargingOutline } 
                        color={ 
                        device?.fld_sensorHigh === null?"medium":
                        device?.fld_sensorHigh ==="1" ? "warning":"success"}></IonIcon>
                        <IonLabel>{device?.fld_sensorHigh}</IonLabel>
                    </IonChip>
                } 
                {quicktTack?.list && device?.online === 1 &&  <IonChip  className="chip stack"  >   <IconRouteToday  device={device} swiper={swiper} /> </IonChip> }
                    {device?.door_status && pto.list &&  <ChipPTO closeOpenSensor={device?.door_status}/> }
                    {temp.list && <ChipTemp temperature={device?.temperature}/> }  
                    {/* {shareDevice?.list &&  <IonChip  className="chip stack" onClick={()=>{setPopShow({ishow:true,id: device?.device_id})}} >
                        <IonIcon id={"share-popover"+device?.device_id} icon={shareSocial}  color="secondary" className="icon-opt" style={{fontSize:"1.3em"}} />
                    </IonChip>} */}

                    {canNavigation.list && <IonChip  className="chip stack"    onClick={()=> {navigationToDevice(device?.latitude,device?.longitude)}} >
                        <IonIcon icon={navigateCircleOutline}  
                            color="secondary"  
                            style={{fontSize:"1.6em" }}
                        />
                    </IonChip>}  
                  
                    {streetView?.list  && <ChipStreetView device={device} /> }
                    { cutEngine?.list  && device.canCutEngin  &&  <ChipCutEngine device={device} /> }
                    {notification?.list &&  <IonChip className="chip stack"  onClick={()=>{openDialog("message",device);setIsOpen(true);}}  >
                        <IonIcon icon={notificationsOutline}    color="secondary"  
                        style={{fontSize:"1.4em" }} />
                    </IonChip> }
                  
                </IonCol>
                <IonCol size="3" className="set-center-row ion-no-padding" >
                 {   expiredDate.list && device.expiration_date && <>
                    <IonLabel 
                     aria-label={isExpired(device.expiration_date)?"expired":"actived"} 
                     color={isExpired(device.expiration_date)?"danger":"success"} 
                     className="expire-date set-center-row" 
                    > 
                        <small>{moment(device.expiration_date).format("DD/MM/YY")} &nbsp;&nbsp;</small>
                    </IonLabel> { isExpired(device.expiration_date)?
                        <IonLabel aria-label="icon-expired" color={"danger"}><FontAwesomeIcon icon={faCircleXmark} /></IonLabel>:
                        <IonLabel aria-label="icon-actived" color={"success"}><FontAwesomeIcon icon={faCircleCheck} /></IonLabel>
                    } 
                 </> }
                 
                </IonCol> 

            
            </IonRow>
        </IonGrid> 
    </IonCard>
    )
}