import { IonAvatar, IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel, IonMenu, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react"
import { calendarOutline, menuOutline } from "ionicons/icons";
import moment, { version } from "moment";
import './css/Dashboard.css'
import { menuController } from "@ionic/core/components";
import { useEffect, useState } from "react";
import { allDevices, deviceEvent,   getStorage } from "../actions";
import { WidgetAreaChart, WidgetCircleChart, WidgetEvent, WidgetEvents, WidgetInZone, WidgetMap, WidgetOutZone, WidgetOverSpeed, WidgetTemperature } from "../components/DashboardComponent";
import { setDevices, setBackupDevices, getDevices, setSignin, getMenuOpen } from "../store/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { getStatusCard, setPieChart, setStatusCard } from "../store/dashboardSlice";
import { AuthCheck } from "./Home";
import { MenuContent } from "../components/Menu";

const Dashboard: React.FC  = ()=>{
    const dispatch = useDispatch()
    const stsCard :any= useSelector(getStatusCard) 
    const [deviceEvents, setDeviceEvents] = useState(mockupEvent) 
    const menuOpen :any= useSelector(getMenuOpen) 

    const openMenu=async()=>{ 
        //'main-menu'   
        await menuController.enable(true,'dashboard-menu');
        await menuController.open('dashboard-menu');
      }
    const getAllDevices=async()=>{
        let result = await allDevices({}) //devicesAll({user_id: parseInt(user_id) })
      
        await dispatch(setDevices(result))
        await dispatch(setBackupDevices(result))
    }

    const singin=async()=>{
        const isSign = await getStorage("isSignin")
        dispatch(setSignin(isSign))
      }

    useEffect(()=>{
        singin()
        getAllDevices() 
        dispatch(setPieChart({data:[44, 55, 41, 17 ],color:['#5FBF05','#e9b702','#c73f0e','#85807e']}))
        dispatch(setStatusCard([{number:2,status:7},{number:3,status:23},{number:2,status:24},{number:2,status:0}]))
    },[])
  
    return(<>
    <IonPage id="main-content">
        {/* <AuthCheck /> */}
        <IonHeader className="ion-no-border"> 
        </IonHeader> 
        <IonContent scrollY className="ion-padding"> 
        <IonGrid>
            <IonRow className="ion-justify-content-center"> 
                <IonCol sizeLg='9' sizeXl='10' style={{height:"100vh", overflowY:"scroll"}}>
                    <IonRow className='ion-padding'>
                        <IonCol size="12" >
                            <IonLabel className="dashboard-title" color={'dark'}>Dashboard</IonLabel><br/>
                            <sub className='dashboard-sub-title'>Devices  Overview </sub> <br/> <br/>
                        </IonCol>
                        <IonCol   sizeXs='12' sizeSm='12'  sizeMd="12" sizeXl="5">
                            <div className="dashboard-card set-center" style={{height:"100%"}}>
                                <IonRow style={{height:"100%"}}>
                                {
                                stsCard.map((sts:any,index:any)=>
                                    <IonCol key={index}  size="6" style={{height:"50%"}}>
                                        <CardStaus detail={sts} />
                                    </IonCol>
                                    )
                                } 
                            </IonRow>
                            </div>
                        </IonCol>
                        <IonCol  sizeXs='12' sizeSm='12' sizeMd='12' sizeXl="7">
                             
                            <div className="dashboard-card" >
                                <WidgetAreaChart />
                            </div>
                        </IonCol>
                        <IonCol size="12" >
                            <IonRow>
                                <IonCol sizeXs='12' sizeSm='12' sizeMd='12' sizeXl="8" >
                                    <IonRow>
                                        <IonCol   sizeXs='12' sizeSm='12' sizeMd='6' sizeXl="7.5" >
                                            <WidgetMap/>
                                        </IonCol>
                                        <IonCol  sizeXs='12' sizeSm='12' sizeMd='6' sizeXl="5"  >
                                            <WidgetCircleChart />
                                        </IonCol>

                                        <IonCol size="12" >
                                            <br/>
                                        <IonRow>
                                            <IonCol  sizeXs='12' sizeSm='6' sizeMd='6' sizeXl="3">
                                                {/* <WidgetOverSpeed /> */}
                                            </IonCol>
                                            <IonCol  sizeXs='12' sizeSm='6' sizeMd='6' sizeXl="3" >
                                                <WidgetTemperature />
                                            </IonCol>
                                            <IonCol  sizeXs='12' sizeSm='6' sizeMd='6' sizeXl="3" >
                                                <WidgetInZone />
                                            </IonCol>
                                            <IonCol  sizeXs='12' sizeSm='6' sizeMd='6' sizeXl="3" >
                                                <WidgetOutZone />
                                            </IonCol>
                                        </IonRow>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                                <IonCol sizeXs='12' sizeSm='12' sizeMd='12' sizeXl="4" >
                                    <WidgetEvents event={deviceEvents}/>
                                </IonCol>
                            </IonRow>
                        </IonCol> 
                    </IonRow>
                </IonCol>
            </IonRow>

          
         </IonGrid>
 
        </IonContent>
    </IonPage>
    <MenuDashboard />
    </> )
}
export default Dashboard;

const DashboardSideMenu=()=>{
    const devices = getStorage(getDevices)
    const [deviceEvents, setDeviceEvents] = useState(mockupEvent)
    const dispatch = useDispatch()

    const getAllDevices=async()=>{
        let result = await allDevices({}) //devicesAll({user_id: parseInt(user_id) })
        return result
    }

    const getEvevnt=async()=>{
        let events:any = []
        let alldevice = await getAllDevices()
        alldevice.forEach(async(el:any) => {
            let event = await  deviceEvent({ 
                device_id:el.device_id,
                name:el.name 
            })
            events = events.concat(event)
        });
        console.log("getEvevnt ",events)
        setDeviceEvents(events)
    }

    const [profile,setProfile] = useState({
        first_name: "Name",
        id: 0 ,
        last_name: "Last Name" ,
        url_realtime: ""
    })
  
    const getProfile=async()=>{
      let locaprofile = await getStorage("profile")
      setProfile(locaprofile)
    }
    

    useEffect(()=>{
        setDeviceEvents([])

        getProfile()
        getEvevnt()
    },[])
    
    return(<div className="dashboard-side-menu" style={{overflowX:"hidden"}}>
        {/* <IonHeader className="ion-no-border"> 
        </IonHeader>  */}
        <div className="ion-padding" >
            <div className='header-menu set-center' style={{backgroundImage:`url('../../assets/img/promthep.jpg')`,borderRadius:"15px"}}>
                <div className='inner-header'> </div> 
                <div className='profile-header set-center' style={{top:"-3rem"}} > 
                    <IonAvatar>
                        <img alt="Silhouette of a person's head" src={`../../assets/icon/user-person.svg`} />
                    </IonAvatar>   
                    <IonLabel color={"dark"}>{profile.first_name} {profile.last_name}</IonLabel> 
                    <IonLabel className='version'>Version {version}</IonLabel>
                </div> 
            </div>
        </div>
        <WidgetEvents event={deviceEvents}/><br/> 
              
    </div>)
}

const MenuDashboard=()=>{
    const [profile,setProfile] = useState({
        first_name: "Name",
        id: 0 ,
        last_name: "Last Name" ,
        url_realtime: ""
    })
  
    const getProfile=async()=>{
      let locaprofile = await getStorage("profile")
      setProfile(locaprofile)
    }
    const closeMenu=async()=>{ 
        await menuController.enable(false,'dashboard-menu');
        await menuController.enable(true,'main-menu');
    }


    return(<>
        <IonMenu menuId="dashboard-menu" contentId="main-content" type='overlay' side='start' 
         onIonWillOpen={()=>{ getProfile()}} onIonDidClose={()=>{closeMenu()}} >
        {/* <IonHeader className="ion-no-border">
          <IonToolbar> 
          </IonToolbar>
        </IonHeader> */}
        <IonContent className="ion-padding">
            <DashboardSideMenu />
        </IonContent>
      </IonMenu>
      </>
    )
}

export const CardStaus=({detail}:any)=>{
    useEffect(()=>{ 
    },[])
    return(
        <div className="card-status" style={{height:"100%"}} >
            <IonRow style={{height:"100%"}}>
                <IonCol size="4.5" className="set-center">
                    <div className="device-number" >
                        <IonLabel>{detail?.number}</IonLabel>
                    </div>
                </IonCol>
                <IonCol size="6.5" style={{height:"100%",padding:'1rem'}} className="ion-text-center set-center">   
                        <img src={
                            detail?.status === 7 ?  "../assets/car-move.svg" :
                            detail?.status === 23 ? "../assets/car-idle.svg":
                            detail?.status === 24 ? "../assets/car-stop.svg":
                            "../assets/car-offline.svg"
                        }  alt='' style={{width:"100%"}}  />
                       <IonLabel className={`status-name`} 
                        color={
                            detail?.status === 7 ?  "success" : 
                            detail?.status === 23 ? "warning" : 
                            detail?.status === 24 ? "danger" :  "medium"
                        }
                       >
                            {
                            detail?.status === 7 ?  "MOVE" : 
                            detail?.status === 23 ? "IDLE" : 
                            detail?.status === 24 ? "STOP" :  "OFFLINE"
                            } 
                        </IonLabel>  
                </IonCol>
            </IonRow> 
       </div>
    )
}






const mockupEvent =[{
    vehicle:"Accord-1345 *เทส*" ,
    address: "( 180.05 m)296 โตโยต้า โคราช 1988 ยูสคาร์  ต.จอหอ อ.เมืองนครราชสีมา  จ.นครราชสีมา 30310",
    codeRef: " ",
    eventName: "ACC OFF",
    eventTime: "2022-08-12 18:39"
  }]