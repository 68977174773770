import { IonAlert, IonButton, IonButtons, IonCheckbox, IonChip, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonPage,   IonRippleEffect,   IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonText, IonTextarea, IonTitle, IonToggle, IonToolbar } from "@ionic/react" 
import {  SelectPopover, Tables } from "../components/AppComponent";
import { MenuContent } from "../components/Menu"; 
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  allDevices, devicetype, getDevices, getStorage, getUsers, tablesDevices, tablesSensor, tablesUsers } from "../actions";
import { getBackup, getLoading, getMenuOpen, getProfile, setDevices, setLoading } from "../store/appSlice";
import { checkmarkCircleOutline, chevronBack, chevronDown, chevronForward, closeCircle, closeCircleOutline, createOutline, search, settingsOutline, trashOutline } from "ionicons/icons";
import contry from "../country.json"
import { SettingIcon } from "../components/OptionDialogs";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'; 
import { EventMap } from "../leaflet/LeafletLayers";
import moment from "moment";
import { getDevicesAdmin, getDevicesAdminBackup, getUsersAdmin, setDevicesAdmin, setDevicesAdminBackup, setUsersAdmin } from "../store/settingSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import 'swiper/css';
import "./css/Admin.css" 
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
  
 
const Admin: React.FC =()=>{
    const loading :any= useSelector(getLoading) 
    const [segment,setSegment] = useState("device")
    const [deCurrent,setDeCurrent] = useState(1)
    const [sensorCurrent,setSensorCurrent] = useState(1)
    const [usrCurrent,setUsrCurrent] = useState(1)
    const [devicesTable,setDeviceTable] = useState({header:[],detail:{},body:[] })
    const [sensorsTable,setSensorTable] = useState({header:[],detail:{},body:[] })
    const [usersTable,setUsersTable] = useState({header:[],detail:{},body:[] })
    const menuOpen :any= useSelector(getMenuOpen)
    const profile :any= useSelector(getProfile)
 

    const dispatch = useDispatch()
  
   
    useEffect(()=>{ 
      dispatch(setLoading(false)) 
      getTableDevice()
 
    },[loading,deCurrent])

    const nextPageDevice=(e:any)=>{ 
        setDeCurrent(e) 
    }

    const nextPageUser=(e:any)=>{ 
        setUsrCurrent(e) 
    }
    const nextPageSensor=(e:any)=>{ 
        setUsrCurrent(e) 
    }
    const getTableDevice=async()=>{
        const devicetable:any = await tablesDevices()
        const sensortable:any = await  tablesSensor()
        const usersTable:any = await  tablesUsers()
        setDeviceTable(devicetable)
        setSensorTable(sensortable)
        setUsersTable(usersTable)

        console.log("usersTable ",usersTable)
        console.log("sensortable ",sensortable)
    }

    useEffect(()=>{
        const setup=async()=>{ 
         let devices = await getDevices({})
         console.log("devices ",devices) 
         dispatch(setDevicesAdmin(devices)) 
         dispatch(setDevicesAdminBackup(devices)) 

         let users = await getUsers({})
         console.log("users ",users) 
         dispatch(setUsersAdmin(users))  
        }
        setup()
     },[])

    return(
        <IonPage>
        <IonContent className="ion-padding"  fullscreen>   
        <IonHeader className="ion-no-border" mode="md" >
         <IonToolbar mode="md">
            <IonTitle>
                <IonText style={{fontWeight:"bold"}} >Admin Panel</IonText> <br/>
                <sub>{moment().format("dddd , MMMM DD YYYY HH:mm")}</sub>
            </IonTitle>
            <IonButton slot="end"  mode="ios" fill="clear" shape="round" color={"dark"}>
                <IonIcon icon={settingsOutline} />
            </IonButton>
          </IonToolbar>
        </IonHeader><br/> 
        <IonGrid>
            <IonRow>
                <IonCol size="12" >
                <Tabs className={"home-screen-tabs"}  >
                    <TabList>
                        <Tab>  อุปกรณ์ </Tab>
                        <Tab>  ผู้ใช้งาน </Tab>
                    </TabList>
                    <TabPanel> 
                        <MinimalTableDevices />
                    </TabPanel>
                    <TabPanel> 
                        <MinimalTableUsers />
                    </TabPanel>
                </Tabs> 
                </IonCol>
            </IonRow>
        </IonGrid>

  
        </IonContent>   
        </IonPage>
    )
}
export default Admin;

const MinimalTableUsers=()=>{
    const users:[] |any= useSelector(getUsersAdmin)  
    const [keyword,setKeyword] = useState("")
    const [usersAdmin,setUsersAdmin] = useState<[]|any>([]) 

    useEffect(()=>{
        setUsersAdmin(users)
        console.log("users ",users)
    },[users])

    const searchChange=async(word:any)=>{
        setKeyword(word)
        if(word.length > 0){ 
            let filter = await users.filter((el:any)=> el.firstname.indexOf(word) > -1 ||  el.username.indexOf(word) > -1 ||  el.email.indexOf(word) > -1 )
            setUsersAdmin(filter)
        }else{
            setUsersAdmin(users)
        }
    }

    return(
    <div>
        <IonGrid>
            <IonRow className={`grid-rows headers`}  >
                <IonCol size="6" className="ion-padding" >
                    <IonLabel className="title">{"Users"}</IonLabel><br/>
                    <sub>{"View And Edit Users"}</sub>
                </IonCol>
                <IonCol size="6" >
                    <IonSearchbar mode="ios" className="admin-search-table" value={keyword} onIonInput={(e:any)=>{setKeyword(e.detail.value);searchChange(e.detail.value)}} ></IonSearchbar>
                </IonCol>

                <IonCol size="2" className="ion-padding"  > <IonLabel style={{fontSize:".9em"}} color={"dark"} >{"User Name"}</IonLabel> </IonCol> 
                <IonCol size="2.5" className="ion-padding"  > <IonLabel style={{fontSize:".9em"}} color={"dark"} >{"First Name"}</IonLabel> </IonCol> 
                <IonCol size="2" className="ion-padding"  > <IonLabel style={{fontSize:".9em"}} color={"dark"} >{"Last Name"}</IonLabel> </IonCol> 
                <IonCol size="2.5" className="ion-padding"  > <IonLabel style={{fontSize:".9em"}} color={"dark"} >{"Email"}</IonLabel> </IonCol> 
                <IonCol size="2" className="ion-padding"  > <IonLabel style={{fontSize:".9em"}} color={"dark"} >{"Account Tmz"}</IonLabel> </IonCol> 
                <IonCol size="1" className="ion-padding"  > <IonLabel style={{fontSize:".9em"}} color={"dark"} >{"Edit"}</IonLabel> </IonCol> 
            </IonRow>
            <div style={{width:"100%",height:"70vh",overflowY:"scroll"}}>
            {
                usersAdmin.map((d:any,index:any)=>
                <IonRow key={index} className={`grid-rows ${index%2 >0 ? "shade":"light"}`}  >
                    <IonCol size="2" className="ion-padding" style={{display:"flex",alignItems:"center"}} >  
                        {d.username ? d.username :"-" }
                     </IonCol>
                     <IonCol size="2.5" className="ion-padding" style={{display:"flex",alignItems:"center"}} >  
                        {d.firstname}
                     </IonCol>
                     <IonCol size="2" className="ion-padding" style={{display:"flex",alignItems:"center"}} >  
                        {d.lastname}
                     </IonCol>
                     <IonCol size="2.5" className="ion-padding"  style={{display:"flex",alignItems:"center"}}>  
                        {d.email}
                     </IonCol>
                     <IonCol size="2" className="ion-padding"  style={{display:"flex",alignItems:"center"}}>  
                        {d.account_tmz}
                     </IonCol>
                     <IonCol size="1" className="ion-padding"  >  
                        <EditAdminUser user={d} />
                     
                     </IonCol> 
                </IonRow> 
                )
            } 
            </div> 
        </IonGrid>
    </div>
    )
}

const EditAdminUser=({user}:any)=>{
     const [modal,setModal] = useState(false)
    return(<>
    <button className="table-admin-btn-edit" onClick={()=>{setModal(true)}} > 
        <FontAwesomeIcon  icon={faEdit} />
    </button>
    <IonModal isOpen={modal} initialBreakpoint={0.8} mode="ios" onIonModalDidDismiss={()=>{setModal(false)}} >
        <IonContent className="ion-padding" >
            {JSON.stringify(user)}
        </IonContent>
    </IonModal>
    </>)
}

const MinimalTableDevices=()=>{
    const [devices,setDevices] = useState<any>([]) 
    const backup :any= useSelector(getDevicesAdminBackup) 
    const [keyword,setKeyword] = useState("")
    const [devicesAdmin,setDevicesAdmin] = useState([]) 
    const [modal,setModal] = useState(false)
    const [name,setName] = useState("")
    const [deviceTypeId , setDeviceTypeId] = useState(-1)
    const [devicetypes , serDevicesType] = useState<any>([])

    useEffect(()=>{ 
        const getadmindevice=async ()=>{ 
            let result = await allDevices({}) 
            console.log("result ",result)
            setDevicesAdmin(result)
           let dtype =await devicetype({})
           console.log("devicetype dtype ",dtype)
           serDevicesType(dtype)
        }
        getadmindevice()
    },[devices])

    const searchChange=async(word:any)=>{
        setKeyword(word)
        if(word.length > 0){ 
            let filter:any  = await devices.filter((el:any)=> el.name.indexOf(word) > -1 )
            setDevicesAdmin(filter)
        }else{
            setDevicesAdmin(devices)
        }
    }
    const selectDevice=(device:any)=>{
        console.log("selectDevice ",device)
        setDeviceTypeId(device?.deviceTypeId)
        setModal(true)
    }

    return(
    <div>
        <IonGrid>
            <IonRow className={`grid-rows headers`}  >
                <IonCol sizeXs="12" sizeMd="6" className="ion-padding" >
                    <IonLabel  className="title">{"Devices"}</IonLabel><br/>
                    <sub>{"View And Edit Devices"}</sub>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6" >
                    <IonSearchbar mode="ios" className="admin-search-table" value={keyword} onIonInput={(e:any)=>{setKeyword(e.detail.value);searchChange(e.detail.value)}} ></IonSearchbar>
                </IonCol>
                <IonCol size="7" className="ion-padding"  > <IonLabel color={"dark"} style={{fontSize:".9em"}} >{"Device Name"}</IonLabel> </IonCol>
                <IonCol size="3" className="ion-padding"  > <IonLabel color={"dark"} style={{fontSize:".9em"}} >{"Device Type"}</IonLabel></IonCol>
                <IonCol size="2" className="ion-padding"  > 
                    Edit
                </IonCol>
            </IonRow>
            <div style={{width:"100%",height:"70vh",overflowY:"scroll"}}>
            {
                devicesAdmin.map((d:any,index:any)=>
                <IonRow key={index}  className={`grid-rows ${index%2 >0 ? "shade":"light"}`}  >
                    <IonCol size="7" className="ion-padding"  > 
                        {d.name}
                     </IonCol>
                    <IonCol size="3" className="ion-padding"  > 
                      {d.deviceType}
                    </IonCol>
                    <IonCol size="2" className="ion-padding wrapper"  > 
                        <button className="ion-activatable ripple-parent rectangle table-admin-btn-edit"  onClick={()=>{selectDevice(d)}}  >
                            <FontAwesomeIcon  icon={faEdit} />
                            <IonRippleEffect></IonRippleEffect> 
                        </button>
                    </IonCol>
                </IonRow> 
                )
            } 
            </div> 
        </IonGrid>
        <IonModal isOpen={modal} initialBreakpoint={0.8} mode="ios" onIonModalDidDismiss={()=>{setModal(false)}} >
            <IonContent className="ion-padding" >
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" >
                        <IonLabel><strong>เพิ่มอุปกรณ์ใหม่</strong></IonLabel><br/><br/>
                    </IonCol>
                    <IonCol size="6" >
                        <IonLabel>Device Name</IonLabel>
                        <div className="input " >
                            <IonInput mode="ios"  value={name} ></IonInput>
                        </div>
                    </IonCol>
                    <IonCol size="6" >
                        <IonLabel>Device Type</IonLabel>
                        {/* <div className="input " >
                            <IonInput mode="ios"  value={name} ></IonInput>
                        </div> */}<div className="input " >
                        <IonSelect  placeholder="Select"  interface="popover" value={deviceTypeId} >
                            {devicetypes.map((dt:any,i:any)=>  <IonSelectOption key={i+dt} value={dt?.val} >{dt?.label}</IonSelectOption>  )  } 
                        </IonSelect></div>
                    </IonCol>
                    <IonCol size="6" >
                        <IonLabel>Device id</IonLabel>
                        <div className="input " >
                            <IonInput mode="ios"  value={name} ></IonInput>
                        </div>
                    </IonCol>
                    <IonCol size="6" >
                        <IonLabel>หมายเลขโทรศัพท์</IonLabel>
                        <div className="input " >
                            <IonInput mode="ios"  value={name} ></IonInput>
                        </div>
                    </IonCol>
                    <IonCol size="6" >
                        <IonLabel>เลขไมล์</IonLabel>
                        <div className="input " >
                            <IonInput mode="ios"  value={name} ></IonInput>
                        </div>
                    </IonCol>
                    <IonCol size="6" >
                        <IonLabel>ชื่อผู้ขับขี่</IonLabel>
                        <div className="input " >
                            <IonInput mode="ios"  value={name} ></IonInput>
                        </div>
                    </IonCol>
                    <IonCol size="12" >
                        <IonLabel>ชื่อผู้ขับขี่</IonLabel>
                        <div className="input " >
                            <IonTextarea readonly={true} label="Readonly textarea" placeholder="Can't edit this"></IonTextarea>
                        </div>
                    </IonCol>
                    <IonCol size="12" >
                        <IonLabel>วันหมดอายุ</IonLabel> <br/>
                        <div className="input  "> 
                          <input type="date" style={{background:"transparent", border:"none",minHeight:"2.6rem"}} ></input>
                        </div>
                    </IonCol> 
                    <IonCol size="12"  className="set-center" style={{justifyContent:"flex-end"}} >
                        <IonButtons>
                            <IonButton size="small" mode="ios" >  <IonLabel>บันทึก</IonLabel> </IonButton>
                            <IonButton size="small" mode="ios" >  <IonLabel> ยกเลิก </IonLabel> </IonButton>
                        </IonButtons>
                    </IonCol>
                  </IonRow>
                </IonGrid>            
            </IonContent>
        </IonModal>
    </div>
    )
}


const AdminProfile=()=>{
    const [contrie,setCountrie] = useState({value: 'Thailand', label: 'Thailand'})
    const [speedunit,setSpeedUnit] = useState({value:'kmh',label:'KM/H'})
    const [distanceUnit,setDistanceUnit] = useState({value:'km',label:'KM'})
    const [volumeUnit,setVolumeUnit] = useState({value:'l',label:'l'})
    const [fuelUnit,setFuelUnit] = useState({value:'kml',label:'kml'})
    const [pressureUnit,setPressureUnit] = useState({value:'kpa',label:'kpa'})
    const [tempUnit,setTempUnit] = useState({value:'C',label:'C'})
    const [countries,setContries] = useState(contry?.countries)
    const [popover,setPopover] = useState(false)
    const [accountEdit ,setAccountEdit ] = useState("") 
    const [company,seetCompany] = useState("")
    const [website,setWebsite] = useState("")
    const [email,setEmail] = useState("")
    const [address,setAddress] = useState("")
    const [city,setCity] = useState("") 
    const [usernameEdit ,setUernameEdit ] = useState("")
    const [passwordEdit ,setPasswordEdit ] = useState("")
    const [confirmpassword,setComfirmPassEdit ] = useState("")
    const [firstnameEdit ,setFirstnameEdit ] = useState("")       
    const [lastnameEdit ,setLastnameEdit ] = useState("") 
    const [timezoneSelect ,setTimezoneEdit ] = useState("") 
    const [lineId ,setLineEdit ] = useState("") 
    const profile :any= useSelector(getProfile)
     
    useEffect(()=>{ 
        console.log("countries ",countries) 
    },[])

    const searchCountry=(word:any)=>{
        if(word && word.length > 1){
            const needle = word.toLowerCase()
            const finding = countries.filter((e)=> e.value.toLowerCase().indexOf(needle) > -1)
            setContries(finding)
        }else{
            setContries(contry?.countries)
        }
    }  
 
    return(
        <div className="admin-profile" >
        <IonGrid>
            <IonRow>
                <IonCol size="12" >
                    <h5><strong>Your Account</strong></h5>
                </IonCol>
                <IonCol size="6" >
                    <div className="input" >
                        <IonInput mode="ios" placeholder="Account" value={accountEdit}  ></IonInput>
                    </div>    
                </IonCol>
                <IonCol size="6" >
                    <div className="input" >
                        <IonInput mode="ios"  placeholder="Company"  ></IonInput>
                    </div>    
                </IonCol>
                <IonCol size="6" >
                    <div className="input" >
                        <IonInput mode="ios" placeholder="Website" ></IonInput>
                    </div>    
                </IonCol>
                <IonCol size="6" >
                    <div className="input" >
                        <IonInput mode="ios" placeholder="Email" ></IonInput>
                    </div>    
                </IonCol>
            </IonRow>

            <IonRow>
                <IonCol size="12" >
                    <strong>Account Address</strong> 
                </IonCol>
                <IonCol size="12" >
                    <div className="input" >
                        <IonInput mode="ios" placeholder="Address"  ></IonInput>
                    </div>    
                </IonCol>
                <IonCol size="6" >
                    <div className="input" >
                        <IonInput mode="ios"  placeholder="City" ></IonInput>
                    </div>    
                </IonCol>
                <IonCol size="6" > 
                    <SelectPopover 
                        id={'country'}
                        contents={countries} 
                        title={'Country'} 
                        subtitle={"select your account's country"}  
                        selected={(e:any)=>{return setCountrie(e)}}
                        current={contrie} 
                    />
                </IonCol> 
            </IonRow>

            <IonRow>
                <IonCol size="12" >
                    <strong>Account Unit</strong> 
                </IonCol>
                <IonCol size="6" >
                    <SelectPopover 
                        id={'speedunit'}
                        contents={[{value:'kmh',label:'KM/H'},{value:'mph',label:'mph'}]} 
                        title={'Speed Unit'} 
                        subtitle={"set default speed unit for application."}  
                        selected={(e:any)=>{return setSpeedUnit(e)}}
                        current={speedunit} 
                    />   
                </IonCol>
                <IonCol size="6" >
                    <SelectPopover 
                        id={'distanceunit'}
                        contents={[{value:'km',label:'KM'},{value:'mile',label:'Mile'}]} 
                        title={'Distance Unit'} 
                        subtitle={"set default distance unit for application."}  
                        selected={(e:any)=>{return setDistanceUnit(e)}}
                        current={distanceUnit} 
                    />   
                </IonCol>
                <IonCol size="6" >
                    <SelectPopover 
                        id={'volumeunit'}
                        contents={[{value:'l',label:'l'},{value:'gal',label:'gal'}]} 
                        title={'Volume Unit'} 
                        subtitle={"set default voulume unit for application."}  
                        selected={(e:any)=>{return setVolumeUnit(e)}}
                        current={volumeUnit} 
                    />   
                </IonCol>
                <IonCol size="6" >
                    <SelectPopover 
                        id={'fuelefficiency'}
                        contents={[{value:'kml',label:'kml'},{value:'mpg',label:'mpg'}]} 
                        title={'Fuel Efficiency'} 
                        subtitle={"set default fuel efficiency for application."}  
                        selected={(e:any)=>{return setFuelUnit(e)}}
                        current={fuelUnit} 
                    />   
                </IonCol>
                <IonCol size="6" >
                    <SelectPopover 
                        id={'pressure'}
                        contents={[{value:'kpa',label:'kpa'},{value:'bar',label:'bar'}]} 
                        title={'Pressure unit'} 
                        subtitle={"set default pressure unit for application."}  
                        selected={(e:any)=>{return setPressureUnit(e)}}
                        current={pressureUnit} 
                    />   
                </IonCol>
                <IonCol size="6" >
                    <SelectPopover 
                        id={'temperature'}
                        contents={[{value:'C',label:'C'},{value:'F',label:'F'}]} 
                        title={'Temperature Unit'} 
                        subtitle={"set default temperature unit for application."}  
                        selected={(e:any)=>{return setTempUnit(e)}}
                        current={tempUnit} 
                    />   
                </IonCol>
            </IonRow>

            <IonRow>
                <IonCol size="12" >
                    <strong>Restrictions</strong> 
                </IonCol>
                <IonCol size="6" >
                    <div className="input" >
                        <IonInput mode="ios" placeholder="Max Nr. Device"  ></IonInput>
                    </div>    
                </IonCol>
                <IonCol size="6" >
                    <div className="input" >
                        <IonInput mode="ios"  placeholder="Max Nr. Users" ></IonInput>
                    </div>    
                </IonCol>
                <IonCol size="6" >
                    <div className="input" >
                        <IonInput mode="ios" placeholder="Max Nr. Geofences "  ></IonInput>
                    </div>    
                </IonCol>
                <IonCol size="6" >
                    <div className="input" >
                        <IonInput mode="ios"  placeholder="Max Nr. Notifiers" ></IonInput>
                    </div>    
                </IonCol>
                <IonCol size="6" >
                    <div className="input" >
                        <IonInput mode="ios" placeholder="Max Nr. POIs"  ></IonInput>
                    </div>    
                </IonCol>  
            </IonRow>
        </IonGrid>
        </div>
    )
}



export const AddEditTableModal=({role,action,isopen,setCloseOpen,deviceId}:any)=>{ 
    const [segment,setSegment] = useState(role)
    const [swiperRef,setSwiperRef] = useState(null) 
    const changeSegment=(val:any)=>{
        setSegment(val) 
        if(val ==="users"){
            //@ts-ignore
            swiperRef?.slideTo(0)
        }else if(val ==="devices"){
            //@ts-ignore
            swiperRef?.slideTo(1) 
        } 
    }
    const slideChange=(slide:any)=>{
        console.log("slide ",slide.activeIndex)
        if(slide.activeIndex === 0){
            setSegment("users")
        }else if(slide.activeIndex === 1){
            setSegment("devices")
        } 
    }
    useEffect(()=>{ 
        console.log("role ",role)
        changeSegment(role)
         
    },[])

    return(
        <div> 
            <IonModal isOpen={isopen} mode="ios" className="add-edit-table-modal" onIonModalDidDismiss={()=>{return setCloseOpen(false)}} >
                <IonHeader className="header ion-no-border">
                    <IonToolbar color={"transparent"}  >
                        <IonRow>
                            <IonCol size="11" className="ion-text-left">
                                <IonTitle>Add / Edit</IonTitle>
                            </IonCol>
                            <IonCol size="1" className="set-center" >
                                <IonButton 
                                    mode="ios" fill="clear" color={"primary"}  size="small"
                                    onClick={()=>{return setCloseOpen(false)}}
                                >
                                    <IonIcon icon={closeCircle}  />
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding" >
                    <IonGrid style={{paddingLeft:'2rem',paddingRight:'2rem'}}>
                        <IonRow>
                            <IonCol size="4" >
                                <IonSegment  
                                    value={segment} 
                                    onIonChange={(e:any)=>{changeSegment(e.detail.value)}}
                                >
                                    <IonSegmentButton value="users">
                                        <IonLabel>User</IonLabel>
                                    </IonSegmentButton>
                                    <IonSegmentButton value="devices">
                                        <IonLabel>Device</IonLabel>
                                    </IonSegmentButton> 
                                </IonSegment>
                            </IonCol>
                            <IonCol size="8" className="set-center" > <div style={{width:'100%',height:'1px',borderTop:'1px solid #DDD'}}></div> </IonCol>
                        </IonRow>
                        <Swiper   
                            draggable={false}   
                            allowTouchMove={false}
                            onSlideChangeTransitionEnd={(e) =>{slideChange(e)}}
                            onSwiper={(swiper:any) => {setSwiperRef(swiper)}}  
                        >
                            <SwiperSlide >
                                {/* user slideTo(0) */}
                                <AddEditUser />
                            </SwiperSlide>
                            <SwiperSlide >
                                {/* devicce */}
                                <AddEditDevice deviceId={deviceId} />
                            </SwiperSlide> 
                        </Swiper>
                        
                    </IonGrid>
                </IonContent>

            </IonModal>
        </div>
    )
}

const AddEditUser=()=>{ 
    const [timezone,setTimezone] = useState({value:'Asia/Bangkok',label:'Asia/Bangkok'})
    const [isSearch , setIsSearch] = useState(false)
    const backup :any= useSelector(getBackup)
    const [username,setUsername] = useState("")
    const [password,setPassword] = useState("")
    const [confirmPass,setConfirmPass] = useState("")
    const [firstname,setFirstname] = useState("")
    const [lastname,setLastname] = useState("")
    const [email,setEmail] = useState("")
    const [language,setLanguage] = useState("")
    const [sendCmd,isSendCmd] = useState(false)
    const [editVehicle,isEditVehicle] = useState(false)

    useEffect(()=>{},[isSearch])

    return(
        <IonGrid className="user-add-edit-container">
            <IonRow  >
                <IonCol size="6" >
                    {/* <IonItem lines="none" color={"transparent"}  > </IonItem> */}
                    <br/><IonLabel className="userprofile-title" >User Profile</IonLabel><br/>
                    <IonRow style={{marginTop:'1rem'}}>
                    <IonCol size="12" >
                        <div className="input" >
                            <IonInput mode="ios" placeholder="Username" value={username} onIonInput={(e:any)=>{setUsername(e.detail.value)}} ></IonInput>
                        </div>    
                    </IonCol>
                    <IonCol size="12" >
                        <div className="input" >
                            <IonInput mode="ios" placeholder="Password" value={password}  onIonInput={(e:any)=>{setPassword(e.detail.value)}} ></IonInput>
                        </div>    
                    </IonCol>
                    <IonCol size="12" >
                        <div className="input" >
                            <IonInput mode="ios" placeholder="Confirm Password"  value={confirmPass}  onIonInput={(e:any)=>{setConfirmPass(e.detail.value)}} ></IonInput>
                        </div>    
                    </IonCol>
                    <IonCol size="12" >
                        <div className="input" >
                            <IonInput mode="ios" placeholder="First name" value={firstname}  onIonInput={(e:any)=>{setFirstname(e.detail.value)}}  ></IonInput>
                        </div>    
                    </IonCol>
                    <IonCol size="12" >
                        <div className="input" >
                            <IonInput mode="ios" placeholder="Last name" value={lastname}  onIonInput={(e:any)=>{setLastname(e.detail.value)}} ></IonInput>
                        </div>    
                    </IonCol>
                    <IonCol size="12" >
                        <div className="input" >
                            <IonInput mode="ios" placeholder="Email" value={email}  onIonInput={(e:any)=>{setEmail(e.detail.value)}}  ></IonInput>
                        </div>    
                    </IonCol>
                    <IonCol size="12" >
                        <div className="input" >
                            <IonInput mode="ios" placeholder="Language" value={language}  onIonInput={(e:any)=>{setLanguage(e.detail.value)}}  ></IonInput>
                        </div>    
                    </IonCol>
                    <IonCol size="12" > 
                        <SelectPopover 
                            id={'timezone'}
                            contents={[{value:'Asia/Bangkok',label:'Asia/Bangkok'}]} 
                            title={'Timezone'} 
                            subtitle={"set default timezone for application."}  
                            selected={(e:any)=>{return setTimezone(e)}}
                            current={timezone} 
                        />       
                    </IonCol>


                    <IonCol size="6" > 
                        <IonToggle color={"success"}>Enable Google</IonToggle>  
                    </IonCol>
                    <IonCol size="6" > 
                        <IonToggle color={"success"}>Group Device</IonToggle>  
                    </IonCol>
                    </IonRow>
                </IonCol>
                <IonCol size="6" >
                    <div className="device-restrictions" >
                        <IonLabel className="restrictions-title" >Commands restrictions</IonLabel>
                        <IonItem  className="ion-text-wrap restrictions-checked"  lines="none" color={"transparent"}  >
                            <IonCheckbox checked={sendCmd} >{"Send commands"}</IonCheckbox>
                        </IonItem> 

                        <IonLabel className="restrictions-title" >Vehicle restrictions</IonLabel>
                        <IonItem  className="ion-text-wrap restrictions-checked"  lines="none" color={"transparent"}  >
                            <IonCheckbox  checked={editVehicle} >{"Edit vehicles profile"}</IonCheckbox>
                        </IonItem>
                   </div> 
                   <IonRow>
                        <IonCol size="10" className="set-center" style={{justifyContent:'flex-start', flexDirection:'row'}}>
                            {   isSearch ? 
                                <IonSearchbar style={{paddingLeft:'0px'}} onIonChange={()=>{}} ></IonSearchbar>
                                : <IonLabel className="device-to-view-title" >Device to view</IonLabel> 
                            }
                        </IonCol>
                        <IonCol size="2" className="set-center">
                            <IonButton size="small" fill="clear" onClick={(e)=>{setIsSearch(!isSearch)}} >
                                <IonIcon icon={search} slot="end" style={{fontSize:'1.4em'}} />
                            </IonButton>  
                        </IonCol>
                   </IonRow> 
                        
                    <div className="device-to-view-container" >
                        {
                            backup.map((dev:any,index:any)=> 
                                <IonItem  key={index} className="ion-text-wrap"  lines="none" color={"transparent"}  >
                                   <IonCheckbox  >{dev.name}</IonCheckbox>
                                </IonItem>
                            )
                        }
                    </div> 
                </IonCol> 
            </IonRow>
            
            <IonRow>
                <IonCol size="12" className="ion-text-right" >
                    <IonButton size="small" color={"primary"} >
                        <IonLabel>Save</IonLabel>
                    </IonButton>
                </IonCol>
            </IonRow>

        </IonGrid>
    )
}


const AddEditDevice=({deviceId}:any)=>{
    const [devicetype,setDeviceType] = useState({value:"car",label:"Car"})
    const [icon,setIcon] = useState(0)
    const [devicetypes,setDeviceTypes] = useState([{value:"car",label:"Car"},{value:"truck",label:"Truck"}])

    return(
        <IonGrid className="add-edit-device-container" >
            <br/><IonLabel className="userprofile-title" >Device Profile</IonLabel><br/>
            <IonRow style={{marginTop:'1rem'}}>
                <IonCol size="6" >
                    <div className="input" >
                       <IonInput mode="ios" placeholder="Device Name"  ></IonInput>
                    </div>    
                </IonCol>
                <IonCol size="6" >
                        <SelectPopover 
                            id={'devicetype'}
                            contents={devicetypes} 
                            title={'Device Type'} 
                            subtitle={"set  device type."}  
                            selected={(e:any)=>{return setDeviceType(e)}}
                            current={devicetype.value} 
                        />      
                </IonCol>
                <IonCol size="6" >
                    <div className="input" >
                       <IonInput mode="ios" placeholder="Device ID"  ></IonInput>
                    </div>    
                </IonCol>
                <IonCol size="6" >
                    <div className="input" >
                       <IonInput mode="ios" placeholder="Phone Number"  ></IonInput>
                    </div>    
                </IonCol>
                <IonCol size="6" >
                    <div className="input" >
                       <IonInput mode="ios" placeholder="Odometer"  ></IonInput>
                    </div>    
                </IonCol>
                <IonCol size="6" >
                    <div className="input" >
                       <IonInput mode="ios" placeholder="Driver name"  ></IonInput>
                    </div>    
                </IonCol>
                <IonCol size="6" >
                    <div className="input" >
                       <IonInput mode="ios" placeholder="Expiration Date"  ></IonInput>
                    </div>    
                </IonCol><IonCol size="12" >
                    <div className="input" >
                        <IonTextarea mode="ios" placeholder="Note" style={{height:"5rem"}}   ></IonTextarea> 
                    </div>    
                </IonCol>
                <IonCol size="12" >
                    <SettingIcon deviceId={deviceId} setIcon={(id:any)=>setIcon(id)} />
                </IonCol>
            </IonRow> 
            <IonRow>
                <IonCol size="12" className="ion-text-right" >
                    <IonButton size="small" color={"primary"} >
                        <IonLabel>Save</IonLabel>
                    </IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}



export const BtnDel=({id}:any)=>{

    return(<>
        <IonButton size="small"  mode="ios" color="danger"  fill="clear"  >  
          <IonIcon icon={trashOutline}  />
        </IonButton>
    </>)
}

export const BtnEdit=({id,role}:any)=>{
    const [isOpen,setIsOpen] = useState(false)

    return(<>
        <AddEditTableModal deviceId={id} role={role} action={"edit"} isopen={isOpen} setCloseOpen={(e:any)=>{setIsOpen(e)}} />
        <div  className="btn-edit" onClick={()=>{setIsOpen(true)}} >  
          <IonIcon icon={createOutline}  />
          <IonLabel>Edit</IonLabel>
        </div>
    </>)
}
export const BtnActive=({active}:any)=>{ 
    return( 
        <div  className={`btn-active ${active} `} >  
          <IonIcon icon={active? checkmarkCircleOutline : closeCircleOutline }  />
          <IonLabel>{active?"Active":"Inactive"}</IonLabel>
        </div>
    )
}

export const BtnMapDialog=({position,content}:any)=>{ 
    const [open,setOpen] = useState(false)
    return(<>
      <IonLabel className="cursor-pointer" color={"dark"}  onClick={()=>{setOpen(true)}}> <small>{content}</small> </IonLabel>
       <IonModal isOpen={open} mode="ios"  onDidDismiss={()=>{setOpen(false)}} >
        <IonContent  style={{position:"relative"}} >
            <IonToolbar  > 
                <IonButton fill="clear"   onClick={()=>{setOpen(false)}}> <IonIcon icon={closeCircle} /> </IonButton>
            </IonToolbar>

            <EventMap position={position} /> 
        </IonContent>
       </IonModal>
    </>)
}

const AddDigitalSensor=()=>{
    const [sensor,setSensor] = useState( {value:"sensor.door_no" ,label:"sensor.door_no"})
    const modal = useRef<HTMLIonModalElement>(null);
    const sensors = [
        {value:"sensor.door_no" ,label:"sensor.door_no"},
        {value:"sensor.volt_low" ,label:"sensor.volt_low"},
        {value:"sensor.volt_height" ,label:"sensor.volt_height"},
        {value:"sensor.temperature_over_heat" ,label:"sensor.temperature_over_heat"},
        {value:"taxi-meter" ,label:"Taxi Meter"},
        {value:"check-gps" ,label:"Check GPS"},
        {value:"door-nc" ,label:"Door(NC)"},
        {value:"pto" ,label:"PTO"},
        {value:"a/c" ,label:"A/C"},
    ]

    function dismiss() {
        modal.current?.dismiss();
      }
    
    return(<>
        <IonButton id="add-digital-sensor" size="small" mode="ios" color={"secondary"} >
            <IonLabel>Add digital sensor</IonLabel>
        </IonButton> 
        <IonModal 
            trigger="add-digital-sensor"
            className="add-digital-sensor"
            mode="ios"  ref={modal}
        >
            <IonHeader className="no-border">
                    <IonToolbar>
                        <IonTitle > Add digital sensor </IonTitle>
                    </IonToolbar>
                </IonHeader>
            <IonContent className="ion-padding"> 
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" > 
                            <SelectPopover 
                                id={'sensors'}
                                contents={sensors} 
                                title={'Sensor type'} 
                                subtitle={"select your account's country"}  
                                selected={(e:any)=>{return setSensor(e)}}
                                current={sensor} 
                            />
                        </IonCol> 
                        <IonCol size="12" >
                            <div className="input" >
                                <IonInput mode="ios" placeholder="Device Input" > 
                                </IonInput>
                            </div>
                        </IonCol>
                        <IonCol size="6" >
                            <IonButton mode="ios" expand="block"   >
                                <IonLabel>Save</IonLabel>
                            </IonButton> 
                        </IonCol>
                        <IonCol size="6" > 
                            <IonButton mode="ios" expand="block"  onClick={dismiss} >
                                <IonLabel>Cancle</IonLabel>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                
            </IonContent>
            
        </IonModal>  

    </>)
}


const AddAnalogSenesor=()=>{
    const [sensor,setSensor] = useState({value:"taxi-meter",label:"Taxi Meter"})
    const modal = useRef<HTMLIonModalElement>(null);
    const sensors= [
        {value:"taxi-meter",label:"Taxi Meter"},
        {value:"external-voltage",label:"External Voltage"}, 
        {value:"battery-voltage",label:"Battery Voltage"}, 
        {value:"temp",label:"Temperature"}, 
        {value:"fuel-liter",label:"Fuel liter"}
    ]
    function dismiss() {
        modal.current?.dismiss();
      }
    const tableSensor ={
         "header": [
            {
                "id": "key",
                "label": "Key", 
            },
            {
                "id": "value",
                "label": "Value", 
            },
            {
                "id": "delete",
                "label": "Delete", 
            }],
        "body": [],
        "detail":{
            "active": 0,
            "inactive": 0,
            "all": 0,
            "page": 0,
            "canAdd": false,
            "role": "sensors-analog"
        }
    }
    
    return(<>
        <IonButton size="small" mode="ios" id="add-analog-sensor" >
            <IonLabel>Add analog sensor</IonLabel>
        </IonButton>
        <IonModal 
            trigger="add-analog-sensor"
            className="add-analog-sensor"
            mode="ios"  ref={modal}
        >
             <IonHeader className="no-border">
                < IonToolbar>
                   <IonTitle > Add analog sensor </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding"> 
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" > 
                            <SelectPopover 
                                id={'sensors'}
                                contents={sensors} 
                                title={'Sensor type'} 
                                subtitle={"select your account's country"}  
                                selected={(e:any)=>{return setSensor(e)}}
                                current={sensor} 
                            />
                        </IonCol> 
                        <IonCol size="12" >
                            <div className="input" >
                                <IonInput mode="ios" placeholder="Device Input" > 
                                </IonInput>
                            </div>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol size="5" >
                            <div className="input" ><IonInput mode="ios" ></IonInput></div>
                        </IonCol>
                        <IonCol size="5" >
                            <div className="input" ><IonInput mode="ios" ></IonInput></div>
                        </IonCol>
                        <IonCol size="2" className="set-center" >
                             <IonButton fill="clear" size="small"> <IonLabel>Add</IonLabel> </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12" >
                            <Tables 
                                title={''} 
                                description={""} 
                                headers={tableSensor.header} rows={tableSensor.body} 
                                tableHead={tableSensor.detail}
                                role="users"
                                current={1}
                                // nextPage={(e:any)=>{return nextPageUser(e)}} 
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow> 
                        <IonCol size="6" >
                            <IonButton mode="ios" expand="block"   >
                                <IonLabel>Save</IonLabel>
                            </IonButton> 
                        </IonCol>
                        <IonCol size="6" > 
                            <IonButton mode="ios" expand="block"  onClick={dismiss} >
                                <IonLabel>Cancle</IonLabel>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                
            </IonContent>
        </IonModal>
    </>)
}


const AddRelay=()=>{
    const [sensor,setSensor] = useState({value:"taxi-meter",label:"Taxi Meter"})
    const modal = useRef<HTMLIonModalElement>(null);
    const sensors= [
        {value:"taxi-meter",label:"Taxi Meter"},
        {value:"external-voltage",label:"External Voltage"}, 
        {value:"battery-voltage",label:"Battery Voltage"}, 
        {value:"temp",label:"Temperature"}, 
        {value:"fuel-liter",label:"Fuel liter"}
    ]
    function dismiss() {
        modal.current?.dismiss();
      }
    return(<>
        <IonButton size="small" mode="ios" id="add-sensor-relay" color={"secondary"} >
            <IonLabel>Add relay</IonLabel>
        </IonButton>

        <IonModal 
            trigger="add-sensor-relay"
            className="add-digital-sensor"
            mode="ios"  ref={modal}
        >
             <IonHeader className="no-border">
                < IonToolbar>
                   <IonTitle > Add relay </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding"> 
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" > 
                            <SelectPopover 
                                id={'sensors'}
                                contents={sensors} 
                                title={'Sensor type'} 
                                subtitle={"select your account's country"}  
                                selected={(e:any)=>{return setSensor(e)}}
                                current={sensor} 
                            />
                        </IonCol> 
                        <IonCol size="12" >
                            <div className="input" >
                                <IonInput mode="ios" placeholder="Device Input" > 
                                </IonInput>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                    <IonCol size="6" >
                            <IonButton mode="ios" expand="block"   >
                                <IonLabel>Save</IonLabel>
                            </IonButton> 
                        </IonCol>
                        <IonCol size="6" > 
                            <IonButton mode="ios" expand="block"  onClick={dismiss} >
                                <IonLabel>Cancle</IonLabel>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                
            </IonContent>
        </IonModal>
    </>)
}