import { IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonPage, IonRow, IonSearchbar, IonText, IonTextarea, IonTitle, IonToolbar, useIonAlert, useIonToast } from "@ionic/react";
import { useEffect, useState } from "react";
import { allDevices, allGeozonesOrder, delNotifier, editNotifier, getNotifierDevices, getNotifierInfo, getNotifiers, getNotifierType, newNotifier, toastFail, toastSuccess } from "../actions";
import { addCircle, addCircleOutline, chevronBackOutline, closeCircleOutline, syncOutline, trashBin, trashBinOutline } from "ionicons/icons";
import NavBar from "../components/NavBar";
import { useHistory } from "react-router";
import { DropDownDeviceMultiple, SelectPopover } from "../components/AppComponent";
import { useDispatch, useSelector } from "react-redux";
import { getGeoZones, setGeozones } from "../store/mapSlice";
import { getBackup, setBackupDevices, setDevices, setLoading } from "../store/appSlice";
import { t } from "i18next";
import { Notifier, NotifierInfo } from "./schema";

 
const soundsNoti = [
    { id:9  , name:"Horn Car" ,url:"../../assets/sounds/horn_car.mp3" },
    { id:1  , name:"Beep Alarm Clock" ,url:"../../assets/sounds/beep-alarm-clock.wav" },
    { id:2  , name:"Beep Alarm Digital" ,url:"../../assets/sounds/beep-alarm-digital-clock.wav" },
    { id:3  , name:"Beep Alarm Warning" ,url:"../../assets/sounds/beep-alarm-warning.wav" },
    { id:4  , name:"Bell Casino" ,url:"../../assets/sounds/bell-casino-reward.wav" },
    { id:5  , name:"Bell School Calling" ,url:"../../assets/sounds/bell-school-calling.wav" },
    { id:6  , name:"Bell" ,url:"../../assets/sounds/bell.wav" },
    { id:7  , name:"Horn Circus Clown" ,url:"../../assets/sounds/horn-at-circus-clown.wav" },
    { id:8  , name:"Horn Small Car" ,url:"../../assets/sounds/horn-small-car.wav" },
    { id:10 , name:"Siren Ambulance" ,url:"../../assets/sounds/siren-ambulance-us.wav" },
    { id:11 , name:"Siren Police(US)" ,url:"../../assets/sounds/siren-police-us.wav" },
    { id:12 , name:"Siren Police" ,url:"../../assets/sounds/siren-police.wav" },
    { id:13 , name:"Siren Slot Machine" ,url:"../../assets/sounds/siren-slot-machine-win.wav" },
]
const formatchoices = [ "{0} - อุปกรณ์","{1} - พื้นที","{2} - ความเร็วเกิน","{3} - เวลา","{4} - สถานที่","{5} - พิกัด","{6} - ความเร็ว","{7} - เลขบัตร","{8} - คนขับ","{9} - เปิด/ปิด","{10} - อุณหภูมิ"]
const notificationType = [
    {
        "value": "notifier.door_alarm_no",
        "label": "เเจ้งเตือนเปิด-ประตู(NO)",
        "format": "notifier.template.door"
    },
    {
        "value": "notifier.door_alarm_nc",
        "label": "เเจ้งเตือนปิด-ประตู(NC)",
        "format": "notifier.template.door"
    },
    {
        "value": "notifier.temperature_over_lower",
        "label": "แจ้งเตือนอุณหภูมิ",
        "format": "{0} temperature{10} engine {9} time {3} {4} http://maps.google.com/maps?f=q&q={5}&z=16"
    },
    {
        "value": "notifier.ac_alarm",
        "label": "notifier.ac_alarm",
        "format": null
    },
    {
        "value": "notifier.geofence",
        "label": "แจ้งเตือนพื้นที่",
        "format": "{0} IN/OUT {1} time {3} {4}   http://maps.google.com/maps?f=q&q={5}&z=16"
    },
    {
        "value": "notifier.idles",
        "label": "จอดติดเครื่อง",
        "format": "Idle alert from vehicle {0} at {3} Address {4} location {5} time {7}"
    },
    {
        "value": "notifier.overspeed",
        "label": "ความเร็วเกินกำหนด",
        "format": "{0} speed  {6} time {3} {4}  http://maps.google.com/maps?f=q&q={5}&z=16"
    }
]

const NotificationSetting =()=>{
    const [notiType,setNotiType] = useState({value:'maxspeed',label:'ความเร็วเกินกำหนด',id:0})
    const [loading,setLoading] = useState(false)
    const [notifiers  , setNotifier] = useState<Notifier[] | []>([])
    const [notifiersBackup  , setNotifierBackup] = useState<Notifier[] | []>([])
    const zones :any= useSelector(getGeoZones)
    const [text,setText] = useState("")
    const [alarm,setAlarm] = useState("Horn Car");
    const [zoneSelect,setZoneSelect] = useState({
        "value": 3717,
        "label": "ออฟฟิตหนองคาย"
    })
    const [vehihle,setVehicle] = useState({device_id:0 ,value: 0,name:"Select"})
    const [vehicleCheck,setVehicleCheck] = useState([{checked:false, device_id:0 ,value: 0,label:"Select"}])
    const [options,setOptions] = useState([])
    const [notiformat,setNotiFormat] = useState("{0} ความเร็ว  {6}  เวลา  {3}   {4}  http://maps.google.com/maps?f=q&q={5}&z=16")
    const [formatchoice , setFormatChoice] = useState(formatchoices)
    const [name,setName] = useState("")
    const [temp,setTemp] = useState("")
    const [idletime,setIdleTime] = useState("")
    const [speeding,setSpeeding] = useState("")
    const [notitype,setNotifierType] = useState([])
    const [selectNoti,setSelectNoti] = useState<Notifier|null>(null)
    const backup :any= useSelector(getBackup)

    const [checkin,setCheckIn] = useState(false)
    const [checkout,setCheckOut] = useState(false)
    const [checktemplow,setTempLow] = useState(false)
    const [checktempheight,setTempHeight] = useState(false)
    const [checkweb,setCheckWeb] = useState(true) 

    const [iontoast,dimisstoast] = useIonToast()
    const [ionAlert,dimissAlert] = useIonAlert()

    let audio: HTMLAudioElement | null = null;

    const dispatch = useDispatch()
    useEffect(()=>{ 
        const getnotisetting=async ()=>{
            let geofences = await allGeozonesOrder({})  
            dispatch(setGeozones(geofences))  

            const result = await getNotifiers()
            console.log("getNotifiers result ",result)
            setNotifier(result)
            setNotifierBackup(result)
            const notitype = await getNotifierType()
            console.log("notitype ",notitype)
            let notitypeopt:any = []
            await notitype.map((e:any)=>{
                notitypeopt = [...notitypeopt , {value: e.key,   label: e.decription ? e.decription :e.key , format: e.format , id: e.id}]
            })
            console.log("notitypeopt ",notitypeopt)
            setNotiType(notitypeopt[0])
            setNotifierType(notitypeopt)
        }
        const getdevices=async()=>{ 
            let result = await allDevices({})  
            await dispatch(setDevices(result))
            await dispatch(setBackupDevices(result)) 
            let options = result.map((e:any)=> {return {value: e.device_id ,label: e.name, ...e } })
            setOptions(options)   
            let optionscheck:any[] = result.map((e:any)=> {return {checked:false, value: e.device_id ,label: e.name, ...e } })
            optionscheck.unshift({checked:false, device_id:0 ,value: 0,label:"ทั้งหมด"})
             
            setVehicleCheck(optionscheck) 
         } 
        getdevices()   
        getnotisetting()
    },[])
    const changeNotiferrType=(e:any)=>{
        console.log("changeNotiferrType ", e)
    }
    const checkedVehicleOption=(index:any , val:any)=>{
        console.log("updateopts ",val)
        let updateopts = vehicleCheck
        if(val.value === 0 && val.checked){  
          updateopts = vehicleCheck.map((e )=>{
            return {...e,...{checked:true}}
          })  
        }else if(val.value === 0 && !val.checked){  
          updateopts = vehicleCheck.map((e )=>{
            return {...e,...{checked:false}}
          })  
        } else{
          setVehicleCheck(updateopts)
          updateopts.splice(index , 0)
          updateopts[index] = val 
        }
        setVehicleCheck(updateopts)
   }

   const onchangeAlarm=async(val:any)=>{ 
        setAlarm(val)
        let sound = await soundsNoti.find((e)=> e.name.indexOf(val) > -1)
        if(audio){  
            audio.remove()
        }  
        audio = new Audio(sound?.url)  
        setTimeout(()=>{
            audio?.play()
        },500) 
    }
    const notisselect=async(noti:any)=>{ 
        console.log("notisselect ", noti)
        setText("")
       let info:NotifierInfo = await  getNotifierInfo(noti?.id) 
       console.log("info sound",info?.sound)
        if(info){
            setCheckIn(info?.checkin)
            setCheckOut(info?.checkout)
            setTempHeight(info?.checktempheight)
            setTempLow(info?.checktemplow)
            setTemp(info?.temp)
            setSpeeding(info.speeding)
            setIdleTime(info?.idletime)
            setAlarm(info?.sound)
            if(info?.notifierType.id === -7){
                let z = zones.find((e:any)=> e.id === info?.geoid)
                if(z){
                    setZoneSelect({value: z.id , label: z.name})
                }
            }
        }


       let result:Notifier[] = await getNotifierDevices(noti?.id) 
        setSelectNoti(noti)
       let updateopts:any =  []
       await  vehicleCheck.map(async(e , i)=>{ 
          let found = await result.find((id:any) => id  === e.value ) 
          updateopts = [...updateopts ,  {...vehicleCheck[i],  ...{ checked: found?true:false  }}] 
        })  
        setVehicleCheck(updateopts)
        setNotiFormat(noti.template)
        setName(noti.name)

        let findType:any = notitype.find((e:any)=> noti?.notifierType?.id === e.id) 
        setNotiType(findType)
    }

    const saveNotiSetting=async()=>{
        setLoading(true)
        let deviceid:any = []
        await vehicleCheck.map((e)=> { deviceid=[...deviceid , e.value]  })
        deviceid = deviceid.filter((id:any)=> id != 0)
        let body ={
            name: name ,
            typeid: notiType.id ,
            type: notiType.value ,
            format:notiformat,
            temp: temp ,
            geoid: zoneSelect?.value ,
            idletime: idletime,
            speeding: speeding ,
            deviceid: deviceid ,
            checkin : checkin , 
            checkout: checkout, 
            checktemplow:checktemplow , 
            checktempheight:checktempheight ,
            checkweb:checkweb,
            sound: alarm 
        } 

        const res = await newNotifier(body)
        console.log("newNotifier res ",res)
        if(res?.result){
            iontoast({
                ...toastSuccess ,
                message: 'เพิ่มรายการแจ้งเตือนใหม่สำเร็จ !!', 
            });
            const result = await getNotifiers() 
            setNotifier(result)
            setNotifierBackup(result)
            setLoading(false)
            clearform()
        }else{
            iontoast({
                ...toastFail,
                message: 'เพิ่มรายการแจ้งเตือนใหม่ ไม่สำเร็จ !!', 
            });
            setLoading(false)
        }
    }

    const searchNotify=(word:any)=>{
    console.log(" searchNotify word ",word)
        if(word.length > 1){
            let found = notifiersBackup.filter((e)=> e.name.toLowerCase().indexOf(word.toLowerCase()) > -1)
            setNotifier(found)
        }else{
            setNotifier(notifiersBackup)
        }
    }
    
    const deleteNotifier=async(noti:any)=>{
        console.log("deleteNotifier noti ",noti)
       ionAlert({
        header: 'ยืนยันการลบ', 
        message: `กดตลงเพื่อลบการแจ้งเตือน ${noti?.name} `,
        buttons: [
            {
                text: t("close"),
                role: 'cancel',
                handler: () => {
                  console.log('Alert canceled');
                },
              },
              {
                text: t("delelte"),
                role: 'confirm',
                handler: async (data:any) => {  
                    const res = await  delNotifier(noti?.id);
                    setLoading(true)
                    if(res?.result){
                        iontoast({
                            ...toastSuccess ,
                            message: 'ลบรายการแจ้งเตือนสำเร็จ !!', 
                        });
                        const result = await getNotifiers() 
                        setNotifier(result)
                        setNotifierBackup(result)
                        setLoading(false)
                        clearform()
                    }else{
                        iontoast({
                            ...toastFail,
                            message: 'ลบรายการแจ้งเตือนไม่สำเร็จ !!', 
                        });
                        setLoading(false)
                    }
                },
              },
        ] 
      })
    }

    const clearform=()=>{
        setName("")
        setTemp("")
        setIdleTime("")
        setSpeeding("")
        setCheckIn(false)
        setCheckOut(false)
        setTempLow(false)
        setTempHeight(false)
        setSelectNoti(null)
        setNotiFormat("")
        let optionscheck:any[] = backup.map((e:any)=> {return {checked:false, value: e.device_id ,label: e.name, ...e } })
        optionscheck.unshift({checked:false, device_id:0 ,value: 0,label:"ทั้งหมด"})
        setVehicleCheck(optionscheck)
    }

    const editNotiSetting=async()=>{
        setLoading(true)
        let deviceid:any = []
        await vehicleCheck.map((e)=> { deviceid=[...deviceid , e.value]  })
        deviceid = deviceid.filter((id:any)=> id != 0)
        let body ={
            name: name ,
            typeid: notiType.id ,
            type: notiType.value ,
            format:notiformat,
            temp: temp ,
            geoid: zoneSelect?.value ,
            idletime: idletime,
            speeding: speeding ,
            deviceid: deviceid ,
            checkin : checkin , 
            checkout: checkout, 
            checktemplow:checktemplow , 
            checktempheight:checktempheight ,
            checkweb:checkweb,
            sound: alarm 
        } 

        const res = await editNotifier(body,selectNoti?.id)
        console.log("editNotifier res ",res)
        if(res?.result){
            iontoast({
                ...toastSuccess ,
                message: 'แก้ไขรายการแจ้งเตือนสำเร็จ !!', 
            });
            const result = await getNotifiers() 
            setNotifier(result)
            setNotifierBackup(result)
            setLoading(false)
            clearform()
        }else{
            iontoast({
                ...toastFail,
                message: 'แก้ไขรายการแจ้งเตือนไม่สำเร็จ !!', 
            });
            setLoading(false)
        }
    }

    return(
        <IonPage>  
        {/* <NavBar />   */} 
        <IonLoading message={t("loading").toString()} isOpen={loading} ></IonLoading>
        <IonContent className="ion-padding " > 
            <IonGrid>
                <IonRow  className='ion-justify-content-around' >
                    {/* <IonCol size="10" > 
                      <IonLabel className="ion-hide-md-down" style={{fontWeight:"bold",fontSize:"1.2em"}} > Notifications Setting </IonLabel> 
                    </IonCol> */}
                    <IonCol size="3" >
                        <IonTitle  >รายการแจ้งเตือน</IonTitle>
                        <div className="set-center-row" >
                            <IonSearchbar mode="ios" value={text} onIonInput={(e:any)=>{setText(e.detail.value!);searchNotify(e.detail.value)}}  ></IonSearchbar>
                            <IonButton color={"primary"}  size="small" fill="clear" mode="ios"
                              onClick={()=>{setText("");setNotifier(notifiersBackup)}}
                            > 
                                <IonIcon icon={syncOutline} /> 
                            </IonButton>
                        </div> 
                        <IonList  style={{height:"70vh",overflowY:"scroll"}} >
                            {
                            notifiers?.map((n,i)=>
                            <IonItem key={i} button   onClick={()=>{notisselect(n)}}>
                                <IonLabel>{n?.name}</IonLabel>
                                <IonButtons slot="end">
                                    <IonButton onClick={()=>{deleteNotifier(n)}}> <IonIcon icon={trashBinOutline} /> </IonButton>
                                </IonButtons>
                            </IonItem> )    
                            }
                        </IonList>
                    </IonCol>
                    <IonCol size="7" >
                        <div className="set-center-row" style={{justifyContent:"flex-start"}}>
                            <IonLabel > 
                                <strong> 
                                {!selectNoti &&<span>เพิ่มการแจ้งเตือนใหม่</span> }
                                {selectNoti && <span > &nbsp; แก้ไข {selectNoti?.name}</span>   }
                                </strong>
                            </IonLabel>
                                {selectNoti &&<IonButton color={"primary"}  size="small" fill="clear" mode="ios" onClick={()=>{clearform()}} > 
                                    <IonIcon icon={closeCircleOutline} /> 
                            </IonButton>  }
                        </div>
                        <IonRow style={{marginTop:'1rem'}}>
                            <IonCol sizeXs="12" sizeMd="4" style={{padding:'.5rem'}} >
                                <div className="input" >
                                 <IonInput mode="ios" 
                                  value={name} 
                                  onIonChange={(e:any)=>{setName(e.detail.value!)}}  
                                  placeholder="ชื่อการแจ้งเตือน" 
                                 ></IonInput>
                                </div>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="4"  style={{padding:'.5rem'}} >
                          
                            <SelectPopover 
                                id={'notitype'}
                                contents={notitype} 
                                title={'Notification Type'} 
                                subtitle={"select notification type."}  
                                selected={(e:any)=>{setNotiFormat(e.format); changeNotiferrType(e);  return setNotiType(e)}}
                                current={notiType} 
                                />   
                            </IonCol>
                            {notiType.value ==="notifier.temperature_over_lower" && 
                            <IonCol sizeXs="12" sizeMd="4" style={{padding:'.5rem'}} >
                                <div className="input" >
                                    <IonInput mode="ios" 
                                     value={temp}
                                     onIonChange={(e:any)=>{setTemp(e.detail.value)}}
                                     placeholder="อุณหภูมิ" 
                                    ></IonInput>
                                </div>
                            </IonCol>}
                            {notiType.value ==="notifier.geofence" && 
                            <IonCol sizeXs="12" sizeMd="4" style={{padding:'.5rem'}} >
                                <SelectPopover 
                                    id={'geozone'}
                                    contents={zones.map((e:any)=>{return {value: e.id , label: e.name}})} 
                                    title={'Geozones'} 
                                    smallfont={false}
                                    subtitle={"select zone to notification."}  
                                    selected={(e:any)=>{return setZoneSelect(e)}}
                                    current={zoneSelect} 
                                />   
                            </IonCol>}
                            {notiType.value ==="notifier.idles" && 
                            <IonCol sizeXs="12" sizeMd="4" style={{padding:'.5rem'}} >
                                <div className="input" >
                                    <IonInput 
                                     mode="ios"  
                                     placeholder="จอดนาน(นาที)" 
                                     value={idletime}
                                     onIonChange={(e)=>{setIdleTime(e.detail.value!)}}
                                    ></IonInput>
                                </div>
                            </IonCol>} 
                            {notiType.value ==="notifier.overspeed" && 
                            <IonCol sizeXs="12" sizeMd="4" style={{padding:'.5rem'}} >
                                <div className="input" >
                                    <IonInput 
                                     mode="ios"  
                                     placeholder="เตือนความเร็ว" 
                                     value={speeding}
                                     onIonChange={(e)=>{setSpeeding(e.detail.value!)}} 
                                    ></IonInput>
                                </div>
                            </IonCol>} 
                      
                            <IonCol sizeXs="12" sizeMd="4"  >  
                                <DropDownDeviceMultiple 
                                    vehicles={vehicleCheck} 
                                    setVehicle={(index:any, val:any)=>{ checkedVehicleOption(index , val)  }}  
                                    optionsbackup={options}  
                                /> 
                            </IonCol>  
                          
                            {notiType.value ==="notifier.geofence" &&    <IonCol size="2.5" className="set-center-row" style={{alignItems: 'flexx-start',}} > 
                              <IonLabel className="notification-way" ><IonCheckbox checked={checkin}   labelPlacement="end" onIonChange={(e)=>{setCheckIn(e.detail.checked)} }>&nbsp;  เตือนเข้าพื้นที่</IonCheckbox></IonLabel>
                            </IonCol> }
                            {notiType.value ==="notifier.geofence" &&     <IonCol size="2.5" className="set-center-row"  style={{alignItems: 'flexx-start',}}> 
                               <IonLabel className="notification-way" ><IonCheckbox checked={checkout}  labelPlacement="end" onIonChange={(e)=>{setCheckOut(e.detail.checked)} }>&nbsp;  เตือนออกพื้นที่</IonCheckbox></IonLabel>
                            </IonCol> }

                            {notiType.value.indexOf("notifier.temperature") > -1 &&   <IonCol  className="set-center-row"  style={{alignItems: 'flexx-start',}}> 
                                <IonLabel className="notification-way" ><IonCheckbox checked={checktemplow} labelPlacement="end"  onIonChange={(e)=>{setTempLow(e.detail.checked)} }>&nbsp;  Temperature Low</IonCheckbox></IonLabel>
                            </IonCol> }
                            {notiType.value.indexOf("notifier.temperature") > -1 &&    <IonCol className="set-center-row"   style={{alignItems: 'flexx-start',}}> 
                             <IonLabel className="notification-way" ><IonCheckbox checked={checktempheight}  labelPlacement="end"  onIonChange={(e)=>{setTempHeight(e.detail.checked)} }>&nbsp;  Temperature Heights</IonCheckbox></IonLabel>
                            </IonCol> }
                           
                            <div className="devider" ></div>
                            <IonLabel className="notification-way" >
                                <IonCheckbox checked={checkweb}   labelPlacement="end" onIonChange={(e)=>{setCheckWeb(e.detail.checked)} }>
                                   เตือนผ่านหน้าเว็บ
                                </IonCheckbox>
                            </IonLabel> &nbsp; 


                    </IonRow><br/> 
                    
                    <IonRow> 
                        <IonCol size="12">
                            <strong>รูปแบบการแจ้งเตือน</strong>
                        </IonCol>
                        <IonCol size="12">
                            <div className="input" >
                              <IonTextarea
                                mode="ios" value={notiformat} placeholder="Notification format" 
                                onIonChange={(e:any)=>{setNotiFormat(e.detail.value)}}
                              ></IonTextarea>
                            </div>
                        </IonCol>
                        <IonCol size="12" >
                            {formatchoice.map((format:any,index:any)=> 
                            <IonButton 
                            onClick={()=>{setNotiFormat(notiformat+' '+format)}}
                            key={index} size="small" fill="clear" color={"dark"} > 
                                <IonIcon icon={addCircle}  />&nbsp;{format}
                            </IonButton>
                            )}
                        </IonCol>
                    </IonRow><br/>
                  
                    <IonRow>
                        <IonCol size="12">
                            <strong>เสียงการแจ้งเตือน</strong>
                        </IonCol>

                        <select value={alarm}  name={alarm} id="alarm-sound" onChange={(e)=>{ onchangeAlarm(e.target.value);}}> 
                            {soundsNoti.map((e:any,id:any)=> <option key={id} value={e.name} > {e.name} </option> )}
                        </select> 
                        <IonCol size="12" className="ion-text-right" style={{borderTop:'1px solid #EEE'}} >
                            <IonButton size="small" color={"danger"} mode="ios" onClick={()=>{clearform()}} > <IonLabel>ยกเลิก</IonLabel> </IonButton>  &nbsp;
                            {!selectNoti &&  <IonButton size="small" color={"secondary"} mode="ios" onClick={()=>{saveNotiSetting()}} > <IonLabel>บันทึก</IonLabel> </IonButton> }
                            {selectNoti &&  <IonButton size="small" color={"secondary"} mode="ios"  onClick={()=>{editNotiSetting()}} > <IonLabel>แก้ไข</IonLabel> </IonButton> }
                        </IonCol> 
                    </IonRow>

                  </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>  
     </IonPage>
    )
}
export default NotificationSetting;
 
