import React, {  useState } from 'react';
import { faDoorOpen, faDownload, faGasPump, faHandPointUp, faSatelliteDish, faTachometer  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonAccordion, IonAccordionGroup, IonButton, IonCardTitle, IonCheckbox, IonCol, IonContent, IonDatetime, IonFooter, IonGrid, IonHeader, IonIcon, IonImg,  IonInput,  IonItem,  IonLabel,  IonList,  IonLoading,  IonModal,  IonPopover,  IonRadio,  IonRadioGroup,  IonRange,  IonRippleEffect,  IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSpinner, IonTextarea, IonToolbar, useIonToast } from '@ionic/react'; 
import { batteryFull,  calendarNumberOutline, calendarOutline, card, carSport, carSportOutline, chatbubbleEllipses, chevronBackOutline, chevronForward, chevronForwardOutline, closeCircle, closeCircleOutline, cloudOffline, cloudOfflineOutline, ellipse, grid, gridOutline, idCard, key, locationSharp, move, open, pause, pencil, person, play, settings, shieldCheckmark, speedometer, stop, stopOutline, terminal, thermometer } from 'ionicons/icons';  
import { useEffect  } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import {  getBackup, getDateEnd, getDateStart, getDevices,   getFilter ,getKeyword, setBackupDevices, setDevice, setDeviceModal, setDeviceModalOpt, setDevices, setFilterDevices, setKeyword } from '../store/appSlice'; 
import { CardDevice, CheckTrackRealtime } from './CardDevice';
import { FilterDevices } from './FilterDevices'; 
import { getFastTrack, getPreviewDevice, getQuickTrackList, getRouteIndex, setFastTrack, setPreviewDevice, setQuickTrackList, setRouteIndex } from '../store/mapSlice';
import ListDevice from './ListDevice';
import LoadingProgress from './LoadingProgress';
import { DeviceName, DeviceStatusNameTime } from './IconStatus'; 
import { getContentFilter, getRatePoint } from '../store/filterSlice';
import { allDevices, editDevice,   getFasttrack, getPttpricing, getStorage, historyRoute, setDeviceName, settingConsumption, toastSuccess } from '../actions'; 
import { ListEvents } from './ContainerHistory'; 
import { t } from 'i18next';
import sha1 from 'sha1'; 
import { convertMsToTime } from '../pages/Reports/ReportUtils';
import * as XLSX from "xlsx";
import { Device, PreveiewDevice } from '../pages/schema';
import { Swiper, SwiperSlide, useSwiper, } from 'swiper/react'; 
import { Swiper as  SwiperType } from 'swiper'; 
import { ModalEditDevice } from '../pages/HomeScreen';
import { borderCells } from '../lib/Excels';
import * as Excel from "exceljs";
import FileSaver from "file-saver";  

import * as Moment from "moment"
import { extendMoment } from "moment-range";
 
import '../theme/style.css';
import './css/DevicesContainer.css' 
import "./css/ContainerHistory.css"
import 'swiper/css';      
import 'sheetjs-style' 
 
const moment = extendMoment(Moment);
const DeviceHeader1=({device}:any)=>{
  const ratePoint :any= useSelector(getRatePoint) 
  const swiper = useSwiper()
  useEffect(()=>{ 
    if(swiper && !device){
      swiper.slideTo(0)
    }
  },[])
  return(
    <IonGrid>
    {device && <IonRow class="ion-justify-content-between">
      <IonCol size="3"  className="ion-text-center" >
          <div style={{position:'relative',height:'90%'}} >
            <div style={{position:'absolute',right:'0',bottom:'0'}}>
              <CheckTrackRealtime device={device} />
            </div>
            {
              device.deviceIconId!==null && device.deviceIconId!==undefined?
              <IonImg  src={`../../assets/types/${!device.deviceIconId? 0 : device.deviceIconId}.png`} />:
              <IonImg  src={`../../assets/types/1.png`} />
            } 
            <div style={{position:'absolute',left:'40%' ,width:'1.5rem',height:'1.5rem',transform:`rotate(${device.heading}deg)`}} 
            className={ `marker-status-divicon sts-icon ${
              device.online===0? 'marker-offline':
              device.status===7? 'marker-move': 
              device.status===23?'marker-stop': 
              device.status===24?'marker-idle':'marker-offline'
            }`}></div>
          </div>
           
      </IonCol>
      <IonCol size='8' style={{paddingLeft:'1rem'}} >
        <DeviceName bold={true} size="1em" name={device?.name2 ? device?.name2 : device?.name} update={device.event_stamp} showRatePonit={ratePoint.card}/> 
        <DeviceStatusNameTime online={device.online} status={device.status} statusName={device.status_name} statusTime={device.status_time} /> 
        <br/><small className='event-time'>{device.event_stamp}</small> 
      </IonCol>
    </IonRow> 
}
 </IonGrid>
  )
}

export  const  DevicePreview=({modal}:any)=> { 
  const preview:PreveiewDevice  = useSelector(getPreviewDevice)  
  const [segmentVal,SetSegmentVal] = useState(0)
  const [refSwiper,setRfSwip] = useState(null)  
  const [openedit,setOpenEdit] = useState(false) 
   
  useEffect(()=>{   },[preview])


  return ( 
        <div color='light' style={{overflowY:"scroll", height:"90vh"}}> 
        <IonHeader className='header ion-no-border' color='light' >
          <IonToolbar color='light'>
            <IonRow>
              <IonCol size='2' > <SlidePrevButton modal={modal}/>  </IonCol> 
            </IonRow>
          </IonToolbar> 
        </IonHeader>
       {preview.active && <div className='ion-padding preview-device-contianer'  >
           <DeviceHeader1  device={preview?.device} /> 
          
            <br/>{/* @ts-ignore */}
           <IonSegment mode='ios' value={segmentVal} onIonChange={(e)=>{refSwiper?.slideTo(e.detail.value)}}>
            {/* @ts-ignore */}
            <IonSegmentButton value={0} onClick={()=>{refSwiper?.slideTo(0)}} > 
              <IonLabel className='segment-label' color={"dark"} ><IonIcon icon={carSport} className='icon' /> <span>{t('home_preview_segment_sum')}</span></IonLabel>
            </IonSegmentButton>
            {/* @ts-ignore */}
            <IonSegmentButton value={1} onClick={()=>{refSwiper?.slideTo(1)}}> 
              <IonLabel className='segment-label'  color={"dark"} ><FontAwesomeIcon icon={faHandPointUp} className='icon'  /> <span>{t('home_preview_segment_action')}</span></IonLabel>
            </IonSegmentButton>
            {/* @ts-ignore */}
            <IonSegmentButton value={2} onClick={()=>{refSwiper?.slideTo(2)}}> 
              <IonLabel className='segment-label' color={"dark"}  ><IonIcon icon={person}  className='icon' /> <span>{t('home_preview_segment_profile')}</span></IonLabel>
            </IonSegmentButton>
          </IonSegment>

          <Swiper 
            ref={()=>refSwiper} 
            onSwiper={(e:any)=>{setRfSwip(e);console.log("refSwiper ",e)}}
            onSlideChange={(e:SwiperType) =>{
              // @ts-ignore
              SetSegmentVal(refSwiper?.activeIndex) 
            }}
          >
            <SwiperSlide>
              <SummaryDevice device={preview?.device} edit={(e:any)=>{setOpenEdit(e)}} /> 
            </SwiperSlide>
            <SwiperSlide>
              <SummaryAction device={preview?.device} /> 
            </SwiperSlide>
            <SwiperSlide>
              <SummaryProfile device={preview?.device} />
            </SwiperSlide>
          </Swiper>
          
          <ModalEditDevice openedit={openedit} setOpenEdit={(e:any)=>{setOpenEdit(e)}} />
        </div>
        } 
        </div>   
    );
}
export const DeviceSensorTable=({device }:any)=>{
  useEffect(()=>{
    console.log("DeviceSensorTable  ", device) 
  },[])
  const speedFormat=(speed:any)=>{ 
    return Number(speed).toFixed(0)
  }
  return(
    <IonGrid className='grid-table' > 
      <IonRow className='ion-justify-content-center bg-white-shade'>
        <IonCol size='12'   > 
          <IonLabel className='flex-content' >
              <IonIcon icon={locationSharp} color='dark' className='icon'/>
              <small className='text-content'>{device.address}</small>
            </IonLabel>
        </IonCol>
      </IonRow>

      <IonRow className='bg-white-tint'>
        <IonCol size='6' className='ion-text-center' >
          <IonLabel className='flex-content' > 
            <IonIcon icon={speedometer} className='icon'/> <br/>
            <IonLabel className='text-content'  > <small> {t('home_speed')} </small> </IonLabel> 
          </IonLabel>
          <IonLabel className='text-bold-content'  ><strong>{speedFormat(device?.speed)  } {t('kmHUnit')}</strong></IonLabel>
        </IonCol>
        <IonCol size='6' >
          <IonLabel className='flex-content'> 
            <IonLabel className='icon'> <FontAwesomeIcon icon={faGasPump} /> </IonLabel> 
            <IonLabel className='text-content' > <small> {t('home_fuel')} </small> </IonLabel>
          </IonLabel>
          <IonLabel className='text-bold-content'><strong>{device?.fuel_liters} {t('lit')}</strong></IonLabel>
        </IonCol> 
        </IonRow>

        {/* {device&&<IonRow className='bg-white-tint'> 
        <IonCol size='6' >
          <IonLabel className='flex-content'> 
            <IonLabel className='icon'> <FontAwesomeIcon icon={faTachometer} /> </IonLabel> 
            <IonLabel className='text-content' > <small> ระยะทางวันนี้ </small> </IonLabel>
          </IonLabel>
          <IonLabel className='text-bold-content'><strong>{device?.odoToday} </strong></IonLabel>
        </IonCol> 
        </IonRow> } */}
 
        <IonRow className='bg-white-shade'>
          <IonCol size='6' >
            <IonLabel className='flex-content'> 
              <IonLabel className='icon'> <FontAwesomeIcon icon={faSatelliteDish} /> </IonLabel> 
              <IonLabel className='text-content' > <small> GPS/GSM </small> </IonLabel>
            </IonLabel>
            <IonLabel className='text-bold-content'><strong> {device?.satellites}/{device?.fld_signalStrength} </strong></IonLabel>
          </IonCol> 
          <IonCol size='6' >
            <IonLabel className='flex-content'> 
              <IonIcon className='icon' color='success' icon={batteryFull} />
              <IonLabel className='text-content' > <small> {t('home_battery')} </small> </IonLabel>
            </IonLabel>
            <IonLabel className='text-bold-content'><strong> { device?.fld_engineLoad ? "Charging":"-"} </strong></IonLabel>
          </IonCol>
        </IonRow>

        <IonRow className='bg-white-tint'>
        <IonCol size='6' >
          <IonLabel className='flex-content'> 
            <IonIcon className='icon' icon={thermometer} />   
            <IonLabel className='text-content' > <small> {t('home_temp')} </small> </IonLabel>
          </IonLabel>
          <IonLabel className='text-bold-content'><strong>{device?.temperature } c</strong></IonLabel>
        </IonCol> 
        {  device?.closeOpenSensor && 
        <IonCol size='6' >
          <IonLabel className='flex-content'> 
            <IonLabel className='icon' color='danger'> <FontAwesomeIcon icon={faDoorOpen} /> </IonLabel> 
            <IonLabel className='text-content' > <small> {t('home_pto')} </small> </IonLabel>
            <IonLabel className='text-bold-content'><strong> {device?.closeOpenSensor === "1" ? "Door Open":"Door Close"}</strong></IonLabel>
          </IonLabel>
        </IonCol>}
        </IonRow>

        <IonRow className='bg-white-shade' >
        <IonCol size='12' >
          <IonLabel className='flex-content'> 
            <IonIcon className='icon' color='success' icon={idCard} />
            <IonLabel className='text-content' > 
              <small > {device?.fld_driverID} <br/> {device?.fld_driverMessage} </small> 
            </IonLabel> 
          </IonLabel>
        </IonCol>  
      </IonRow> 
  </IonGrid>
  )
}

const SummaryProfile=({device}:any)=>{
 
  const callPhone=()=>{
    if(device.phone_number){
      window.open("tel:"+device.phone_number)
    }
  }
  
  const openLinkRealtime=async()=>{
    const profile = await getStorage("profile")  
    const linkRealtime = profile.url_realtime
    const code = btoa(JSON.stringify(device.device_id) +':Andaman067');
 
    window.open(linkRealtime+code ,'_blank')
  }

  useEffect(()=>{   },[])

  return(<div className='profile-device' >
    
      <IonRow   >
        <IonCol size='4' > <IonLabel className='summary-title'>{t('home_profile_name')}</IonLabel> </IonCol>
        <IonCol size='8' > <IonLabel className='summary-val'>{device.name}</IonLabel> </IonCol> 
      </IonRow>

      <IonRow   >
        <IonCol size='4' > <IonLabel className='summary-title'>{t('home_profile_drivername')}</IonLabel> </IonCol>
        <IonCol size='8' > <IonLabel className='summary-val'>{device.fld_driverMessage?device.fld_driverMessage:"-"}</IonLabel> </IonCol> 
      </IonRow>
      <IonRow className='cursor-pointer'  onClick={()=>{ callPhone();  }}>
        <IonCol size='4' > <IonLabel className='summary-title'>{t('home_profile_phoneNumber')}</IonLabel> </IonCol>
        <IonCol size='8' > <IonLabel className='summary-val'>{device.phone_number?device.phone_number:"-"}</IonLabel> </IonCol> 
      </IonRow>
      <IonRow className='cursor-pointer'    >
        <IonCol size='4' > <IonLabel className='summary-title'>{t('home_profile_odometer')}</IonLabel> </IonCol>
        <IonCol size='8' > <IonLabel className='summary-val'>{device.mile} {t('mile')}</IonLabel> </IonCol> 
      </IonRow>
      <IonRow className='cursor-pointer'    >
        <IonCol size='4' > <IonLabel className='summary-title'>รุ่น</IonLabel> </IonCol>
        <IonCol size='8' > <IonLabel className='summary-val'>{device.deviceType}  </IonLabel> </IonCol> 
      </IonRow> 
      <IonRow className='cursor-pointer'  onClick={()=>{openLinkRealtime() }} >
        <IonCol size='4' > <IonLabel className='summary-title'>{t('home_profile_linkRealtime')}</IonLabel> </IonCol>
        <IonCol size='8'  > <IonLabel className='summary-val'>  <IonIcon icon={open} /> </IonLabel> </IonCol> 
      </IonRow>

  </div>)
}



const SummaryAction=({device}:any)=>{
  const dispatch = useDispatch() 
  const openDialog=(option:any)=>{
    console.log("click summary")
    dispatch(setDeviceModalOpt(option))
    dispatch(setDevice(device))
    setTimeout(()=>{
      dispatch(setDeviceModal(true))
    },100)
  }

  return(<div className='action-device' >
      <IonRow  >
        <IonCol size='2' className='set-center' > 
           <IonCheckbox></IonCheckbox>
        </IonCol>
        <IonCol size='8' > <IonLabel className='action-name'> {t('home_action_realtimetracking')} </IonLabel> </IonCol>
      </IonRow>
      <IonRow className='cursor-pointer'  >
        <IonCol size='2' className='set-center' > 
           <IonIcon icon={terminal} color={ device?.status_engin === 0 ? "danger":"success"}  className='action-icon' onClick={()=>{openDialog('cmd')}}/>
        </IonCol>
        <IonCol size='8' onClick={()=>{openDialog('cmd')}}> 
          <IonLabel className='action-name'> {t('home_action_sendCMD')} </IonLabel> 
        </IonCol> 
      </IonRow>
      <IonRow  className='cursor-pointer'   onClick={()=>{openDialog('message')}} >
        <IonCol size='2' className='set-center' > 
           <IonIcon icon={chatbubbleEllipses} className='action-icon'  onClick={()=>{openDialog('message')}} />
        </IonCol>
        <IonCol size='8' onClick={()=>{openDialog('message')}} > 
          <IonLabel className='action-name'> {t('home_action_messageEvent')}</IonLabel> 
        </IonCol> 
      </IonRow>
      {/* <IonRow  className='cursor-pointer'   onClick={()=>{openDialog('setting')}}  >
        <IonCol size='2' className='set-center' > 
           <IonIcon icon={settings} className='action-icon' />
        </IonCol>
        <IonCol size='8'  onClick={()=>{openDialog('setting')}} > 
          <IonLabel className='action-name'>
            {t('home_action_settingIconConsump')}
          </IonLabel> 
        </IonCol>
      </IonRow> */}
  </div>)
}

export const SummaryDevice=({device,edit}:any)=>{

  const isExpire=()=>{
    if(moment(device?.expiration_date).format() > moment().format() ){
      return true
    }else{
      return false
    }
  }

  return(<div className='summary-device' > 
      <IonRow  >
        <IonCol size='4' > <IonLabel className='summary-title'>{t('vehicle')}</IonLabel> </IonCol>
        <IonCol size='8' > 
          <IonLabel className='summary-val'>{device.name}</IonLabel> &nbsp; 
        </IonCol>
     
        <IonCol size='4' > <IonLabel className='summary-title'>{t('eventTime')}</IonLabel> </IonCol>
        <IonCol size='8' > <IonLabel className='summary-val'>{device.event_stamp}</IonLabel> </IonCol>
    
        <IonCol size='4' > <IonLabel className='summary-title'> {t('recieveTime')} </IonLabel> </IonCol>
        <IonCol size='8' > <IonLabel className='summary-val'>{device.event_stamp}</IonLabel> </IonCol>
      
        <IonCol size='4' > <IonLabel className='summary-title'>{t('home_speed')}</IonLabel> </IonCol>
        <IonCol size='8' > <IonLabel className='summary-val'>{device.speed} <small> <strong>{t('kmHUnit')}</strong> </small></IonLabel> </IonCol>
      
        <IonCol size='4' > <IonLabel className='summary-title'>{t('home_odometer')}</IonLabel> </IonCol>
        <IonCol size='8' > <IonLabel className='summary-val'>{device.mile} <small> <strong>{t('mile')}</strong> </small></IonLabel> </IonCol>
     
        <IonCol size='4' > <IonLabel className='summary-title'>{t('ignition')}</IonLabel> </IonCol>
        <IonCol size='8' > <IonLabel className='summary-val'> {device.ignition ? "ON" : "OFF" }  &nbsp; <IonIcon icon={key} style={{fontSize:"1.1em"}} color={device.ignition ? "success" : "danger" } /> </IonLabel> </IonCol>
    
        <IonCol size='4' > <IonLabel className='summary-title'>{t('home_address')}</IonLabel> </IonCol>
        <IonCol size='8' > <IonLabel className='summary-val'> {device.address} </IonLabel> </IonCol>
    
        <IonCol size='4' > <IonLabel className='summary-title'>Lat/Lon</IonLabel> </IonCol>
        <IonCol size='8' > <IonLabel className='summary-val'> {device.latitude} , {device.longitude} </IonLabel> </IonCol>
      
        <IonCol size='4' > <IonLabel className='summary-title'>GSM/GPS</IonLabel> </IonCol>
        <IonCol size='8' > <IonLabel className='summary-val'> {device.fld_signalStrength}/{device.satellites} </IonLabel> </IonCol>
        <IonCol size='4' > <IonLabel className='summary-title'>รุ่น</IonLabel> </IonCol>
        <IonCol size='8' > <IonLabel className='summary-val'> {device.deviceType}  </IonLabel> </IonCol>

        
 
      </IonRow>
      {
        device?.expiration_date &&
        <IonRow  >
          <IonCol size='4' > <IonLabel className='summary-title'>{t('home_expire')}</IonLabel> </IonCol>
          <IonCol size='8' > <IonLabel className='summary-val'  color={isExpire()?'success':'danger'}> <IonIcon icon={isExpire()?shieldCheckmark:closeCircle} /> &nbsp; {moment(device?.expiration_date).format("DD/MM/YYYY")} </IonLabel> </IonCol>
        </IonRow>
      }
      <IonRow  >
        <IonCol size='12' className='set-center' > 
          <IonButton size='small'  mode='ios' onClick={()=>{ return edit(true) }}  >
            <IonLabel> {t("edit")}</IonLabel>  &nbsp; <IonIcon icon={pencil} />
          </IonButton>
        </IonCol> 
      </IonRow>
  </div>)
}
   
export const DevicesContainer:React.FC  = () => {  
  const devices :any= useSelector(getDevices)
  const backup :any= useSelector(getBackup)
  const keyword  :any= useSelector(getKeyword)
  const dispatch = useDispatch()

  const  toggleDarkTheme=(shouldAdd:boolean)=> {
    document.body.classList.toggle('dark', shouldAdd);
  } 

   useEffect(()=>{
    console.log("screen innerWidth", window.innerWidth)
      const toggle = document.querySelector('#themeToggle');
      toggle?.addEventListener('ionChange', (ev:any) => {
        console.log("ev.detail.checked ", ev.detail.checked)
       document.body.classList.toggle('dark', ev.detail.checked); 
      }); 
      
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      console.log("prefersDark ",prefersDark)
      prefersDark?.addListener((e) => toggleDarkTheme(e.matches)); 
      toggleDarkTheme(prefersDark.matches) 
    },[])

    const searchChange=(keyword:any) => { 
      console.log("keyword ",keyword)
      const needle:any = keyword
      if(needle.length > 0){ 
        let res = backup.filter((d:any) => d.name.toLowerCase().indexOf(needle) > -1)
       //console.log("res ",res)
        dispatch(setDevices(res)) 
        dispatch(setKeyword(needle))
      }else{
        clearSearch()
      }
      //backupDevices
    }

    const clearSearch=()=>{
      dispatch(setKeyword(""))
      dispatch(setDevices(backup))
    }  

    return ( 
      <> 
       <div className='container-devices'>
        <div className='container-search'>
            <IonRow>
                <IonCol size='10.5' style={{paddingRight:'0px'}} >
                  <IonSearchbar mode='ios' value={keyword} debounce={200} placeholder={t('search').toString()}
                  onIonInput={(e:any) => searchChange(e.detail.value)} onIonClear={()=>{clearSearch()}} ></IonSearchbar>
                </IonCol>
                <IonCol size='1.5' className='set-center'  >
                    <FilterDevices />
                </IonCol> 
            </IonRow> 
        </div>
        <div className='content' >
        <IonContent color='light-tint' >
            <PageSlider />
         </IonContent> 
        </div> 
       </div>
       </>
      ); 
    }
export default DevicesContainer
   
 
export function SlideNextButton({device,modal,select}:any) {
   
  const dispatch= useDispatch()

  const selectDevice=()=>{
    dispatch(setPreviewDevice({active:true, device: device}))  
  }
  
  return (
    <IonButton 
    fill='clear' 
    color={"secondary"} 
    onClick={()=>{   
      selectDevice();
      modal?.setCurrentBreakpoint(0.8)
      return select(device)
    }
    }> 
      <IonIcon icon={chevronForwardOutline} /> 
    </IonButton>
  );
}
function SlidePrevButton({modal}:any) {
  const swiper = useSwiper();
  const dispatch= useDispatch()

  const clearDevice = ()=>{ 
    dispatch(setDevice(null)) 
    dispatch(setPreviewDevice({active:false, device: null}))
  }

  return (
    <IonButton 
      fill='clear' 
      color={"secondary"} 
      onClick={() =>{ 
        swiper?.slidePrev();
        modal.setCurrentBreakpoint(0.1)
        clearDevice()
      }}> 
      <IonIcon icon={chevronBackOutline}    /> 
      <IonLabel> {t('back')} </IonLabel> 
    </IonButton>
  );
}

export const PageSlider=()=>{  
  return(  
    <Swiper  
      style={{width:'100%',height:"98%"}}
      draggable={false}  
      onSlideChange={(e) => console.log('slide change',e)}
      onSwiper={(swiper) => console.log(swiper)}
      allowTouchMove={false}
    >
      <SwiperSlide style={{background:"#FFF"}}>
          <LoadingProgress />
          <DevicesList/> 
      </SwiperSlide>
      <SwiperSlide style={{background:"#FFF"}}>
         <DevicePreview />
      </SwiperSlide>  
      <SwiperSlide style={{background:"#FFF"}}>
         <PreviewRouteHistory />
      </SwiperSlide>  
    </Swiper> 
  )
}

const  DevicesList=()=>{
  const template :any= useSelector(getContentFilter)
  const devices :any= useSelector(getDevices) 
  const status :any= useSelector(getFilter)
  const dispatch= useDispatch() 
  const changeStatus=async(val:any)=>{ 
    await dispatch(setFilterDevices(val)) 
  }

  useEffect(()=>{ 
  },[devices])
  
  return(
    <div className='page-slider'>
      <IonSegment color='light' mode='ios' value={status} className="status-device" onIonChange={(e)=>{changeStatus(e.detail.value!)}}>
        <IonSegmentButton mode='ios' value="all"  > 
          <IonIcon icon={status==='all'?grid:gridOutline} />
          <IonLabel>{t('home_segment_all')}</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton mode='ios' value="move">
          <IonIcon icon={move} />
          <IonLabel>{t('home_segment_move')}</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton mode='ios' value="idle">
          <IonIcon icon={status==='idle'? carSport:carSportOutline} />
          <IonLabel>{t('home_segment_idle')}</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton mode='ios' value="stop">
          <IonIcon icon={status ==='stop'?stop:stopOutline} />
          <IonLabel>{t('home_segment_stop')}</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton mode='ios' value="offline">
          <IonIcon icon={status==='offline'? cloudOffline:cloudOfflineOutline} />
          <IonLabel>{t('home_segment_offline')}</IonLabel>
        </IonSegmentButton>
      </IonSegment>
     <>
     {
        devices.length > 0 ?
        <div  className='list-content '> 
        {
          template === 'card' ?<>
            { status === "all" ?<>{ devices.map((device:any,index:any)=>
              <CardDevice device={device} key={index} /> 
            )}</>:<>
            { devices.filter((device:any)=>
              status === "offline" ? device.online === 0 :
              status === "move" ? device.status === 7 :
              status === "idle" ? device.status === 24 :  device.status === 23 
            ).map((device:any,index:any)=>
              <CardDevice device={device} key={index} /> 
            )}  
            </>}   
          </>:
          <>
            <ListDevice status={status} mapref={null} />
          </>
        }
        
        </div>:
        <div  className='list-content '> </div>
       }</>
       
    </div>
  )
}
 
interface RouteEvent {
  address :String  
  driver_id :String 
  driver_message :String 
  event_stamp : Date 
  heading :Number 
  id :Number 
  latitude:Number 
  longitude:Number 
  speed:Number 
  status:Number 
}
 
interface RouteHistory {
  count :Number  
  datetime_start :String |Date  
  datetime_stop :String |Date  
  distance_all :String | Number  
  list: RouteEvent[] 
  speed_max :Number  
  time_all:String 
  time_max:String 
} 
export const PreviewRouteHistory=({mapref}:any)=>{
  const preview:any= useSelector(getPreviewDevice)
  const [segment,setSegment] = useState("daily")
  const trackList:any[] = useSelector(getQuickTrackList)
  const dateStart:any= useSelector(getDateStart)
  const dateEnd:any = useSelector(getDateEnd)
  const [listAll,setListAll] = useState<RouteEvent[]>([])
  const [limit,setLimit] = useState(10)
  const fasttrack:any = useSelector(getFastTrack)
  const dispatch = useDispatch()
  const [datestart,setDateStart] = useState(moment().format("YYYY-MM-DDT00:00:00"))
  const [dateend,setDateEnd]= useState(moment().format("YYYY-MM-DDT23:59:59"))
  const [loading,setLoading] = useState(false)

  const swiper = useSwiper();
  useEffect(()=>{ 
    setDate()
  },[preview,segment])

  const setDate=()=>{
    setDateEnd(moment(trackList[0]?.date).format("YYYY-MM-DDT23:59:59"))
    setDateStart(moment(trackList[trackList.length-1]?.date).format("YYYY-MM-DDT00:00:00"))
  }

  const goback=()=>{
    swiper?.slideTo(0); 
    setTimeout(()=>{ 
      dispatch(setFastTrack(null))
      dispatch(setQuickTrackList([]))
    },600)
  } 

  const selectEvent=(index:any)=>{
    dispatch(setRouteIndex(index))
  }

  const segmentChange=async(val:any)=>{
    setSegment(val)
    if(val==="all" && listAll.length === 0){
      let token =await  getStorage('token'); //'96a7461bda051faaca7777ce93351975b15b868546c6074a99fbedda388245fe'
      let host =  await getStorage("api") 
     
      let deviceId =  preview.device.device_id
      let date = moment(dateStart).format("DD/MM/YYYY")
      let dateend = moment(dateEnd).format("DD/MM/YYYY")
      let start = moment(dateStart).format("YYYY-MM-DD 00:00:00")   
      let end = moment(dateEnd).format("YYYY-MM-DD 23:59:59") 
      let route = await historyRoute({deviceId,date,dateend,start,end,token,host})
      setListAll(route.list) 

      console.log("segmentChangeRoute ",route) 
    }
  }

  function exportUserInfo(content:any, filename: String) {
   // const fileData = JSON.stringify(content);
    const blob = new Blob([content], { type: "gpx" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = filename.toString() ;
    link.href = url;
    link.click();
  }

  const dowloadGPX=()=>{  
    if(segment ==="all"){
      let gpxtext = '<?xml version="1.0" encoding="UTF-8" ?> <gpx version="1.1" creator="gpxgenerator.com">'
      gpxtext=gpxtext+'<wpt lat="'+listAll[1].latitude+'" lon="'+listAll[1].longitude+'"> <name>'+listAll[1].address+'</name>'+
      '<number>1</number> <ele>'+1+'</ele> </wpt>' +
      '<trk> <number>1</number>'+ '<trkseg>'

      for(let i =0; i<listAll.length;i++){
        let newtext =  
            '<trkpt lat="'+listAll[i].latitude+'" lon="'+listAll[i].longitude+'"><ele>'+i+'</ele>'+
              '<time>'+new Date(listAll[i].event_stamp)+'</time>'+
            '</trkpt>' 

          let lsttext = gpxtext + newtext
          gpxtext = lsttext
      }
      gpxtext = gpxtext + '</trkseg> </trk>'

      for(let i =1; i<listAll.length;i++){
          gpxtext=gpxtext+'<wpt lat="'+listAll[i].latitude+'" lon="'+listAll[i].longitude+'"> <name>'+listAll[i].address+'</name><number>i</number> <ele>'+i+'</ele> </wpt>'
      } 
      gpxtext = gpxtext + '</gpx>' 
      let filename = preview.device.name+"'s route "+'-'+dateStart+'-'+dateEnd+'.gpx'

      exportUserInfo(gpxtext,filename)
    }
  }

  useEffect(()=>{ 
    setDate() 
  },[trackList])

  return(
    <div className='preview-routeistory'> 
        <IonHeader className='header ion-no-border' color='light' >
          <IonToolbar color='light'>
            <IonRow>
              <IonCol size='2' > 
                <IonButton 
                  fill='clear' 
                  color={"secondary"} 
                  onClick={() =>{ 
                     goback()
                  }}> 
                    <IonIcon icon={chevronBackOutline}    /> 
                    <IonLabel> {t('back')} </IonLabel> 
                  </IonButton>
              </IonCol> 
            </IonRow>
          </IonToolbar> 
        </IonHeader> 
        <div className='ion-padding preview-device-contianer' >
            {fasttrack &&   <IonCardTitle style={{fontSize:"1.2em"}} className={` text-bold`}   >
              {fasttrack?.name} 
        </IonCardTitle>   }  
        </div> 
          <IonSegment className='preview-route-segment' value={segment} mode='ios' onIonChange={(e:any)=>{ segmentChange(e.detail.value)}}>
            <IonSegmentButton value="daily"> 
                <IonLabel>
                  <IonIcon className='icon' icon={calendarNumberOutline} /> 
                  <span>{t('home_preview_daily')}</span>
                </IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="all">
                <IonLabel>
                  <IonIcon className='icon' icon={calendarOutline} />
                  <span>{t('home_preview_all')}</span>
                </IonLabel> 
            </IonSegmentButton>
          </IonSegment>
          <div style={{width:'90%',marginLeft:'5%',marginTop:'.5rem'}} > 
              <DateTimeRange 
               start={datestart} end={dateend} 
               loading={loading} setLoading={setLoading}
               setDateStart={(e:any)=>{return setDateStart(e)}} 
               setDateEnd={(e:any)=>{return setDateEnd(e)}} 
              />
              <IonRow className='preview-route-overview'>
                <IonCol size='4'  >
                    <span className='overview-title'>{t('previewTotalDis')}</span><br/>
                    <IonLabel className='overview-val'>{fasttrack?.distance_all}</IonLabel>
                </IonCol>
                <IonCol size='6'  >
                    <span className='overview-title'>{t('previewTotalDrivin')}</span><br/>
                    <IonLabel className='overview-val'>{fasttrack?.time_all}</IonLabel>
                </IonCol>
                <IonCol size='2' >
                  <IonButton fill='clear' color={"dark"} id='dowload-device-history-detail' >
                    <FontAwesomeIcon icon={faDownload}  style={{fontSize:'1.3em'}} /> 
                  </IonButton>
                  <IonPopover mode='ios' trigger={"dowload-device-history-detail"} className='dowload-device-history-detail' >
                    <IonContent  className='padding' >
                       <IonRow>
                         <IonCol size="6" className='set-center cursor-pointer ion-activatable ripple-parent' 
                         onClick={()=>{dowloadGPX()}} >   
                             <img src={"../../assets/icon/gpx.svg"} style={{height:'2rem'}}  /> 
                             <IonLabel style={{margin:".3rem",fontSize:'.8em',fontWeight:'bold'}}>GPX</IonLabel>
                             <IonRippleEffect></IonRippleEffect>
                         </IonCol>
                         <IonCol size='6' className='set-center  cursor-pointer ion-activatable ripple-parent' > 
                         <ExcelPrint content={fasttrack?.list} details={fasttrack}  />
                            
                         </IonCol>
                       </IonRow>
                    </IonContent>
                  </IonPopover>
                </IonCol>
              </IonRow> 
          </div>   
        <div  className='ion-padding' style={{width:"100%"}} >
        {segment === 'daily' ?  
          <IonList className='route-list-preview-container ion-padding'>  
              <div>   
                  {trackList.map((list:any,index:any)=>
                      <RoutList route={list} key={index}  viewonmap={(bound:any[])=>{mapref?.fitBounds(bound,{padding:[100,-100]})}} />
                    )}   
                { trackList.length === 0 && <div style={{width:"100%",flexDirection:"row",alignItems:"center"}} className='set-center' >
                    <IonLabel color={"medium"} style={{alignItems:"center"}} >{t('loading')}  &nbsp; <IonSpinner name='lines-small' /></IonLabel> 
                  </div>
                }  
              </div>    
            </IonList>:
            <IonList className='route-list-container ion-padding' style={{maxHeight:"40vh"}} >
            {
              listAll .map((el:any,index:any)=>
                <ListEvents key={index} index={index} list={el} swiper={swiper} selectEvent={(e:any)=>{return selectEvent(e)}} /> 
              )
            } 
         </IonList>
        } 
       </div> 
    </div>
  )
}

const RoutList=({route,viewonmap}:any)=>{
  const fastTrack:any = useSelector(getFastTrack)   
  const preview:any = useSelector(getPreviewDevice)
  const [loading,setLoading] = useState(false)
  const [popup,setPopup] = useState(false)
  const dispatch = useDispatch()
  useEffect(()=>{
    // console.log("RoutList route tarck",route )
  },[])

  const setFastTract=async(id:any, route:any,routedate:any)=>{ 
        dispatch(setRouteIndex(0))
        setLoading(true)
     
          let tarck={   
              _id : id ,
              name: route?.name ,
              route:route ,
              list: route?.list ,   
              typeId: route?.deviceIconId ?1:route?.deviceIconId , 
              bound:route?.bound  ,
              color: "#000",
              count: route?.count,
              datetime_start: route.datetime_start,
              datetime_stop: route.datetime_stop ,
              speed_max: route.speed_max ,
              time_all: route.time_all,
              distance_all: route.distance, 
              deviceId: fastTrack?.deviceId ,
              ...route
          }
          console.log("tarck ",tarck)
          dispatch(setFastTrack(tarck))
        
        setLoading(false)
  }

  return( <div> 
      <IonRow>
        <IonCol size='4' >
           <IonLabel className='route-day' >{moment(route.date).format("dddd")}</IonLabel>
        </IonCol>
        <IonCol size='5'  className='set-center' > <div className='line-btw-date' >  <IonIcon icon={ellipse} className='left' />  <IonIcon icon={ellipse} className='right' /> </div></IonCol>
        <IonCol size='3' className='ion-text-right' >
          <small  className='route-date'>{moment(route.date).format("DD/MM/YYYY")}</small>
        </IonCol>

        <IonCol size='4' > 
          <small className='name'>{t('distance')}</small><br/>
          <IonLabel className='val'>{route.distance} {t('kmUnit')}</IonLabel>
        </IonCol>
        <IonCol size='4' > 
          <small className='name'>{t('duration')}</small><br/>
          <IonLabel className='val'>{route.duration} </IonLabel>
        </IonCol>
        <IonCol size='4' className='ion-text-right' >  
          {
            fastTrack?._id === route.routeid ? 
            <div  className="set-center" style={{fontSize:".7em",flexDirection:"row" }} > 
              <IonButton mode="ios" color={"primary"} size="small"  
                 style={{fontSize:"1em"}} 
                 >
                  <IonIcon slot="start" icon={pause}></IonIcon> 
                  <span>{t('control_pause')}</span> 
              </IonButton>
              <IonButton mode="ios" color={"primary"}   size="small" 
                style={{fontSize:"1em", width:'2rem'}} 
                id={'route-popup'+route.routeid}
                onClick={()=>{setPopup(true); return viewonmap(route?.bound) }}
              >
                <IonIcon icon={chevronForward} />
              </IonButton>  
            </div> :
            <IonButton 
              size='small' mode='ios' 
              color={'secondary'}  
              onClick={()=>{setFastTract(route.routeid,route,route.date); setPopup(true)}}  
            > {loading? <IonSpinner name="crescent" />: <IonIcon icon={play} />  }  

            </IonButton>  
          }  
          
          {/* {fastTrack&&  <IonPopover  isOpen={popup} showBackdrop={false}
            mode='ios' className='popup-dragale-content'
            trigger={'route-popup'+route.routeid}
            onIonPopoverDidDismiss={(e)=>{setPopup(false)}}  
          >
            
            <IonContent class="ion-padding" style={{position:"relative"}} >
              <IonButton className='close-popup' size='small' fill='clear' onClick={()=>{setPopup(false)}} > 
                  <IonIcon icon={closeCircle} style={{fontSize:'1em'}} /> 
              </IonButton>
              <IonRow>
                <IonCol size='12' >
                  <IonLabel className='name'><strong> {preview.device?.name} </strong></IonLabel><br/>
                  <IonLabel className='date'><small> {moment(route.date).format("DD/MM/YYYY 00:00:00")} - {moment(route.date).format("DD/MM/YYYY 23:59:59")} </small></IonLabel>
                </IonCol>
              </IonRow>
              <RouteDragable list={fastTrack?.list}  />
            </IonContent>
          </IonPopover>}  */}

        </IonCol>
      </IonRow> 
        {fastTrack && popup && fastTrack.id ===route?.id && 
        <IonGrid>
            <IonRow>
              <IonCol size='10' > {fastTrack?.name} </IonCol>
              <IonCol size='2' className='set-center' > <IonButton color={"danger"} fill={'clear'}  onClick={()=>{setPopup(false)}}> <IonIcon icon={closeCircle} /> </IonButton> </IonCol>
              <IonCol size='12' >
                <RouteDragable list={fastTrack?.list}  />
              </IonCol>
            </IonRow>
          <IonRow> 

          </IonRow>  
        </IonGrid>
        }  
  </div>  )
}

export const RouteDragable=({list}:any)=>{ 
  const preview :any= useSelector(getPreviewDevice)
  const indexRoute :any= useSelector(getRouteIndex)
  const dispatch = useDispatch()
  
  const dragBar=(val:any)=>{
    dispatch(setRouteIndex(val))
  } 
  return(
    <div  className='route-dragale-content' >
      <IonRow> 
        <IonCol size='12' className='set-center' > 
              <div className="route-bar"  >  
                  { list.map((l:any,index:any)=> 
                    <div key={index}  
                        style={{ width: list.length/100 +'%'}} 
                        className={`status ${
                          l.status === 7 ?"status-move" :
                          l.status === 23 ?"status-idle" :
                          l.status === 24?"status-stop" : "status-offline"}
                        `}
                    ></div> 
                  )} 
              </div> 
            <IonRange 
                className='route-bar-range' aria-label="rang"  value={indexRoute}
                mode='ios' ticks={true} snaps={true} min={0} max={list.length}
                onIonKnobMoveEnd={({ detail }) => dragBar(detail.value)}
            ></IonRange>
        </IonCol>
      </IonRow>
      
    </div>
  )
}

const ExcelPrint=({content,details}:any)=>{ 
  
  const printExcelss=()=>{
    console.log("details ", details)
    let column = Object.keys( content[0]);   
    var options = {
      filename: './streamed-workbook.xlsx',
      useStyles: true,
      useSharedStrings: true
    }; //@ts-ignore
    let w = new Excel.Workbook(options);
    var wh = w.addWorksheet('My Sheet', {properties:{tabColor:{argb:'FFC0000'}}});
     
    wh.addRow(["History Route",'','' ,'-']);     
    wh.addRow(["-",'','','-']);  
    wh.addRow(['']) 
    wh.addRow(['AMOUNT','DESCRIPTION','UNIT PRICE','PRICE'])
    wh.columns =[{width: 13},{width: 25},{width: 13},{width: 13}]
    wh.mergeCells('A1:C1');
    wh.mergeCells('A2:C2'); 
  }

  const printExcel=async ()=>{
    try{   
        var options = {
            filename: './streamed-workbook.xlsx',
            useStyles: true,
            useSharedStrings: true
        }; //@ts-ignore 
        let workbook = new Excel.Workbook(options);
        var ws = workbook.addWorksheet( ` History Route - ${details?.name}` , {properties:{tabColor:{theme:1}}});
        
        ws.columns =[{width:30},{width: 20},{width: 20},{width: 20},{width: 15},{width: 15},{width: 15},{width: 15} ] 
        // ws.getRow(1).alignment = {horizontal:'center' , vertical:'middle' }

        const header = [t("title_location"),t("title_time"),"fuel","lat","lon", t("title_speed") ,t("title_status"),"statusTime" ]
        ws.mergeCells('A1', 'J1'); 
        ws.getCell("A1").value = "History Route"

        ws.mergeCells('A3', 'J3'); 
        ws.getCell("A3").value = `ทะเบียน ${details?.name}`
        ws.getCell("A3").alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 

        ws.mergeCells('A4', 'J4'); 
        ws.getCell("A4").value =  `วันที่ ${moment(content[0].time_stamp).format("DD MMMM   HH:mm")} - ${moment(content[content.length-1].time_stamp).format(" DD MMMM  HH:mm")}`
        ws.getCell("A4").alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 
        ws.addRow([''])
       
        ws.addRow([''])
        ws.addRow([''])

        ws.addRow(header)
        const rowheader = ws.getRow(1); 
        rowheader.eachCell({includeEmpty: true}, ((cell:any) => {
            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 
            cell.font = { bold: true ,  family: 4, size: 16, underline: true, }
            cell.border = borderCells
        }));  

        content.map((rows:any)=>{  
            let row =  [
              rows?.address ,
              moment(rows?.eventStamp).format("HH:mm DD MMM YYYY") ,
              rows?.fuelLiters,
              rows?.latitude ,
              rows?.longitude ,
              rows?.speed ,
              rows?.status,
              rows?.statusTime
            ]
            
            console.log("row ",row)
            
            let rowadded = ws.addRow(row)
            rowadded.height =   rowadded.height*2 ;
            rowadded.eachCell({includeEmpty: true}, ((cell:any,column:any) => {  
                cell.border = borderCells 
                cell.inset= [0.25, 0.25, 0.35, 0.35]
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 
            }));   
        }) 
        ws.addRow([''])  
        // ws.getCell(`A${table.rows.length+15}`).value = details.pttprice
        
        let fileName=`History Route - ${details?.name}-${moment(content[0].time_stamp).format("DD MMMM   HH:mm")} - ${moment(content[content.length-1].time_stamp).format(" DD MMMM  HH:mm")}.xlsx`;  
        workbook.xlsx.writeBuffer() 
        .then(function(buffer:any) { 
            const data: Blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            FileSaver.saveAs(data, fileName);
        }); 

    }catch(err){
        console.log("errr ",err)
    }
}


  return(
    <div style={{width:"100%",height:"100%"}} onClick={()=>{printExcel()}}>
      <img src={"../../assets/icon/excel.svg"} style={{height:'2rem'}}  /> 
      <IonLabel style={{margin:".3rem",fontSize:'.8em',fontWeight:'bold'}}>Excel</IonLabel>
      <IonRippleEffect></IonRippleEffect>
    </div>
  )
}

const DateTimeRange=({start,end,setDateStart,setDateEnd ,loading,setLoading}:any)=>{
  const dispatch = useDispatch()
  const preview:any = useSelector(getPreviewDevice) 
  const fasttrack:any = useSelector(getFastTrack)
  // const [loading,setLoading] = useState(false)
  
  const searchNewTrack=async()=>{ 
      dispatch(setRouteIndex(0))
      setLoading(true)
      console.log("searchNewTrack fasttrack ",fasttrack)
      const deviceId=   preview.device?.device_id

      let routeLists:any=[]
      let  rangeDate:any[] = []
      const begin = moment(start);
      const stop = moment(end);
      const r1 = moment.range(begin, stop); 
      const diff = r1.diff('day') ; 
      let datecurrent = new Date(start)

      for(let i=0; i<diff;i++){
        rangeDate = [...rangeDate , { start: new Date(moment(datecurrent).format("YYYY-MM-DD 00:00:00")).getTime() , end:  new Date(moment(datecurrent).format("YYYY-MM-DD 23:59:59")).getTime() }]  
        datecurrent =  new Date(moment(datecurrent).add(1,'day').format())
      } 
      let routeid = await sha1(`start:${start},end:${end},id:${deviceId},create:${moment().format()}`)   
      let body ={
        rangeDate ,
        deviceId: fasttrack?.deviceId
      } 
      let result = await getFasttrack(
          body , routeid
      )
      console.log("searchNewTrack result ",result)
  
      let tarck={   
          _id : routeid+"1" , 
          route: null ,
          list: result?.fastrack[0]?.list ,   
          bound: result?.fastrack[0]?.bound , 
          count: result?.fastrack[0]?.count,
          datetime_start: result?.fastrack[0]?.datetime_start,
          datetime_stop: result?.fastrack[0]?.datetime_stop ,
          speed_max: result?.fastrack[0]?.speed_max ,
          time_all: result?.fastrack[0]?.time_all,
          distance_all: result?.fastrack[0]?.distance, 
          ...fasttrack
      }
       
      tarck.distance_all = (result?.fastrack[0]?.distance/1000).toFixed(0)+" "+t('kmUnit')
      tarck.time_all =convertMsToTime(result.allDuration)

      await result?.fastrack.forEach(async(el:any,index:any) => {   
          routeLists.push(
              {   date: moment(el?.date).format("YYYY-MM-DD") , 
                  distance: (el?.distance/1000).toFixed(0)  ,
                  duration: convertMsToTime(el.duration) ,  
                  routeid: el?.routeid ,
                  bound: el?.bound,
                  list: el?.list ,// listInDate
                  route: el?.bound,
              },
          )
      });
      console.log("routeLists ",routeLists) 
      setLoading(false)  
      
      console.log("setQuickTrackList tarck ",tarck)
      dispatch(setPreviewDevice({active:false , device:null}))
      dispatch(setFastTrack(tarck))
      dispatch(setQuickTrackList(routeLists))
      }

      useEffect(()=>{ 
        console.log("loading  ",loading)
      },[loading])

  return( 
    <div id="click-trigger" style={{width:"100%",fontSize:".8em",backgroundColor:"#EEE",padding:".5rem", borderRadius:"7px"}} >
      <IonRow>
      <IonCol size='5' className='set-center ' style={{padding:"0px"}}>
        <IonLabel>{moment(start).format("DD MMM YY HH:mm")}</IonLabel> 
      </IonCol>
      <IonCol size='1'className='set-center ' style={{padding:"0px"}}>  : </IonCol>
      <IonCol size='5' className='set-center  ' style={{padding:"0px"}}>
        <IonLabel>{moment(end).format("DD MMM YY HH:mm")}</IonLabel>
      </IonCol>
      </IonRow>
      <IonPopover trigger="click-trigger" mode='ios' className='popover-datetime-range'  triggerAction="click">
        <IonContent class="ion-padding">
          <IonGrid>
            <IonRow>
            <IonCol size='6' >
               <IonDatetime value={start} onIonChange={(e:any)=>{return setDateStart(e.detail.value)}} ></IonDatetime></IonCol>
            <IonCol size='6' >
              <IonDatetime value={end} max={moment().add(7,'day').format("YYYY-MM-DDTHH:mm:ss")} onIonChange={(e:any)=>{return setDateEnd(e.detail.value)}}></IonDatetime></IonCol>
            </IonRow>
          </IonGrid>

          <IonFooter>
             <IonGrid>
               <IonRow>
                  <IonCol size="12"  className='ion-text-right' >
                    <IonButton onClick={()=>{searchNewTrack()}}> <IonLabel>  {t('save')} </IonLabel> </IonButton>
                  </IonCol>
               </IonRow>
             </IonGrid>
          </IonFooter>
        </IonContent>
      </IonPopover>
      <IonLoading isOpen={loading} mode='ios' message={t('loading').toString()} onIonLoadingDidDismiss={()=>{setLoading(false)}} ></IonLoading>
    </div> 
  )
}