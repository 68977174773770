import { IonApp, IonButton, IonCol, IonContent, IonHeader, IonIcon,  IonItem,  IonLabel,  IonMenuButton,  IonPage,  IonPopover,  IonRouterOutlet, IonRow, IonSplitPane, IonTabBar, IonTabButton, IonTabs, IonToolbar, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, useHistory  } from 'react-router-dom';  
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import 'react-leaflet-markercluster/dist/styles.min.css';
/* Theme variables */
import 'split-pane-react/esm/themes/default.css';
import './theme/variables.css'; 
import Map from './pages/Map';
import { useDispatch, useSelector } from 'react-redux';
import { getIsSignin,  getMenuOpen, getWindowWidth, setDarkMode,  setDefaultMap,  setOS, setSignin, setWindowWidth } from './store/appSlice';
import Signin from './pages/Signin';
import { useEffect, useState } from 'react';
import { Settings } from './pages/Settings'; 
import Manual from './pages/Manual';
import Reports from './pages/Reports';  
import { getStorage, setStorage, userSetting  } from './actions';
import { Device } from '@capacitor/device';
import Menu, { CallapseMenu, MenuContent } from './components/Menu'; 
import { useTranslation } from 'react-i18next';    
import Admin from './pages/Admin';
import Notifications from './pages/Notifications';  
import Maintenance from './pages/Maintenance';
import SetRoute from './pages/SetRoute'; 
import DrivingPoints from './pages/BeahaviorDriving'; 
import RepTravelSum from './pages/Reports/RepTravelSum';
import Dashboards from './pages/Dashboards';
import Passroute from './pages/Passroute';
import HomeScreen from './pages/HomeScreen';
import Geozones from './pages/Geozones';
import { Toaster } from 'react-hot-toast';  
import PassrouteMap from './pages/PassrouteMap';
import PlayBack from './pages/Mdvr/PlayBack';
import RealtimeMdvr from './pages/Mdvr/RealtimeMdvr';
import TravelRoute from './pages/TravelRoute';
import TravelRouteAdd from './pages/TravelRouteAdd';
import Longdo from './pages/Longdo';
import { setMapDark } from './store/mapSlice';
import PageNotFound from './pages/PageNotFound';
import Employees from './pages/settings/Employees'; 
import GroupDevice from './pages/GroupDevice';
import Guests from './pages/Guests';
import Geofences from './pages/Geofences';
import GroupMonitor from './pages/GroupMonitor';
import NotificationSetting from './pages/NotificationSetting';
import GroupRouteHistory from './pages/GroupRouteHistory';
import IdleTimerComponent from './components/IdleTimerComponent';
import HomeSplitpane  from './pages/HomeSplitpane'; 
import Behaviors from './pages/Behaviors';
import NavBar from './components/NavBar';
setupIonicReact();

const App: React.FC = () => {
  const dispatch = useDispatch()  
  const isSinin :any= useSelector(getIsSignin) 
  const width =useSelector(getWindowWidth) 
  const [os,setOs] = useState("ios")  
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');  

  const menuOpen :any= useSelector(getMenuOpen)
  const {t,i18n}= useTranslation()
 
  const setDeviceModel=async()=>{ 
    dispatch(setWindowWidth(window.innerWidth))
    const info = await Device.getInfo()   
    setOs(info.operatingSystem.toLowerCase())
    dispatch(setOS(info.operatingSystem.toLowerCase()))  
  }
    
  const checkDarkMode=async()=>{
    const isDark = await getStorage("darkmode")
    const darkmap = await getStorage("darkmap") 
    console.log("darkmap ", typeof darkmap , darkmap)
    dispatch(setMapDark(darkmap === null ||  darkmap === undefined ||  darkmap === true  ||  darkmap === '' ?true:false))    
      //document.body.classList.add( "map-dark" )
    if(isDark){  
      dispatch(setDarkMode(isDark)) 
    }
  } 

  const checkSignin=async()=>{
    const issigni = await getStorage("isSignin")
    if(issigni){
      dispatch(setSignin(issigni))
    }
  }
  
  const getDefaultMap=async()=>{  
    if(isSinin){ 
      let setting = await userSetting()  
      setStorage("default", setting) 
      dispatch(setDefaultMap(setting?.defaultMap))
    } 
  }


  useEffect(()=>{    
    checkSignin()
    // window.addEventListener("resize", ()=>setDeviceModel());
    setDeviceModel() 
    dispatch(setWindowWidth(window.innerWidth))
    checkDarkMode() 

    getDefaultMap()
  },[width,prefersDark,window.location,isSinin])

  return (
    // <Firebase>
    <IonApp  > 
       <ReactNotifications />
          <IonReactRouter>  
             <Menu/>    
              {
                ! isSinin ?   
                        <IonRouterOutlet id="main">  
                         <Route
                              path="*"
                              component={ PageNotFound  }
                          />
                              <Route path='/signin' component={Signin} exact={true} /> 
                              <Route path='/home' component={HomeScreen} exact={true} />
                              <Route path='/map' component={Map} exact={true} />
                              <Route path='/settings' component={Settings} exact={true} />
                              <Route path="/dashboard" component={Dashboards} exact={true} />
                              <Route path='/routehistory' component={Passroute} exact={true} />
                              <Route path="/geofences" component={Geozones} exact={true} />
                              <Route path='/manual' component={Manual} exact={true} /> 
                              <Route path='/reports' component={Reports} exact={true} /> 
                              <Route path='/admin' component={Admin} exact={true} /> 
                              <Route path='/notification' component={Notifications} exact={true} />  
                              <Route path='/notificationSetting' component={NotificationSetting} exact={true} />  
                              <Route path='/maintenance' component={ Maintenance} exact={true} /> 
                              <Route path='/suggestRoute' component={TravelRoute} exact={true} /> 
                              <Route path='/suggestRoute/add' component={TravelRouteAdd} exact={true} /> 
                              <Route path={'/reports/repTravelSum'} component={RepTravelSum} exact={true} />
                              <Route path={'/mdvr/playback'} component={PlayBack} exact={true} />
                              <Route path={'/mdvr/realtime'} component={RealtimeMdvr} exact={true} />
                              <Route path={'/longdotest'} component={Longdo} exact={true} />
                              <Route path={'/employees'} component={Employees} exact={true} />
                              <Route path={"/groupdevice"} component={GroupDevice} exact={true}  />
                              <Route path={"/guests"} component={Guests} exact={true}  />
                              <Route path={"/setRoute"} component={SetRoute} exact={true}  /> 
                              <Route path={"/groupmonitor"} component={GroupMonitor} exact={true}  /> 
                              <Route path={"/grouproutehistory"} component={GroupRouteHistory} exact={true}  /> 
                              
                              
                              <Route exact path="/" render={() => <Redirect to="/signin" />} />
                          </IonRouterOutlet>
                    :    
                    <IonRow  >  
                      <IdleTimerComponent />
                      {/* <IonCol  sizeLg={".5"}   sizeXl={".5"}  className='ion-hide-lg-down'  >
                         
                              <CallapseMenu profile={{}}   />
                      </IonCol>  */}
                      <NavBar />   
                      <IonCol style={{paddig:'0px'}}  >
                          <IonRouterOutlet id="main" >  
                            <Route path='/home' component={HomeSplitpane} exact={true} />
                            <Route path='/map' component={Map} exact={true} />
                            <Route path='/settings' component={Settings} exact={true} />
                            <Route path="/dashboard" component={Dashboards} exact={true} />
                            <Route path='/routehistory' component={Passroute} exact={true} />
                            <Route path='/routehistoryMap' component={PassrouteMap} exact={true} />
                            <Route path="/geofences" component={Geozones} exact={true} />
                            <Route path='/manual' component={Manual} exact={true} /> 
                            <Route path='/admin' component={Admin} exact={true} /> 
                            <Route path='/reports' component={Reports} exact={true} />  
                            <Route path='/notification' component={Notifications} exact={true} /> 
                            <Route path='/maintenance' component={ Maintenance} exact={true} /> 
                            <Route path='/suggestRoute' component={TravelRoute} exact={true} /> 
                             <Route path='/suggestRoute/add' component={TravelRouteAdd} exact={true} /> 
                            <Route path={'/drivingPoint'} component={DrivingPoints} exact={true} />
                            <Route path={'/behaviors'} component={Behaviors} exact={true} /> 
                            <Route path={'/longdotest'} component={Longdo} exact={true} />
                            <Route path={'/reports/repTravelSum'} component={RepTravelSum} exact={true} />
                            <Route path='/notificationSetting' component={NotificationSetting} exact={true} /> 
                            <Route path={'/mdvr/playback'} component={PlayBack} exact={true} />
                            <Route path={'/mdvr/realtime'} component={RealtimeMdvr} exact={true} />
                            <Route path={'/mdvr/realtime'} component={RealtimeMdvr} exact={true} />
                            <Route path={'/employees'} component={Employees} exact={true} />
                            <Route path={"/groupdevice"} component={GroupDevice} exact={true}  />
                            <Route path={"/guests"} component={Guests} exact={true}  />
                            <Route path={"/setRoute"} component={SetRoute} exact={true}  /> 
                            <Route path={"/groupmonitor"} component={GroupMonitor} exact={true}  /> 
                            <Route path={"/grouproutehistory"} component={GroupRouteHistory} exact={true}  /> 
                            {/* <Route path={"/splitpane"} component={Splitpane} exact={true}  />  */}
                            
                             
                            <Route exact path="/" render={() => <Redirect to="/home" />} />
                          </IonRouterOutlet>
                        </IonCol>
                    </IonRow>   
                }   
          </IonReactRouter>    
      <Toaster />
    </IonApp>
    // </Firebase>
  );
};

export default App;
 
 