import { IonButton, IonLabel, IonIcon, IonGrid, IonRow, IonCol, IonText } from "@ionic/react";
import { table } from "console";
import { printOutline } from "ionicons/icons";
import moment from "moment";
import { TableEvents } from "../../components/ReportComponent/ReportTable";

const RepFuelUasage=({details,table}:any)=>{
    console.log("details,table" ,details,table)
    const printReport=()=>{

    }
    return(
    <div className="ion-padding" >    
        <IonButton  
            onClick={()=>{printReport()}}
            mode="ios" fill="clear" 
            style={{position:"relative", top:"1rem", right:"1rem"}} 
        >
            <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
            <IonIcon icon={printOutline}  /> 
        </IonButton>
        <IonGrid>
            <IonRow> 
                <IonCol size="12" >
                <IonLabel style={{fontSize:"1.2em",fontWeight:"bold"}} >{details.reportName}</IonLabel><br/>
                <IonLabel style={{fontSize:'.8em'}}>
                    <IonText>วันที่ {moment(details.start).format("DD MMM YYYY HH:mm")}</IonText>
                    <IonText>-</IonText>
                    <IonText>วันที่ {moment(details.end).format("DD MMM YYYY HH:mm")}</IonText>
                </IonLabel>
                </IonCol>
            </IonRow><br/> 
    
            <IonRow>  
                <IonCol size="12" >   
                {/* <tr className="bg-light-shade">
                <th  className="ion-text-center"  style={{width:"3%"}}>#</th> */}

                    <table style={{width:"100%"}} >
                        <tr>
                            {table?.headers.map((e:any , index:any)=>
                               <th 
                                key={index}  style={{width: e?.width }} 
                                className="ion-text-center bg-light-shade"
                               > {e?.label} </th> 
                            )}
                        </tr>
                        {
                            table?.rows.map((e:any,index:any)=>
                             <tr key={index} > 
                                <td  className={`ion-text-center ion-padding-horizontal ${index%2 ==0 ? "bg-light" : "bg-light-tint"}`} style={{padding:"5px"}} >{e?.name}</td>
                                <td className={`ion-text-center ion-padding-horizontal ${index%2 ==0 ? "bg-light" : "bg-light-tint"}`} style={{padding:"5px"}} >{e?.first}</td>
                                <td className={`ion-text-center ion-padding-horizontal ${index%2 ==0 ? "bg-light" : "bg-light-tint"}`} style={{padding:"5px"}} >{e?.last}</td>
                             </tr>
                            )
                        }
                    </table>
                </IonCol>
            </IonRow>
        </IonGrid>
    </div>
    )
}
export default RepFuelUasage;