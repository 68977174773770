import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { setStorage } from "../actions";
import { RootState } from "./store";

const initialState = { 
  notify: null , 
  mdvrVehicles: [{dl:[{cc:4 ,cn: "CH1,CH2,CH3,CH4", id:"4sd000066"  }],nm:'',chnName:"",isOnline:0}],
  mdvrVehiclesBackup:[]
};

export const menuSlice = createSlice({
  name: "menuState",
  initialState,
  reducers: { 
    setNotify  : (state, action) => {
        state.notify = action.payload;
    },
    setMdvrVehicle: (state, action) => {
      state.mdvrVehicles = action.payload;
    }, 
    setMdvrVehicleBackup: (state, action) => {
      state.mdvrVehiclesBackup = action.payload;
    }, 
  },
});

// Action creators are generated for each case reducer function
export const { 
  setNotify, setMdvrVehicle , setMdvrVehicleBackup
} = menuSlice.actions;
 
export const getNotify = (state:RootState) => state.menuState.notify;   
export const getMdvrVehicles = (state:RootState) => state.menuState.mdvrVehicles; 
export const getMdvrVehiclesBackup = (state:RootState) => state.menuState.mdvrVehiclesBackup; 

export default menuSlice.reducer;
