import React, { useEffect, useRef, useState } from 'react';
import {  IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonMenuButton, IonPage, IonRow, IonSegment, IonSegmentButton, IonSpinner, IonText, IonTitle, IonToolbar, useIonLoading } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { getBackup, getLoading, setLoading } from '../store/appSlice'; 
import { AuthCheck } from './Home';  
import { grid, gridOutline,  warning, warningOutline, trailSign, trailSignOutline, alertCircle, alertCircleOutline, refreshOutline, reload, cogSharp, chevronUpCircleOutline, closeCircle} from 'ionicons/icons';
import { CardStaus } from './Dashboard'; 
import * as Moment from "moment"
import { extendMoment } from 'moment-range';     
import { dashChartTemperature, dashDeviceInZone, dashRiskRealtime, dashStatusRealtime, dashStsSummary, dashViolation, dashViolationOverIdle, dashViolationOverSpeed, dashViolations, driveOvertime, violationOverIdleDevice, violationSpeedDevice } from '../actions';
import { t } from 'i18next';   
import { Swiper, SwiperSlide } from 'swiper/react'; 
import 'swiper/css';
import './css/Dashboard.css' 
import ReactApexChart from 'react-apexcharts';
import { Circle, FeatureGroup, MapContainer, Marker, Polyline, Popup, TileLayer } from 'react-leaflet';
import { circle } from '../leaflet/PublicMarker';
import NavBar from '../components/NavBar';

const moment = extendMoment(Moment);
moment.locale("th")

var options = {
  series: [] ,
  chart: {  height: 400,  type: 'area', id:'chart-sum-status' },
  title: {  text: 'สถิติย้อนหลัง 24 ชั่วโมง ทุกช่วงเวลา 20 นาที',  align: 'left' },
  colors:['#97DE75', '#FF0000' ,'#E8DF00', ], 
  stroke: {  show: false,   width: 0, curve: 'smooth'  }, 
  subtitle: { text: 'วันเวลา 26/12/2023 10:20 - 27/12/2023 09:20',  align: 'left'  },
  dataLabels: { enabled: false },
  grid: {
    show: true,   borderColor: '#E7E7E7', strokeDashArray: 0, position: 'back',
    xaxis: { lines: { show: true ,   } },   
    yaxis: { lines: { show: true } }, 
    row: {  colors: ['#FAFAFA', '#FFF', '#FAFAFA']    }, 
  }, 
  xaxis: { type: 'datetime', categories: [] ,
    labels: {  formatter: function (value:any) { return  moment(value).format("HH:mm") } },
  }, 
  tooltip: { x: { format: 'dd/MM/yy HH:mm' }, },
};
 
 
const Dashboard: React.FC = () => {
  const dispatch = useDispatch()  
  const [segment,setSegment] = useState('all') 
  const [refSwiper,setRfSwip] = useState(null)  
  const [summStatus,setSumStatus] = useState<any>(null)
  const [lastfetch,setLastFetch] = useState<any>(0)
  const [deviceInZone , setDeviceInZone] = useState()
  const [stsRealtime , setStsRealtime] = useState({series:[],categories:[]})
  const [violationArea,setViolationArea]= useState<any>({series:[],categories:[]})
  const [riskTemp,setRiskTemp]= useState<any>({series:[],categories:[]})
  const [viOverSpeed,setViOverSpeed] = useState<any>(null)
  const [viOverIdle,setViOverIdle] = useState<any>( null)
  const [dayViolation,setDayViolation] = useState(4)

  const [loadOverSpeed,setLoadOverSpeed] = useState(false)
  const [loadOverIdle,setLoadOverIdle] = useState(false)
  const [loadTemp,setLoadTemp] = useState(false)

  const [riskRealtime,setRiskRealtime] = useState()
  const [overTime,setOverTime] = useState()
  const [loading,setLoading] = useState(false);

  useEffect(()=>{   
    changeStatus(segment)
    updateDashboard()
  },[loading,lastfetch])
 

  const changeStatus=async(val:any)=>{
    setSegment(val)
    if(val === "all"){
      //@ts-ignore
      refSwiper?.slideTo(0)
    }else  if(val === "risk"){
      //@ts-ignore
      refSwiper?.slideTo(1) 
      if(timeupdate()){
        await updateRiskSlide()
      }
     
    }else  if(val === "traveling"){
      //@ts-ignore
      refSwiper?.slideTo(2)
    }else  if(val === "violation"){
      //@ts-ignore
      refSwiper?.slideTo(3)
      if(timeupdate()  ){
       await updateViolation()
      }
    }
  }

  const updateViolation=async()=>{
    setLoading(true)
    let day  = dayViolation;
    let date:any = []
     for(let i =day;  i>=0 ;i--){
      await date.push({
        start: new Date(moment().subtract(i,'day').format()).getTime() ,
        end: new Date(moment().subtract(i-1,'day').format()).getTime()
      })
    }
    console.log("date ",date)

    const violation  = await dashViolations(date)   
    setViolationArea(violation)
    
    setLoadOverSpeed(true)
    const overSpeed  =  await  dashViolationOverSpeed({
      start: new Date(moment().subtract(3,'day').format()).getTime() ,
      end: new Date(moment().format()).getTime() ,
      deviceId: 0 ,
    })   
    setViOverSpeed(overSpeed)
    setLoadOverSpeed(false)

    setLoadOverIdle(true)
    const overIdle = await dashViolationOverIdle({
      start: new Date(moment().subtract(3,'day').format()).getTime() ,
      end: new Date(moment().format()).getTime() ,
      deviceId: 0 ,
    })
    setViOverIdle(overIdle)
    setLoadOverIdle(false)

    setLoading(false) 
  }
 

  const update=async()=>{
    setLoading(true)
    setLastFetch(new Date().getTime())

    const stsSumm = await dashStsSummary(); 
    setSumStatus(stsSumm)  

    const statusRealtime = await dashStatusRealtime({body:""})
    console.log("statusRealtime ",statusRealtime)
    setStsRealtime(statusRealtime)
 
    const deviceInZone = await dashDeviceInZone()  
    setDeviceInZone(deviceInZone)

    if(segment === "violation"){
      updateViolation()
    } 

    setLoading(false)
  }

  const timeupdate=()=>{
    const start = moment(lastfetch);
    const end = moment(new Date());
    const r1 = moment.range(start, end); 
    var diff = r1.diff('minute') ; 
    return diff > 2 ?true:false
  }

  const updateDashboard=async()=>{  
    if(timeupdate()){
      update()
      updateRiskSlide()
      updateViolation()
    } 
  }

  const updateRiskSlide=async()=>{ 
    setLoading(true)
    const riskRealtime = await dashRiskRealtime({
      start: new Date(moment().subtract(1,'day').format()).getTime() ,
      end: new Date(moment().format()).getTime() ,
      deviceId: 0 ,
    })   
    setRiskRealtime(riskRealtime)
    if(timeupdate()){
      const overtime = await driveOvertime({
        "start": new Date(moment().subtract(1,'day').format()).getTime(),
        "end": new Date(moment().format()).getTime(),
        "deviceId": 0
      }) 
      console.log("driveOvertimeresult ", overtime )
      setOverTime(overtime)

      setLoadTemp(true)
      const temperature= await dashChartTemperature({
        "start": new Date(moment().subtract(1,'day').format()).getTime(),
        "end": new Date(moment().format()).getTime(),
        "deviceId": 0
      })  
      setLoadTemp(false)
      if(temperature.categories){ 
        setRiskTemp(temperature) 
      } 
    }
    setLoading(false)
  }

  const setOvedrSpeed=async(date:any)=>{
  setLoadOverSpeed(true)
  console.log("date ",date)
  const overSpeed  = await  dashViolationOverSpeed({
    start: date.start ,
    end: date.end ,
    deviceId: 0 ,
  })  
  console.log("overSpeed ",overSpeed)
  setViOverSpeed(overSpeed)
  setLoadOverSpeed(false) 
  }

  const setOvedrIdle=async(date:any)=>{
    setLoadOverIdle(true)
    const overIdle  = await  dashViolationOverIdle({
      start: date.start ,
      end: date.end ,
      deviceId: 0 ,
    })  
    console.log("overIdle ",overIdle)
    setViOverIdle(overIdle)
    setLoadOverIdle(false)
  }

  const searchViolation=async(day:any)=>{
    setLoadOverIdle(true) 
      let date:any = []
      setDayViolation(day)
      for(let i =day;  i>=0 ;i--){
      await date.push({
        start: new Date(moment().subtract(i,'day').format()).getTime() ,
        end: new Date(moment().subtract(i-1,'day').format()).getTime()
      })
    }  
    const violation  = await dashViolations(date)  
    console.log("violation ",violation)
    setViolationArea(violation) 
    setLoadOverIdle(false)
  }
    
  return (
   <IonPage> 
   {/* <AuthCheck /> */}
   {/* <NavBar /> */}
    <IonContent scrollY={false}  > 
    <div  style={{width:"100%",height:"100vh",backgroundColor:"#EFEFEF",padding:".6rem"}} >
      <div  className='dashboard-ground'  > 
       <IonRow className='ion-padding'> 
          <IonCol size="1.5" className="ion-hide-md-up"  >
            <IonMenuButton />
          </IonCol>
          <IonCol >
            <IonLabel className="dashboard-title" color={'dark'}>Dashboard</IonLabel><br/>
            <IonText> <sub className='dashboard-sub-title' style={{lineHeight:"-1rem"}}>Devices  Overview </sub></IonText>  
          </IonCol> 
        </IonRow>
        <IonRow className='ion-padding'>
          <IonCol sizeSm='12'  sizeMd="2.5" >
            <IonSegment color='light' mode='ios' value={segment} className="status-device" onIonChange={(e)=>{changeStatus(e.detail.value!)}}>
            <IonSegmentButton mode='ios' value="all"  > 
              <IonIcon icon={segment==='all'?grid:gridOutline} />
              <IonLabel>{'ภาพรวม'}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton mode='ios' value="risk">
              <IonIcon icon={segment==='risk'? warning: warningOutline} />
              <IonLabel>{"ความเสี่ยง"}</IonLabel>
            </IonSegmentButton>
            {/* <IonSegmentButton mode='ios' value="traveling">
              <IonIcon icon={segment==='traveling'? trailSign:trailSignOutline} />
              <IonLabel>{"แผนการเดินทาง"}</IonLabel>
            </IonSegmentButton> */}
            <IonSegmentButton mode='ios' value="violation">
              <IonIcon icon={segment ==='violation'? alertCircle:alertCircleOutline} />
              <IonLabel>{"การฝ่าฝืน"}</IonLabel>
            </IonSegmentButton> 
          </IonSegment>
          </IonCol>
          <IonCol   className='ion-text-right'>
            <IonButton fill='clear' size='default' onClick={()=>{ updateDashboard() }} > 
            { loading ? <IonSpinner  name='crescent' /> :<IonIcon icon={refreshOutline} /> }
              &nbsp; <small>{ loading ? 'Updating..' : `Update Now` } </small>
            </IonButton><br/>
            <IonText color={"medium"} ><span style={{fontSize:".6em"}} > Update data at {moment(lastfetch).format("HH:mm DD/MM") }</span></IonText>
          </IonCol>
        </IonRow> 
        <Swiper 
          slidesPerView={1}
          draggable={false}
          allowTouchMove={false}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper:any) =>{setRfSwip(swiper); console.log(swiper)}}
        >
          <SwiperSlide>
            <IonGrid> 
              <IonRow id='row-chart'>
                <IonCol sizeXs='12' sizeSm='12' sizeMd='3' className='ion-padding'  >
                    <SumCurrentEvent  summStatus={summStatus} />
                  </IonCol>
                  <IonCol sizeXs='12' sizeSm='12' sizeMd='8' className={` ${!stsRealtime && 'set-center'} `}   >  
                   {stsRealtime ?  <ChartRelitmeSts stsRealtime={stsRealtime} /> : <IonSpinner name='lines' />  }
                  </IonCol>
                </IonRow> 
                <IonRow  className='ion-justify-content-center'>
                  <IonCol sizeXs='12' sizeSm='12' sizeMd='4' className='ion-padding'  >
                    { summStatus ? <DohnutStatus  summStatus={summStatus} /> : <IonSpinner name='dots' /> }
                  </IonCol> 
                  <IonCol sizeXs='12' sizeSm='12' sizeMd='4' className='ion-padding'  >  
                    { summStatus ?  <RadarStop summStatus={summStatus} stop={summStatus}  all={summStatus} /> : <IonSpinner name='dots' /> }
                  </IonCol> 
                  <IonCol sizeXs='12' sizeSm='12' sizeMd='4' className={`ion-padding ${!deviceInZone && 'set-center'}`} >
                     {deviceInZone ?  <DevicesInZone deviceInZone={deviceInZone} />: <IonSpinner name='dots' /> }
                  </IonCol>  
                </IonRow>
              </IonGrid>
          </SwiperSlide>
          <SwiperSlide>
            <IonGrid>
              <IonRow>
                <IonCol size='12'  className={`ion-no-padding ion-padding-horizontal  ${!riskRealtime && 'set-center'}`}> 
                 {riskRealtime ?  <RiskRealtime riskRealtime={riskRealtime} /> : <IonSpinner name='dots' /> }
                </IonCol>
                <IonCol sizeXs='12' sizeSm='12' sizeMd='4' className={`ion-no-padding ion-padding-horizontal  ${!overTime && 'set-center'}`} > 
                 {overTime ? <ChartOvertime overTime={overTime} /> : <IonSpinner name='dots' /> }
                </IonCol>
                <IonCol sizeXs='12' sizeSm='12' sizeMd='8' >
                  {riskTemp?.series.length > 0 ? <ChartRiskTemperature  temperature={riskTemp} isLoading={loadTemp} /> : <IonSpinner name='dots' /> }
                </IonCol> 
              </IonRow>
              <IonRow>
                 
              </IonRow>
            </IonGrid>
          </SwiperSlide>
          <SwiperSlide>Slide 3</SwiperSlide>
          <SwiperSlide>
            <IonGrid>
              <IonRow>
                <IonCol size='12' >
                   { violationArea && <ChartViolation violation={violationArea} searchViolation={(e:any)=>{return searchViolation(e) }}  />}
                </IonCol>
                <IonCol sizeXs='12' sizeSm='12' sizeMd='6'  className={!viOverSpeed?"set-center":""} >
                  {viOverSpeed ? <ColumnOverSpeed overSpeed={viOverSpeed} setOverSpeed={(e:any)=>{setOvedrSpeed(e)}} isLoading={loadOverSpeed} />: <IonSpinner  name={"crescent"} /> }
                </IonCol>
                <IonCol sizeXs='12' sizeSm='12' sizeMd='6'  className={!viOverIdle?"set-center":""}  >
                  {viOverIdle  ? <ColumnOverIdle overIdle={viOverIdle} setOverIdle={(e:any)=>{setOvedrIdle(e)}} isLoading={loadOverIdle} /> : <IonSpinner  name={"crescent"} /> }
                </IonCol>
                
              </IonRow>
            </IonGrid>
          </SwiperSlide> 
        </Swiper>
 
      </div>
    </div>
    </IonContent> 
    </IonPage>
  );
}
export default Dashboard;


const ChartRiskTemperature=({temperature ,isLoading }:any)=>{
  const [update,setUpdate] = useState(0)
  var tempOptions = {
    series: [] ,
    chart: {  height: 400,  type: 'area', id:'chart-risk-temperature' },
    title: {  text: 'สถิติอุณหภูมิย้อนหลัง '+24+' ชั่วโมง  ',  align: 'left' },
    colors:  ["#ff7c70","#f79359","#f7bd59","#f7f459","#d9fc58","#a1fc68","#6afc68" ], 
    stroke: {  show: false,   width: 0, curve: 'smooth'  }, 
    subtitle: { text: 'วันเวลา 26/12/2023 10:20 - 27/12/2023 09:20',  align: 'left'  },
    dataLabels: { enabled: false },
    grid: {
      show: true,   borderColor: '#E7E7E7', strokeDashArray: 0, position: 'back',
      xaxis: { lines: { show: true ,   } },   
      yaxis: { lines: { show: true } }, 
      row: {  colors: ['#FAFAFA', '#FFF', '#FAFAFA']    }, 
    }, 
    xaxis: { type: 'datetime', categories: [] ,
      labels: {  formatter: function (value:any) {  
        return  moment(value).format("HH:mm  ddd DD") 
      } },
    }, 
    tooltip: { x: { format: 'dd/MM/yy HH:mm' }, },
  }; 

  const updateChartSeries=()=>{  
      tempOptions.xaxis.categories =  temperature?.categories ;
      ApexCharts.exec("chart-risk-temperature" , "updateOptions", tempOptions ); 
   
    return temperature.series
  }

  const drawChart=()=>{ 
    console.log("temperature ",temperature) 
    tempOptions.subtitle.text = `วันเวลา ${moment().subtract(6,'hour').format("DD/MM/YYYY HH:mm")} - ${moment().format("DD/MM/YYYY HH:mm")}`
    tempOptions.series = temperature?.series ;
    tempOptions.xaxis.categories =  temperature?.categories ;  
    tempOptions.chart.id = "chart-risk-temperature"  
    ApexCharts.exec("chart-risk-temperature" , "updateOptions", tempOptions ); 
  }
 
  useEffect(()=>{ 
    drawChart() 
  },[])

  return(<>
   <IonRow>
    <IonCol size='12' >
      {temperature.series.length > 0 && 
      <div className='chart-risk-temperature' >
        {/* @ts-ignore */}
        <ReactApexChart  options={tempOptions} series={ temperature?.series } type='area' height={350} />
      </div>
      }
    </IonCol>
   </IonRow>
  </>)
}

 
const evntsidleover = [{
  "idleTime": 11,
  "address": "Geofence  ออฟฟิตอันดามันภูเก็ต",
  "start": 1704776519000,
  "end": 1704777184000,
  "events": [
      {
          "id": "659cd353e4b0b418a06cb6c8",
          "time_stamp": 1704776531947,
          "eventStamp": 1704776519000,
          "status": 24,
          "latitude": 7.99099,
          "longitude": 98.345048,
          "speed": 0,
          "heading": 176,
          "altitude": 4,
          "deviceId": 49974,
          "address": "Geofence  ออฟฟิตอันดามันภูเก็ต",
          "message": null,
          "webPopup": false,
          "virtualOdometer": 34412.291,
          "ignition": true,
          "statusTime": 182000,
          "inputState": "00000200",
          "userId": null,
          "temperature": null,
          "fuelLiters": 47.8,
          "satellites": 9,
          "serverTime": "2024-01-09T05:02:11.478+00:00",
          "tempLong1": 1704776519,
         "fld_sensorLow": null,
          "fld_driverMessage": null,
          "doorSensor": null,
          "fld_signalStrength": null,
          "fld_cellTowerID": null,
          "fld_mobileCountryCode": null,
          "fld_mobileNetworkCode": null,
          "fld_HDOP": null,
          "fld_engineLoad": null,
          "fld_locationAreaCode": null,
          "fld_sensorHigh": null,
          "magneticAlarm": null,
          "magneticSwipe": null,
          "dodometer": null
      },
      {
          "id": "659cd392e4b0e522f6667eba",
          "time_stamp": 1704776594105,
          "eventStamp": 1704776579000,
          "status": 24,
          "latitude": 7.99099,
          "longitude": 98.345048,
          "speed": 0,
          "heading": 176,
          "altitude": 4,
          "deviceId": 49974,
          "address": "Geofence  ออฟฟิตอันดามันภูเก็ต",
          "message": null,
          "webPopup": false,
          "virtualOdometer": 34412.291,
          "ignition": true,
          "statusTime": 242000,
          "inputState": "00000200",
          "userId": null,
          "temperature": null,
          "fuelLiters": 47.28,
          "satellites": 10,
          "serverTime": "2024-01-09T05:03:14.050+00:00",
          "tempLong1": 1704776579,
         "fld_sensorLow": null,
          "fld_driverMessage": null,
          "doorSensor": null,
          "fld_signalStrength": null,
          "fld_cellTowerID": null,
          "fld_mobileCountryCode": null,
          "fld_mobileNetworkCode": null,
          "fld_HDOP": null,
          "fld_engineLoad": null,
          "fld_locationAreaCode": null,
          "fld_sensorHigh": null,
          "magneticAlarm": null,
          "magneticSwipe": null,
          "dodometer": null
      } 
  ]
}]

const ColumnOverIdle=({overIdle , setOverIdle ,isLoading }:any)=>{
  const [segmentDate,setSegmentDate] = useState(3)
  const backup :any= useSelector(getBackup);
  const [range,setRange] = useState({start:0,end:0})
  const [name,setdevice] = useState("")
  const [deviceOverIdle,setDeviceIdleOver] = useState(evntsidleover);
  const [chartLoad , dismissChart] = useIonLoading();
  const eventRef = useRef<any>()

  var optionsColumn = {
    series:[], 
    chart: { height: 350, type: 'bar', id:"chart-violation-overspeed", padding: ".5rem", borderRadius:"10px" ,
      events:{
        click: function(event:any, chartContext:any, config:any ) { 
            console.log(config.config.series[config.seriesIndex]?.data[config.dataPointIndex])
            console.log(overIdle.categories[config.dataPointIndex] )
            searchDeviceIdleOver(overIdle.categories[config.dataPointIndex]) 
        }
      } 
    },
    plotOptions: { bar: {  width: "80%",borderRadius: 10 , marginBottom: 3}  },
    dataLabels: { enabled: false  }, 
    color: [ "#ADD2F" ], 
    xaxis: {
      labels: {  rotate: -45  ,  formatter: function (value:any) { return value   } },
      categories: [ ],
      tickPlacement: 'on'
    }, 
  } 

  const searchDeviceIdleOver=async(name:any)=>{
    let find = backup.find((e:any)=> e.name?.toLowerCase() === name.toLowerCase() )
    console.log("find ",find?.device_id)
    let today = new Date().getTime()
    let start = new Date(moment().subtract(segmentDate,'day').format()).getTime()
    let newrange = {start:start , end: today }
    setRange(newrange)
    setdevice(find?.name)
     console.log("newrange ",newrange)
    if(find){ 
      chartLoad({
        message: t('loading')
      })
      const result = await violationOverIdleDevice({ 
        ...newrange ,
        "deviceId": find?.device_id 
      })
      setDeviceIdleOver(result)
      eventRef.current.scrollIntoView({ behavior: 'smooth' }); 
      dismissChart()
      console.log("result ",result) 
    }
  }

  const drawChart=()=>{
    let series:any = [{
      data: overIdle.devices ,
      name: "Over Idle"
    }]
    optionsColumn.series = series
    optionsColumn.xaxis.categories =  overIdle.categories 
  }
  
  useEffect(()=>{
    drawChart()
    setDeviceIdleOver([])
  },[])

  const changDate=(day:any)=>{
    setSegmentDate(day)
    let range = {
      start: new Date(moment().subtract(day,'day').format()).getTime(),
      end: new Date(moment().format()).getTime()
    }
    return setOverIdle(range)
  }
  const updateChartSeries=()=>{  
    let series:any = [{
      data: overIdle.devices ,
      name: "Over Idle"
    }]
    optionsColumn.xaxis.categories =  overIdle.categories  
    return series
  }

  return(<>
    <div style={{background:"#FBFBFB"}}  >
  <IonGrid>
    <IonRow  className='ion-justify-content-between'>
          <IonCol sizeXs="12" sizeMd='6' style={{paddingLeft:"1rem"}} >
            <IonLabel style={{fontWeight:"bold" , fontSize:"1em" }} >{"จอดติดเครื่องนานเกิน"}</IonLabel><br/>
            <IonLabel style={{fontSize:'.8em'}} > 
              {`ข้อมูลตั้งแต่วันที่ ${moment().subtract(segmentDate , 'day').format('DD MMM YYYY HH:mm')} - ${moment().format("DD MMM YYYY HH:mm")}`}  
            </IonLabel>
          </IonCol>
          <IonCol size='1' className='set-center' >
            {isLoading && <IonSpinner name={"crescent"} /> }
          </IonCol>
          <IonCol sizeXs="12" sizeMd='5' >
            <IonSegment value={segmentDate} mode='ios'  onIonChange={(e)=>{changDate(e.detail.value)}}>
              <IonSegmentButton value={1}  >{"1 วัน"}</IonSegmentButton>
              <IonSegmentButton value={3}  >{"3 วัน"}</IonSegmentButton>
              <IonSegmentButton value={7}>{"7 วัน"}</IonSegmentButton>
              <IonSegmentButton value={14}>{"14 วัน"}</IonSegmentButton>
            </IonSegment>
          </IonCol>
        </IonRow>
    <IonRow>
    <IonCol size='12' >
      <div className='chart-violation-overidle'  >
        {/* @ts-ignore */}
        <ReactApexChart  options={optionsColumn} series={updateChartSeries()} type='bar' height={350} />
      </div>
    </IonCol>
  </IonRow>
  </IonGrid>
  </div>
  <div ref={eventRef} > 
  {deviceOverIdle.length > 0 &&   <IonGrid  style={{width:"95%", border:"1px solid #DDD" ,margin:"2.5%" ,borderRadius:"10px",}} > 
      <IonRow>
      <IonCol  size='10' >
        <IonLabel>รายการจอดติดเครื่องยนต์เกินเวลา ของ {name} ระหว่างวันที่ {moment(range.start).format("DD/MM/YY")} - {moment(range.end).format("DD/MM/YY")}</IonLabel>
      </IonCol>
      <IonCol  className='set-center' >
          <IonButton fill='clear' size='small' onClick={()=>{ setDeviceIdleOver([])  }} >
            <IonIcon icon={closeCircle} />
          </IonButton>
      </IonCol>
      <IonCol  >
        <IonList style={{height:"35rem" , overflowY:"scroll"}} >  
        {
            deviceOverIdle.map((e:any,index:any)=> 
              <ItemsMapIdleOverTime  key={index} event={e} />
            )
          }
        </IonList>
        </IonCol>
      </IonRow>
  </IonGrid>} 
  </div>
  </> )
}
const ItemsMapIdleOverTime=({event}:any)=>{
  const [openmap,setOpenMap] = useState(false) 
  return(<>
   <IonItem color={"transparent"} lines='none' className='cursor-pointer'  onClick={()=>{ setOpenMap(!openmap) }}
      style={{fontSize:".8em",border:"1px solid #ddd",borderRadius:"10px",marginBottom:".3rem" ,backgroundColor:"#F0F0F0",padding:"0.3rem 0 0.3rem 0" }}>
     <IonRow>
       <IonCol >
          <IonLabel>
            <IonText><small>จอดติดเครื่องยนต์ตั้งแต่ </small></IonText> <br/>
            <IonText style={{fontWeght:"bold" , fontSize:"medium"}} > {moment(event.start).format("HH:mm")} - {moment(event.end).format("HH:mm")} เป็นเวลา {event.idleTime} min </IonText>
          </IonLabel>
       </IonCol>
       {
        !event?.events[0].fuelLiters && event?.events[0].fuelLiters > 0 &&   <IonCol size='6' >
        <IonLabel className='ion-text-nowrap' >
          <IonText>การใช้ำมันระหว่างจอดติดเเครื่องโดยประมาณ ~</IonText>
          <p style={{fontWeight:"bold"}} > {event?.events[0].fuelLiters } - {event.events[event.events.length-1].fuelLiters }</p>
        </IonLabel>
      </IonCol>
       } 
        <IonCol size='12' className='ion-no-padding' >
          <IonLabel>
            <small>{event.address}</small>
          </IonLabel>
       </IonCol>
       </IonRow> 
   </IonItem>

   {  openmap && 
    <MapContainer
    id="map-device-overspeed"
    center={[13.714538, 100.510928]}
    zoom={16}
    scrollWheelZoom={true}
    zoomControl={false}
    style={{borderRadius:"15px" ,height: "20rem",marginBottom:".3rem"}}
    ref={async (comp)=>{  
        comp?.invalidateSize() 
        if(comp){
          // @ts-ignore 
          if(openmap){ 
            const bound =await  event.events.map((e:any)=> {return [e.latitude , e.longitude]})
            comp?.fitBounds(bound) 
          }
        }
        comp?.on("zoomlevelschange",()=>{
            comp?.invalidateSize() 
        }) 
    }} 
    >
      <TileLayer
        attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
        url={`https://api.mapbox.com/styles/v1/${"bthongbut"}/${"clm901e2x014n01r7gkm8gxo7"}/tiles/256/{z}/{x}/{y}@2x?access_token=${"pk.eyJ1IjoiYnRob25nYnV0IiwiYSI6ImNsNGo5b3Q3ZjE1NG8zcXN2aGxrZDRtbnIifQ.r0o9Uw4EkOMCVQXY1De2gw"}`}
      />
      <Marker position={[event.events[0].latitude , event.events[0].longitude]} icon={circle} />
      <Circle 
        center={{lat: event.events[0].latitude, lng:  event.events[0].longitude}}
        fillColor="yellow" 
        stroke={false}
        radius={50}
      />

    </MapContainer> }
  </>)
} 


const deviceOverSpeed=[{
  "address": "( 36.92 m) บริษัท เมืองไทยประกันชีวิต จำกัด  ต.บางกุ้ง อ.เมืองสุราษฎร์ธานี  จ.สุราษฎร์ธานี 84000",
  "start": 1704703428000,
  "end": 1704703428000,
  "maxSpeed": 92,
  "events": [
      {
          "id": "659bb667e4b08ce6d0a2d448",
          "time_stamp": 1704703591218,
          "eventStamp": 1704703428000,
          "status": 7,
          "latitude": 9.1532,
          "longitude": 99.35921,
          "speed": 92,
          "heading": 100,
          "altitude": 0,
          "deviceId": 62715,
          "address": "( 36.92 m) บริษัท เมืองไทยประกันชีวิต จำกัด  ต.บางกุ้ง อ.เมืองสุราษฎร์ธานี  จ.สุราษฎร์ธานี 84000",
          "message": null,
          "webPopup": false,
          "virtualOdometer": 181.19401891490736,
          "ignition": true,
          "statusTime": 167000,
          "inputState": "00000000",
          "userId": null,
          "temperature": null,
          "fuelLiters": null,
          "satellites": 10,
          "serverTime": "2024-01-08T08:46:31.036+00:00",
          "tempLong1": 1704703428,
         "fld_sensorLow": null,
          "fld_driverMessage": null,
          "doorSensor": null,
          "fld_signalStrength": null,
          "fld_cellTowerID": null,
          "fld_mobileCountryCode": null,
          "fld_mobileNetworkCode": null,
          "fld_HDOP": null,
          "fld_engineLoad": null,
          "fld_locationAreaCode": null,
          "fld_sensorHigh": null,
          "magneticAlarm": "1",
          "magneticSwipe": "0",
          "dodometer": null
      }
  ]
}]
const ColumnOverSpeed=({overSpeed , setOverSpeed ,isLoading }:any)=>{
  const [range,setDateRange] = useState({start:0,end:0})
  const [sermentDate,setSegmentDate] = useState(3)
  const backup :any= useSelector(getBackup)
  const [overspeedDevice,setOverSpeedDevice] = useState(deviceOverSpeed)
  const [device,setdevice] = useState("")
  const eventRef = useRef<any>()
  const [chartLoad , dismissChart] = useIonLoading();

  var optionsColumn ={
    series:[], 
    chart: { height: 350, type: 'bar', id:"chart-violation-overspeed", padding: ".5rem", borderRadius:"10px" ,
      events:{
        click: function(event:any, chartContext:any, config:any ) { 
            console.log(config.config.series[config.seriesIndex]?.data[config.dataPointIndex])
            console.log(overSpeed.categories[config.dataPointIndex] )
            searchDeviceSpeed(overSpeed.categories[config.dataPointIndex])
          // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
        }
      } 
    },
    plotOptions: { bar: {  width: "80%",borderRadius: 10 , marginBottom: 3}  },
    dataLabels: { enabled: false  }, 
    color: ["#ADD2F"], 
    xaxis: {
      labels: {  rotate: -45  ,  formatter: function (value:any) { return value   } },
      categories: [ ],
      tickPlacement: 'on'
    }, 
  } 

  const searchDeviceSpeed=async(name:any)=>{
    let find = backup.find((e:any)=> e.name?.toLowerCase() === name.toLowerCase() )
    console.log("find ",find?.device_id)
    let today = new Date().getTime()
    let start = new Date(moment().subtract(sermentDate,'day').format()).getTime()
    let newrange = {start:start , end: today }
    setdevice(find?.name)
     console.log("newrange ",newrange)
    if(find){
      chartLoad({
        message: 'Dismissing after 3 seconds...', 
      })
      const result = await violationSpeedDevice({ 
        ...newrange ,
        "deviceId": find?.device_id 
      })
      console.log("resultSpeedDevic ",result)
      setOverSpeedDevice(result);
      eventRef.current.scrollIntoView({ behavior: 'smooth' });
      dismissChart()
    }
  }

  const drawChart=()=>{
    let series:any = [{
      data: overSpeed.devices ,
      name: "Over Speed"
    }]
    optionsColumn.series = series
    optionsColumn.xaxis.categories =  overSpeed.categories 
  }
  
  useEffect(()=>{
    setOverSpeedDevice([])
    drawChart()
  },[])

  const updateChartSeries=()=>{  
    let series:any = [{
      data: overSpeed.devices ,
      name: "Over Speed"
    }]
    optionsColumn.xaxis.categories =  overSpeed.categories  
    return series
  }

  const changDate=(day:any)=>{
    setSegmentDate(day)
    let today = new Date().getTime()
    let start = new Date(moment().subtract(day,'day').format()).getTime()
    let newrange = {start:start , end: today }
    setDateRange(newrange) 
    ApexCharts.exec("chart-violation-overspeed", "updateOptions", { 
      color: [
        function({ value, seriesIndex, w }:any) {
          if (value < 40) {
            return '#000'
          } else  if (value > 40 && value < 60)  {
            return '#fc7703'
          }else  if ( value > 60)  {
            return '#ff4400'
          }
        }
      ]
    }); 
   return setOverSpeed(newrange)
  }

  return(<> 
    <div style={{background:"#FBFBFB"}}  >
      <IonGrid>
        <IonRow  className='ion-justify-content-between'>
          <IonCol sizeXs="12" sizeMd='6' style={{paddingLeft:"1rem"}} >
            <IonLabel style={{fontWeight:"bold" , fontSize:"1em" }} >{"ความเร็วเกิน"}</IonLabel><br/>
            <IonLabel style={{fontSize:'.8em'}} > 
              {`ข้อมูลตั้งแต่วันที่ ${moment().subtract(sermentDate , 'day').format('DD MMM YYYY HH:mm')} - ${moment().format("DD MMM YYYY HH:mm")}`}  
            </IonLabel>
          </IonCol>
          <IonCol size='1' className='set-center' >
            {isLoading && <IonSpinner name={"crescent"} /> }
          </IonCol>
          <IonCol sizeXs="12" sizeMd='5' className='set-center' style={{alignItems:"flex-start"}} >
            <IonSegment value={sermentDate} mode='ios'  onIonChange={(e)=>{changDate(e.detail.value)}}>
              <IonSegmentButton value={1}  >{"1 วัน"}</IonSegmentButton>
              <IonSegmentButton value={3}  >{"3 วัน"}</IonSegmentButton>
              <IonSegmentButton value={7}>{"7 วัน"}</IonSegmentButton>
              <IonSegmentButton value={14}>{"14 วัน"}</IonSegmentButton>
            </IonSegment>
          </IonCol>
        </IonRow>
        <IonCol size='12' > 
        <div className='chart-violation-overspeed'  >
          {/* @ts-ignore */}
          <ReactApexChart  options={optionsColumn} series={updateChartSeries()} type='bar' height={350} />
        </div>
        </IonCol>
      </IonGrid> 
  </div>
    <div ref={eventRef}>  
       { overspeedDevice.length > 0 &&   <IonGrid  style={{width:"95%", border:"1px solid #DDD" ,margin:"2.5%" ,borderRadius:"10px",}} >
       <IonRow>
          <IonCol size='11' className='set-center' style={{alignItems:"flex-start"}} > 
              <IonLabel> รายการความเร็วเกินของ  {device} {moment(range.start).format("DD/MM/YYYY") } - {moment(range.end).format("DD/MM/YYYY")}</IonLabel>
          </IonCol>
          <IonCol size='1' >
            <IonButton fill='clear' onClick={()=>{setOverSpeedDevice([])}} > <IonIcon icon={closeCircle} /> </IonButton>
          </IonCol>
          <IonCol size='12' >
            <IonList  style={{height:"35rem",overflowY:"scroll"  ,borderRadius:"10px"}} > 
            { overspeedDevice.map((e , index:any)=> 
             <ItemMapDeviceOverSpeed key={index} event={e} /> 
            )}
            </IonList>
          </IonCol> 
       </IonRow>
      </IonGrid>
      }
    </div> 
  </>
  )
}

const ItemMapDeviceOverSpeed=({event}:any)=>{ 
  const [openmap , setOpenMap] = useState(false)
  const [mapref,setMapRef] = useState(null)
 
  return(<>
   <IonItem color={"transparent"} lines='none' className='cursor-pointer'  onClick={()=>{ setOpenMap(!openmap) }}
      style={{fontSize:".8em",border:"1px solid #ddd",borderRadius:"10px",marginBottom:".3rem" ,backgroundColor:"#F0F0F0" }}>
        <IonLabel  className="ion-text-nowrap" > 
        <IonText>  ความเร็วสูงสุด {event.maxSpeed} km/h</IonText> <br/>
        <IonText>  เกินความเร็ว {moment(event.start).format("HH:mm")} - {moment(event.end).format("HH:mm")} / {moment(event.end).format("DD MMM YYYY")} </IonText>
        <IonLabel  style={{fontSize:"smaller",  }}> {event.address} </IonLabel> 
      </IonLabel> 
  </IonItem>
  {  openmap && 
    <MapContainer
    id="map-device-overspeed"
    center={[13.714538, 100.510928]}
    zoom={16}
    scrollWheelZoom={true}
    zoomControl={false}
    style={{borderRadius:"15px" ,height: "20rem",marginBottom:".3rem"}}
    ref={async (comp)=>{  
        comp?.invalidateSize() 
        if(comp){
          // @ts-ignore
          setMapRef(comp)
          if(openmap){ 
            const bound =await  event.events.map((e:any)=> {return [e.latitude , e.longitude]})
            comp?.fitBounds(bound) 
          }
        }
        comp?.on("zoomlevelschange",()=>{
            comp?.invalidateSize() 
        }) 
    }} 
    >
      <TileLayer
        attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
        url={`https://api.mapbox.com/styles/v1/${"bthongbut"}/${"clm901e2x014n01r7gkm8gxo7"}/tiles/256/{z}/{x}/{y}@2x?access_token=${"pk.eyJ1IjoiYnRob25nYnV0IiwiYSI6ImNsNGo5b3Q3ZjE1NG8zcXN2aGxrZDRtbnIifQ.r0o9Uw4EkOMCVQXY1De2gw"}`}
      />

      <Polyline  positions={event?.events.map((e:any, index:any)=> {return [e.latitude , e.longitude ]} )}    />
     {
      event?.events.map((e:any, index:any)=> 
        <FeatureGroup key={index} > 
          <Marker position={[e.latitude , e.longitude ]}  icon={circle} />
          <Popup className='popup-device-overspeed' position={[e.latitude , e.longitude ]} offset={[0,50]} > 
                <IonRow  style={{fontSize:".8em"}} >
                  <IonCol size='2' className='set-center' >
                    <IonIcon icon={chevronUpCircleOutline} style={{ transform:`rotate(${e.heading}deg)` , fontSize:"1.7em"}} />
                  </IonCol>
                  <IonCol size='12' >  Address:  {e?.address}  </IonCol>
                  <IonCol size='12' >  Odometer: {e?.virtualOdometer.toFixed(2)} </IonCol>
                </IonRow> 
            </Popup>
        </FeatureGroup>
      )
     }
    </MapContainer> }
  </>)
}

const ChartViolation=({violation , searchViolation}:any)=>{
  const [day,setDay] = useState(4)
  const changDate=(val:any)=>{
    setDay(val) 
    return searchViolation(val)
  }

  var violationOptions = {
    series: [] ,
    chart: {  height: 400,  type: 'area', id:'chart-violation-line' },
    title: {  text: 'สถิติย้อนหลัง '+day+' วัน  ',  align: 'left' },
    colors:  ["#ff7c70","#f79359","#f7bd59","#f7f459","#d9fc58","#a1fc68","#6afc68" ], 
    stroke: {  show: false,   width: 0, curve: 'smooth'  }, 
    subtitle: { text: 'วันเวลา 26/12/2023 10:20 - 27/12/2023 09:20',  align: 'left'  },
    dataLabels: { enabled: false },
    grid: {
      show: true,   borderColor: '#E7E7E7', strokeDashArray: 0, position: 'back',
      xaxis: { lines: { show: true ,   } },   
      yaxis: { lines: { show: true } }, 
      row: {  colors: ['#FAFAFA', '#FFF', '#FAFAFA']    }, 
    }, 
    xaxis: { type: 'datetime', categories: [] ,
      labels: {  formatter: function (value:any) { return  moment(value).format("HH:mm  ddd DD") } },
    }, 
    tooltip: { x: { format: 'dd/MM/yy HH:mm' }, },
  };
  const drawChart=()=>{ 
    console.log("violation ",violation) 
    violationOptions.subtitle.text = `วันเวลา ${moment().subtract(1,'day').format("DD/MM/YYYY HH:mm")} - ${moment().format("DD/MM/YYYY HH:mm")}`
    violationOptions.series = violation?.series ;
    violationOptions.xaxis.categories =  violation?.categories ;  
    violationOptions.chart.id = "chart-violation-line"  
  }

  const updateChartSeries=()=>{  
    if(violation?.categories){ 
      ApexCharts.exec("chart-violation-line", "updateOptions",{xaxis:{categories:violation?.categories }}); 
    }
    return violation?.series
  }

  useEffect(()=>{
    drawChart()
  },[violation])
  return(
    <> 
    <IonGrid>
      <IonRow  className='ion-justify-content-between' >
        <IonCol  size='6' >
           <IonTitle color={"medium"} className='ion-no-padding' style={{fontWeight:'bold', marginLeft:".8rem"}}>   การฝ่าฝืน  </IonTitle>
        </IonCol>
        <IonCol  sizeSm='6' sizeMd='3' sizeLg='2.5' >
            <IonSegment value={day} mode='ios' onIonChange={(e:any)=>{changDate(e.detail.value)}}  >
              <IonSegmentButton value={1} >{"1 วัน"}</IonSegmentButton>
              <IonSegmentButton value={2} >{"2 วัน"}</IonSegmentButton> 
              <IonSegmentButton value={3} >{"3 วัน"}</IonSegmentButton> 
              <IonSegmentButton value={4} >{"4 วัน"}</IonSegmentButton>
            </IonSegment>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='12' >
          <div className='chart-violation-line' >
            {/* @ts-ignore */}
              <ReactApexChart  options={violationOptions} series={updateChartSeries()} type='area' height={350} />
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
     
    </>
  )
}

const ChartOvertime=({overTime}:any)=>{ 
  var optionsTimeline = {
  series:[  { data: [ ]  } ],
  chart: { height: 350, type: 'rangeBar' ,  background: '#FDFDFD' },
  title: { text: 'ขับเกินเวลา  สถิติย้อนหลัง 24 ชั่วโมง ทุกช่วงเวลา 20 นาที',  align: 'left' },
  subtitle: {  text: '',  },
  plotOptions: {  bar: { horizontal: true  } },
  tooltip: { x: {  format: 'hh:mm '  } }, 
  xaxis: { type: 'datetime' ,   },
  grid: {
    show: true, 
    borderColor: '#E7E7E7',
    strokeDashArray: 0,
    position: 'back',   
    xaxis: {
      lines: { show: true }
    },  
  }, 
  color:["#ff7c70","#f79359","#f7bd59","#f7f459","#d9fc58","#a1fc68","#6afc68","#68fcb0","#64fad9","#64e1fa","#64b9fa"]
  };

  useEffect(()=>{
    const drawChart=async()=>{ 
      console.log("overTime ",overTime)  
    }
    drawChart()
  },[])
  const updateChartSeries=()=>{ 
    return overTime?.series
  }

  return( 
   <IonCard mode='ios'  > 
      <IonCardContent className='ion-no-padding'>
        <IonHeader style={{marginBottom:".5rem"}}>
          <IonToolbar mode='ios' >
            <IonLabel color={"medium"} style={{fontSize:".9em"}}  >ขับเกิน 4 ชั่วโมง </IonLabel>
          </IonToolbar>
        </IonHeader>
      <div id="timeline-chart-overtime" >
        {/* @ts-ignore */}
        <ReactApexChart  options={optionsTimeline} series={updateChartSeries()} type='rangeBar' height={250} />
      </div>
    </IonCardContent>
   </IonCard>
  )
}

const RiskRealtime=({riskRealtime}:any)=>{
  const chartRef = useRef<ApexCharts>();

  let  risksOption = {
    series: [] ,
    chart: {  height: 400,  type: 'area', id:'chart-sum-status' },
    title: {  text: 'สถิติย้อนหลัง 24 ชั่วโมง ทุกช่วงเวลา 20 นาที',  align: 'left' },
    colors:['#97DE75', '#FF0000' ,'#E8DF00', ], 
    stroke: {  show: false,   width: 0, curve: 'smooth'  }, 
    subtitle: { text: 'วันเวลา 26/12/2023 10:20 - 27/12/2023 09:20',  align: 'left'  },
    dataLabels: { enabled: false },
    grid: {
      show: true,   borderColor: '#E7E7E7', strokeDashArray: 0, position: 'back',
      xaxis: { lines: { show: true ,   } },   
      yaxis: { lines: { show: true } }, 
      row: {  colors: ['#FAFAFA', '#FFF', '#FAFAFA']    }, 
    }, 
    xaxis: { type: 'datetime', categories: [] ,
      labels: {  formatter: function (value:any) { return  moment(value).format("HH:mm") } },
    }, 
    tooltip: { x: { format: 'dd/MM/yy HH:mm' }, },
  };

  useEffect(()=>{
   const drawChart=async()=>{ 
    let el= document.getElementById("chart-risk-realtime")! 
    risksOption.subtitle.text = `วันเวลา ${moment().subtract(1,'day').format("DD/MM/YYYY HH:mm")} - ${moment().format("DD/MM/YYYY HH:mm")}`
    risksOption.series = riskRealtime?.series ;
    risksOption.chart.type = "area"
    risksOption.title.text = "ความเสี่ยงทั้งหมด สถิติย้อนหลัง 24 ชั่วโมง ทุกช่วงเวลา 15 นาที" 
    risksOption.xaxis.categories =  riskRealtime?.categories ; 
    risksOption.colors =["#eb6e34","#f74e00","#ffb700","#ffea00","#ff0593"] 

    ApexCharts.exec("chart-risk-realtime", "updateOptions",risksOption); 
   } 
   drawChart()
  },[])

  const chartSeries=()=>{ 
    risksOption.subtitle.text = `วันเวลา ${moment().subtract(1,'day').format("DD/MM/YYYY HH:mm")} - ${moment().format("DD/MM/YYYY HH:mm")}`
    risksOption.series = riskRealtime?.series ; 
    risksOption.title.text = "ความเสี่ยงทั้งหมด สถิติย้อนหลัง 24 ชั่วโมง ทุกช่วงเวลา 15 นาที" 
    risksOption.xaxis.categories =  riskRealtime?.categories ; 
    risksOption.colors =["#eb6e34","#f74e00","#ffb700","#ffea00","#ff0593"]
    risksOption.chart.id =  "chart-risk-realtime"
    return riskRealtime?.series 
  }

  return(<>
    <IonTitle className='ion-no-padding' style={{fontWeight:'bold', marginLeft:".8rem"}}> ความเสี่ยงทั้งหมด </IonTitle>
    <div id="chart-risk-realtime" > 
    {/* @ts-ignore */}
      <ReactApexChart type='line' ref={chartRef} options={risksOption} series={chartSeries()}  width={"100%"} height={350}/>
    </div>
  </>)
}

const DevicesInZone=({deviceInZone}:any)=>{
  var optionDeviceInZ= {
    series: [],
    chart: {
      height: 230,   type: 'polarArea' ,  legend: {  show: false,  }, 
    }, 
    xaxis:{ show:false , 
      style: {fontSize: '10px' },
      labels:{show:false }
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: { fontSize: '22px', },
          value: { fontSize: '16px',  },
          total: { show: false, }
        }
      } 
    },
    yaxis:{ 
      formatter: function (val:any) {
      return Number(val.toFixed(0))  
      }
    },
    labels: [ ]
  }

  useEffect(()=>{
    const getdevicesInzone=async()=>{ 
      let labels:any = []
      let data:any = []
      await deviceInZone?.categories.map((e:string)=>{ labels.push(e)  })
      await deviceInZone?.devices.map((e:string)=>{ data.push(e)  })
      let el = document.getElementById("radial-device-inzone")
      optionDeviceInZ.series = data //series
      optionDeviceInZ.labels = labels
      
      setTimeout(()=>{ 
       if(!el?.innerHTML  ){
        var chart = new ApexCharts(document.querySelector("#radial-device-inzone"), optionDeviceInZ );
        chart.render();
       } 
      },300)
    }
    getdevicesInzone()
  },[])
  return(
    <IonCard mode='ios'  > 
      <IonCardContent className='ion-no-padding'>
        <IonHeader>
          <IonToolbar mode='ios' >
            <IonLabel color={"medium"} style={{fontSize:".9em"}}  > Device In Zone</IonLabel>
          </IonToolbar>
        </IonHeader> 
        <div id="radial-device-inzone" ></div>
      </IonCardContent>
    </IonCard> 
  )
}

const RadarStop=({summStatus, stop,all}:any)=>{
  const [charts,setCharts] = useState<any>(null)

  var radarOpt={ 
    series: [0],
    legend: {  show: false,  },
    chart: {  height: 270,  type: 'radialBar' ,  legend: {  show: false,  },id:"radar-chart-stop" },
    colors:['#ff8080' ], 
    plotOptions: {  
        radialBar: {  
          hollow: {  margin: 5, size: '50%',  },
          dataLabels: {
            name: { show:false  },
            value: { 
              fontSize: '35px', fontWeight:"bold",  color: '#ff8080',
              formatter: function (val:any) {  return val + "%"; }
            },
          }, 
          track: {  show: true,  strokeWidth: '97%',  }
        }
      } 
  }
  
  const drawChart=()=>{  
    const el = document.getElementById("radar-stop")  
    let stopPercen = ((summStatus?.stop/summStatus?.all) *100).toFixed(0)
    let percen = Number(stopPercen)
    radarOpt.series = [percen] 
  }
  useEffect(()=>{   
    drawChart()
  },[charts ])

  const chrtSeries=()=>{
    let stopPercen = ((summStatus?.stop/summStatus?.all) *100).toFixed(0)
    let percen = Number(stopPercen)
    return [percen]
  }

  return(
    <IonCard mode='ios'  > 
      <IonCardContent className='ion-no-padding'>
        <IonHeader>
          <IonToolbar mode='ios' >
            <IonLabel color={"medium"} style={{fontSize:".9em"}}  >จำนวนรถจอดปัจจุบัน</IonLabel>
          
          </IonToolbar>
        </IonHeader> 
        <div id='radar-chart-stop'>  
        {/* @ts-ignore */}
        <ReactApexChart options={radarOpt} series={chrtSeries()} height={250} type="radialBar" />
 
        </div>   
      </IonCardContent>
    </IonCard> 
  ) 
}

const DohnutStatus=({summStatus}:any)=>{ 
  var optionsDonutStatus ={
    series : [0,0,0,0],
    chart: {  id: "donut-status", height: 270, type:"donut" },
    xaxis: { categories: [ ],  },   
    stroke: {  dashArray: 4 },
    colors: ['#97DE75', '#ff8080' ,'#E8DF00','#c7c7c7' ],  //[ '#ffce00','#10dc60', '#f04141','#666666'],
    labels:['เคลื่อนที่','หยุดนิ่ง','จอดติดเครื่องยนต์','ขาดการติดต่อ'],
    legend: {  show: false,  },
    plotOptions:{ 
      dataLabels: { show: false }, 
      pie: {
        expandOnClick:false, 
        donut: {
          size:'40%',
        }
      }},
    dataLabels: {
      enabled: true,
    }
  };
  const drawChart=async()=>{ 
    var series =[summStatus?.move,summStatus?.stop,summStatus?.idle,summStatus?.offline] 
    optionsDonutStatus.series = series 
   
  }

  useEffect(()=>{   
    if(summStatus){  drawChart() }
  },[summStatus ])

  const chrtSeries=()=>{
    optionsDonutStatus.colors= ['#97DE75', '#ff8080' ,'#E8DF00','#c7c7c7' ]
    var series =[summStatus?.move,summStatus?.stop,summStatus?.idle,summStatus?.offline] 
    return series 
  }

  return( 
    <IonCard mode='ios'  > 
      <IonCardContent className='ion-no-padding'>
        <IonHeader>
          <IonToolbar mode='ios' >
            <IonLabel color={"medium"} style={{fontSize:".9em"}}  >สถานะรถปัจจุบัน</IonLabel>
          </IonToolbar>
        </IonHeader> 
        <div id='donut-status'>  
        {/* @ts-ignore */}
         <ReactApexChart options={optionsDonutStatus} series={chrtSeries()} height={250} type="donut" />
        </div>   
      </IonCardContent>
    </IonCard>
 )
}

const ChartRelitmeSts=({stsRealtime}:any)=>{
  const [loadingChart ,setLoadingChart] = useState(false);
  const [lastfetch,setLastFetch] = useState<any>(0)
  const chartref = useRef();
  const dispatch = useDispatch();
  var areaOption =  {
    series: [] ,
    chart: {  height: 400,  type: 'area', id:'chart-sum-status' },
    title: {  text: 'สถิติย้อนหลัง 24 ชั่วโมง ทุกช่วงเวลา 1 นาที',  align: 'left' },
    colors:  ['#97DE75', '#FF0000' ,'#E8DF00', ], 
    stroke: {  show: false,   width: 0, curve: 'smooth'  }, 
    subtitle: { text: `วันเวลา ${moment().subtract(1,'day').format("DD/MM/YYYY HH:mm")} - ${moment().format("DD/MM/YYYY HH:mm")}`,  align: 'left'  },
    dataLabels: { enabled: false },
    grid: {
      show: true,   borderColor: '#E7E7E7', strokeDashArray: 0, position: 'back',
      xaxis: { lines: { show: true ,   } },   
      yaxis: { lines: { show: true } }, 
      row: {  colors: ['#FAFAFA', '#FFF', '#FAFAFA']    }, 
    }, 
    xaxis: { type: 'datetime', categories: [] ,
      labels: {  formatter: function (value:any) { return  moment(value).format("HH:mm") } },
    }, 
    tooltip: { x: { format: 'dd/MM/yy HH:mm' }, },
  };

  useEffect(()=>{  
    console.log("stsRealtime ",stsRealtime)
    dispatch(setLoading(false)) 
    drawChart()
  },[stsRealtime])

  const drawChart=async()=>{
    const start = moment(lastfetch);
    const end = moment(new Date());
    const r1 = moment.range(start, end);  
    if(r1.diff('minute') > 5){
      setLoadingChart(true)
      // const statusRealtime = await dashStatusRealtime({body:""})
      setLastFetch(new Date().getTime()) 
      setLoadingChart(false)
      console.log("stsRealtime ",stsRealtime)

      let elRow= document.getElementById("chart-sum-status")!  
      areaOption.series = stsRealtime?.series ;
      areaOption.xaxis.categories =  stsRealtime?.categories;  
      areaOption.chart.type = "area" 
      ApexCharts.exec("chart-sum-status", "updateOptions",areaOption); 
    }
  }

  const updateSeries=()=>{ 
    areaOption.xaxis.categories =  stsRealtime?.categories;   
    return stsRealtime?.series
  }

  return(<> 
    <IonTitle color={"medium"} className='ion-no-padding' style={{fontWeight:'bold', marginLeft:".8rem"}}> สถานะรถเรียลไทม์ </IonTitle>
    <div id="chart-sum-status" >
       {/* @ts-ignore */}
       <ReactApexChart options={areaOption}  series={updateSeries()} height={360} width={"100%"} type="area" />
    </div>
    </>
  )
}
 
const SumCurrentEvent=({summStatus}:any)=>{
  const [eventSum,setEventSum] = useState<any>()
  const [lastfetch,setLastFetch] = useState<any>(0)
  const getSumStatus=async()=>{
    setEventSum(summStatus)  
  }

  useEffect(()=>{
    getSumStatus()
  },[summStatus])

  return(
    <div  className='sum-current-event'   >
      <IonGrid>  
        <IonRow>
          <IonCol size='6'  className='set-center'  style={{ justifyContent: 'flex-end' }} >
              <CardStaus detail={{status:7 ,number:  eventSum?.move }} />
          </IonCol>
          <IonCol size='6'  className='set-center'  style={{ justifyContent: 'flex-end' }} >
              <CardStaus detail={{status:23 ,number: eventSum?.idle}} />
          </IonCol>
          <IonCol size='6'  >
              <CardStaus detail={{status:24 ,number: eventSum?.stop }} />
          </IonCol>
          <IonCol size='6'  className='set-center'  style={{ justifyContent: 'flex-end' }} >
              <CardStaus detail={{status:0 ,number:  eventSum?.offline }} />
          </IonCol>
        </IonRow><br/>
        <IonRow>
          <IonCol size='8' >
             <IonLabel>{"สรุปเหตุการณ์ปัจจุบัน"}</IonLabel>
          </IonCol>
          <IonCol size='4' className='ion-text-right' >
            <IonLabel style={{fontSize:".7em"}} color={"medium"} >{`ทั้งหมด ${eventSum?.all ? eventSum?.all :0 } `}</IonLabel>
          </IonCol>
        </IonRow> 
        <IonRow>
          <IonCol size='12' >
            <IonList  color={"transparent"} >  

              <IonItem style={{fontSize:".8em"}}  lines='none'>
                <IonLabel >  {"ความเร็วเกิน"} </IonLabel>
                <IonLabel slot='end' >
                {eventSum?.overMaxSpeed}คัน
                </IonLabel>
              </IonItem>

              <IonItem style={{fontSize:".8em"}}   lines='none'>
                <IonLabel >  {"ไม่รูดใบขับขี่"} </IonLabel>
                <IonLabel slot='end' >
                  {eventSum?.noSwipeDriveLicense} คัน
                </IonLabel>
              </IonItem>

              <IonItem style={{fontSize:".8em"}}   lines='none'>
                <IonLabel >  {"จอดติดเครื่องนานเกิน"} </IonLabel>
                <IonLabel slot='end' >
                  {eventSum?.overIdletime} คัน
                </IonLabel>
              </IonItem>
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  )
}