import L from "leaflet"
import { useEffect, useState } from "react"
import { FeatureGroup, useMap } from "react-leaflet"
import { EditControl } from "react-leaflet-draw"
import "leaflet-draw/dist/leaflet.draw.css"
import { useDispatch } from "react-redux"
import { setCircle, setPolygon } from "../store/mapSlice"
import { drawCircle } from "../actions"

  const DrawControl=({saveModal}:any)=>{
    const map = useMap() 
    const dispatch = useDispatch()
 
    useEffect(()=>{
        console.log("DrawControl saveModal ",saveModal)
    },[])

    const createDraw=async (draw:any)=>{ 
      console.log("createDraw ", draw)
      if(draw?.layerType === "polygon"){
        const latlngs = await draw?.layer?.editing?.latlngs 
        const bound = latlngs[0][0].map((l:any)=> { return [l.lat, l.lng ] } )
       
        dispatch( setPolygon(bound)) 
      }else if(draw?.layerType === "circle"){ 
        const position = draw?.layer._latlng
        let positons = await drawCircle(position.lat, position.lng ,draw?.layer?._radius)
        dispatch( setPolygon(positons)) 
      }else if(draw?.layerType === "rectangle"){ 
        const northEast =    draw?.layer?._bounds?._northEast
        const southWest =    draw?.layer?._bounds?._southWest
        const bound = [[northEast?.lat ,northEast?.lng],[southWest?.lat,southWest?.lng]]
        dispatch( setPolygon(bound)) 
      }

      return saveModal(true)
    }

    return(
        <FeatureGroup>
         <EditControl  
            position="topleft"
            onCreated={(e)=>{ createDraw(e) }}
            onEdited={(e)=>{console.log("onedit ",e)}}
            onDeleted={(e)=>{console.log("onDelete ",e)}}
            draw={{
                polyline: false,
                rectangle: false ,
                polygon: true , 
                circle: true ,
                circlemarker: false,
                marker: false
            }}
          />
        </FeatureGroup>
    )
}

export default DrawControl