import { IonAvatar, IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonLoading, IonMenuButton, IonPage, IonRadio, IonRadioGroup, IonRange, IonRoute, IonRow, IonSpinner, IonText, IonToolbar, useIonAlert, useIonLoading, useIonToast } from "@ionic/react"
import { DropDownDevice } from "../components/AppComponent"
import { useEffect, useState } from "react" 
import { DateStartReport, DateEndReport, DateRangPopover } from "../components/ReportComponent/ReportComponent"
import moment from "moment"
import { addGeometry, allDevices, drawCircle, getLongdokey, getStorage, passRoute, toastFail, toastSuccess } from "../actions"
import sha1 from "sha1" 
import { Swiper, SwiperSlide } from "swiper/react"
import { alertCircle, closeCircleOutline, list, listOutline, pause, play, stop } from "ionicons/icons"  
import { t } from "i18next"  
import { useDispatch, useSelector } from "react-redux"
import { getBackup, setBackupDevices, setDefaultMap, setDevices } from "../store/appSlice"
import { DivIcon, Icon, map, Map as MapType , Marker as MarkerType, point} from "leaflet"
import { getCircle, getPassRoute, getPassRouteIndex, getPassRouteInterval, getPassrouteList, getPlayAction,  getPolygon,  setCircle,  setPassRoute, setPassRouteIndex, setPassRouteInterval, setPassrouteList, setPlayAction, setPolygon, setRouteDraw, setRouteSet  } from "../store/mapSlice"
import L from "leaflet"  
import { CardRoute, SlidBack, SideOverView, MapPassroute,   speeds } from "./PassrouteMap"
import { useHistory } from "react-router" 
import { Device as AppDevice } from "@capacitor/device"
import "./css/Passroute.css"
import { Device, Options, Route, Velocity } from "./schema"
import { ModalGeozoneNotify } from "./Geozones"
import NavBar from "../components/NavBar"
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css'; 
import ReactApexChart from "react-apexcharts"; 
import axios from "axios"
 
const colors= ["#B314CD", "#EE15F3" , "#134985" , "#1560F3","#31E214","#EDF209","#F2B109" , "#E98611" ,"#F31515"]

let markerroute:any  = null ;
let markerstatus:any  = null ;
let routeplyline:any  = null ;

const Passroute=()=>{
    const [refSwiper,setRfSwip] = useState(null)
    const [vehicle,setVehicle] = useState({device_id:0, value:0 , label:""})
    const [dateend,setDateEnd] = useState(moment().format("YYYY-MM-DDT23:59").toString()) 
    const [datestart,setDateStart] = useState(moment().format("YYYY-MM-DDT00:00").toString()) 
    const [openDate , setOpenDate ] = useState(false)
    // const [lists,setLists] = useState<Route[]>([])
    const lists:Route[] = useSelector(getPassrouteList)
    const [removing,setRemoving] = useState(false)
    const [playingid,setPlayingid] = useState<any>("")
    const [route,setRoute] = useState<Route|null>(null)
    const [speed,setSpeed] = useState({name:"4x",val:200}) 
    const [index,setIndex] = useState(0)
    const [device,setDevice] = useState<Device>()
    const backup :any= useSelector(getBackup)
    const [loading , setLoading] = useState(false)
    const [loadingSpinner , setLoadingSpinner] = useState(false)
    const [trackMarker,setTrackMarker] = useState(false) 
    const passroute:Route | any= useSelector(getPassRoute)  
    const [speedChoice,setSpeedChoice] = useState(false)
    const [color,setColor] = useState("#134985")
    const passRouteIndex :any= useSelector(getPassRouteIndex) 
    const [ionalert,dimiss] = useIonAlert();
    const playAction :any= useSelector(getPlayAction)
    const passrouteinterval :any= useSelector(getPassRouteInterval)
    const history = useHistory() 

    const [detail, showDetail] = useState(false)
    
    const [modalSave,setModalSave] = useState(false) 
    const [modalType,setModalType] = useState("add")
    const [addZone,setAddZone] = useState(false)
    const [name , setName] = useState("")
    const [radius,setRadius] = useState(0.5) 
    const polygon :any= useSelector(getPolygon)
    const circle :any= useSelector(getCircle)   
    const [currentIcon , setCurrentIcon] = useState(0) 
    const [optChecked , setOptChecked ] = useState<Number[]>([])
    const geoType:any = "circle"
    const [ toast , dimisstoast] = useIonToast();
    const [checkall , setCheckall] = useState<boolean | undefined>(false)
    const [options,setOptions] = useState<Options[]>([]) 
    const [markersIcon , setMarkersIcon] = useState([])
    const [sizes, setSizes] = useState([300,  'auto']); 
    const [hsizes, setHSizes] = useState([ "100%", 'auto' ]); 
    const [velocity , setVelocity] = useState<Velocity | null>(null)
    const [checkin,setCheckin] = useState({   
        label:"เข้า" ,
        checked: true ,
        format:"{0} เข้า  {1} เวลา {3}  {4}   http://maps.google.com/maps?f=q&q={5}&z=16"
    })
    const [checkout,setCheckout] = useState({   
        label:"ออก" ,
        checked: true ,
        format:"{0} ออก {1} เวลา {3}  {4}   http://maps.google.com/maps?f=q&q={5}&z=16"
    })
 
    let mapref:MapType|any = null
    let playindex = 0
    let playininterval:any =  0;
    let routedraw: any[] = [] 
    let playinterval: NodeJS.Timeout | any = null
    let eventlocate:any= [0,0]
    let lastHeading = 0
    
    const dispatch = useDispatch()

    const changeRangeKnob=(val:any)=>{
        console.log("changeRangeKnob ", val)
        // setKnob(val)
        playindex = val;
        dispatch(setPassRouteIndex(val)) 
        if(mapref){
            eventlocate =  passroute?.route[val] 
            let listindex  = passroute?.list[val]
            let icon = passroute?.deviceIcon
        //    drawMarkerL(icon,listindex ,eventlocate)
            markerroute?.setRotationAngle(Number(listindex.heading))
            markerstatus?.setRotationAngle(Number(listindex.heading))
            // mapref.fitBounds(route?.route)
        }
        
    }

    const searhDevice=async()=>{
        console.log("searhDevice ",vehicle)
        setLoading(true)
        const deviceId = vehicle.device_id 
        let start =  new Date(moment(datestart).zone(0).format()).getTime()
        let end = new Date(moment(dateend).zone(0).format()).getTime() 
        let devic = backup.find((e:any)=> e.device_id === deviceId )
        if(devic) { setDevice(devic) }
 
        let routelist = await passRoute({
            deviceId: deviceId,
            start: new Date(datestart).getTime() ,
            end: new Date(dateend).getTime() ,
        })
        console.log("routelist ",routelist)
        if(routelist.list!== null){
            let bound:any = [] = await routelist?.list?.map(  (el:any) =>{
                return [el.latitude ,el.longitude]
            })
            let routeid = await sha1(`start:${start},end:${end},id:${deviceId},create:${moment().format()}`)
            if(routelist.list.length >0){
                //let listRoute:any = routelist.list
                let newRoute:Route[] = [...lists,{
                    ...routelist ,
                    _id : routeid ,
                    name:  vehicle?.label ,
                    route: bound, 
                    color: "",
                    show: true ,
                    distance_all: (Number(routelist.distance_all)/1000).toFixed(2),
                    deviceIcon: devic?.deviceIcon ? devic?.deviceIcon : 1 ,  
                    typeId:  1 , 
                    device: devic
                }]
                // setLists(newRoute)
                if(mapref && bound){ mapref?.fitBounds(bound) }
                dispatch(setPassrouteList(newRoute))
            }else{
                ionalert({
                    mode:"ios",
                    header : "ไม่พบเส้นทาง ! ",
                    message: `ไม่พบข้อมูลเส้นทางของ ${devic?.name} ในช่วงเวลาวันที่ ${ moment(start).format("DD/MM/YY HH:mm:ss")} - ${moment(end).format("DD/MM/YY HH:mm:ss") } `,
                    buttons: ['ตกลง']  
                }) 
            } 
            setLoading(false)
        }else{ 
            ionalert({
                mode:"ios",
                header : "ไม่พบเส้นทาง ! ",
                message: `ไม่พบข้อมูลเส้นทางของ ${devic?.name} ในช่วงเวลาวันที่ ${ moment(start).format("DD/MM/YY HH:mm:ss")} - ${moment(end).format("DD/MM/YY HH:mm:ss") } `,
                buttons: ['ตกลง']  
            })
            setLoading(false)
        } 
    }

    const removeRoute=(id:any)=>{ 
        setPlayingid("")
        setRoute(null)
        setRemoving(true) 

        setTimeout(()=>{
            let listRoute:any = lists
            listRoute = listRoute.filter((e:any)=> e._id !== id)
            // setLists(listRoute)
            dispatch(setPassrouteList(listRoute))
            setRemoving(false)
        },speed.val)
    }
    

    useEffect(()=>{  
        const getdevices=async()=>{ 
            if(options.length === 0){
                let result = await allDevices({})  
                await dispatch(setDevices(result))
                await dispatch(setBackupDevices(result)) 
                let opts = result.map((e:any)=> {return {value: e.device_id ,label: e.name, ...e } })
                setOptions(opts)   
                let optionscheck:any[] = result.map((e:any)=> {return {checked:false, value: e.device_id ,label: e.name, ...e } })
                optionscheck.unshift({checked:false, device_id:0 ,value: 0,label:"ทั้งหมด"}) 
            }
        } 
        getdevices()  
  
    },[vehicle,passroute,passRouteIndex,options])

    useEffect(()=>{
      return(()=>{
            dispatch(setPassRoute(null))
            mapref?.eachLayer(function(layer:any){ 
                if(layer?.editing){
                    mapref?.removeLayer(layer)
                }
            });
       })
    },[])

    const selectRoute=async(route:Route,index:any)=>{ 
        console.log("route ",route)
        setLoading(true);
        let info = await AppDevice.getInfo() 
        if(info?.operatingSystem !== "mac" && info?.operatingSystem != "windows" && info?.operatingSystem !== "unknown"){
            history.push("/routehistoryMap",{
                device: device ,
                openDate: openDate ,
                lists : lists[index]  ,
                removing: removing ,
                playingid :playingid,
                route:route,
                speed:speed
            })
        } 
        setRoute(route);
        dispatch(setPassRoute(route))
        setPlayingid(route?._id);
        setLoading(false)
  
        if(mapref){
            setHSizes(["auto","45%"])
            eventlocate =  route?.route[0] 
            let listindex  = route?.list[0]
            let icon = route?.deviceIcon
            drawMarkerL(icon,listindex ,eventlocate)
            markerstatus?.setRotationAngle(Number(listindex.heading))
            mapref.fitBounds(route?.route)
            setTimeout(()=>{
                genVelocity(route)
            },1000)
        }
    }

    const getPopupcontent=(content:any)=>{
        return `
           <div style={{width:"12rem"}} className="ion-padding" >
            <IonGrid>
                <IonRow> 
                    <IonCol size="12" >
                        <IonLabel><strong>Time:</strong> ${content?.event_stamp} </IonLabel> <br/>
                        <IonLabel><strong>address:</strong> ${content?.address} </IonLabel> <br/>
                        <IonLabel> <strong>speed: </strong> ${content?.speed} ${t("kmHUnit")}</IonLabel><br/>
                        <IonLabel>driver: ${content?.driver_id} ${content?.driver_message} </IonLabel><br/>
                    </IonCol>
                </IonRow>
            </IonGrid>
          </div> 
        `
    }

    const drawMarkerL=async (icon:any,listindex:any,position:any)=>{
         if(markerroute){  mapref?.removeLayer(markerroute) }
        if(markerstatus){  mapref?.removeLayer(markerstatus) }
        if(routeplyline){  mapref?.removeLayer(routeplyline) }

         
        markerroute = L.marker(position, {icon: new Icon({  
            iconUrl: `assets/types/type-${icon}.png` ,
            rotationOrigin: "center", 
            iconSize: [60, 60], 
            iconAnchor: [25, 60], 
        })}).addTo(mapref) 
         

        // let popupcontent =  getPopupcontent(listindex)  
        // markerroute.bindPopup(popupcontent,{offset:[0, -66],keepInView:true,autoPan:true}).openPopup()
        markerstatus = L.marker(position,{icon:  new DivIcon({
            className: `marker-status-divicon set-center ${ 
                listindex?.status===7? 'marker-move': 
                listindex?.status===23?'marker-stop': 
                listindex?.status===24?'marker-idle':'marker-offline'
            }`, 
            html:`<div id="marker-device-playback" style="color:#FFF;"  > <i class="fa-regular fa-circle-up"></i></div>` ,        
            iconSize: [20, 20],
            iconAnchor: [5, 65],  
        })}).addTo(mapref)

        routedraw?.push(position)
        if(routedraw.length-1 < passRouteIndex){
            let sliceroute = passroute?.route.slice(0,passRouteIndex)
            routedraw = sliceroute
        }
        routeplyline = L.polyline(routedraw,{color: color}).addTo(mapref)
         
        markerroute.on("contextmenu",(e:any)=>{
            mapref?.removeLayer(markerroute)
            mapref?.removeLayer(markerstatus) 
        })
      
        markerstatus?.setRotationOrigin("center" )   
        if(listindex?.status === 7){
            lastHeading = Number(listindex?.heading);
            markerstatus?.setRotationAngle(Number(listindex?.heading))
        }else{
            markerstatus?.setRotationAngle(lastHeading)
        }
        mapref?.invalidateSize() 
        if(trackMarker){
            mapref?.setView(position)
        }
    }  

    const playingRoute=()=>{
        console.log("playindex ",playindex ," passroute ", passroute?.list.length ," acton ",playAction)
       
        if(playindex+1 >= passroute?.route.length-1){
            routedraw=[]
            console.log("playindex more route ")
            clearInterval(playinterval) ; 
            dispatch(setPassRouteInterval(undefined))
        
            // dispatch(setPlayAction("stop"))   
           
            stopRoute({});
            if(routeplyline){ mapref?.removeLayer(routeplyline)   }  
        }else{ 
            eventlocate =  passroute?.route[playindex] 
            let list  = passroute?.list[playindex]
            drawMarkerL(passroute?.deviceIcon,list ,eventlocate) 
            if(trackMarker){
            // mapref?.setView( passroute?.route[playindex])
            }
        }
        playindex++; 
        return playindex
    }

    const playRoute=(e:any)=>{  
        dispatch(setPlayAction("playing"))  
        if(passroute?.list.length >5){
            console.log("action ",playAction)
            if( playAction ==="pause" && playindex!= passRouteIndex){
              playindex =passRouteIndex 
            } 
            console.log("playindex ",playindex ," passroute ", passroute?.list.length ," acton ",playAction)
    
            let interval   = setInterval(() => {
                let indexx =   playingRoute()
                dispatch(setPassRouteIndex(indexx)) 
            } ,speed.val) 
            dispatch(setPassRouteInterval(interval )) 
            console.log("interval ",interval)
        }
       } 

    const pauseRoute=async(e:any)=>{   
            dispatch(setPlayAction("pause"))  
            clearInterval(passrouteinterval) ; 
        
    }

    const stopRoute=async(e:any)=>{
        console.log("playindex ",passRouteIndex)
        console.log("playinterval ",passrouteinterval)
        clearInterval(passrouteinterval) ; 
        
            dispatch(setPlayAction("stop"))  
            playindex=0;
            routedraw =[];
            mapref?.removeLayer(routeplyline) 
            
            clearInterval(passrouteinterval) ; 
            dispatch(setPassRouteIndex(0))
       
    }

    const slelectSpeed=async(sp:any)=>{
        await pauseRoute({})

        setTimeout(()=>{ 
            setSpeed(sp)
            setSpeedChoice(false)
            if(playAction !== "stop"){
             playRoute(null)
            }
        },300)
    }

    const slideback=()=>{ 
        if(mapref){ 
            setHSizes([ "100%", 'auto' ])
            mapref.eachLayer(function (layer:any) { 
                if(layer?._url === null || layer?._url === undefined){ 
                    mapref.removeLayer(layer);
                }
            })
        }
        setPlayingid(""); 
        dispatch(setPlayAction("stop"))  
        dispatch(setPassRoute(null))  
    }

    const statsSize=(length:any)=>{
       let size =  Number(length)/100 
       return size
    }

    const changeTrackMarker=async(val:any)=>{
        await pauseRoute({})
        setTrackMarker(val)
        setTimeout(()=>{ 
            if(playAction !== "play"){
              playRoute(null)
            }
        },300)
    }
    const changeindex=(val:any)=>{ 
        dispatch(setPassRouteIndex(val)) 
        playindex =val;

        let sliceRoute = passroute?.route.slice(0,val-1)
        routedraw = sliceRoute 
    }

    const saveGeofences=async()=>{
        let geometryArr:any = []
        if(geoType=== "polygon"){ 
          polygon.forEach((el:any) => {
            geometryArr = geometryArr.concat(el)
          }); 
        }else{ 
          circle.forEach((el:any) => {
            geometryArr = geometryArr.concat(el)
          });
        } 
        console.log("saveGeofences circle ",circle)
        if(geometryArr.length > 0){ 
            const body = {
                locationName: name ,
                geometry:   JSON.stringify(geometryArr)   ,  
                iconId : currentIcon , 
                checkin:  checkin.checked ,
                checkout: checkout.checked ,
                devices:  optChecked ,
                templatein: checkin.format ,
                templateout: checkout.format 
            }
            console.log("saveGeofences body ",body)
            if(optChecked.length > 0 && name !== ""){
                setLoading(true)
                const result = await addGeometry(body)
                if(result.status===1){
                    toast({
                        ...toastSuccess,
                        message:"Add geofences success."
                    })
                    dispatch(setCircle([]))
                    dispatch(setPolygon([]))
                    setName("") 
                    setModalSave(false) 
                    setAddZone(false)
                } 
                setLoading(false)
            }else if(name==="" || name === null || name === undefined){
                toast({
                    ...toastFail ,
                    message:"กรุณา ระบุชื่อพื้นที่ !!"
                })
            }else{ 
                toast({
                    ...toastFail ,
                    message:"กรุณาเลือกรถก่อนการบันทึก !!"
                })
            }
        }else{
            toast({
                ...toastFail ,
                message:"Not found geofences bounds. Please try again."
            })
        }
    }
 
    const changeOption=(checked:any, deviceId:any)=>{ 
        let checkeddevice = optChecked
        checkeddevice = checkeddevice.filter((id)=> id !== deviceId)
        let updateopt =  options.map((e)=>{
            return {...e , ...{ checked: deviceId===e.value?checked: e.checked  } }
        })
        setOptions(updateopt) 
        if(checked){ 
            checkeddevice.push(deviceId)
            setOptChecked(checkeddevice)
        }else{  
            setOptChecked(checkeddevice)
        }  
     } 
     
     const checkAll=async (checked:boolean)=>{  
        setCheckall(checked)
        let checkeddevice: any[] = []
        let options = backup.map((e:any)=> {
        if(checked){ checkeddevice.push( e.device_id) }
        return {
            value: e.device_id ,label: e.name, checked:checked 
        } }) 
        setOptions(options)
        setOptChecked(checkeddevice) 
     }
     const createCirclePoly=async(open:any, point:any,radius:any)=>{ 
        console.log("createCirclePoly point ",point)
        let position = await drawCircle(  point.latitude , point.longitude ,radius) 
        dispatch( setCircle(position))
        setModalSave(open) 
        if(mapref.getZoom() < 15){
            mapref.fitBounds(position)
        }
     }

     const changeShowAllPoint=async(checked:any)=>{
        if(checked){
            await markersIcon.map((e:MarkerType)=>{
                e.addTo(mapref)
            })
        }else{
            mapref.eachLayer(function (layer:any) {     
              if(layer?.options?.icon?.options?.className?.indexOf( "marker-status-divicon" ) > -1){ 
                mapref.removeLayer(layer);
              }
           }) 
        }
    }

    const updateShowList=async(detail:any ,checked:any)=>{
        // let listlst = lists
        let updatelist = await lists.map((e)=>{
            if(e._id === detail._id){
                return {...e , ...{show: checked} }
            }else{
                return e
            }
        })
        console.log("updateShowList ",updatelist)
        dispatch(setPassrouteList(updatelist))
    }

    
    var el = document.querySelector("#area-speed")
    const genVelocity=async(routes:any)=>{
        let optionupdate:any = {
            chart: 	 { id: "area-speed"	, type: 'area', background: 'transparent' , stacked: true,height: 250},
            xaxis: 	 { type: 'datetime' , categories:[] , }, 
            stroke:  { curve: 'smooth'	, width: 1 },  
            colors: ['#134985', '#FD5F06','#134985'],
            yaxis: {
                labels: {
                    formatter: function (value:any) {
                      return value?.toFixed(0);
                    }
                },
            },
            dataLabels: { enabled: false },
            markers: { size: 0, style: 'hollow',  }, 
            tooltip: {
                x: {  format: 'HH:mm dd/MMM/yy'   }
            },
           fill: {
                type: 'gradient',
                gradient: {
                  shadeIntensity: 1,
                  opacityFrom: 0.7,
                  opacityTo: 0.9,
                  stops: [0, 100]
                }
            },  
          }
                  
        let serie:any = {  data: [] , name: "Speed(KM/H)" , type:"area" } 
        let serieData:any[] = []
        let serieFuel:any = [] 
        await routes?.list.map((e:any,index:any)=>{
            if(index+1 < routes?.list.length -1){
                const route = e 
                serieData  =[...serieData ,  [  new Date(moment(route.event_timestamp).add(7,"hour").format()) ,  route.speed   ] ]
                serieFuel = [...serieFuel,   [  new Date(moment(route.event_timestamp).add(7,"hour").format()) ,  route.fuel   ] ]
                optionupdate.xaxis.categories[index] = new Date(moment(route.event_timestamp).add(7,"hour").format())
            }
        })  
        serieData.filter((e:any)=> e!= null) 
        optionupdate.series = [ {data : serieData , type: 'area' ,  name: "Speed(KM/H)"  } ,  {data : serieFuel , type: 'area' ,  name: "Fuel (Liter / Percentage)"  }] // [{data : serieData ,  name: "Speed(KM/H)" , type:"area"}]
        let updateViocity = {
            series: [ {data : serieData , type: 'area' } ,  {data : serieFuel , type: 'line' }] , 
            options: optionupdate
        } 
        setVelocity(updateViocity) 
        el = document.querySelector("#area-speed") 
        
        if(el){ 
            var chart = new ApexCharts(document.querySelector("#area-speed"), optionupdate);
            chart.render();
        }
    }

    function haversineDistance(lat1:any, lon1:any, lat2:any, lon2:any, unit = 'km') {
        const toRadians = (angle:any) => (angle * Math.PI) / 180;
    
        const R = unit === 'km' ? 6371 : 3958.8; // Radius of Earth in kilometers or miles
    
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);
    
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) *
                Math.cos(toRadians(lat2)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
    
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    
        return R * c; // Distance in the chosen unit
    }

    function interpolatePoints(lat1:any, lon1:any, lat2:any, lon2:any, numPoints:any) {
        const toRadians = (angle:any) => (angle * Math.PI) / 180;
        const toDegrees = (angle:any) => (angle * 180) / Math.PI;
    
        const lat1Rad = toRadians(lat1);
        const lon1Rad = toRadians(lon1);
        const lat2Rad = toRadians(lat2);
        const lon2Rad = toRadians(lon2);
    
        const points = [];
    
        for (let i = 0; i <= numPoints; i++) {
            const fraction = i / numPoints;
    
            const A = Math.sin((1 - fraction) * Math.PI / 2) / Math.sin(Math.PI / 2);
            const B = Math.sin(fraction * Math.PI / 2) / Math.sin(Math.PI / 2);
    
            const x = A * Math.cos(lat1Rad) * Math.cos(lon1Rad) +
                      B * Math.cos(lat2Rad) * Math.cos(lon2Rad);
            const y = A * Math.cos(lat1Rad) * Math.sin(lon1Rad) +
                      B * Math.cos(lat2Rad) * Math.sin(lon2Rad);
            const z = A * Math.sin(lat1Rad) + B * Math.sin(lat2Rad);
    
            const lat = Math.atan2(z, Math.sqrt(x * x + y * y));
            const lon = Math.atan2(y, x);
    
            points.push({
                lat: toDegrees(lat),
                lon: toDegrees(lon)
            });
        }
    
        return points;
    }
     
    const featureRoute=async(flon:any,flat:any,tlon:any,tlat:any,lkey:any)=>{
        return await axios.get(`https://api.longdo.com/RouteService/geojson/route?flon=${flon}&flat=${flat}&tlon=${tlon}&tlat=${tlat}&mode=t&type=25&locale=th&key=${lkey}`)
        .then(async( res )=>{ 
           return res.data.features
        })
    }
    function insertAtIndex(array:any, index:any, item:any) {
        array.splice(index, 0, item);
        return array;
    }
    const mergeInPlace = (a:any, b:any, i=0) => a.splice(i, 0, ...b);
    const addRouteToSetRoute=async()=>{
        let boundtime:any = []
        let update:any[] = [] 
        let route:any[] = [] 
        let routeInsert:any = []
        let updateInsert:any = []
        let lkey = await getLongdokey({}) 
        console.log("addRouteToSetRoute passroute ",passroute) 
        await Promise.all(
        await passroute?.list.map(async (e:any,i:any)=>{
            if(i > 0 ){
                let distance = haversineDistance(passroute?.list[i-1].longitude, passroute?.list[i-1].latitude, e.longitude, e.latitude)
                if(  distance >1){
                //   let latlngbtween =   interpolatePoints(passroute?.route[i-1][0], passroute?.route[i-1][1], e[0], e[1],5) 
                //   update = [...update ,{lat: latlngbtween[1].lat , lng: latlngbtween[1].lon } ]
                //   update = [...update ,{lat: e[0] , lng: e[1] } ]
                //   route  = [...route ,{lat: latlngbtween[1].lat , lon: latlngbtween[1].lon } ]
                //   route  = [...route ,{lat: e[0] , lon: e[1] } ]
                // }else if(distance > 0 && distance >= 2   ){ 
                   const feature:any[] =  await Promise.all( await  featureRoute( passroute?.list[i-1].longitude, passroute?.list[i-1].latitude, e.longitude, e.latitude,lkey) )
                   
                   await feature.map(async(fea:any,fi:any)=>{
                        let updatei:any[] = []
                        let routei:any[]  = []
                       await  fea?.geometry.coordinates.map(async(coord:any,ci:any)=>{  
                            //    let routei  = {lat: coord[1] , lon: coord[0] } //await insertAtIndex(route,i,{lat: coord[1] , lon: coord[0] })// [...route ,{lat: coord[1] , lon: coord[0] } ]  
                            //    let updatei  ={lat: coord[1] , lng: coord[0] } //await insertAtIndex(route,i,{lat: coord[1] , lng: coord[0] })
                            //    routeInsert = [...routeInsert , { routei , i: i+fi+ci}]
                            //    updateInsert = [...updateInsert,{ updatei, i: i+fi+ci}] 
                            // updatei = [...updatei ,{lat: coord[1] , lng: coord[0] } ]
                            // routei  = [...routei ,{lat: coord[1] , lon: coord[0] } ]
                            boundtime = [...boundtime ,{ latlng:{lat: coord[1] , lng: coord[0] } ,time: e.event_timestamp+ci }]
                        })
                        // updateInsert = [...updateInsert, {route:updatei , index: i  }]
                        // routeInsert  = [...routeInsert, {route:routei , index: i}]
                    })    

                }else  if(distance > 0) {
                    // update = [...update ,{lat: e.latitude , lng: e.longitude } ]
                    // route  = [...route ,{lat: e.latitude , lon: e.longitude } ]
                    boundtime = [...boundtime ,{ latlng:{lat:  e.latitude , lng:e.longitude  } ,time: e.event_timestamp }]
                }
            }else{
                // update = [...update ,{lat: e.latitude , lng: e.longitude } ]
                // route  = [...route ,{lat: e.latitude , lon: e.longitude } ]
                boundtime = [...boundtime ,{ latlng:{lat:  e.latitude , lng:e.longitude  } ,time: e.event_timestamp }]
            } 
        }) 
    ) 
    console.log("addRouteToSetRoute boundtime ",boundtime)
        let newboundtime = boundtime.sort((a:any, b:any) => (b.time || 0) - (a.time || 0) );
       await  newboundtime.map((e:any)=>{  
            update = [...update ,{lat: e.latlng.lat , lng: e.latlng.lng } ]
            route  = [...route , {lat: e.latlng.lat , lon: e.latlng.lng } ]
        })

        console.log("addRouteToSetRoute routeInsert ",routeInsert)
        console.log("addRouteToSetRoute updateInsert ",updateInsert)
        // await Promise.all( await routeInsert.map(async(ri:any , index:any)=>{
        // //  await   mergeInPlace(route,  ri?.route, ri?.index); 
        //     route.splice(ri?.index+index, 0, ...ri?.route)
        // }) )
        // await Promise.all(  await updateInsert.map(async(ri:any , index:any)=>{
        //     // await  mergeInPlace(update,  ri?.route, ri?.index);  
        //     update.splice(ri?.index+index, 0, ...ri?.route)
        // }) )
        
        // await updateInsert.map((ri:any,i:any)=>{
        //     update.splice(i, 0 , ri?.updatei) //insertAtIndex(update, ri?.i, ri?.updatei)
        // })
        console.log("addRouteToSetRoute route ",route)
        console.log("addRouteToSetRoute update ",update)
       setTimeout(()=>{
            dispatch(setRouteDraw(update))
            dispatch(setRouteSet({
                acceptDistance : 1000,
                sname: "",
                distance: passroute?.distance_all,
                route:route
            }))
           setTimeout(()=>{
            history.push("/setRoute",{
                segmant:"add" ,
                routeDraw: update
            })
           },200)
       },2000)
    }

    return(
        <IonPage>  
            <IonContent color={"light"}><div style={{ height:"95vh",}}   > 
            {/* @ts-ignore */}
            <SplitPane
                split='vertical'
                sizes={sizes}
                onChange={setSizes}
            >
                <Pane  style={{height:"100vh"}}  >
                <div className='splitpane pane' style={{ ...{height: '100vh'} }}> 
                        <div  className="ion-padding passroute-container"  >
                        <Swiper  
                            draggable={false}
                            allowTouchMove={false}
                            onSlideChange={(e) => console.log('refSwiper change',refSwiper)}
                            onSwiper={(e:any)=>{setRfSwip(e);console.log("refSwiper ",e)}}
                        >
                            <SwiperSlide style={{height:"100%"}}>
                                <h1 className="ion-no-margin ion-margin-bottom" > 
                               <IonLabel  style={{fontWeight:"bold",display:"flex",flexDirection:"row"}} >  
                                    <IonMenuButton color={"dark"} className="ion-hide-md-up" /> &nbsp;  
                                    <IonText>เส้นทางย้อนหลัง <br/>
                                        <sub style={{fontSize:".6em",fontWeight:"normal"}} >ประวัติการเดินทาง เส้นทางย้อนหลัง</sub>
                                    </IonText> 
                                </IonLabel> <br/> 
                                </h1>  
                                <br/>
                                <div style={{display:"flex" , flexDirection:"row"}} >
                                <DropDownDevice vehihle={vehicle} setVehicle={(e:any)=>{setVehicle(e) }} optionsbackup={options} />
                                {loadingSpinner && <IonSpinner name="circles" />}
                                </div>

                                <IonRow>
                                    <IonCol  sizeLg="12" sizeXl="6" className="ion-no-padding ion-padding-top ">
                                       <DateStartReport date={datestart} openDate={setOpenDate} bg={true}  showtime={false}/> 
                                    </IonCol>
                                    {/* <IonCol sizeLg="2"  className="set-center">  <IonButton size='small' fill='clear' color={"dark"}>ถึง</IonButton>      </IonCol> */}
                                    <IonCol  sizeLg="12" sizeXl="6" className="ion-no-padding ion-padding-top ion-padding-bottom">
                                       <DateEndReport date={dateend} openDate={setOpenDate} bg={true}  showtime={false}/> 
                                    </IonCol>
                                </IonRow> 
                                <IonButton  style={{height:"2.5rem"}} expand="block" mode="ios"  onClick={()=>{searhDevice()}}>
                                    <IonLabel>{loading && <IonSpinner name={"crescent"} />}&nbsp; Seach </IonLabel>
                                </IonButton>
                                <div className="set-center" style={{width:"100%",padding:".5rem",display:"flex",flexDirection:"row"}} >
                                    <IonRadioGroup value={color} onIonChange={(e:any)=>{setColor(e.detail.value)}} >
                                    { colors.map((color,index:any)=> 
                                        <IonRadio 
                                            key={index}
                                            value={color} className="radio-color" 
                                            mode="ios"  color={"transparent"}
                                            style={{backgroundColor: color }} 
                                        ></IonRadio> 
                                    )}  
                                    </IonRadioGroup>
                                </div>

                                <div style={{width:"100%",height:"1px",borderTop:"1px solid #DDD", margin:"1rem 0 .5rem"}} ></div> 
                                <IonList>
                                    {lists.map((e:Route,index:any)=> 
                                      <CardRoute 
                                        key={index} detail={e} index={index} 
                                        size={lists.length}  playingid={playingid} hideRoute={(e:any,checked:any)=>{updateShowList(e ,checked); console.log("hideRoute ",e ,checked)}}
                                        removing={removing}  setRoute={(e:Route)=>{selectRoute(e ,index) }}
                                        removeRoute={(e:any)=>{removeRoute(e)}}  
                                      />
                                    )} 
                                </IonList> 
                           </SwiperSlide>
                           <SwiperSlide> 
                             <IonGrid className="ion-no-padding">
                                <IonRow>
                                    <IonCol size="12" >
                                        <SlidBack  clearPlayId={(()=>{slideback();  })} />
                                    </IonCol>

                                    <IonCol size="12" className="ion-padding" style={{fontSize:".9em"}}>
                                        <IonLabel  style={{fontWeight:"bold" , display:"flex" , flexDirection:"row", alignItems:"center"}}>
                                            <IonCheckbox class="check-route"  aria-label=""  checked={trackMarker}  onIonChange={(e:any)=>{changeTrackMarker(e.detail.checked);}}></IonCheckbox> &nbsp;&nbsp;
                                            <IonText >{device?.name}</IonText>
                                        </IonLabel><br/>
                                        <IonLabel  style={{fontSize:"smaller"}} >{moment(datestart).format("DD/MM/YYYY HH:mm")} - {moment(dateend).format("DD/MM/YYYY HH:mm")} </IonLabel>
                                    </IonCol>
                                    <IonCol size="12" className="ion-padding-horizontal" style={{fontSize:".9em"}}>
                                        <IonRow>
                                            <IonCol size="4" style={{fontSize:"smaller"}}> 
                                              <IonLabel>ระยะทาง </IonLabel><br/>
                                              <IonText  style={{fontWeight:"bold"}}> {passroute?.distance_all.toString()} {t("kmUnit")} </IonText> 
                                            </IonCol>
                                            <IonCol size="4"  style={{fontSize:"smaller"}}> 
                                              <IonLabel>ระยะเวลา </IonLabel><br/>
                                              <IonText  style={{fontWeight:"bold"}}> {passroute?.time_all} </IonText> 
                                            </IonCol>
                                            <IonCol size="4" style={{fontSize:"smaller"}}> 
                                              <IonLabel>ความเร็วสูงสุด </IonLabel><br/>
                                              <IonText  style={{fontWeight:"bold"}}> {passroute?.speed_max.toString()} {t("kmHUnit")}</IonText>  
                                            </IonCol>
                                        </IonRow>
                                    </IonCol> 
                                    <IonCol size="12" className="  ion-padding-horizontal" >
                                       <RangeStatus statsSize={statsSize} isInZone={passroute?.isInZone} iconZone={passroute?.iconZone} changeRangeKnob={(e:any)=>{console.log("changeRangeKnob ",e); changeRangeKnob(e)}} />
                                    </IonCol>  
                                </IonRow>
                             </IonGrid>
                             <div className="ion-padding-horizontal" >
                                <IonButton size="small" expand="block" mode="ios" onClick={()=>{ addRouteToSetRoute()  }} > 
                                   <IonLabel>เพิ่มในกำหนดเส้นทาง</IonLabel>
                                </IonButton>
                             </div>
                              <div> 
                               <IonList style={{maxHeight:"50vh" , overflowY:"scroll"}} > 
                                { passroute?.spevents.map((e:any,index:any)=>
                                    <IonItem key={index} className="cursor-pointer"  onClick={()=>{mapref?.setView([e.lat,e.lng],18) }}  > 
                                            {
                                                e?.code=== 91  ? <IonIcon    src="assets/icon/harshTurnRight.svg" style={{fontSize:"1.8em"}} /> :  
                                                e?.code=== 90  ?  <IonIcon    src="assets/icon/harshTurnLeft.svg" style={{fontSize:"1.8em"}} />  :
                                                e?.code=== 129 ?  <IonIcon    src="assets/icon/harshBraking.svg" style={{fontSize:"1.8em"}} /> : 
                                                e?.code=== 130 ?  <IonIcon    src="assets/icon/harshAcceleration.svg" style={{fontSize:"1.8em"}} />  :
                                                e?.code=== 19 ?  <IonIcon    src="assets/icon/harshSpeeding.svg" style={{fontSize:"1.8em"}} />  :
                                                <IonIcon    src="assets/icon/harshIdling.svg" style={{fontSize:"1.8em"}} /> 
                                            }
                                        
                                        <IonLabel style={{fontSize:".8em",marginLeft:".5rem"}} className="ion-text-wrap cursor-pointer" >
                                            <IonText>{e?.message} {moment(e?.eventTime).format("HH:mm DD/MM")}</IonText><br/>
                                            <IonText>{e?.address}</IonText>
                                        </IonLabel>   
                                    </IonItem>
                                )}</IonList>  
                              </div>
                             <SideOverView 
                                speed={speed}
                                passroute={passroute} 
                                playindex={playindex}  
                                statsSize={statsSize}
                                playRoute={()=>{return playRoute(null)}}
                                pauseRoute={()=>{return pauseRoute({})}}
                                stopRoute={()=>{return stopRoute({})}}
                                setSpeed={(e:any)=>{slelectSpeed(e)}}
                                changeIndex={(e:any)=>{changeindex(e)}}
                             />
                             
                           </SwiperSlide>
                        </Swiper>
                        </div>
                    </div>
                </Pane>
                <Pane  style={{height:"100vh"}} >
                    <div className="set-center" style={{ height: '100vh' , background: '#fff' }}>
                   
                       {/* @ts-ignore */}
                       <SplitPane
                           split='horizontal'
                            sizes={hsizes} 
                            // @ts-ignore
                            onChange={setHSizes}
                        >
                            <Pane> 
                                 <div> </div>
                            <div style={{position:"fixed", zIndex:9999 , bottom:"1rem",left:"1rem" ,borderRadius:"5px",background:"#FFF" }}>
                                <button  className="ion-boxshadow ion-radius" onClick={()=>{showDetail(!detail)}} style={{position:"relative",padding:"7px"}}>
                                    <IonIcon icon={listOutline} style={{fontSize:"larger"}} color="primary" /> 
                                    {!detail && playAction !== "stop" &&  <IonIcon icon={alertCircle} color="danger" style={{position:"absolute",right:"2px",top:"-.5rem"}}/>  }
                                </button>
                                {detail &&<div className="bg-light" style={{position:'absolute' ,bottom:"3rem",zIndex:999,width:"20rem",borderRadius:"5px" , fontSize:".8em",minHeight:"8rem",padding:"5px"}} >
                                    <IonGrid>
                                        <IonRow> 
                                            <IonCol size="12" style={{position:"relative"}} >
                                                <IonButton fill="clear" size="small" style={{position:"absolute",right:"5px",top:"0"}} onClick={()=>{showDetail(false)}} >  <IonIcon icon={closeCircleOutline} /> </IonButton> 

                                                <IonLabel><strong>Time:</strong> {passroute?.list[passRouteIndex]?.event_stamp} </IonLabel> <br/>
                                                <IonLabel><strong>address:</strong> {passroute?.list[passRouteIndex]?.address} </IonLabel> <br/>
                                                <IonLabel> <strong>speed: </strong> {passroute?.list[passRouteIndex]?.speed} {t("kmHUnit")}</IonLabel><br/>
                                                <IonLabel>driver: {passroute?.list[passRouteIndex]?.driver_id} {passroute?.list[passRouteIndex]?.driver_message} </IonLabel><br/>
                                        </IonCol>
                                        </IonRow>
                                </IonGrid>
                                </div> }
                            </div> 
                            <div style={{width:"100%"}} className="set-center" > 
                            { passroute && <div className="playback-control" style={{}} >    
                                <IonRow className="ion-justify-content-center" >
                                <IonCol size="10" className="ion-no-padding">
                                    <IonRange value={passRouteIndex} max={passroute?.route.length} onIonChange={(e)=>{ dispatch(setPassRouteIndex(e.detail.value)) }} ></IonRange>
                                </IonCol>
                                <IonCol size="11" className="ion-no-padding" style={{display:"flex",flexDirection:"row",justifyContent:"flex-start" }} >
                                
                                    <IonButtons style={{backgroundColor:"#FFF" , borderRadius:"5px"}} >
                                        <IonButton mode={"ios"}  onClick={(e)=>{  playRoute(e)}} color={"primary"} fill="clear" size="small"  disabled={playAction === "playing" ?true:false}>
                                            <IonIcon icon={play} />
                                        </IonButton>
                                        <IonButton mode={"ios"} onClick={(e)=>{ pauseRoute(e)}} color={"primary"}  fill="clear"  size="small"  disabled={playAction === "pause" ?true:false}>
                                            <IonIcon icon={pause} />
                                        </IonButton>
                                        <IonButton mode={"ios"} onClick={(e)=>{ stopRoute(e)}} color={"primary"}  fill="clear" size="small"   disabled={playAction === "stop" ?true:false}>
                                            <IonIcon icon={stop} />
                                        </IonButton>
                                    </IonButtons>
                                    <IonButtons 
                                    onMouseLeave={()=>{setSpeedChoice(false)}} 
                                    onMouseOver={()=>{setSpeedChoice(true)}} 
                                    style={{backgroundColor:"#FFF" , borderRadius:"5px",marginRight:".5rem"}} > 
                                    {!speedChoice &&
                                        <IonButton  onClick={()=>{setSpeedChoice(!speedChoice)}} mode={"ios"}  color={"primary"} fill="clear" size="small" >
                                            <IonLabel style={{fontSize:"small"}}>
                                                {speed.name}
                                            </IonLabel>
                                        </IonButton>  }{
                                        speedChoice &&  <>
                                        {  speeds.map((e:any,index:any)=>
                                            <IonButton  onClick={()=>{slelectSpeed(e)}} key={index} mode={"ios"}  fill="clear" size="small" 
                                                    color={e.val === speed.val ?"primary":"dark"} 
                                                >
                                                    <IonLabel style={{fontSize:"small"}}>
                                                    {e.name}
                                                </IonLabel>
                                            </IonButton>  
                                        )  } 
                                        </>
                                    }  
                                    </IonButtons>
                                
                                </IonCol>
                                </IonRow>  
                            </div>
                            }
                            </div>
                            <div  style={{height:"97vh",width:"100%"}} >  
                                <div style={{position:"absolute" , zIndex: 999 , right:"1rem" , top:"5rem"}} > 
                                    <IonCheckbox checked={true} labelPlacement="end" onIonChange={(e)=>{changeShowAllPoint(e.detail.checked)}} ><IonLabel>แสดงจุดทั้งหมด</IonLabel></IonCheckbox>
                                </div>
                                <MapPassroute  uniq={"main"}
                                    cssClass={"ion-hide-md-down"}
                                    route={route} 
                                    playingid={playingid} 
                                    speed={speed} 
                                    passroute={passroute}
                                    setPlayIndex={((e:any)=>{playindex = e } )}
                                    playindex={playindex} 
                                    setMapRef={(ref:any)=>{ mapref = ref;}} 
                                    setModalssave={(open:any,point:any,radius:any)=>{ createCirclePoly(open,point,radius);}}
                                    color={color}
                                    updateMarker={(e:any)=>{console.log("updateMarker ",e ); setMarkersIcon(e)}}
                                />  
                            </div> 
                            </Pane>  
                            <Pane>
                                <div className="ion-padding" >
                                    <IonLabel> ความเร็ว และน้ำมัน</IonLabel>
                                     {velocity &&  <ReactApexChart options={velocity?.options} series={velocity?.options?.series}  height={250} type="area" /> }
                                </div>
                            </Pane>  
                        </SplitPane>  


                     </div>
                    </Pane> 
                </SplitPane>  
           </div></IonContent>

        <DateRangPopover start={datestart} setStart={setDateStart} end={dateend} setEnd={setDateEnd} openDate={setOpenDate} isOpen={openDate} />
        <ModalGeozoneNotify 
            modalSave={modalSave}
            modalType={modalType}
            setModalType={(e:any)=>setModalType(e)}
            setModalSave={(e:any)=>setModalSave(e)}
            name={name}
            setName={(e:any)=>{setName(e)}}
            saveUpdateGeozone={()=>{}}
            saveGeofences={()=>saveGeofences()} 
            changeOption={(a:any,b:any)=>changeOption(a,b)}
            checkin={checkin}
            setCheckin={(e:any)=>setCheckin(e)}
            checkout={checkout}
            setCheckout={(e:any)=>setCheckout(e)}
            currentIcon={currentIcon}
            setCurrentIcon={(e:any)=>setCurrentIcon(e)}
            options={options}
            checkAll={(e:any)=>checkAll(e)}
            checkall={checkall}
        />
    </IonPage>
    )
}
export default Passroute;

const stopoverview={
    stop: 1 ,
    stopDuration: "00:15:22",
    stop_location:"" ,
    start: "16/01/2024 07:29:40" ,
    end: "16/01/2024 07:58:01" , 
    stopMaxDuraion: "00:28:21" , 
    lastStopAddress: "สถานะที่จอดล่าสุด : ( 73.49 m) โรงแรมพจน์แสนเจริญโฮเต็ล ต.แชะ อ.ครบุรี จ.นครราชสีมา 30250",
    idleCount: 23
}

const RangeStatus=({statsSize,changeRangeKnob,isInZone,iconZone}:any)=>{ 
    const passroute:Route|any= useSelector(getPassRoute)
    const passRouteIndex :any= useSelector(getPassRouteIndex)
    const dispatch = useDispatch()

    useEffect(()=>{ 
        const getdefaultSett=async()=>{
            const defaultsett = await getStorage("default")
           console.log("defaultsett ",defaultsett)
           dispatch(setDefaultMap(defaultsett?.defaultMap.toLowerCase()))
        }
        getdefaultSett()
    },[passRouteIndex])
    
    return(
        <div className="wrap-range-status" >
        <div className="status-wrap" style={{position:"relative"}}>
          {passroute?.list.map((e:any,i:any)=>
            <div key={i} style={{width: `${statsSize(passroute?.list.length)}%` }}  className={`status-inrange ${ 
             e.status === 7 ? "bg-move" :  e.status  === 23 ? "bg-stop": e.status  === 24 ? "bg-idle": "bg-offline" }`}  >   </div>
          ) }
          {isInZone && <div style={{position:'absolute',bottom:"-1rem"}} > - </div>}
        </div>
        <IonRange 
             labelPlacement="start" mode="ios"  
             min={0} max={passroute?.count} className="range-passroute-status"
             value={passRouteIndex} onIonChange={(e:any)=>{return changeRangeKnob(e.detail.value) }} 
         ></IonRange>
     </div>
    )
}
