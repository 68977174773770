import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonMenuButton, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router'; 
import { getLoading, getMenuOpen, getProfile, setLoading } from '../store/appSlice';
import '../theme/style.css'; 
 
import {  Document, Page, pdfjs } from "react-pdf"; 
import { chevronBackOutline } from 'ionicons/icons';
import NavBar from '../components/NavBar';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Manual: React.FC = () => {  
  const history = useHistory();
  const dispatch = useDispatch()
  const menuOpen :any= useSelector(getMenuOpen)
  const profile :any= useSelector(getProfile)
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1); // start on first page
  const [loading, setLoading] = useState(true);
  const [pageWidth, setPageWidth] = useState(0);

  

  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: {
    numPages: number;
  }) {
    setNumPages(nextNumPages);
  }

  function onPageLoadSuccess() {
    setPageWidth(window.innerWidth);
    setLoading(false);
  }

  function onDocumentLoadErr(e:any){ console.log(e) }
  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
    workerSrc: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`  
  };

    
  return (
    <IonPage> 
    <IonHeader color='transparent' className='ion-no-border'   > 
      <IonToolbar color='transparent' mode='ios' className='ion-no-padding'>  
          {/* <NavBar />   */} 
            <IonButtons slot="start"  className='ion-hide-md-up  '  >
             <IonButton size='small' onClick={()=>{history.goBack()}}> <IonIcon icon={chevronBackOutline} />  <IonText >Back</IonText> </IonButton>
            </IonButtons>
            <IonTitle style={{fontWeight:"bold",fontSize:"1.3em",marginLeft:"1rem" }} >{'Reports'}</IonTitle>
      </IonToolbar>
    </IonHeader>


      <IonContent fullscreen className='ion-padding'>
             
          <IonGrid  >
           <IonRow>
            <IonCol size='12' >  
              {/* <h1 style={{fontWeight:"bold"}}>Manual</h1>  */}
            </IonCol>
              {/* {( menuOpen)&&<IonCol sizeLg='3' sizeXl='2' className='ion-hide-xl-down'  >
              <div className={`main-side-menu ${menuOpen && "slide-right"}`} style={{height:'90vh' }} > 
              <MenuContent profile={profile} /></div>
            </IonCol>}  */}
            <IonCol  size='4' >
              <div className='ion-padding' style={{width:"100%",height:"100%",backgroundColor:"#f2f2f2"}} >
                <h1 style={{fontWeight:"bold",margin:"0px"}}>Manual</h1> 
              </div>
            </IonCol>
            <IonCol size='8' >   
              <div className='set-center' style={{width:"100%",height:"100%",backgroundColor:"#f2f2f2"}} >
              <Document 
                file={"http://localhost:8100/assets/manual/ManualWebApplication.pdf"}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadErr}
                options={options}
                renderMode="canvas"
                className="pdf-document"
              >
                <Page
                  className=""
                  key={1}
                  pageNumber={1}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  onLoadSuccess={onPageLoadSuccess}
                  onRenderError={() => setLoading(false)}
                  // width={Math.max(800 * 0.8, 390)}
                />
              </Document>
              </div>
            </IonCol> 

           </IonRow> 
          </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Manual;
