import { faDoorClosed, faDoorOpen, faSatelliteDish, faStreetView } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonAlert, IonBadge, IonButton, IonCardTitle, IonChip, IonCol, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonText, useIonAlert } from "@ionic/react"
import { flameOutline, lockClosed, speedometerOutline, star, terminal, thermometerOutline, wifiOutline } from "ionicons/icons"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getFilterJson, getlistTemplate, setDevice, setDeviceModal, setDeviceModalOpt, setDeviceParams } from "../store/appSlice"
import { getIconFilter, getName, getStatusName, getUpdateTime } from "../store/filterSlice"
import { StartRate } from "./AppComponent" 
import { useHistory } from "react-router"

import "./css/CardDevice.css"
import { t } from "i18next"
var SHA1 = require("crypto-js/sha1");
var CryptoJS = require("crypto-js");

// --------------- General ----------------------------------------//
const DeviceIcon=(props:any)=>{
    const history = useHistory()
    const filterJson :any= useSelector(getFilterJson)
    const [show,setShow] = useState({card  :  true, detail :  false})
    const showIcon :any= useSelector(getIconFilter)

    useEffect(()=>{  
    },[showIcon]) 

    return(
        showIcon.card ?  <IonCol size="3"  className="ion-text-center set-center" style={{paddingTop:'.5rem',paddingBottom:'0',position:'relative',justifyContent:"center"}}>
            { 
             props.deviceIconId!==null?
            <IonImg  src={`../../assets/types/${props.deviceIconId}.png`} alt="icon-type" style={{maxWidth:"2.7rem"}} />:
            <IonImg  src={`../../assets/types/1.png`}  alt="icon-type-1" />
            }
            {/* <IonImg className="sts-icon" src={`../../assets/types/sts-${props.online!=0 ?props.status: props.online}.png`} alt="icon-status" /> */}
            <div style={{position:'absolute',left:'40%' ,width:'1rem',height:'1rem',transform:`rotate(${props.heading}deg)`}} className={ `marker-status-divicon sts-icon ${
              props.online===0? 'marker-offline':
              props.status===7? 'marker-move': 
              props.status===23?'marker-stop': 
              props.status===24?'marker-idle':'marker-offline'
            }`}></div>
            {/* {props.employee && <IonButton className="employee-name" size="small"  onClick={()=>{history.push("/employees")}}>
              <IonLabel style={{fontWeight:"bold"}}>{props.employee}</IonLabel>
            </IonButton>}  */}
            
            <IonChip className="ion-text-center" mode="md" style={{marginTop:"-.5rem"}}
             color={
                props.online===0 ? "medium" :
                props.status===7 ? "success":
                props.status===23? "danger" :
                props.status===24? "warning": "medium"
              }
            >
                <IonLabel><small>{ props.online===0 ? "Offline" :props?.statusName}</small></IonLabel>
            </IonChip>
        </IonCol>:
        <></>
    )
}

const DeviceName=(props:any)=>{  
    const history = useHistory()
    const dispatch = useDispatch()

    const actionNamePoint=()=>{ 
        dispatch(setDeviceParams(props.deviceid)) 
        history.push("/drivingPoint" ) 
    }
   
    return(
        <IonCardTitle style={{fontSize: props.size, color:"#000"}} className={`${props.bold && 'text-bold'}`}    >
            {/* <IonIcon icon={star} color="warning" /> */}
              {props?.name} 
        </IonCardTitle>  
    )
}

const DeviceStatusNameTime=(props:any)=>{ 
    const status :any= useSelector(getStatusName)
    const template :any= useSelector(getlistTemplate) 
    useEffect(()=>{},[status])

    return(<> 
        <IonLabel color={
            props.online === 0?"medium": 
            props.status === 7 ?"success": 
            props.status === 24 ? "warning" : 
            props.status === 23 ? "danger" :"medium"} 
        >
            <span className="status-name" > 
                <small>{
                    props.online === 0  ? t("home_status_offline").toUpperCase() : 
                    props.status === 7  ? t("home_segment_move").toUpperCase() : 
                    props.status === 24 ? t("home_status_idle").toUpperCase() : 
                    props.status === 23 ? t("home_status_stop").toUpperCase() : t("home_status_offline").toUpperCase()
                }</small></span> 
            <DeviceUpdateTime update={props.statusTime}/>  
        </IonLabel>  
     </>
    )
}

const DeviceUpdateTime=({update}:any)=>{
    const filterJson :any= useSelector(getFilterJson)
    const [show,setShow] = useState({card  :  true, detail :  false})
    const [updateTime , setUpdateTime] = useState("")

    useEffect(()=>{
       const sensors =  filterJson.filter((group:any)=> group.group_name === "General")  
    //    const member = sensors[0].member
    //    const isShow = member.find((e:any) => e.type ===  "update_time" ) 
    //    setShow(isShow) 

       let member =[] 
       let isShow = show
        if( sensors.length > 0 ){  
            member = sensors[0].member
            isShow = member.find((e:any) => e.type ===  "update_time" ) 
        }else{
            isShow = show
        }
        setShow(isShow)


       const timeArr =  update ?  update?.split(":"):  update;  
       if(timeArr){ 
        let hh = Number(timeArr[0])
        let mm = Number(timeArr[1])
        let ss = Number(timeArr[2])
 
        if(hh > 0){
         setUpdateTime( `${hh} ${t("hh")} ${mm} ${t("min")}`)
        }else if(hh <= 0 && mm > 0){
         setUpdateTime(`${mm} ${t("min")}`)  
        }else if(hh <= 0 && mm <= 0){
         setUpdateTime(`${ss} ${t("sec")}`)
        }
       }
    },[filterJson]) 

    return(
        show.card && update ?<IonBadge mode="ios" className="badge-device-update" color="secondary">{updateTime}</IonBadge>:  <></>
    )
}



// --------------- Sensor ----------------------------------------//
const ChipSpeed=(props:any)=>{
    const filterJson :any= useSelector(getFilterJson)
    const [show,setShow] = useState({card  :  true, detail :  false})

    useEffect(()=>{
       const sensors =  filterJson.filter((group:any)=> group.group_name === "Sensors")  
    //    const member = sensors[0].member
    //    const isShow = member.find((e:any) => e.type ===  "sensor_speed" ) 
    //    setShow(isShow)

       let member =[] 
       let isShow = show
        if( sensors.length > 0 ){  
            member = sensors[0].member
            isShow = member.find((e:any) => e.type ===  "sensor_speed" ) 
        }else{
            isShow = show
        }setShow(isShow)

    },[filterJson]) 
    return(
    show.card ?
        <IonChip  className="chip stack"  >
            <IonIcon icon={speedometerOutline} color="secondary"></IonIcon>
            <IonLabel>{props?.speed}</IonLabel>
        </IonChip>:
    <></>
  )
}

const ChipFuel=(props:any)=>{
    const filterJson :any= useSelector(getFilterJson)
    const [show,setShow] = useState({card  :  true, detail :  false})

    useEffect(()=>{
       const sensors =  filterJson.filter((group:any)=> group.group_name === "Sensors")  
    //    const member = sensors[0].member
    //    const isShow = member.find((e:any) => e.type ===  "sensor_fuel" ) 
    //    setShow(isShow)

       let member =[] 
       let isShow = show
        if( sensors.length > 0 ){  
            member = sensors[0].member
            isShow = member.find((e:any) => e.type ===  "sensor_fuel" ) 
        }else{
            isShow = show
        }setShow(isShow)


    },[filterJson]) 
    return(
    show.card && props.fuel_liters.indexOf("-") < 0?
        <IonChip  className="chip stack" >
            <IonIcon icon={flameOutline} color="secondary"></IonIcon>
            <IonLabel>{props.fuel_liters}</IonLabel>
        </IonChip>:
     <></>
  )
}


const ChipGSM=(props:any)=>{
    const filterJson :any= useSelector(getFilterJson)
    const [show,setShow] = useState({card  :  true, detail :  false})

    useEffect(()=>{
       const sensors =  filterJson.filter((group:any)=> group.group_name === "Sensors")  
    //    const member = sensors[0].member
    //    const isShow = member.find((e:any) => e.type ===  "sensor_GSM" ) 
    //    setShow(isShow)

       let member =[] 
       let isShow = show
        if( sensors.length > 0 ){  
            member = sensors[0].member
            isShow = member.find((e:any) => e.type ===  "sensor_GSM" ) 
        }else{
            isShow = show
        } setShow(isShow)

    },[filterJson]) 
    return(
   show.card ?
    <IonChip  className="chip stack" >
        <IonIcon icon={wifiOutline} color="secondary"></IonIcon>
        <IonLabel>{props.fld_signalStrength}</IonLabel>
    </IonChip>:
    <></>
  )
}


const ChipGPS=(props:any)=>{
    const filterJson :any= useSelector(getFilterJson)
    const [show,setShow] = useState({card  :  true, detail :  false})

    useEffect(()=>{
       const sensors =  filterJson.filter((group:any)=> group.group_name === "Sensors")  
    //    const member = sensors[0].member
    //    const isShow = member.find((e:any) => e.type ===  "sensor_GPS" ) 
    //    setShow(isShow)

       let member =[] 
       let isShow = show
        if( sensors.length > 0 ){  
            member = sensors[0].member
            isShow = member.find((e:any) => e.type ===  "sensor_GPS" ) 
        }else{
            isShow = show
        }setShow(isShow)

    },[filterJson]) 
    return(
   show.card ?
    <IonChip  className="chip stack" >
        <IonLabel color={"secondary"}><FontAwesomeIcon icon={faSatelliteDish} /></IonLabel>   &nbsp;&nbsp;
        <IonLabel>{props.satellites}</IonLabel>
    </IonChip>:
    <></>
  )
}

const ChipPTO=(props:any)=>{
    const filterJson :any= useSelector(getFilterJson)
    const [show,setShow] = useState({card  :  true, detail :  false})

    useEffect(()=>{
       const sensors =  filterJson.filter((group:any)=> group.group_name === "Sensors")  
    //    const member = sensors[0].member
    //    const isShow = member.find((e:any) => e.type ===  "sensor_PTO" ) 
    //    setShow(isShow)

       let member =[] 
       let isShow = show
        if( sensors.length > 0 ){  
            member = sensors[0].member
            isShow = member.find((e:any) => e.type ===  "sensor_PTO" ) 
        }else{
            isShow = show
        }setShow(isShow)
    },[filterJson]) 
    return(
   show.card ?
    <IonChip  className="chip stack" >
        <IonLabel color={"secondary"}>
            <FontAwesomeIcon icon={ props.door_status ==="1"?faDoorOpen:faDoorClosed} /> &nbsp; 
            <IonLabel>{props.door_status==="1"?t('home_door_นยำื'):t('home_door_close')}</IonLabel>
        </IonLabel>  
    </IonChip>:
    <></>
  )
}

const ChipTemp=(props:any)=>{
    const filterJson :any= useSelector(getFilterJson)
    const [show,setShow] = useState({card  :  true, detail :  false})

    useEffect(()=>{
       const sensors =  filterJson.filter((group:any)=> group.group_name === "Sensors")   
       let member =[] 
       let isShow = show
        if( sensors.length > 0 ){  
            member = sensors[0].member
            isShow = member.find((e:any) => e.type ===  "sensor_temperature" ) 
        }else{
            isShow = show
        }setShow(isShow)

    },[filterJson]) 
    return(
   show.card && props.temperature.indexOf("-") < 0 ?
    <IonChip  className="chip stack" >
        <IonIcon icon={thermometerOutline} color="secondary"></IonIcon>
        <IonLabel>{props.temperature}</IonLabel>
    </IonChip>:
    <></>
  )
}

export const ChipStreetView=({ device}:any)=>{
    const filterJson :any= useSelector(getFilterJson)
    const [show,setShow] = useState({card  :  true, detail :  false})
    const viewInStreetView=()=>{ 
        console.log("device ",device)
          window.open(`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${device?.latitude},${device?.longitude}&heading=-45&pitch=38&fov=80`) 
      }

    return( <IonChip  className="chip  " onClick={()=>{viewInStreetView()}} >
        <IonText  color="secondary">
          <FontAwesomeIcon icon={faStreetView}  style={{fontSize:'1.4em'}}/> 
        </IonText>  
    </IonChip>)
}

export const ChipCutEngine=({ device}:any)=>{
    const filterJson :any= useSelector(getFilterJson) 
    const dispatch = useDispatch()
    const openDialog=(option:any)=>{ 
        dispatch(setDeviceModalOpt(option))
        dispatch(setDevice(device))
        setTimeout(()=>{
          dispatch(setDeviceModal(true))
        },100)
      }
      

    return( <IonChip  className="chip stack" onClick={()=>{openDialog("cmd")}}> 
        { device.canCutEngin  &&  <IonIcon icon={terminal} color={device?.status_engin===0?"danger":"success"} /> } 
    </IonChip>)
}


const MileInput=(props:any)=>{
    const filterJson :any= useSelector(getFilterJson)
    const [show,setShow] = useState({card  :  true, detail :  false}) 
    const [editmile,setEditMile] = useState(false)
    const [mile,setMile] = useState(0)

    useEffect(()=>{
       const sensors =  filterJson.filter((group:any)=> group.group_name === "Sensors")   
       setMile(props.mile)
       let member =[] 
       let isShow = show
        if( sensors.length > 0 ){  
            member = sensors[0].member
            isShow = member.find((e:any) => e.type ===  "sensor_mile" ) 
        }else{
            isShow = show
        }setShow(isShow)

    },[filterJson])  

   return(<>{ 
    props.input ? 
        <div className='input' style={{cursor:"pointer"}} onClick={()=>setEditMile(true)} >
            <IonItem mode='ios' lines='none'  > 
                <IonLabel position="fixed" slot="start">{t('home_card_mile')}</IonLabel>
            <IonInput mode='ios' disabled aria-label=""  placeholder="Mile" value={props.mile}></IonInput>
            <IonLabel slot="end"> {t('kmHUnit')} </IonLabel>
            </IonItem>
        </div>:
        <div onClick={()=>setEditMile(true)} className="set-center bg-light " style={{width:"100%" , height:"100%",borderTopLeftRadius:".5rem",fontSize:".8em",padding:"3px"}} >
          <IonLabel color={"dark"} >{props?.mile} {t('home_card_mile')} </IonLabel>
        </div>
    }
    <IonAlert
        isOpen={editmile}
        onDidDismiss={(e)=> setEditMile(false)}
        header={ t('home_edit_mileage').toString() }
        subHeader={props.car?.name}
        mode='ios'
        inputs={[{
                name: 'odometer',
                type: 'number',
				value: mile ,
                placeholder:  "odometer" 
            }]}
        buttons={[ 
			{ text: t('cancle') , role:"cancle" },
            {
                    text: t('ok')  ,
                    handler:( data:any )=> {
                        console.log('data ',data)
                        // setOdometer(data.odometer)  
                    }
                }
			]}
        />
   
    </>
  )
}



export {DeviceIcon,DeviceName,DeviceStatusNameTime,DeviceUpdateTime ,
     ChipSpeed,ChipFuel,ChipGSM,ChipGPS,ChipPTO,ChipTemp,MileInput,}