
import { IonCheckbox, IonCol, IonContent, IonIcon,IonGrid, IonItem, IonLabel, IonPopover, IonRow, IonList, IonButton, IonListHeader, IonToolbar, IonRadio, IonRadioGroup, useIonLoading, IonLoading, IonHeader, useIonToast, IonText, IonFooter, IonModal } from "@ionic/react";
import { chevronBackOutline,filterCircleOutline} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilters,  menuContentFilter,  menuFilter, setStorage, subMenuFilter } from "../actions";
import { getFilterJson, getlistTemplate, setFiltered ,setListTemplate, setLoading } from "../store/appSlice";
import "./css/FilterDevices.css"
import { getAddress, getCommand, getContentFilter, getEmei, getExpireDate, getIconFilter, getMessage, getName, getNavigation, getNotification, getPhoneNumber, getQuickTrack, getRatePoint, getRealtimeTrack, getSensorBattery, getSensorCardName, getSensorCardNumber, getSensorCutengine, getSensorFuel, getSensorGPS, getSensorGSM, getSensorMile, getSensorPTO, getSensorSpeed, getSensorTemperture, getShare, getStatusName, getStreetview, getUpdateTime, setAddress, setCommand, setContentFilter, setEmei, setExpireDate, setIconFilter, setMessage, setName, setNavigation, setNotification, setPhoneNumber, setQuickTrack, setRatePoint, setRealtimeTrack, setSensorBattery, setSensorCardName, setSensorCardNumber, setSensorCutengine, setSensorFuel, setSensorGPS, setSensorGSM, setSensorMile, setSensorPTO, setSensorSpeed, setSensorTemperature, setShare, setStatusName, setStreetview, setToAll, setUpdateTime } from "../store/filterSlice";
import i18next, { t } from "i18next";
 
const filter = [
  {
    group_name: "General",
    member: [
      { type: "icon", card: true, detail: false },
      { type: "name", card: true, detail: false },
      { type: "emei", card: true, detail: false },
      { type: "status_name", card: true, detail: false },
      { type: "update_time", card: true, detail: false },
      { type: "address", card: true, detail: false },
      { type: "navigate", card: true, detail: false },
      { type: "expired_date", card: true, detail: false },
      { type: "phone_number", card: true, detail: false },
    ],
  },
  {
    group_name: "Sensors",
    member: [
      { type: "sensor_fuel", card: true, detail: false },
      { type: "sensor_speed", card: true, detail: false },
      { type: "sensor_battery", card: true, detail: false },
      { type: "sensor_GSM", card: true, detail: false },
      { type: "sensor_GPS", card: true, detail: false },
      { type: "sensor_PTO", card: true, detail: false },
      { type: "sensor_mile", card: true, detail: false },
      { type: "sensor_temperature", card: true, detail: false },
      { type: "driver_card_name", card: true, detail: false },
      { type: "driver_card_number", card: true, detail: false },
    ],
  },
  {
    group_name: "Options",
    member: [
      { type: "quick_track", card: true, detail: false },
      { type: "share", card: true, detail: false },
      { type: "messages", card: true, detail: false },
      { type: "command", card: true, detail: false },
    ],
  },
]

export const FilterDevices:React.FC  = () => { 
  const filterJson :any= useSelector(getFilterJson)
  const [open,setOpen] = useState(false)
  const dispatch = useDispatch()
  const contentFilter :any= useSelector(getContentFilter)
  const [showLoading, setShowLoading] =  useState(false);

  useEffect(()=>{
    // dispatch(setFiltered(filter))
  },[])

  const setDeviceTheme=(e:any)=>{
    console.log('DeviceTheme ',e) 
    setStorage('content',e)
    setShowLoading(true)
    dispatch(setContentFilter(e)) 
    setFilterDeviceTheme(e)
    setShowLoading(false)
  }
 

  const setupSubContent=(menufilter:any)=>{  
    }

    const setFilterDeviceTheme=async(e:any)=>{
      let body = { 
        "filterContent": e ,
        "currentContent": e === "card" ? "list" : "card" 
       }
      //  dispatch(setLoading(true))
      let menufilter:[] = await menuContentFilter(body)  
    }

    return (  
        <div className="set-center" style={{width:'2rem',height:'2rem'}} > 
         <IonButton size="small" fill="clear"   color={open?'primary':'medium' } onClick={()=>{setOpen(!open)}} >
          <IonIcon 
              icon={filterCircleOutline} 
              id="open-popover"  
              style={{fontSize:'1.5rem',cursor:'pointer'}}  
            /> 
         </IonButton>
        
          <IonModal isOpen={open} mode='ios' color='light' className=" ion-hide-md-up" initialBreakpoint={0.98} onIonModalDidDismiss={()=>{setOpen(false)}}>
            <IonToolbar>
              <IonRow>
                <IonCol size="12"  >
                <IonLabel onClick={()=>{setOpen(false)}} color={"secondary"} className="set-center" style={{flexDirection:"row",justifyContent:"flex-start",cursor:'pointer'}}>  
                      <IonIcon icon={chevronBackOutline} color="secondary" /> <IonText>{t('close')}</IonText>
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonToolbar> 
            <IonContent className="ion-padding" >
             <div className="filter-content"  >
                <IonRow  style={{borderBottom:'1px solid #EEE',paddingBottom:'.5rem'}}>
                  <IonCol size="12" >
                     <IonLabel className="title">{t('filterTitle')}</IonLabel>
                  </IonCol>
                  <IonCol size="12"  >  
                      <IonRadioGroup 
                        value={contentFilter} 
                        onIonChange={(e)=>setDeviceTheme(e.detail.value) } 
                        style={{flexDirection:"row",justifyContent:"flex-start"}}
                        className="set-center"
                      >
                        <IonRadio mode="ios" value={'card'}  ></IonRadio><IonLabel >&nbsp; Card Content</IonLabel>  &nbsp;&nbsp;&nbsp;
                        <IonRadio mode="ios" value={'list'}  ></IonRadio><IonLabel >&nbsp; List Content</IonLabel> 
                      </IonRadioGroup> 
                  </IonCol>  
                </IonRow>

                <FiltersContent setupSubContent={(e:any)=>{setupSubContent(e)}}/>
              </div> 
            </IonContent> 
          </IonModal>

           <IonPopover  showBackdrop={false} isOpen={open} onIonPopoverDidDismiss={()=>{setOpen(false)}}
            mode='ios' color='light'  alignment='start'  size="auto" side="right" trigger="open-popover"
            className="filter ion-hide-md-down ion-padding"  
            backdropDismiss={false} 
            keepContentsMounted={true}  
           >
             <IonHeader className="ion-no-border">
             <IonToolbar>
              <IonRow>
                <IonCol size="12"  >
                <IonLabel onClick={()=>{setOpen(false)}} color={"secondary"} className="set-center" style={{flexDirection:"row",justifyContent:"flex-start",cursor:'pointer'}}>  
                      <IonIcon icon={chevronBackOutline} color="secondary" /> <IonText>{t('close')}</IonText>
                  </IonLabel>
                </IonCol>
              </IonRow>
             </IonToolbar> 
             </IonHeader>
            <IonContent color='light'   className="ion-padding" > 
             <div className="filter-content"  >
                <IonRow  style={{borderBottom:'1px solid #EEE',paddingBottom:'.5rem'}}>
                  <IonCol size="12" >
                     <IonLabel className="title">{t('filterTitle')}</IonLabel>
                  </IonCol>
                  <IonCol size="12"  >  
                      <IonRadioGroup 
                        value={contentFilter} 
                        onIonChange={(e)=>setDeviceTheme(e.detail.value) } 
                        style={{flexDirection:"row",justifyContent:"flex-start"}}
                        className="set-center"
                      >
                        <IonRadio mode="ios" value={'card'}  ></IonRadio><IonLabel >&nbsp; Card Content</IonLabel>  &nbsp;&nbsp;&nbsp;
                        <IonRadio mode="ios" value={'list'}  ></IonRadio><IonLabel >&nbsp; List Content</IonLabel> 
                      </IonRadioGroup> 
                  </IonCol>  
                </IonRow>

                <FiltersContent setupSubContent={(e:any)=>{setupSubContent(e)}}/>
              </div>
            </IonContent> 
           </IonPopover>
 
        </div>
      ); 
    }
     
export default FilterDevices
 

const FiltersContent = ({setupSubContent}:any) => {
  const dispatch = useDispatch() 
  const template :any= useSelector(getContentFilter)
  const [toast] = useIonToast()
   
  const [generral,setGenerral] = useState({name:'',translate:{th:"",en:""},choices:[]})
  const [sensors,setSensor] = useState({name:'',translate:{th:"",en:""},choices:[]})
  const [option,setOption] = useState({name:'',translate:{th:"",en:""},choices:[]}) 
  // const iconFilter :any= useSelector(getIconFilter)
  const name :any= useSelector(getName)
  const imei :any= useSelector(getEmei)
  const statusName :any= useSelector(getStatusName)
  const updateTime :any= useSelector(getUpdateTime)
  const address :any= useSelector(getAddress)
  const navigationFilter :any= useSelector(getNavigation)
  const expiredDate :any= useSelector(getExpireDate)
  const phoneNumber :any= useSelector(getPhoneNumber)
  const ratePoint :any= useSelector(getRatePoint)

  const stsName :any= useSelector(getStatusName) 
  const iconShow :any= useSelector(getIconFilter) 
  const showName :any= useSelector(getName)  
  const cutEngine:any= useSelector(getSensorCutengine)
  const streetView:any= useSelector(getStreetview)
  const notification:any= useSelector(getNotification)

  const sensorFuel :any= useSelector(getSensorFuel)  
  const sensorSpeed :any= useSelector(getSensorSpeed) 
  const sensorBattery :any= useSelector(getSensorBattery) 
  const sensorGSM :any= useSelector(getSensorGSM )  
  const sensorGPS :any= useSelector(getSensorGPS )  
  const sensorPTO :any= useSelector(getSensorPTO )  
  const sensorMile :any= useSelector(getSensorMile) 
  const sensorTemp :any= useSelector(getSensorTemperture )  
  const sensorCarsName :any= useSelector(getSensorCardName) 
  const sensorCarsNumber :any= useSelector(getSensorCardNumber)  

  const quicktrack =  useSelector(getQuickTrack  )
  const share =  useSelector(getShare  )
  const messsage =   useSelector(getMessage)  
  const cmd =  useSelector(getCommand ) 
  const realtimeTrack :any= useSelector(getRealtimeTrack)
 
  const getFilter=async()=>{
    const res:any = await  getFilters({})
   
    setGenerral(res?.general)
    setSensor(res?.sensors)
    setOption(res?.options)   
  }

  useEffect(()=>{ 
    getFilter() 
  },[  ])
 
  const getMenuFilter=async()=>{
    const menufilter = await menuFilter({}); 
    console.log("getMenuFilter ",template ,menufilter )
    menufilter.sort((a:any, b:any) =>  a.type - b.type)  
    let iconc = menufilter.find((e:any)=> e.type ===10 )
    let iconl = menufilter.find((e:any)=> e.type === 110)

    let namec = menufilter.find((e:any)=>  e.type === 11)
    let namel = menufilter.find((e:any)=> e.type ===111 )

    let imeic = menufilter.find((e:any)=> e.type ===12  )
    let imeil = menufilter.find((e:any)=> e.type === 112)

    let addressc = menufilter.find((e:any)=> e.type ===13  )
    let addressl = menufilter.find((e:any)=> e.type === 113)

    let stsNamec = menufilter.find((e:any)=> e.type ===14 )
    let stsNamel = menufilter.find((e:any)=> e.type === 114)

    let updateTimec = menufilter.find((e:any)=> e.type ===15  )
    let updateTimel = menufilter.find((e:any)=> e.type === 115)

    let navc = menufilter.find((e:any)=> e.type ===16  )
    let navl = menufilter.find((e:any)=> e.type === 116)

    let expirec = menufilter.find((e:any)=> e.type ===17  )
    let expirel = menufilter.find((e:any)=> e.type === 117)

    let phonec = menufilter.find((e:any)=> e.type ===18 )
    let phonel = menufilter.find((e:any)=> e.type === 118)

    let fuelc = menufilter.find((e:any)=> e.type ===19 )
    let fuell = menufilter.find((e:any)=> e.type === 119)

    let speedc = menufilter.find((e:any)=> e.type ===20 )
    let speedl = menufilter.find((e:any)=> e.type === 120)

    let batteryc = menufilter.find((e:any)=> e.type ===21  )
    let batteryl = menufilter.find((e:any)=> e.type === 121)

    let gsmc = menufilter.find((e:any)=> e.type ===22  )
    let gsml = menufilter.find((e:any)=> e.type === 122)

    let gpsc = menufilter.find((e:any)=> e.type ===23 )
    let gpsl = menufilter.find((e:any)=> e.type === 123)

    let milec = menufilter.find((e:any)=> e.type ===24 )
    let milel = menufilter.find((e:any)=> e.type === 124)

    let tempc = menufilter.find((e:any)=> e.type ===25 )
    let templ = menufilter.find((e:any)=> e.type === 125)
    let driverMessc = menufilter.find((e:any)=> e.type ===26 )
    let driverMessl = menufilter.find((e:any)=>  e.type === 126)
    let driverIdc = menufilter.find((e:any)=> e.type ===27  )
    let driverIdl = menufilter.find((e:any)=> e.type === 127)
    let quickTrackc = menufilter.find((e:any)=> e.type ===28 )
    let quickTrackl = menufilter.find((e:any)=> e.type === 128)
    let sharec = menufilter.find((e:any)=> e.type ===29 )     
    let sharel = menufilter.find((e:any)=> e.type === 129)
    let messagec = menufilter.find((e:any)=> e.type ===30 )
    let messagel = menufilter.find((e:any)=> e.type ===30 )
    let trackRealtimec = menufilter.find((e:any)=> e.type ===31 )
    let trackRealtimel = menufilter.find((e:any)=> e.type === 131)
    let ptoc = menufilter.find((e:any)=> e.type === 32 )
    let ptol = menufilter.find((e:any)=> e.type === 132)
    let cmdc = menufilter.find((e:any)=> e.type === 33)
    let cmdl = menufilter.find((e:any)=> e.type === 133)
  
    dispatch(setIconFilter({card: iconc.card ,list: iconl.list ,type: template === "card" ? iconc?.type : iconl?.type }))
    dispatch(setName({card: namec.card,list: namel.list , type: template === "card" ? namec?.type : namel?.type})) 
    dispatch(setEmei({card: imeic.card,list: imeil.list ,type:  template === "card" ? imeic?.type : imeil?.type })) 
    dispatch(setAddress({card: addressc.card,list:addressl.list ,type: template === "card" ? addressc?.type : addressl?.type  })) 
    dispatch(setStatusName({card: stsNamec.card,list:stsNamel.list ,type: template === "card" ? stsNamec?.type : stsNamel?.type  })) 
    dispatch(setUpdateTime({card:updateTimec.card,list:updateTimel.list ,type:template === "card" ? updateTimec?.type : updateTimel?.type  })) 
    dispatch(setNavigation({card:navc.card,list:navl.list  ,type: template === "card" ? navc?.type : navl?.type  })) 
    dispatch(setExpireDate({card:expirec.card,list:expirel.list ,type:template === "card" ? expirec?.type : expirel?.type  })) 
    dispatch(setPhoneNumber({card:phonec.card,list:phonel.list ,type: template === "card" ? phonec?.type : phonel?.type  })) 
    // dispatch(setRatePoint({card:e.card,list:updateTime.list})) 
  
    dispatch(setSensorFuel({card: fuelc.card,list: fuell.list ,type: template === "card" ? fuelc?.type : fuell?.type  }))   
    dispatch(setSensorSpeed({card: speedc.card,list: speedl.list ,type:  template === "card" ? speedc?.type :speedl?.type }))  
    dispatch(setSensorBattery({card: batteryc.card,list: batteryl.list ,type:  template === "card" ? batteryc?.type :batteryl?.type }))  
    dispatch(setSensorGSM({card: gsmc.card,list: gsml.list ,type: template === "card" ? gsmc?.type :gsml?.type }))    
    dispatch(setSensorGPS({card: gpsc.card,list: gpsl.list ,type: template === "card" ? gpsc?.type :gpsl?.type }))      
    dispatch(setSensorMile({card: milec.card,list: milel.list ,type:  template === "card" ? milec?.type :milel?.type  }))  
    dispatch(setSensorTemperature({card: tempc.card,list: templ.list ,type:  template === "card" ? tempc?.type :templ?.type  }))    
    dispatch(setSensorCardName({card: driverMessc.card,list: driverMessl.list ,type:  template === "card" ? driverMessc?.type :driverMessl?.type }))  
    dispatch(setSensorCardNumber({card: driverIdc.card,list: driverIdl.list   ,type:   template === "card" ? driverIdc?.type :driverIdl?.type }))   
  
    dispatch(setQuickTrack({card: quickTrackc.card,list: quickTrackl.list  ,type:  template === "card" ? quickTrackc?.type :quickTrackl?.type }))   
    dispatch(setShare({card: sharec.card,list: sharel.list  ,type:  template === "card" ? sharec?.type :sharel?.type }))   
    dispatch(setMessage({card: messagec.card,list: messagel.list   ,type:  template === "card" ? messagec?.type :messagel?.type }))    
    dispatch(setRealtimeTrack({card: trackRealtimec.card,list:trackRealtimel.list  ,type:  template === "card" ? trackRealtimec?.type :trackRealtimel?.type })) 
    dispatch(setSensorPTO({card: ptoc.card,list: ptol.list ,type:  template === "card" ? ptoc?.type :ptol?.type  })) 
    dispatch(setCommand({card: cmdc.cardc,list: cmdl.list   ,type:  template === "card" ? cmdc?.type :cmdl?.type }))  
  }
 
  const templateId=(el:any)=>{ 
    console.log(" templateId ", el)
    const islist = template=== "list" ? true :false 
    return islist &&  parseInt(el.type) < 100  ?  parseInt(el.type)+100 :
               !islist &&  parseInt(el.type) > 100  ?  parseInt(el.type)-100 : parseInt(el.type) 
  }
  const isCard=(el:any)=>{
    return  template === "card" && el?.card !== null ?  el?.card : (el?.list !== null ? el?.list :false )
  }

  const save=async ()=>{
    // dispatch(setFiltered(filter)) 
      //save card
      dispatch(setLoading(true)) 
      console.log("template ",template) 
      let body:any = []
      body =[...body, {menuId: templateId(iconShow),checked:  isCard(iconShow) } ]
      body =[...body, {menuId: templateId(name)       ,checked:  isCard(name ) } ]
      body =[...body, {menuId: templateId(imei)       ,checked:  isCard( imei ) } ]
      body =[...body, {menuId: templateId(statusName) ,checked:  isCard( statusName ) } ]
      body =[...body, {menuId: templateId(updateTime) ,checked:  isCard( updateTime ) } ]
      body =[...body, {menuId: templateId(address)    ,checked:  isCard( address ) } ]
      body =[...body, {menuId: templateId(navigationFilter),checked:    isCard( navigationFilter )  } ]
      body =[...body, {menuId: templateId(expiredDate),checked:  isCard( expiredDate )} ]
      body =[...body, {menuId: templateId(phoneNumber),checked:  isCard( phoneNumber )} ] 
      body =[...body, {menuId: templateId(sensorFuel) ,checked:  isCard( sensorFuel ) } ]
      body =[...body, {menuId: templateId(sensorSpeed),checked:  isCard( sensorSpeed ) } ]
      body =[...body, {menuId: templateId(sensorBattery),checked:isCard( sensorBattery ) } ]
      body =[...body, {menuId: templateId(sensorGSM)  ,checked:  isCard( sensorGSM ) } ]
      body =[...body, {menuId: templateId(sensorGPS)  ,checked:  isCard( sensorGPS ) } ]
      body =[...body, {menuId: templateId(sensorPTO)  ,checked:  isCard( sensorPTO )} ]
      body =[...body, {menuId: templateId(sensorMile) ,checked:  isCard( sensorMile ) } ]
      body =[...body, {menuId: templateId(sensorTemp) ,checked:  isCard( sensorTemp )} ]
      body =[...body, {menuId: templateId(sensorCarsName) ,checked:  isCard( sensorCarsName ) } ]
      body =[...body, {menuId: templateId(sensorCarsNumber),checked: isCard( sensorCarsNumber ) } ]
      body =[...body, {menuId: templateId(quicktrack) ,checked:   isCard( quicktrack )} ]
      body =[...body, {menuId: templateId(share)      ,checked:   isCard( share )} ]
      body =[...body, {menuId: templateId(messsage)   ,checked:   isCard( messsage.card  ) } ]
      body =[...body, {menuId: templateId(cmd)        ,checked:   isCard( cmd )} ]
      body =[...body, {menuId: templateId(realtimeTrack),checked: isCard( realtimeTrack )} ] 

      body =[...body, {menuId: templateId(stsName)    ,checked: isCard( stsName )} ] 
      body =[...body, {menuId: templateId(iconShow)   ,checked: isCard( iconShow )} ] 
      body =[...body, {menuId: templateId(showName)   ,checked: isCard( showName )} ] 
      body =[...body, {menuId: templateId(cutEngine)  ,checked: isCard( cutEngine )} ] 
      body =[...body, {menuId: templateId(streetView) ,checked: isCard( streetView )} ] 
      body =[...body, {menuId: templateId(notification),checked:isCard( notification )} ]
 
      console.log("body ",body  )
      const menufilter = await subMenuFilter(body)
      menufilter.sort((a:any, b:any) =>  a.type - b.type) 
      console.log("menufilter res ",menufilter)
      if(menufilter !==false){ 
        toast({
          message:"Saved your filter configuration.",
          mode:'ios',
          position:'top',
          color:'success',
          duration: 3000
        })
        dispatch(setLoading(false)) 
        getMenuFilter()
        // return setupSubContent(menufilter)
      }
      dispatch(setLoading(false))
  }

  return(
    <div>  
      <IonGrid > 
        <IonList >
            <IonListHeader>
              <IonLabel> 
                {i18next.language=="th"?  generral?.translate.th : generral?.translate.en }
              </IonLabel> 
            </IonListHeader>  
              <IonItem lines="none" color='light' className="display-types " >
                <IonCheckbox  aria-label=""  slot="start" checked={iconShow.card} 
                  onIonChange={(e)=>{dispatch(setIconFilter({card:e.target.checked,list:iconShow.list ,type:  10   }))}}  >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"   aria-label=""   checked={iconShow.list}  
                 onIonChange={(e)=>{dispatch(setIconFilter({card:iconShow.card  ,list:e.target.checked ,type:  110 }))}}  
                 ></IonCheckbox>
                <IonLabel >{t('filter_icon')}</IonLabel> 
              </IonItem>   
              
              <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={name.card} 
                  onIonChange={(e)=>{console.log("e.target.checked ",e.target.checked) ;dispatch(setName({card:e.target.checked,list:name.list,type:  11 }))}}  >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={name.list}  
                 onIonChange={(e)=>{ dispatch(setName({card:name.card  ,list:e.target.checked!,type:  111 })) ;console.log("name ",name) }}  
                 ></IonCheckbox>
                <IonLabel >{t('filter_name')}</IonLabel>
              </IonItem> 

              <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""   slot="start" checked={imei.card} 
                  onIonChange={(e)=>{dispatch(setEmei({card:e.target.checked,list:imei.list ,type:  12 }))}} 
                 >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"   aria-label=""   checked={imei.list}  
                 onIonChange={(e)=>{dispatch(setEmei({card:imei.card,list:  e.target.checked ,type:  112 }))}} 
                ></IonCheckbox>
                <IonLabel >{t('filter_imei')}</IonLabel>
              </IonItem>   
              <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={address.card}
                  onIonChange={(e)=>{dispatch(setAddress({card:e.target.checked,list:address.list ,type:  13 }))}} 
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"   aria-label=""   checked={address.list}  
                 onIonChange={(e)=>{dispatch(setAddress({card: address.card,list:  e.target.checked ,type:  113 }))}} 
                ></IonCheckbox>
                <IonLabel >{t('filter_address')}</IonLabel>
              </IonItem>  
              <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={statusName.card}  
                 onIonChange={(e)=>{dispatch(setStatusName({card:e.target.checked,list:statusName.list ,type:  14 }))}} 
                >   <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={statusName.list} 
                 onIonChange={(e)=>{dispatch(setStatusName({card: statusName.card ,list: e.detail.checked ,type:  114 }))}} 
                ></IonCheckbox>
                <IonLabel >{t('filter_statusName')}</IonLabel>
              </IonItem>  

              <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={updateTime.card} 
                  onIonChange={(e)=>{dispatch(setUpdateTime({card:e.target.checked,list:updateTime.list ,type:  15 }))}} 
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={updateTime.list} 
                  onIonChange={(e)=>{dispatch(setUpdateTime({card: updateTime.card ,list: e.detail.checked ,type:  115  }))}} 
                ></IonCheckbox>
                <IonLabel >{t('filter_updateTime')}</IonLabel>
              </IonItem> 

              <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={navigationFilter.card} 
                   onIonChange={(e)=>{dispatch(setNavigation({card:e.target.checked,list:navigationFilter.list ,type: 16}))}} 
                >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={navigationFilter.list} 
                  onIonChange={(e)=>{dispatch(setNavigation({card: navigationFilter.card ,list: e.detail.checked  ,type: 116}))}} 
                ></IonCheckbox>
                <IonLabel >{t('filter_navigateToDevice')}</IonLabel>
              </IonItem>  

              <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={expiredDate.card}  
                   onIonChange={(e)=>{dispatch(setExpireDate({card:e.target.checked,list:expiredDate.list ,type: 17}))}} 
                >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"    aria-label=""   checked={expiredDate.list}  
                  onIonChange={(e)=>{dispatch(setExpireDate({card: expiredDate.card ,list: e.detail.checked ,type: 117}))}} 
                ></IonCheckbox>
                <IonLabel >{t('filter_expireDate')}</IonLabel>
              </IonItem>   

              <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={phoneNumber.card}   
                   onIonChange={(e)=>{dispatch(setPhoneNumber({card:e.target.checked,list:phoneNumber.list ,type: 18 }))}} >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start" disabled aria-label=""   checked={phoneNumber.list}  
                  onIonChange={(e)=>{dispatch(setPhoneNumber({card: phoneNumber.card ,list: e.detail.checked ,type: 118 }))}}
                   ></IonCheckbox>
                <IonLabel >{t('filter_phoneNumber')}</IonLabel>
              </IonItem>  

              {/* <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={ratePoint.card}   
                   onIonChange={(e)=>{dispatch(setRatePoint({card:e.target.checked,list:ratePoint.list ,type: '16'}))}} >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={ratePoint.list}  
                  onIonChange={(e)=>{dispatch(setRatePoint({card: ratePoint.card ,list: e.detail.checked }))}}
                   ></IonCheckbox>
                <IonLabel > Rate Pont </IonLabel>
              </IonItem>    */}

        </IonList> 
        <IonList >
          <IonListHeader>
            <IonLabel>   {i18next.language=="th"?  sensors?.translate.th : sensors?.translate.en } </IonLabel> 
          </IonListHeader> 
          
            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorFuel.card} 
                   onIonChange={(e)=>{dispatch(setSensorFuel({card:e.target.checked,list:sensorFuel.list  ,type: 19 }))}} 
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorFuel.list}  
                  onIonChange={(e)=>{dispatch(setSensorFuel({card: sensorFuel.card ,list: e.detail.checked ,type: 119 }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_sensorFuel')} </IonLabel>
            </IonItem>   

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorSpeed.card}   
                   onIonChange={(e)=>{dispatch(setSensorSpeed({card:e.target.checked,list: sensorSpeed.list  ,type: 20}))}} 
                >   <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorSpeed.list}   
                  onIonChange={(e)=>{dispatch(setSensorSpeed({card:  sensorSpeed.card ,list: e.detail.checked  ,type: 120 }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_sensorSpeed')} </IonLabel>
            </IonItem>   

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorBattery.card}  
                 onIonChange={(e)=>{dispatch(setSensorBattery({card:e.target.checked,list: sensorBattery.list  ,type: 21 }))}} 
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorBattery.list}  
                   onIonChange={(e)=>{dispatch(setSensorBattery({card:  sensorBattery.card ,list: e.detail.checked ,type: 121 }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_sensorBattery')}</IonLabel>
            </IonItem>    

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorGSM.card}  
                  onIonChange={(e)=>{dispatch(setSensorGSM({card:e.target.checked,list:sensorGSM.list  ,type: 22 }))}} 
                > <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorGSM.list}  
                 onIonChange={(e)=>{dispatch(setSensorGSM({card: sensorGSM.card ,list: e.detail.checked ,type: 122 }))}} 
                ></IonCheckbox>
                <IonLabel >  {t('filter_sensorGSM')} </IonLabel>
            </IonItem> 

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorGPS.card} 
                  onIonChange={(e)=>{dispatch(setSensorGPS({card:e.target.checked,list:sensorGPS.list  ,type: 23 }))}} 
                 >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorGPS.list}
                   onIonChange={(e)=>{dispatch(setSensorGPS({card: sensorGPS.card ,list: e.detail.checked ,type: 123 }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_sensorGPS')} </IonLabel>
            </IonItem> 

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorPTO.card} 
                 onIonChange={(e)=>{dispatch(setSensorPTO({card:e.target.checked,list:sensorPTO.list ,type: 32 }))}} 
                >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorPTO.list}  
                   onIonChange={(e)=>{dispatch(setSensorPTO({card: sensorPTO.card ,list: e.detail.checked ,type: 132 }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_sensorPTO')} </IonLabel>
            </IonItem> 

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorMile.card} 
                 onIonChange={(e)=>{dispatch(setSensorMile({card:e.target.checked,list: sensorMile.list ,type: 24 }))}}  
                > <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start" disabled aria-label=""   checked={sensorMile.list}  
                   onIonChange={(e)=>{dispatch(setSensorMile({card:  sensorMile.card ,list: e.detail.checked ,type: 124 }))}} 
                 ></IonCheckbox>
                <IonLabel > {t('filter_sensorMile')} </IonLabel>
            </IonItem> 

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorTemp.card}   
                  onIonChange={(e)=>{dispatch(setSensorTemperature({card:e.target.checked,list: sensorTemp.list ,type: 25 }))}}  
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorTemp.list}  
                 onIonChange={(e)=>{dispatch(setSensorTemperature({card:  sensorTemp.card ,list: e.detail.checked ,type: 125 }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_sensorTemp')} </IonLabel>
            </IonItem> 
            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorCarsName.card} 
                  onIonChange={(e)=>{dispatch(setSensorCardName({card:e.target.checked,list: sensorCarsName.list ,type: 26}))}}  
                >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorCarsName.list}  
                   onIonChange={(e)=>{dispatch(setSensorCardName({card:  sensorCarsName.card ,list: e.detail.checked ,type: 126 }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_sensorDriverName')} </IonLabel>
            </IonItem> 
            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorCarsNumber.card}  
                  onIonChange={(e)=>{dispatch(setSensorCardNumber({card:e.target.checked,list: sensorCarsNumber.list ,type: 27  }))}}  
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorCarsNumber.list}   
                  onIonChange={(e)=>{dispatch(setSensorCardNumber({card:  sensorCarsNumber.card ,list: e.detail.checked ,type: 127 }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_sensorDriverID')} </IonLabel>
            </IonItem>  
            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={cutEngine.card}  
                  onIonChange={(e)=>{dispatch(setSensorCutengine({card:e.target.checked,list: cutEngine.list ,type: 34 }))}}  
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={cutEngine.list}   
                  onIonChange={(e)=>{dispatch(setSensorCutengine({card:  cutEngine.card ,list: e.detail.checked ,type: 134 }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_cut-engine')} </IonLabel>
            </IonItem>  
          </IonList> 
          <IonList  >
            <IonListHeader>
              <IonLabel> {i18next.language=="th"?  option?.translate.th : option?.translate.en } </IonLabel>  
            </IonListHeader> 
            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={quicktrack.card} 
                   onIonChange={(e)=>{dispatch(setQuickTrack({card:e.target.checked,list: quicktrack.list  ,type:  28 }))}}  
                 >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={quicktrack.list}  
                  onIonChange={(e)=>{dispatch(setQuickTrack({card:  quicktrack.card ,list: e.detail.checked ,type: 128 }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_quickTrack')} </IonLabel>
            </IonItem> 

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={share.card}  
                  onIonChange={(e)=>{dispatch(setShare({card:e.target.checked,list: share.list ,type: 29 }))}}   
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={share.list} 
                  onIonChange={(e)=>{dispatch(setShare({card:  share.card ,list: e.detail.checked ,type: 129 }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_share')} </IonLabel>
            </IonItem>     

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={messsage.card}   
                   onIonChange={(e)=>{dispatch(setMessage({card:e.target.checked,list: messsage.list ,type: 30 }))}}  
                > <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={messsage.list}  
                    onIonChange={(e)=>{dispatch(setMessage({card:  messsage.card ,list: e.detail.checked ,type: 130 }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_message')} </IonLabel>
            </IonItem>    

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={cmd.card}  
                  onIonChange={(e)=>{dispatch(setCommand({card:e.target.checked,list: cmd.list ,type: 33 }))}}  
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={cmd.list}  
                  onIonChange={(e)=>{dispatch(setCommand({card:  cmd.card ,list: e.detail.checked ,type: 133 }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_command')}  </IonLabel>
            </IonItem>  
 
            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={realtimeTrack.card}  
                  onIonChange={(e)=>{dispatch(setRealtimeTrack({card:e.target.checked,list: realtimeTrack.list ,type: 31 }))}}  
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={realtimeTrack.list}  
                  onIonChange={(e)=>{dispatch(setRealtimeTrack({card:  realtimeTrack.card ,list: e.detail.checked ,type: 131 }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_realtimeTrack')}  </IonLabel>
            </IonItem>

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={streetView.card}  
                  onIonChange={(e)=>{dispatch(setStreetview({card:e.target.checked,list: streetView.list ,type: 35 }))}}  
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={streetView.list}  
                  onIonChange={(e)=>{dispatch(setStreetview({card:  streetView.card ,list: e.detail.checked ,type: 135 }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_streetview')}  </IonLabel>
            </IonItem>
            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={notification.card}  
                  onIonChange={(e)=>{dispatch(setNotification({card:e.target.checked,list: notification.list ,type: 36 }))}}  
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={notification.list}  
                  onIonChange={(e)=>{dispatch(setNotification({card:  notification.card ,list: e.detail.checked ,type: 136 }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_notification')}  </IonLabel>
            </IonItem>
             

        </IonList> 
      </IonGrid> 
      <IonFooter mode="ios" className="ion-padding">
        <IonButton  size="default" onClick={()=>save()}>{t('save')}</IonButton>
      </IonFooter>
     </div>
    )
} 

