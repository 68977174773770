import { IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonAlert, useIonLoading, useIonToast } from "@ionic/react";
import { chevronBackOutline, closeCircle, createOutline, sync, timeOutline, trashBinOutline } from "ionicons/icons";
import NavBar from "../components/NavBar";
import { useHistory } from "react-router";

import "./css/GroupDevice.css"
import { useEffect, useState } from "react";
import { t } from "i18next";
import { addGroup, allDevices, delGroup, deviceInGroup,  deviceRemovegroup, deviceUpdateGroup, editGroup, groupDevices } from "../actions";
import { GroupDevice } from "./schema";
import { useSelector } from "react-redux";
import { getBackup, setDevice } from "../store/appSlice";
 

const GroupDevices=()=>{
    const history = useHistory()
    const [groups , setGroups] = useState<GroupDevice[]>([])
    const [selectgroup,setSelectGroup] = useState<GroupDevice|null>(null)
    const [devices ,setDevices] = useState([])
    const backup :any= useSelector(getBackup)
   
    const [dInGroup ,setDInGroup] = useState([])
    // const [dOutGroup ,setDOutGroup] = useState([])
    const [ionalert] = useIonAlert();
    const [iontoast] = useIonToast();
    const [loading,setLoading] = useState(false);
 
    const presentToast = (position: 'top' | 'middle' | 'bottom', message:any,color:any) => {
        iontoast({
          message: message,
          duration: 3000,
          position: position,
          color: color ,
          mode:"ios"
        });
    };
    
    const getgroupdevice=async()=>{ 
        const gr = await groupDevices()
        setGroups(gr) 
    }
    useEffect(()=>{ 
        getgroupdevice()
    },[selectgroup])

    const aletAddGroup=()=>{
        ionalert({
            mode:"ios",
            header: 'กลุ่มอุปกรณ์', 
            message: 'เพิ่มกลุ่มอุปกรณ์ใหม่',
            inputs:[{
                name: 'name', 
                placeholder: "ชื่อกลุ่ม"
            }],
            buttons: [
                {
                    text: t("close"),
                    role: 'cancel',
                    handler: () => {
                      console.log('Alert canceled');
                    },
                  },
                  {
                    text: t("add"),
                    role: 'confirm',
                    handler: (data:any) => { 
                         console.log("data ",data.name)
                         setLoading(true)
                         sendAddGroup(data.name)
                         setTimeout(()=>{setLoading(false)}, 500)
                    },
                  },
            ] 
          })
    }

    const sendAddGroup=async(name:any)=>{ 
        setLoading(true)
        const res = await addGroup({name})
        if(res.result){
            presentToast("top",`เพิ่ม ${name} สำเร็จ !! `,"success")
            getgroupdevice()
        }else{ 
            presentToast("top",`เพิ่ม ${name} ไม่สำเร็จ !! `,"danger")
            setTimeout(()=>{setLoading(false)}, 500)
        }
    }
    const sendEditGroup=async(groupid:any, name:any)=>{ 
        setLoading(true)
        const res = await editGroup({groupid,name})
        if(res.result){
            presentToast("top",`แก้ไข ${name} สำเร็จ !! `,"success")
            getgroupdevice()
        }else{ 
            presentToast("top",`แก้ไข ${name} ไม่สำเร็จ !! `,"danger")
            setTimeout(()=>{setLoading(false)}, 500)
        }
    }
    const sendDelGroup=async(groupid:any,name:any)=>{ 
        ionalert({
            mode:"ios",
            header: `ลบ ${name} ออกจากกลุ่มอุปกรณ์`, 
            buttons: [
                {
                    text: t("close"),
                    role: 'cancel',
                    handler: () => {  },
                  },
                  {
                    text: t("delelte"),
                    role: 'confirm',
                    handler: async () => { 
                        const res = await delGroup({groupid})
                        if(res.result){
                            presentToast("top",`ลบ ${name} สำเร็จ !! `,"success")
                            getgroupdevice()
                        }else{ 
                            presentToast("top",`ลบ ${name} ไม่สำเร็จ !! `,"danger")
                        }
                    },
                  },
            ] 
        })  
    }

    const editName=async(groupid:any,name:any)=>{ 
        ionalert({
            mode:"ios",
            header: 'กลุ่มอุปกรณ์', 
            message: `แก้ไขชื่อ กลุ่มอุปกรณ์`, 
            inputs:[{
                name: 'name', 
                placeholder: "ชื่อกลุ่ม"
            }],
            buttons: [
                {
                    text: t("close"),
                    role: 'cancel',
                    handler: () => {
                      console.log('Alert canceled');
                    },
                  },
                  {
                    text: t("add"),
                    role: 'confirm',
                    handler: (data:any) => { 
                         console.log("data ",data.name)
                         setLoading(true)
                         sendEditGroup(groupid,data.name)
                         setTimeout(()=>{setLoading(false)}, 500)
                    },
                  },
            ] 
          })
    }

    const selectGroup=async(grid:any)=>{
        setLoading(true)
        let ingroup = await deviceInGroup({groupid: grid})
        const alldevices = await allDevices({})
        setDevices(alldevices)
        // let outgroup = await deviceOutGroup({groupid: grid})
        
        console.log("ingroup ",ingroup)
        setDInGroup(ingroup)
        // setDOutGroup(outgroup)
        setTimeout(()=>{setLoading(false)}, 500)
    }

    const updataGroup=async(groupid:any,deviceId:any,name:any)=>{
       
        setLoading(true)
        const res = await deviceUpdateGroup({groupid:groupid,deviceId:deviceId})
        if(res.result){
            presentToast("top",`อัพเดตกลุ่มให้กับ ${name} สำเร็จ !! `,"success")
            selectGroup( selectgroup?.id)
        }else{ 
            presentToast("top",`อัพเดตกลุ่มให้กับ ${name} ไม่สำเร็จ !! `,"danger")
        }
        setTimeout(()=>{setLoading(false)}, 500)
    }
    const removefromgroup=async(groupid:any,deviceId:any,name:any)=>{
        setLoading(true)
        const res = await deviceRemovegroup({groupid:groupid,deviceId:deviceId})
        if(res.result){
            presentToast("top",`ลบ ${name} ออกจากกลุ่มสำเร็จ !! `,"success")
            selectGroup( selectgroup?.id)
        }else{ 
            presentToast("top",`ลบ ${name} ออกจากกลุ่มไม่สำเร็จ !! `,"danger")
        }
        setTimeout(()=>{setLoading(false)}, 500)
    }
    
    

    return(<IonPage>
        <IonLoading message={t("loading").toString()} isOpen={loading} mode="ios" />
        <IonContent  >
          {/* <IonHeader color='transparent' className='ion-no-border'   > 
            <IonToolbar color='transparent' mode='ios' className='ion-no-padding'>   
                    <IonButtons slot="start"  className='ion-hide-md-up  '  >
                    <IonButton size='small' onClick={()=>{history.goBack()}}> <IonIcon icon={chevronBackOutline} />  <IonText >Back</IonText> </IonButton>
                    </IonButtons>
                    <IonTitle style={{fontWeight:"bold",fontSize:"1.3em",marginLeft:"1rem" }} >{'Reports'}</IonTitle>
            </IonToolbar>
        </IonHeader> */}
         <div className="page-container with-toolbar"  > 

            <IonGrid>
                <IonRow>
                    <IonCol sizeXs="12" sizeSm="3"   >
                        <div  className="group-side"  > 
                        <IonRow >
                            <IonCol sizeSm="12" sizeMd="12" sizeLg="12" className="set-center-row" style={{justifyContent: 'flex-start',}}  >   
                                 <IonLabel className='title'>  <strong> รายการ กลุ่ม อุปกรณ์</strong>  </IonLabel>   
                             </IonCol>   
                             <IonCol  className="set-center-row" style={{justifyContent: 'flex-start',}}>
                                <IonButton  
                                    onClick={()=>{aletAddGroup()}}
                                    color={"primary"} mode="ios" 
                                    fill='outline' size='small' 
                                 > เพิ่ม  </IonButton>  
                                 
                             </IonCol> 
                        </IonRow>
                        <IonList>
                            {
                                groups.map((e:GroupDevice,index:any)=> 
                                 <IonItem key={index} button >
                                    <IonLabel onClick={()=>{ setSelectGroup(e);selectGroup(e.id)}} >{e.name}</IonLabel>
                                    <IonButtons>
                                        <IonButton 
                                         color={"primary"} 
                                         onClick={()=>{editName(e.id, e.name)}} 
                                        > <IonIcon icon={createOutline}  /> 
                                        </IonButton>
                                        <IonButton 
                                         color={"danger"} 
                                         onClick={()=>{sendDelGroup(e.id, e.name)}}
                                        > <IonIcon icon={trashBinOutline}  /> 
                                        </IonButton> &nbsp;&nbsp;  
                                        { selectgroup?.id  === e.id && <IonButton 
                                         color={"danger"} 
                                         onClick={()=>{ setSelectGroup(null); setDInGroup([])}}
                                        > <IonIcon icon={closeCircle}  /> 
                                        </IonButton>}
                                    </IonButtons>
                                 </IonItem>
                                )
                            }
                        </IonList>
                        </div>
                    </IonCol>
                    <IonCol  > 
                    <div  className="group-side"  > 
                    <IonRow className="ion-justify-content-around" >
                        <IonCol sizeXs="12" sizeSm="4.5" > <br/> <br/> 
                            <div className="inner-group">
                                <IonCard  className="ion-padding  ion-margin" mode="ios" >
                                    <IonLabel color={"dark"} ><strong>อุปกรณ์ อื่นๆ</strong></IonLabel><br/>
                                    <IonLabel><small>อุปกรณ์ที่ยังไม่จัดกลุ่ม  </small></IonLabel>
                                </IonCard>
                                <div className="list-device ion-margin"  >
                                   {
                                       devices.map((e:any,index:any)=>
                                        <IonItem key={index} button>
                                            <IonLabel>{e.name}</IonLabel>
                                            <IonButtons slot="end" > 
                                                <IonButton fill="clear" color={"primary"} 
                                                  onClick={()=>{updataGroup(selectgroup?.id ,e.device_id , e.name)}}
                                                 > เพิ่มเข้ากลุ่ม </IonButton>
                                            </IonButtons>
                                        </IonItem>
                                        )
                                    }
                                </div>
                            </div>
                        </IonCol>
                        <IonCol sizeXs="12" sizeSm="4.5" > <br/> <br/> 
                            <div className="inner-group">
                                <IonCard  className="ion-padding ion-margin" mode="ios" >
                                    <IonLabel color={"dark"} >อุปกรณ์  ในกลุ่ม <strong>{selectgroup ? selectgroup?.name : "( ยังไม่เลือก )"}</strong></IonLabel><br/>
                                    <IonLabel><small>อุปกรณ์ที่อยู่ในกลุ่ม   {selectgroup ? selectgroup?.name : "ยังไม่เลือก"}</small></IonLabel>
                                </IonCard> 
                                <div className="list-device  ion-margin"  > 
                                     {
                                        dInGroup.map((e:any,index:any)=>
                                        <IonItem key={index} button >
                                            <IonLabel>{e.name}</IonLabel>
                                            <IonButtons slot="end" >
                                                 <IonButton fill="clear" 
                                                 color={"danger"} 
                                                 onClick={()=>{removefromgroup( selectgroup?.id ,e.device_id, e.name)}}  >  นำออก 
                                                 </IonButton> 
                                            </IonButtons>
                                        </IonItem>
                                        )
                                    }
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                    </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
            </div>
        </IonContent>
    </IonPage>)
}
export default GroupDevices;


