import { IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonLabel, IonLoading, IonModal, IonPage, IonRow, IonSearchbar, IonText, IonTextarea, IonTitle, IonToggle, IonToolbar, useIonToast } from "@ionic/react"
import { t } from "i18next";
import { addCircle, addCircleOutline, checkmarkCircle, chevronBackOutline, closeCircle, closeOutline, copyOutline, createOutline, peopleCircleOutline, peopleOutline, trashBinOutline } from "ionicons/icons";
import NavBar from "../components/NavBar";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import moment from "moment";
import { url } from "inspector";
import { addGuest, getGuestsAll, getStorage } from "../actions";
import { GuestInfo } from "./schema";  

const Guests=()=>{
    const history = useHistory()
    const [loading,setLoading] = useState(false)
    const [modal ,setModal] = useState(false)
    const [guests ,setGuests] = useState<GuestInfo[]>([])
    const [guest , setGuest]  = useState<GuestInfo|null>(null)
    const [iontoast,dimisstoast] = useIonToast()
  
    const getguests=async()=>{
        const guestss = await getGuestsAll({}) 
        setGuests(guestss)
    } 

    const addguest=async (body:any,id:any,name:any)=>{
        console.log("saveGues ", body)
        const res:any = await addGuest(body)
        console.log("addGuest res ",res)
        if(res.result){
            iontoast  ({
                message: `${id ? "แก้ไข " :"เพิ่ม "} ผู้รับเชิญ ${name} สำเร็จ!! `,
                duration: 3000,
                position: "top",
                color:"success"
            })
            getguests()
        }else{
            iontoast  ({
                message: `${id ? "แก้ไข " :"เพิ่ม "} ผู้รับเชิญ ${name} ไม่สำเร็จ!! `,
                duration: 3000,
                position: "top",
                color:"danger"
            })
        }
    }
 
    useEffect(()=>{
        getguests()
    },[guest])
 
    return(
    <IonPage>
        <IonLoading message={t("loading").toString()} isOpen={loading} mode="ios" />
        <IonContent  >
          {/* <IonHeader color='transparent' className='ion-no-border'   > 
            <IonToolbar color='transparent' mode='ios' className='ion-no-padding'>  
                <NavBar />   
                    <IonButtons slot="start"  className='ion-hide-md-up  '  >
                    <IonButton size='small' onClick={()=>{history.goBack()}}> <IonIcon icon={chevronBackOutline} />  <IonText >Back</IonText> </IonButton>
                    </IonButtons>
                    <IonTitle style={{fontWeight:"bold",fontSize:"1.3em",marginLeft:"1rem" }} >{'Reports'}</IonTitle>
            </IonToolbar>
        </IonHeader> */}
         <div className="page-container with-toolbar"  > 

            <div className="ion-padding"   > 
                <IonGrid className="top-container ion-no-margin" style={{borderTopRadius:"0px" }}>
                    <IonRow className="ion-justify-content-center" >
                        <IonCol size="8"  className="set-center-row"  >
                            <IonLabel className="set-center-row">
                                <IonIcon icon={peopleCircleOutline}  style={{fontSize:"1.5rem"}}  /> &nbsp;
                                <IonLabel  style={{fontSize:"1.3rem"}}  >ผู้รับเชิญ</IonLabel>
                            </IonLabel>  
                            <IonSearchbar mode="ios" style={{maxWidth:"25rem"}}/>
                        </IonCol>
                        <IonCol size="4" className="set-center" style={{flexDirection:"row"}} > 
                        <IonButton mode="ios"  size="small" onClick={()=>{setModal(true)}} >  
                            <IonLabel >เพิ่มใหม่</IonLabel> &nbsp;
                            <IonIcon icon={addCircle} />    
                        </IonButton>
                        </IonCol>
                    </IonRow> 
                </IonGrid>

                <IonGrid className="  top-container" > 
                    <IonRow className="ion-justify-content-center" >
                        <IonCol size="10" > 
                            <IonRow  >
                                <IonCol size=".5" > <IonLabel style={{fontSize:".9em"}}>#</IonLabel></IonCol>
                                <IonCol size="2"  > <IonLabel style={{fontSize:".9em"}} >ชื่อ / ชื่อบริษัท</IonLabel></IonCol>
                                <IonCol size="1.5"> <IonLabel style={{fontSize:".9em"}} >ชื่อผู้ใช้งาน</IonLabel></IonCol>
                                <IonCol size="1.5"> <IonLabel style={{fontSize:".9em"}} >รหัสผ่าน</IonLabel> </IonCol>
                                <IonCol size="2"  > <IonLabel style={{fontSize:".9em"}} >เริ่มต้น</IonLabel></IonCol>
                                <IonCol size="2"  > <IonLabel style={{fontSize:".9em"}} >สิ้นสุด</IonLabel></IonCol>
                                <IonCol size=".5" className="ion-text-center"> <IonLabel style={{fontSize:".9em"}} > ใช้งาน </IonLabel></IonCol>
                                {/* <IonCol size="1.5"> <IonLabel className="ion-text-center" style={{fontSize:".9em"}} >โทเคน</IonLabel></IonCol> */}
                                <IonCol   className="ion-text-center" > 
                                 
                                </IonCol>
                            </IonRow> 
                            
                        </IonCol>
                    </IonRow>
                </IonGrid><br/>  
                <IonGrid className="  top-container" > 
                    <IonRow className="ion-justify-content-center" >
                        <IonCol size="10" >
                            {
                                guests.map((e:GuestInfo,index:any)=> 
                                <IonRow key={index} >
                                    <IonCol size=".5"> <IonLabel style={{fontSize:".9em"}} > {index+1} </IonLabel></IonCol>
                                    <IonCol size="2"> <IonLabel style={{fontSize:".9em"}}>{e.name}</IonLabel></IonCol>
                                    <IonCol size="1.5"> <IonLabel style={{fontSize:".9em"}}>{e.username}</IonLabel></IonCol>
                                    <IonCol size="1.5"> <IonLabel style={{fontSize:".9em"}}>{e.password }</IonLabel> </IonCol>
                                    <IonCol size="2"> <IonLabel style={{fontSize:".9em"}}>{moment(new Date(e.begin)).format("DD MMM YYYY")}</IonLabel></IonCol>
                                    <IonCol size="2"> <IonLabel style={{fontSize:".9em"}}>{moment(new Date(e.expire)).format("DD MMM YYYY")}</IonLabel></IonCol>
                                    <IonCol size=".5"> <IonLabel style={{fontSize:".9em"}} >  {e.enabled ? <IonIcon icon={checkmarkCircle} color="success" />: <IonIcon icon={closeCircle} color="danger" />} </IonLabel></IonCol>
                                    {/* <IonCol size="1.5" className="ion-text-center" > 
                                        <IonLabel style={{fontSize:".9em"}} className="ion-text-center" >
                                        {e.token.substring(0,2)} **** {e.token.substring(e.token.length-5, e.token.length-1)}
                                        </IonLabel>
                                    </IonCol> */}
                                    <IonCol className="ion-text-center" >  
                                        <IonButton fill="clear" mode="ios" size="small" >
                                            <IonIcon icon={trashBinOutline} />
                                        </IonButton>
                                        <IonButton fill="clear" mode="ios" size="small" 
                                         onClick={()=>{setGuest(e); setModal(true) }}
                                        >
                                            <IonIcon icon={ createOutline} />
                                        </IonButton>
                                    </IonCol>
                                </IonRow> 
                                )
                            }   
                        </IonCol>
                    </IonRow>
                </IonGrid><br/>  
            </div>
        </div>
        </IonContent>
        <ModalAddEditGuest 
            modal={modal} 
            guest={guest} 
            setModal={(e:any)=>{setModal(e); e === false && setGuest(null) }} 
            addGuest={(b:any,id,name)=>{addguest(b,id,name)}} 
        />
    </IonPage>
    )
} 
export default Guests;

interface ModalAddEditGuest{
    modal: any | Boolean
    setModal: (e:any)=> void
    addGuest: (e:any, id:any , name:any)=> void
    guest: GuestInfo | null
}
const ModalAddEditGuest=({modal,setModal,guest ,addGuest}:ModalAddEditGuest)=>{
    const history = useHistory()
    const [guestId,setGuestId] = useState<any>(null)
    const [enabled,setEnabled] = useState<any>("1")
    const [fullname,setFullName] = useState<any>("")
    const [username,setUsername] = useState<any>("")
    const [password,setPassword] = useState<any>("")
    const [begin,setBegin] = useState<any>("")
    const [expire,setExpire] = useState<any>("")
    const [token,setToken] = useState<any>("")
    const [note,setNote] = useState<any>("")
    const [guestUrl,setGuestUrl] = useState<String|any>("")
    const [wUserName , setWUsername] = useState(false)
    const [wPassword , setWPassword] = useState(false)
    const [iontoast,dimisstoast] = useIonToast()

    useEffect(()=>{
        
        setBegin(moment().format("YYYY-MM-DD"))
        setExpire(moment().add(7,"day").format("YYYY-MM-DD"))
        setGuestUrl(window.location.href.replace(history.location.pathname ,`:8894/signin?guest=true`)) 
        
        if(guest){
            setGuestId(guest.id)
            setEnabled(guest.enabled ? "1":"0")
            setFullName(guest.name)
            setUsername(guest.username)
            setPassword(guest.password) 
            setToken(guest.token)
            setNote(guest.note) 
            setBegin(moment(guest.begin).format("YYYY-MM-DD"))
            setExpire(moment(guest.expire).format("YYYY-MM-DD"))
            setGuestUrl(guest.url)
             
        }
    },[guest ,  username ,password ])

    const saveGuestInfo=async()=>{
        // let pasEn =  sha1(password )  
        const profile = await getStorage("profile")
        const body ={
            id: guestId ,
            enabled:enabled === "1" ? true : false ,
            name:fullname ,
            username:  btoa("username:"+username) ,
            password:  btoa("password:"+password) ,
            begin: new Date(begin).getTime() ,
            expire: new Date(expire).getTime() ,
            token: token ,
            note: note,
            url: guest ? guestUrl : `${guestUrl}&owner=${btoa(profile.id)}&username=${username}&password=${password}`, 
        } 
       return addGuest(body,guestId,fullname) 
    }
    const dimissDialog=async ()=>{
        const profile = await getStorage("profile")
        setEnabled("1")
        setFullName("")
        setUsername("")
        setPassword("") 
        setToken("")
        setNote("") 
        setGuestUrl("")
    } 

   
    return(
    <IonModal mode="ios"  className="dialog-add-employee"  isOpen={modal} onIonModalDidDismiss={()=>{dimissDialog();setModal(false)}} >
        <IonContent className="ion-padding" >
        <IonGrid>
            <IonRow  className="border-bottom" >
                <IonCol size="11"  className="set-center-row  " style={{justifyContent:"flex-start",fontSize:"1.15em"}} >
                    <IonIcon icon={addCircleOutline} style={{fontSize:"1.5em"}} /> &nbsp;
                    <IonLabel>ผู้รับเชิญ</IonLabel>
                </IonCol>
                <IonCol size="1" >
                    <IonButton fill="clear" onClick={()=>{setModal(false)}} > <IonIcon icon={closeOutline} />  </IonButton>
                </IonCol>
            </IonRow><br/>

            <IonRow className="border-bottom" >
                <IonCol size="12" > <IonLabel>ข้อมูลผู้รับเชิญ</IonLabel> </IonCol>
                <IonCol size="12"> 
                    <IonToggle checked={enabled === "0"?false:true  } onIonChange={(e)=>{setEnabled(e.detail.checked?"1":"0")}} />
                </IonCol>
                <IonCol size="12"> 
                    <div className="input">
                        <IonInput 
                            mode="ios" 
                            label="ชื่อ / ชื่อบริษัท" 
                            labelPlacement="stacked" 
                            placeholder="ชื่อ / ชื่อบริษัท" 
                            value={fullname} 
                            onIonChange={(e)=>{setFullName(e.detail.value!)}} 
                        ></IonInput>
                    </div>
                </IonCol>
                <IonCol size="6"> 
                    <div className="input">
                        <IonInput 
                            mode="ios" label="ชื่อผู้ใช้"
                            placeholder="ชื่อผู้ใช้"  
                            labelPlacement="stacked" 
                            value={username} 
                            onIonChange={(e)=>{setUsername(e.detail.value!)}} >
                        </IonInput>
                    </div>
                </IonCol>
                <IonCol size="6"> 
                    <div className="input">
                        <IonInput 
                            mode="ios" label="รหัสผ่าน" 
                            placeholder="รหัสผ่าน"  
                            labelPlacement="stacked"  
                            value={password} 
                            onIonChange={(e)=>{setPassword(e.detail.value!)}} 
                        ></IonInput>
                    </div>
                </IonCol>
                <IonCol size="6"> 
                    <div className="input">
                        <IonInput 
                        mode="ios" label="เริ่มต้น"  placeholder="เริ่มต้น"  
                        labelPlacement="stacked"  
                        type="date" value={begin}  
                        onIonChange={(e)=>{ setBegin(e.detail.value!)}} 
                        ></IonInput>
                    </div>
                </IonCol>
                <IonCol size="6"> 
                    <div className="input">
                        <IonInput mode="ios" label="สิ้นสุด" 
                        placeholder="สิ้นสุด"  
                        labelPlacement="stacked" type="date"
                        value={expire}  
                        onIonChange={(e)=>{ setExpire(e.detail.value!)}}
                        ></IonInput>
                    </div>
                </IonCol> 
                <IonCol size="12"> 
                    <div className="input">
                        <IonInput mode="ios" label="โทเคน" 
                        placeholder="Token"  
                        labelPlacement="stacked"  
                        disabled value={token}  
                        onIonChange={(e)=>{ setToken(e.detail.value!)}}
                        ></IonInput>
                    </div>
                </IonCol>
                
                <IonCol size="11">  
                    <div className="input">
                        <IonTextarea 
                        mode="ios" label="Url" placeholder="Enter Guest url"  
                        labelPlacement="stacked"  value={guestUrl}  
                        onIonChange={(e)=>{ setGuestUrl(e.detail.value!)}} 
                        style={{height:"6rem",fontSize:".9em"}} >  
                        </IonTextarea>
                    </div>
                </IonCol>
                <IonCol size="1">  <IonButton fill="clear" 
                    onClick={()=>{
                        navigator.clipboard.writeText(guestUrl);
                        iontoast({message:`คัดลอกลิงค์ไปยังคลิปบอร์ดแล้ว` , mode:"ios" ,color:"success",duration: 3000 ,position:"top"})
                    }}
                    > <IonIcon icon={copyOutline} /> </IonButton> </IonCol>
                <IonCol size="12" > 
                        <div className="input"> 
                            <IonTextarea 
                            mode="ios" 
                            value={note} 
                            onIonChange={(e)=>{setNote(e.detail.value!)}}
                            placeholder="หมายเหตุ"
                            label="หมายเหตุ" 
                            labelPlacement="stacked"
                            style={{height:"6rem"}}> 
                            </IonTextarea>
                        </div> 
                </IonCol>
            </IonRow>
                <IonRow className="ion-justify-content-end" >
                    <IonCol size="3" className="set-center-row " style={{justifyContent: 'flex-end',}} > <br/> 
                        <IonButton  size="small" onClick={()=>{saveGuestInfo()}} > 
                            <IonLabel>บันทึก</IonLabel> 
                        </IonButton> 
                    </IonCol>
                </IonRow>
        </IonGrid>
        </IonContent>
    </IonModal>
    )
}