import { IonBackButton, IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonModal, IonPage, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonSpinner, IonText, IonTitle, IonToolbar, useIonToast } from "@ionic/react"
import { addCircleOutline, caretBack, caretForward, chevronBackOutline, contractOutline, expandOutline, eyeOutline } from "ionicons/icons"
import NavBar from "../components/NavBar"
import { useHistory } from "react-router"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getBackup, setBackupDevices, setDevice, setDevices } from "../store/appSlice"
import { allDevices } from "../actions"
import { FeatureGroup, MapContainer } from "react-leaflet"
import { LayersControlMap, MarkerIcon } from "../leaflet/LeafletLayers"
import { Map as MapType} from "leaflet"
import { FullscreenControl } from "react-leaflet-fullscreen"
import "./css/GroupMonitor.css"
import LoadingProgress from "../components/LoadingProgress"
 
let lastUpdate: any = null;
const GroupMonitor=()=>{
    const history = useHistory()
    const [layout,setLayout] = useState(4)
    const [keyword , setKeyword] = useState("")
    const backup :any= useSelector(getBackup)
    const [devicesAll , setDevicesAll] = useState([])
    const [devices,setdevices] = useState([])
    const [expand , setExpand] = useState(false)
    const [channels , setChannels] = useState([
        { channel: 1 , size:"12" } , { channel: 4 , size:"6" } , { channel: 9 , size:"4" } ,
    ])
    const [maxCh, setMaxCh] = useState(9)
    const [channel, setChannel] = useState(1)
    const [chDevice , setChDevice] = useState([  { channel: 1 , size:"12" , devices: [] } ])
    const [targetCh , setTargetCh] = useState(1)
    const dispatch = useDispatch()
    const [modal ,setModal] = useState(false)
    const [iontoast,toastdimiss] = useIonToast()
    const [fullscreen,setFullScreen] = useState(false)
    const searchDevice=(word:any)=>{
        setKeyword(word)
    }
    const addcheckbox=async()=>{
        let devicecheck:any = []
        let result = await allDevices({}) 
        await dispatch(setDevices(result))
        await dispatch(setBackupDevices(result))  
        setDevicesAll(result)
        await result.map((d:any)=>   devicecheck = [...devicecheck , {...d , ...{checked:false}}]   )
        setdevices(devicecheck)
    }
    useEffect(()=>{ 
       
        addcheckbox()
        let selectEl:any = document.getElementById("select-channal")
        if(selectEl){
            selectEl.onchange = (event:any) => {
                event.preventDefault(); 
               setTargetCh(selectEl?.selectedIndex+1)
            };
        } 
        changeCh(9); setChannel(9)

        return(()=>{
              clearChecked()
              clearChDevice()
        })
     },[  ])

     const changeCh=(ch:any)=>{ 
        let select = channels.find((e)=> e.channel === ch)
        console.log("changeCh select ",select)
        let update:any = []
        for(let i =0 ; i <ch ; i++){
            let devicesinch = chDevice[i]?.devices
            update = [...update , {...select , ...{ channel : i+1, devices: devicesinch ? devicesinch : []}}] 
        }
        console.log("changeCh update ",update)
        setChDevice(update)
     }

     
     const checkDeviceToCh=async(device:any , checked:any)=>{ 
        console.log("checkDeviceToCh checked" , checked)
        let update:any =  []
        await devices.map((e:any)=> {  
            if( device.device_id === e.device_id ){
                update =  [...update ,  {...e , ...{checked: checked}}]
            }else{
                update =  [...update ,  e]
            }
           }) 
        setdevices(update)
     }

     const addDeviceToCh=async()=>{
        let deviceChecked = devices.filter((e:any)=> e.checked === true).map((d:any) =>{ return d.device_id})
   
        let update:any = []
        await chDevice.map((e)=>{
            if(targetCh  === e.channel ){
                update = [...update , {...e , devices:deviceChecked }]
            }else{
                update = [...update , e]
            }
        }) 
        setChDevice(update)
        await clearChecked()
        let chEl = document.getElementById(`channal-${targetCh}`)
        if(chEl){
            chEl.scrollIntoView({behavior:"smooth"})
        }
        setModal(false)
     }
    
     const clearChecked=async()=>{
        let updatedevice:any = []
        await devices.map((e:any)=>{
            updatedevice = [...updatedevice , {...e , ...{checked:false}}]
        })
        setdevices(updatedevice)
     }
     const clearChDevice=()=>{
        let update:any = []
        for(let i =0 ; i <chDevice.length ; i++){
            let devicesinch = chDevice[i]?.devices
            update = [...update , {...chDevice[i] , ...{ channel : i+1, devices: devicesinch ? devicesinch : []}}] 
        } 
        setChDevice(update)
     }
    

     const focusChannal=async(ch:{channel: any , size:string , devices:any[]},chindex:any)=>{
        console.log("focusChannal  ",ch)
        console.log("target ch  ", targetCh)
        if(!expand){
            setModal(!modal)
        } 
        let checkOnCh:any  = [] 
        if(!ch.devices   ||ch.devices.length === 0 ){
           
                // iontoast({
                //     message: 'ไม่พบอุปกรณ์ ใน Channal ที่ '+ch.channel+' , โปรดเลือกอุปกรณ์ แล้วเพิ่มลง Channal อีกครั้ง...',
                //     duration: 4000,
                //     position: "top",
                //     color:"warning"
                // });
          
        }else{
            await devices?.map(async(e:any)=> { 
                let found =await ch.devices.find((id:any)=> id === e.device_id) 
                if(found){
                    checkOnCh = [...checkOnCh , {...e , ...{checked:true}}]
                }else{
                    checkOnCh = [...checkOnCh , {...e , ...{checked:false}}]
                } 
            }) 
            setdevices(checkOnCh)
            setTargetCh(ch.channel)
        }
     }

    const scrolltoadd=()=>{
        const  addEl = document.getElementById("devices-selector")
        if(addEl){
            addEl.scrollIntoView({behavior:"smooth"})
            addcheckbox()
        }
    }

    const btnAddtoChannal=async (chi:any)=>{
        console.log("btnAddtoChannal  ",chi)
        let deviceChecked:any[] = devices.filter((e:any)=> e.checked === true).map((d:any) =>{ return d.device_id})
            if(deviceChecked && deviceChecked.length > 0){
             
                let update:any = []
                await chDevice.map((e)=>{
                    if(chi  === e.channel ){
                        update = [...update , {...e , devices:deviceChecked }]
                    }else{
                        update = [...update , e]
                    }
                }) 
                setChDevice(update)
                let updatedevice:any = []
                await devices.map((e:any)=>{
                    updatedevice = [...updatedevice , {...e , ...{checked:false}}]
                })
                setdevices(updatedevice)
                let chEl = document.getElementById(`channal-${chi}`)
                if(chEl){
                    chEl.scrollIntoView({behavior:"smooth"})
                }
            }else{

                iontoast({
                    message: 'ไม่พบอุปกรณ์ ใน Channal ที่ '+ targetCh +' , โปรดเลือกอุปกรณ์ แล้วเพิ่มลง Channal อีกครั้ง...',
                    duration: 4000,
                    position: "top",
                    color:"warning"
                });
            }
    }

    return(
        <IonPage> 
        <IonContent color={"transparent"} > 
            {/* <IonHeader color='transparent' className='ion-no-border'   > 
                <IonToolbar color='transparent' mode='ios' className='ion-no-padding'>   
                        <IonButtons slot="start"  className='ion-hide-md-up  '  >
                        <IonButton size='small' onClick={()=>{history.goBack()}}> <IonIcon icon={chevronBackOutline} />  <IonText >Back</IonText> </IonButton>
                        </IonButtons>
                        <IonTitle style={{fontWeight:"bold",fontSize:"1.3em",marginLeft:"1rem" }} >{'Monitor แบบกลุ่ม'}</IonTitle>
                </IonToolbar>
            </IonHeader> */}
            <div className="page-container" style={{height:"fit-content",paddingTop:".3rem"}}>  
                <button onClick={()=>{setExpand(!expand)}} 
                    style={{ width:"100%", height:"2rem",borderRadius:"10px" ,margin: '.5rem'}}
                    className="set-center-row bg-light ion-hide-lg-up"
                > 
                    <IonLabel> รายการรถ </IonLabel>
                    <IonIcon icon={expand ? caretForward : caretBack} />
                </button>
            <IonGrid style={{width:"100%"}}>
                <IonRow>
                    <IonCol size=".2"  className='ion-hide-lg-down' >
                     <button onClick={()=>{setExpand(!expand)}} className="set-center bg-light"
                       style={{ width:"100%", height:"90vh",borderRadius:"10px"}}> 
                       <IonIcon icon={expand ? caretForward : caretBack} />
                      </button>
                    </IonCol>
                    { !expand && 
                    <IonCol sizeSm="12" sizeMd="2.5" sizeXl="2.3">
                       <DevicesSelector
                        keyword={keyword} 
                        searchDevice={(val:any)=>searchDevice(val)}
                        devices={devices} 
                        checkDeviceToCh={(d:any,check:any)=>checkDeviceToCh(d,check)} 
                        channel={channel} 
                        setTargetCh={(t:any)=>setTargetCh(t)} 
                        addDeviceToCh={()=>addDeviceToCh()}
                        targetCh={targetCh}
                      >
                        <IonLabel className="set-center-row ion-hide-lg-down" style={{justifyContent:"flex-start",fontSize:"1.1em"}} > 
                        <IonButton fill="clear" color={"dark"} size="small" onClick={()=>{  clearChecked();
                        clearChDevice();history.goBack()}}   >  <IonIcon icon={chevronBackOutline} />  </IonButton>
                        &nbsp; <IonLabel><strong>Monitor แบบกลุ่ม</strong></IonLabel> 
                        </IonLabel>
                      </DevicesSelector>
                    </IonCol> }
                    <IonCol  > 
                        <div id="channals-map" aria-expanded={fullscreen} className="set-center bg-light"  >
                        <LoadingProgress mapref={null} updatetime={(e:any)=>{ lastUpdate=e }} />  
                           <div className="set-center-row" style={{paddingTop:"1rem"}} > 
                                { channels.map((ch)=> 
                                    <IonButton 
                                        size="small" mode="ios" 
                                        onClick={()=>{ setChannel(ch.channel);changeCh(ch.channel);}} > 
                                        จำนวน {ch.channel} 
                                    </IonButton>
                                )}&nbsp;&nbsp;
                                <IonButton 
                                    size="small" mode="ios" color={"secondary"} 
                                    onClick={()=>{setFullScreen(!fullscreen);setExpand(true)}} 
                                > 
                                    {fullscreen ? "ย่อ":"เต็มหน้าจอ" }  &nbsp;
                                    <IonIcon icon={fullscreen? contractOutline: expandOutline} /> 
                                </IonButton>
                           </div>
                            <div style={{minWidth:"95%",minHeight:"95%", maxWidth:"65vw"}}>
                            <IonRow>
                            { chDevice.map((ch,i)=>  
                             <IonCol key={i}  sizeXs={"12"}   sizeMd={ch.size} > 
                                <MapChannal 
                                    id={"channal-"+(i+1)}
                                    channel={channel} 
                                    chindex={i+1} 
                                    devices={ch.devices} 
                                    height={channel === 1 ?"80vh" :channel ===4 ? "40vh": "25vh"}  
                                    clickview={(chi:any)=>{console.log("clickview ",chi," - ",ch);  setTargetCh(chi); focusChannal(ch,chi) } } 
                                    addChannal={(chi:any )=>{console.log("addChannals ",ch);  setTargetCh(chi);setModal(true);scrolltoadd();btnAddtoChannal(chi) }}
                                >   
                                </MapChannal> 
                             </IonCol> 
                              )
                            }  
                            </IonRow> 
                           </div>
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
       {expand &&  <IonModal isOpen={modal} onIonModalDidDismiss={()=>{setModal(false)}} >
            <IonContent className="ion-padding" >
                <DevicesSelector
                 keyword={keyword} 
                 searchDevice={(val:any)=>searchDevice(val)}
                 devices={devices} 
                 checkDeviceToCh={(d:any,check:any)=>checkDeviceToCh(d,check)} 
                 channel={channel} 
                 setTargetCh={(t:any)=>setTargetCh(t)} 
                 addDeviceToCh={()=>{addDeviceToCh()}}
                 targetCh={targetCh}
               > 
                </DevicesSelector>
            </IonContent>
        </IonModal>}
        </div>
        </IonContent>
        </IonPage>
    )
} 
export default GroupMonitor;

const DevicesSelector=({keyword, searchDevice,devices ,checkDeviceToCh ,channel,setTargetCh,targetCh,addDeviceToCh,children}:any)=>{
     
    return(
        <div id="devices-selector" className="ion-padding bg-light" style={{ width:"100%", height:"90vh",borderRadius:"10px"}}>
            {/* <IonBackButton text={"Monitor แบบกลุ่ม"} mode="ios" ></IonBackButton> */}
          
        {children}
      {/*   <IonTitle  className='ion-hide-lg-down  ion-no-padding' >Monitor แบบกลุ่ม</IonTitle> */}
        <IonSearchbar style={{paddingLeft:"0px"}} mode="ios" placeholder="ค้นหา" value={keyword} onIonInput={(e)=>{ searchDevice(e.detail.value)}}  ></IonSearchbar> 
         <IonList style={{height:"65%",overflowY:"scroll"}}>
         {
            devices.map((device:any,index:any)=> 
                <IonItem key={index} mode="ios" > 
                    <IonCheckbox value={device?.name} checked={device?.checked} labelPlacement="end"   justify="start" 
                    onIonChange={(e)=>{checkDeviceToCh(device , e.detail.checked)}}  >
                      <IonLabel style={{fontSize:"small"}} >{device?.name}</IonLabel>
                    </IonCheckbox> 
                </IonItem>
            )
         }
        </IonList>
        <br/>
     
        <IonSelect  interface="alert" placeholder="เลือก Channal" value={targetCh} onIonChange={(e)=>{setTargetCh(e.detail.value)}}> 
          {  Array.from(Array(channel), (e, i)=> <IonSelectOption value={i+1} onClick={()=>{setTargetCh(i+1)}} > <IonLabel >CH {i+1}</IonLabel></IonSelectOption> )}
        </IonSelect>
     
      
        <br/>
        <IonButton expand="block" mode="ios" className="set-center-row" style={{alignItems:"space-between"}}   
          onClick={()=>{addDeviceToCh()}}
        > 
            <IonLabel>View</IonLabel>  
        </IonButton>
    </div>
    )
}

const MapChannal=({id,channel , chindex , height ,devices ,clickview ,addChannal, children}:any)=>{
    const backup :any= useSelector(getBackup)
    const [device , setDevice] = useState<any[]>([])
    const [zoom ,setZoom] = useState(16)
    const [mapref,setMapRef] = useState<MapType | null>(null)
    const [cbound , setCBound] = useState<any>([])
     

    useEffect(()=>{ 
        const deviceSelect=async()=>{
            let markertoview:any = []
           
            await devices?.map(async(e:any)=> {
             let found =await backup.filter((b:any)=>  b != null &&  b.event_id > 0 ).find((b:any)=> b.device_id === e )
             if(found){
                markertoview = [...markertoview ,found]
             }
            })  
            setDevice(markertoview)
            setTimeout(()=>{boundMap(markertoview)},500)
          
        }
        deviceSelect()
        if(mapref){  mapref?.invalidateSize() }
    },[devices,backup,mapref])

    const boundMap=async(marks:any)=>{
        let bound:any = []
        if(marks && marks.length > 0){
            bound = []
            await marks.map((d:any)=>{
                bound = [...bound , [ Number(d.latitude) , Number(d.longitude)]]
            })
        }else{
            bound = []
            await device.map((d)=>{
                bound = [...bound , [ Number(d.latitude) , Number(d.longitude)]]
            })  
            }
        bound = bound.filter((e:any)=> e[0] != null)
        console.log("bound ",bound.length)
        if(bound.length > 1 && mapref){ 
            try{ 
                mapref?.fitBounds(bound)
                mapref?.invalidateSize()
                setCBound(bound)
            }catch(err){
                setTimeout(()=>{
                    mapref?.fitBounds(bound)
                    mapref?.invalidateSize()
                },1000)
            }
        }else if(bound.length === 1 && mapref){ 
            console.log("bound.length 1  ",bound[0])
            mapref?.invalidateSize()
            mapref?.setView(bound[0] ,16)
        }
    }

    
    return(
    <div id={id} className={`map-channal ch-${channel}`}     >  
        {/* <div style={{position:"absolute" ,zIndex:999 , padding:".5rem"}} >
           <small> CH-{chindex}</small>
        </div> */}
        {device.length > 0 &&<div style={{ justifyContent:"flex-start"}} className="set-center-row  group-toolbar bg-dark" >
        <IonButton color={"light"} fill="clear" mode="ios" size="small"  > <IonLabel color={"light"} ><small> CH-{chindex}</small> </IonLabel> </IonButton>
           <IonButton color={"light"} fill="clear" mode="ios" size="small" onClick={()=>{boundMap(null)}} >  <IonIcon icon={eyeOutline} />  </IonButton>
           <IonButton color={"light"} fill="clear" mode="ios" size="small" onClick={()=>{clickview(chindex);}} >  
             <IonIcon icon={addCircleOutline} />  
           </IonButton>
        </div> }
        {device.length > 0 ? 
            <MapContainer
            id="ch-map"
            // center={cbound[0]}
            zoom={zoom}
            scrollWheelZoom={true}
            zoomControl={false}
            style={{borderRadius:"15px",height:"100%"  }}
            ref={async (comp)=>{  
                comp?.invalidateSize()  
                if(comp){ setMapRef(comp) }
            }} 
            >    
                <FullscreenControl />
                <LayersControlMap  />
                <FeatureGroup>
                    { device.filter((device:any)=> device.latitude !== null ).map((device:any,index:any)=>
                    <MarkerIcon key={index} size={backup.length}
                        device={device} vehicle={device.name}     
                        markersize={{
                            iconSize: [40, 40], 
                            iconAnchor: [15, 50],
                            statusiconSize: [15, 15], 
                            statusiconAnchor: [0, 58],  
                          }}  
                    />
                    ) }
                </FeatureGroup>
            </MapContainer> :
            <div className="set-center-row"  style={{width:"100%" , height:"100%"}} >
                <IonLabel>CH{chindex}</IonLabel>
                <IonButton mode="ios" fill="clear" onClick={()=>{addChannal(chindex)}} > 
                    <IonIcon icon={addCircleOutline} />
                </IonButton>
            </div>
        }
    </div> 
    )
}