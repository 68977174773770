import { IonButton, IonButtons, IonCheckbox, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonPage, IonRow, IonSearchbar, IonText, IonTitle, IonToggle, IonToolbar } from "@ionic/react"; 
import { MenuContent } from "../components/Menu";
import { AuthCheck } from "./Home";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
 
import "./css/Maintenance.css"
import { addCircle, checkmarkCircle, chevronBackOutline, chevronForward,  lockClosed,  lockClosedOutline,  lockOpen,  lockOpenOutline,  trashBinOutline } from "ionicons/icons";
import { SelectMultiplePopover, SelectPopover } from "../components/AppComponent";
import { useSelector } from "react-redux";
import { getBackup, getMenuOpen, getProfile } from "../store/appSlice";
import NavBar from "../components/NavBar";
import { useHistory } from "react-router";

const Maintenance: React.FC = ()=>{
    const menuOpen :any= useSelector(getMenuOpen)
    const profile :any= useSelector(getProfile)
    const history = useHistory()
  
    return(
   <IonPage >
        {/* <AuthCheck />   */} 
        <IonHeader color='transparent' className='ion-no-border'   > 
        <IonToolbar color='transparent' mode='ios' className='ion-no-padding'>  
            {/* <NavBar />   */} 
                <IonButtons slot="start"  className='ion-hide-md-up  '  >
                <IonButton size='small' onClick={()=>{history.goBack()}}> <IonIcon icon={chevronBackOutline} />  <IonText >Back</IonText> </IonButton>
                </IonButtons>
                <IonTitle style={{fontWeight:"bold",fontSize:"1.3em",marginLeft:"1rem" }} >{'Maintenace'}</IonTitle>
        </IonToolbar>
        </IonHeader>
        <IonContent fullscreen> 
            <IonGrid>
            <IonRow>  
                {( menuOpen)&&<IonCol sizeLg='3' sizeXl='2' className='ion-hide-xl-down'  >
                    <div className={`main-side-menu ${menuOpen && "slide-right"}`} style={{height:'90vh' }} > 
                    <MenuContent profile={profile} /></div>
                </IonCol>} 
                <IonCol  sizeLg='12' sizeXl='12'  className="notification-content" > 
                    <div  style={{height:'90vh', maxWidth:"90vw",overflowY:'scroll' }} >
                        <Swiper  
                            draggable={false} 
                            onSlideChange={(e) => console.log('slide change',e)}
                            onSwiper={(swiper) => console.log(swiper)}
                            allowTouchMove={false} 
                        >
                            <SwiperSlide>
                                <div style={{position:'relative',overflowX:'scroll'}}  className="wrapper" >
                                   {/* <h2><IonLabel>  <strong></strong> </IonLabel></h2>  */}
                                   <IonRow className="ion-justify-content-center">
                                   <IonCol size="10" > 
                                    <MaintenanceTitle title={"Maintenance"} isBack={false}  /><br/>
                                   </IonCol>
                                    <IonCol sizeSm="12" sizeMd="10" > 
                                        <MaintenanceTable
                                            all={2}
                                            current={1}
                                        /> 
                                    </IonCol>
                                   </IonRow>
                                </div>  
                            </SwiperSlide>
                            <SwiperSlide>
                                <MaintenanceTitle title={"Add Maintenance"} isBack={true}  />
                                <AddMaintenance />
                            </SwiperSlide>
                            <SwiperSlide>
                                <MaintenanceTitle title={"Add Maintenance Type"} isBack={true}  />
                                <AddMaintenanceType />
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </IonCol>
            </IonRow>
            </IonGrid>
        </IonContent>

        </IonPage>
    )
}

export default Maintenance;

const maintenaceData=[
    {id:0, vehicle:"Accord-1345 *เทส*" , type:"น้ำมันเครื่อง" , status:"success" , lastMaintenace:"2023-08-18T14:23:00" , canEdit:true , canDelete: true } ,
    {id:1, vehicle:"กรุงเทพฯ ยท 9459 นม" , type:"ไส้กรองน้ำมันเครื่อง" , status:"success" , lastMaintenace:"2023-08-18T14:23:00" , canEdit:true , canDelete: true },
    {id:3, vehicle:"พิจิตร 8กร-7628 (โจ) S50" , type:"ไส้กรองอากาศ" , status:"overdue" , lastMaintenace:"2023-08-18T14:23:00" , canEdit:true , canDelete: true },
    {id:4, vehicle:"พิจิตร 8กร-7628 (โจ) S50" , type:"ไส้กรองอากาศ" , status:"overdue" , lastMaintenace:"2023-08-18T14:23:00" , canEdit:true , canDelete: true },
    {id:5, vehicle:"พิจิตร 8กร-7628 (โจ) S50" , type:"ไส้กรองอากาศ" , status:"overdue" , lastMaintenace:"2023-08-18T14:23:00" , canEdit:true , canDelete: true },
    {id:6, vehicle:"พิจิตร 8กร-7628 (โจ) S50" , type:"ไส้กรองอากาศ" , status:"overdue" , lastMaintenace:"2023-08-18T14:23:00" , canEdit:true , canDelete: true },
    {id:7, vehicle:"พิจิตร 8กร-7628 (โจ) S50" , type:"ไส้กรองอากาศ" , status:"overdue" , lastMaintenace:"2023-08-18T14:23:00" , canEdit:true , canDelete: true },
    {id:8, vehicle:"พิจิตร 8กร-7628 (โจ) S50" , type:"ไส้กรองอากาศ" , status:"overdue" , lastMaintenace:"2023-08-18T14:23:00" , canEdit:true , canDelete: true }
]

const MaintenanceTable=({all,current}:any)=>{
    const Swiper = useSwiper()
    
    return(<div className="maintenance-table" >
        <IonGrid className="table-grid">
            <IonRow className="table-header-grid" >
                <IonCol sizeSm="12" sizeMd="6" >
                    {/* Search */} 
                     <IonSearchbar mode="ios" style={{paddingLeft:'0px'}}></IonSearchbar>
                </IonCol>
                <IonCol  sizeSm="12" sizeMd="6"   className="set-center" style={{alignItems:'flex-end'}}>
                    <IonButton mode="ios" size="small" fill="clear" onClick={()=>{Swiper.slideNext()}} > 
                        <IonLabel>Add Maintenance</IonLabel> &nbsp;
                        <IonIcon icon={addCircle} />
                    </IonButton>
                </IonCol>
                {/* header */}

                <IonCol size="12" >
                    <IonRow>
                        <IonCol size=".5"> <IonLabel>{"ID"}</IonLabel> </IonCol>
                        <IonCol size="3"> <IonLabel>{"Vehicle"}</IonLabel> </IonCol>
                        <IonCol size="2"> <IonLabel>{"Type"}</IonLabel> </IonCol>
                        <IonCol size="2" className="ion-text-center" > <IonLabel>{"Status"}</IonLabel> </IonCol>
                        <IonCol size="2.5"> <IonLabel>{"Last Maintenace"}</IonLabel> </IonCol>
                        <IonCol size="1" className="set-center"> <IonLabel>{""}</IonLabel> </IonCol>
                        <IonCol size="1" className="set-center"> <IonLabel>{""}</IonLabel> </IonCol>
                    </IonRow>
                </IonCol> 
            </IonRow>

            {
                maintenaceData.map((el:any, index:any)=>
                <IonRow key={index} className="table-rows-grid">
                    <IonCol size=".5" className="set-center"  >{el.id}</IonCol>
                    <IonCol size="3" >{el.vehicle}</IonCol>
                    <IonCol size="2" >{el.type}</IonCol>
                    <IonCol size="2" className="set-center"> <MaintenanceStatus status={el.status} /> </IonCol>
                    <IonCol size="2.5" >{el.lastMaintenace}</IonCol>
                    <IonCol size="1" className="set-center"> <MaintenanceEdit canEdit={el.canEdit} /> </IonCol>
                    <IonCol size="1" className="set-center"> <MaintenanceDelete canDelete={el.canDelete}/> </IonCol>
                </IonRow>
                )
            }
            <br/>
            <IonRow>
                <IonCol size=".5" >
                    <IonButton size="small" fill="clear" color={"medium"} > 
                        <IonIcon icon={chevronBackOutline} /> 
                        <IonIcon icon={chevronBackOutline} style={{marginLeft:'-10px'}}/> 
                    </IonButton>
                </IonCol>
                <IonCol size=".5" >
                    <IonButton size="small" fill="clear" color={"medium"} > 
                        <IonIcon icon={chevronBackOutline} />  
                    </IonButton>
                </IonCol>
                <IonCol size=".5" >
                    <IonButton size="small" fill="clear" color={"medium"} > 
                        <IonLabel color={"primary"} ><strong>{current}</strong>&nbsp;</IonLabel> <IonLabel> / {all}</IonLabel>
                    </IonButton>
                </IonCol>
                <IonCol size=".5" >
                    <IonButton size="small" fill="clear" color={"medium"} > 
                        <IonIcon icon={chevronForward} />  
                    </IonButton>
                </IonCol>
                <IonCol size=".5" >
                    <IonButton size="small" fill="clear" color={"medium"} > 
                        <IonIcon icon={chevronForward} /> 
                        <IonIcon icon={chevronForward} style={{marginLeft:'-10px'}}/> 
                    </IonButton>
                </IonCol>
            </IonRow>
            
        </IonGrid>
    </div>
    )
}

const MaintenanceStatus=({status}:any)=>{
    return(
        status === "success" ? <IonChip color={"success"} mode="ios" outline={true}> <IonIcon icon={checkmarkCircle} />&nbsp; <small>Success</small></IonChip>:
        status === "overdue" ?  <IonChip color={"danger"} mode="ios" outline={true}> <IonIcon icon={checkmarkCircle} />&nbsp; <small>Overdue</small></IonChip>:
        <IonChip color={"warning"} mode="ios" outline={true}> <IonIcon icon={checkmarkCircle} />&nbsp; <small>Almost</small></IonChip>
       
    )
}

const MaintenanceEdit=({canEdit}:any)=>{

    return(
        <IonChip disabled={!canEdit} color={"secondary"} mode="ios" outline={true}> 
            <IonIcon icon={checkmarkCircle} />&nbsp; <small>Edit</small>
        </IonChip>
    )
}

const MaintenanceDelete=({canEdit}:any)=>{

    return(
        <IonButton color={"danger"} fill="clear" size="small" >
            <IonIcon icon={trashBinOutline} />
        </IonButton>
    )
}

const MaintenanceTitle=({title,isBack}:any)=>{ 
    const Swiper = useSwiper();
    return( 
    <IonLabel   className='ion-hide-md-down  ' >  
        <IonButton size="small" fill="clear" color={"dark"} onClick={()=>{isBack && Swiper.slidePrev()}} > 
            {isBack && <IonIcon icon={chevronBackOutline} style={{marginRight:'1rem'}} /> }
            <h1  > <strong>{title}</strong>  </h1>  
        </IonButton> 
    </IonLabel>
     )
}


const maintenanceType = [
    {value: 1,label:'น้ำมันเครื่อง'} ,
    {value: 2,label:'ไส้กรองน้ำมันเครื่อง'} ,
    {value: 3,label:'ไส้กรองอากาศ'} ,
    {value: 4,label:'ไส้กรองน้ำมันเชื้อเพลิง'} ,
    {value: 5,label:'น้ำมันเครื่อง'} ,
    {value: 6,label:'น้ำมันเบรก'} ,
    {value: 7,label:'ท่อน้ำมันเบรก'} ,
    {value: 8,label:'ท่อไอเสีย'} ,
]

const AddMaintenance=()=>{
    const Swiper = useSwiper();
    const [alldevice,setAllDevice] = useState(true)
    const backup  :any= useSelector(getBackup)
    const [selecteds,setSelecteds] = useState([])
    const [maintenType,setMaintenType] = useState({value:'opendoor-no',label:'น้ำมันเครื่อง'})

    const selectDevice=(selection:any)=>{ 
        const find = selecteds.find((e:any)=> e.value === selection.value) 
        if(!find){ 
            let select:any = selecteds
            select[select.length] = selection
            //select = [...select, e]
            console.log("select ",select)
            //@ts-ignore
            setSelecteds(select) 
        }
    }

    const clearSelected=(index:any)=>{ 
        let select:any[] = selecteds
        select.splice(index,1)
        select = select.filter((e:any)=> e !== null) 
    }

    useEffect(()=>{},[alldevice])

    return(
    <div className="add-maintenance-conatiner">  
        <IonGrid>
            <IonRow >
                <IonCol sizeMd="12" sizeXl="6" >
                    <IonRow> 
                        <IonCol size="12"> 
                            <IonLabel className="notification-way" ><IonCheckbox checked={alldevice} 
                                onIonChange={(e:any)=>{setAllDevice(e.detail.checked)}}
                                ></IonCheckbox>&nbsp;&nbsp;  เลือกยานพาหนะทั้งหมด</IonLabel>
                            </IonCol> 
                            <IonCol size="12" > 
                                <SelectMultiplePopover 
                                    id={'devices'}
                                    contents={backup.map((e:any)=>{ return {value: e.device_id , label: e.name} })} 
                                    title={'Devices'} 
                                    subtitle={"select devices."}  
                                    selected={(e:any)=>{return selectDevice(e)}} 
                                    clearSelect={(e:any)=>{return clearSelected(e)}}
                                    current={!alldevice?selecteds: backup.map((e:any)=>{ return {value: e.device_id , label: e.name} })} 
                                    // checkedall={alldevice}
                                    position={{
                                        side:"right",
                                        alignment:"center"
                                    }}
                                /> 
                        </IonCol> 
                    </IonRow>
                </IonCol>
                <IonCol sizeMd="12" sizeXl="6"  style={{padding:'.5rem',display:'flex',flexDirection:'row'}} > 
                    <div style={{marginTop:'2rem',width:'95%'}} >
                    <SelectPopover 
                        id={'maintentype'}
                        contents={maintenanceType} 
                        title={'Maintenance Type'} 
                        subtitle={"select maintenance type."}  
                        selected={(e:any)=>{return setMaintenType(e)}}
                        current={maintenType} 
                    />  
                    </div>
                    <IonButton size="small" fill="clear" style={{marginTop:'2rem'}} onClick={()=>{Swiper.slideNext()}} > <IonIcon icon={addCircle} /> </IonButton>
                </IonCol>
            </IonRow> 
            <IonRow>
                <IonCol sizeMd="12" sizeXl="1.5">
                    <strong style={{paddingLeft:'.5rem'}}>เงื่อนไข :</strong>
                </IonCol> 
                <IonCol size="10" > 
                    <IonCheckbox labelPlacement="end" style={{marginRight:'1rem'}} ><IonLabel><small>ยกเลิกการตรวจสอบ</small></IonLabel> </IonCheckbox>
                    <IonCheckbox labelPlacement="end" style={{marginRight:'1rem'}} ><IonLabel><small>ระยะกิโลเมตร</small></IonLabel> </IonCheckbox>
                    <IonCheckbox labelPlacement="end" style={{marginRight:'1rem'}} ><IonLabel><small>ระยะเดือน</small></IonLabel> </IonCheckbox>
                    <IonCheckbox labelPlacement="end" style={{marginRight:'1rem'}} ><IonLabel><small>ระยะกิโลเมตร และ จำนวนเดือน</small></IonLabel> </IonCheckbox> 
                </IonCol>
            </IonRow><br/>
            <IonRow>
               <IonCol  size="12" >
                    <div style={{marginBottom:'.5rem'}}>  <IonLabel > <small>ระยะทางซ่อมบำรุงเริ่มต้น (กม.) : </small></IonLabel> </div>
                    <div className="input" >
                        <IonInput mode="ios" placeholder="ระยะทางซ่อมบำรุงเริ่มต้น" ></IonInput>
                    </div>
               </IonCol>
               <IonCol  size="12" >
                    <div style={{marginBottom:'.5rem'}}>  <IonLabel > <small>ซ่อมบำรุง (เดือน) : </small></IonLabel> </div> 
                    <div className="input" >
                        <IonInput mode="ios" placeholder="ซ่อมบำรุง" ></IonInput>
                    </div>
               </IonCol>
               <IonCol sizeMd="12" sizeXl="12" >
                  <IonButton  mode="ios"> <IonLabel>ADD</IonLabel> </IonButton>
               </IonCol>
            </IonRow>
            
            
       </IonGrid>    
   </div>
    )
}

const AddMaintenanceType=()=>{
    const [isPublic,setIsPublic] = useState(false)
    const [languages,setLanguage] = useState([{langCode:"th",langIcon:"../assets/icon/th.png"},{langCode:"uk",langIcon:"../assets/icon/uk.png"} ])
    const [typesLang,setTypesLang] = useState([{langCode:"th",type: ""}])
    const [thType,setTHType] = useState("") 
    const [enType,setENType] = useState("") 

    useEffect(()=>{
        setTypesLang([])
    },[isPublic])
  
    return(<div className="add-maintenancetype-conatiner">  
        <IonGrid> 
            <div style={{marginBottom:'.5rem'}}>  <IonLabel > <small>ชื่อการซ่อม : </small></IonLabel> </div> 
            <div style={{position:'relative'}}>
                <div style={{width:'2px',height:'5rem',backgroundColor:'#CDCDCD',position:'absolute',zIndex: 1 , marginLeft:'1.5rem'}} ></div>

                <div  className="input" style={{position:'relative',zIndex: 2 }}>
                <IonRow >
                    <IonCol size=".5" className="set-center" style={{padding:'0px'}}> 
                        <IonImg src={"../assets/icon/th.png"} 
                        alt={"icon-th"} style={{width:'1.5rem'}} /> 
                    </IonCol>
                    <IonCol size="11" style={{padding:'0px'}} >
                        <IonInput mode="ios" style={{marginLeft:'1rem'}} clearInput value={thType} onBlur={(e:any)=>{ setTHType(e.detail.value)}}> 
                        </IonInput>
                    </IonCol>
                </IonRow>
                </div>
                <div  className="input" style={{position:'relative',zIndex: 2 }}>
                <IonRow >
                    <IonCol size=".5" className="set-center" style={{padding:'0px'}}> 
                        <IonImg src={"../assets/icon/uk.png"} 
                        alt={"icon-uk"} style={{width:'1.5rem'}} /> 
                    </IonCol>
                    <IonCol size="11" style={{padding:'0px'}} >
                        <IonInput mode="ios" style={{marginLeft:'1rem'}} clearInput value={enType} onBlur={(e:any)=>{ setENType(e.detail.value)}}> 
                        </IonInput>
                    </IonCol>
                </IonRow>
                </div>


            {/* {
                languages.map((lang:any,index:any)=> 
                <div key={index} className="input" style={{position:'relative',zIndex: 2 }}>
                <IonRow >
                    <IonCol size=".5" className="set-center" style={{padding:'0px'}}> <IonImg src={lang.langIcon} alt={"icon-"+lang.langCode} style={{width:'1.5rem'}} /> </IonCol>
                    <IonCol size="11" style={{padding:'0px'}} >
                        <IonInput mode="ios" style={{marginLeft:'1rem'}} clearInput onBlur={(e:any)=>{changeTypeLang(lang.langCode,e.detail.value)}}> 
                        </IonInput>
                    </IonCol>
                </IonRow>
                </div>
                
            )} */}
           </div> 
               {isPublic ? <IonIcon name={trashBinOutline}  color="success" ></IonIcon>: <IonIcon name={trashBinOutline}  color="danger" ></IonIcon> } 
           <IonRow>
             <IonCol size="12" className="" style={{display:'flex',flexDirection:'row' , alignItems:'center'}} > 
                <IonLabel style={{marginRight:'.5rem'}} color={isPublic?"dark":"danger"}  >
                    <IonLabel>Private</IonLabel>&nbsp;
                    <IonIcon icon={isPublic?lockClosedOutline:lockClosed} /> 
                </IonLabel> 
                    <IonToggle className="ispublic-maintenancetype" mode="ios" checked={isPublic} onIonChange={(e)=>{setIsPublic(e.detail.checked)}}   > </IonToggle>
                <IonLabel  style={{marginLeft:'.5rem'}}  color={!isPublic?"dark":"success"} >
                    <IonIcon icon={!isPublic?lockOpenOutline:lockOpen} /> &nbsp;
                    <IonLabel>Public</IonLabel>
                </IonLabel> 
             </IonCol>
          
            <IonCol size="12" >
                <br/>
                <IonButton  mode="ios" >Add</IonButton>
            </IonCol>
           </IonRow>

        </IonGrid>
    </div>)
}