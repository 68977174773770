import "leaflet/dist/leaflet.css";
import { DivIcon, Icon } from "leaflet";

/************************** PIN MARKER **********************************/
export var pinstop = new Icon({ 
  iconUrl: "../assets/pin-stop.png",
  iconSize: [15, 15],
  rotationOrigin: "center",
  iconAnchor: [0, 15],
  popupAnchor: [0, 0],
  tooltipAnchor: [0, 0],
});
export var markerStart = new Icon({ 
  iconUrl: "assets/icon/pin-start.svg",
  iconSize: [30, 30],
  rotationOrigin: "center",
  iconAnchor: [15, 20],
  popupAnchor: [0, 0],
  tooltipAnchor: [0, 0],
});
export var markerStop= new Icon({ 
  iconUrl: "assets/icon/pin-stop.svg",
  iconSize: [30, 30],
  rotationOrigin: "center",
  iconAnchor: [15, 20],
  popupAnchor: [0, 0],
  tooltipAnchor: [0, 0],
});
export var pinstart = new Icon({ 
  iconUrl: "../assets/start.png",
  iconSize: [25, 25],
  rotationOrigin: "center",
  iconAnchor: [0, 25],
  popupAnchor: [0, 0],
  tooltipAnchor: [0, 0],
});
export var pinend = new Icon({ 
  iconUrl: "../assets/pin-end.png",
  iconSize: [25, 25],
  rotationOrigin: "center",
  iconAnchor: [0, 25],
  popupAnchor: [0, 0],
  tooltipAnchor: [0, 0],
});
export var circle = new Icon({ 
  iconUrl: "../assets/color-purple.png",
  iconSize: [10, 10],
  rotationOrigin: "center",
  iconAnchor: [5, 5],
  popupAnchor: [0, 0],
  tooltipAnchor: [0, 0],
});
export var iconStop = new Icon({
  iconUrl:  "assets/icons-pin/sts-23.png" ,
  iconSize: [20, 20],
  rotationOrigin: "center",
  iconAnchor: [10, 10],
});
export var circleWhite =  new DivIcon({
  className: `marker-div-draw `,
  iconSize: [15, 15], 
  iconAnchor: [7, 7],  
})
export var squreRotate =  new Icon({
  iconUrl:  `../assets/icon/squreRotate.svg`,
  iconSize: [25, 25], 
  iconAnchor: [12, 12],  
})
export var streetviewIcon = new Icon({ 
  iconUrl: "../assets/icon/street-view-solid.svg",
  iconSize: [60, 60],
  rotationOrigin: "center",
  iconAnchor:  [30, 30], 
  shadowSize:   [50, 64], // size of the shadow 
  shadowAnchor: [4, 62],  // the same for the shadow
  popupAnchor:  [0, -30]
});
export var defaultPin = new Icon({ 
  iconUrl: "../assets/icon-place/map-icon-0.png",
  iconSize: [25, 25],
  rotationOrigin: "center",
  iconAnchor: [12, 12],
  popupAnchor: [0, 0],
  tooltipAnchor: [0, 0],
});
export var editPin = new Icon({ 
  iconUrl: "../assets/icon/markerEdit.png",
  iconSize: [30, 30],
  rotationOrigin: "center",
  iconAnchor: [12, 20],
  popupAnchor: [0, 0],
  tooltipAnchor: [0, 0],
});
export var  removePin = new Icon({ 
  iconUrl: "../assets/icon/markerRemove.png",
  iconSize: [25, 25],
  rotationOrigin: "center",
  iconAnchor: [12, 12],
  popupAnchor: [0, 0],
  tooltipAnchor: [0, 0],
});


export var pinCar = new Icon({ 
  iconUrl: "../assets/icons-pin/pin_car.png",
  iconSize: [60, 60],
  rotationOrigin: "center",
  iconAnchor: [25, 70],
  popupAnchor: [0, 0],
  tooltipAnchor: [0, 0],
});

export var pinIdling = new Icon({
  iconUrl: "../assets/icons-pin/sts-24.png",
  iconSize: [24, 24],
  rotationOrigin: "center",
  iconAnchor: [5, 80],
  popupAnchor: [0, 0],
  tooltipAnchor: [0, 0],
});

export var pinOffline = new Icon({
  iconUrl: "../assets/icons-pin/sts-0.png",
  iconSize: [24, 24],
  rotationOrigin: "center",
  iconAnchor: [5, 80],
  popupAnchor: [0, 0],
  tooltipAnchor: [0, 0],
});

export var pinStop = new Icon({
  iconUrl: "../assets/icons-pin/sts-23.png",
  iconSize: [24, 24],
  rotationOrigin: "center",
  iconAnchor: [5, 80],
  popupAnchor: [0, 0],
  tooltipAnchor: [0, 0],
});

export var pinArrowMove = new Icon({
  iconUrl: "../assets/icons-pin/sts-7.png",
  iconSize: [24, 24],
  rotationOrigin: "center",
  iconAnchor: [5, 80],
  popupAnchor: [0, 0],
  tooltipAnchor: [0, 0],
});

export var pinBoat = new Icon({
  iconUrl: "../assets/icons-pin/pin_boat.png",
  iconSize: [60, 60],
  rotationOrigin: "center",
  iconAnchor: [25, 70],
  popupAnchor: [0, 0],
  tooltipAnchor: [0, 0],
});