import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonPage, IonRow, IonText, IonTitle } from "@ionic/react"
import { useSelector } from "react-redux";
import { getTravelSum } from "../../store/reportSlice";
import { useEffect, useState } from "react";
import { MapContainer, Polyline, TileLayer } from "react-leaflet";
import { LayersControlMap } from "../../leaflet/LeafletLayers";
import moment from "moment";
import { printOutline } from "ionicons/icons"; 
import domtoimage from 'dom-to-image';
import { pdfMake } from "../../lib/pdfMake" 

const RepTravelSum=({polyline, detail, overview}:any)=>{
    const reportContent :any= useSelector(getTravelSum) 
    const [mapImg,setMapImg] = useState("")

    useEffect(()=>{
        
    },[reportContent,mapImg])

    function blobToDataURL(blob:any, callback:any) {
        var a = new FileReader();
        a.onload = function(e:any) {callback(e.target.result);}
        a.readAsDataURL(blob);
    }
     

    const printReport=()=>{
        const el:any =document.querySelector("#rep-travelsum-map") 
        domtoimage.toBlob(el).then(function (blob) { 
            blobToDataURL(blob, function(dataurl:any){
                console.log('domtoimage ',dataurl);
                setMapImg(dataurl)
                pdfMake.createPdf({
                    images: {
                        maproute: dataurl
                    },
                    content:[ 
                        {
                            marginTop: 15 ,
                            columns: [ 
                                {   width: '10%',   text:`` },
                                {text: detail.reportName , bold: true ,alignment:'left' , fontSize: 18  },
                            ]
                        },
                        {
                            columns: [ 
                                {   width: '10%',   text:`` },
                                {text: `ทะเบียน ${detail.name} `  ,alignment:'left' ,   },
                            ]
                        },
                        {
                            columns: [ 
                                {   width: '10%',   text:`` },
                                {text: `วันที่ ${moment(detail.start).format("DD MMM YYYY HH:mm")} - ${moment(detail.end).format("DD MMM YYYY HH:mm")} `  ,alignment:'left' ,marginBottom: 20   },
                            ]
                        }, 
                        {
                            columns: [ 
                                {   width: '10%',   text:`` },
                                {   width: '25%',   text: `ภาพรวมการเดินทาง`  ,alignment:'left' ,bold:true ,marginBottom: 10 }, 
                            ],
                            marginBottom: 5
                        },
                        {
                            columns: [ 
                                {   width: '10%',   text:`` },
                                { 
                                    width: '30%',
                                    text:`วันที่ริ่มต้น ${moment(detail.start).format("DD MMM YYYY HH:mm")}`
                                },
                                { 
                                    width: '30%',
                                    text: `วันที่ริ่มต้น ${moment(detail.end).format("DD MMM YYYY HH:mm")} `
                                },
                                {  width: '10%',  text:``  },
                            ]
                        },
                        {
                            columns: [ {  width: '10%',  text:``  },
                                { 
                                    width: '30%',
                                    text: `ระยะทางทั้งหมด ${overview?.distance_all} กม.`
                                },
                                { 
                                    width: '30%',
                                    text: `ความเร็วสูงสุด ${overview?.speed_max}  กม./ชั่วโมง`
                                }, {  width: '10%',  text:``  },
                            ]
                        },
                        {
                            columns: [ {  width: '10%',  text:``  },
                                { 
                                    width: '30%',
                                    text: `เคลื่อนที่  ${overview?.time_all} ชั่วโมง`
                                },
                                { 
                                    width: '30%',
                                    text:  `ช่วงเวลาที่เดินทางมากที่สุด ${overview?.time_max}`
                                }, {  width: '10%',  text:``  },
                            ],
                            marginBottom: 15
                        },  
                        {image: 'maproute'   , alignment:'center' , width: 450 },
                    ],
                    header: function (currentPage, pageCount) {
                        return {
                            columns: [
                                { 
                                    text: detail.reportName,
                                    fontSize: 12 ,
                                    marginLeft: 16,
                                    margin: [20, 20, 50, 20] 
                                } ,
                                { text: "หน้า " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', margin: [0, 20, 50, 0] }
                            ]
                        }
                    },
                    footer: {
                        columns: [
                            { 
                                text:"บริษัท อันดามันแทร็คกิ้ง จำกัด | Andaman Tracking .Co.Ltd  @Generate on  tracking.andamantracking.com" ,
                                fontSize: 12 ,
                                marginLeft: 15
                            } 
                        ]
                    },
                    defaultStyle: {
                        font: "THSarabunNew",
                        fontSize: 14
                    }
                }).open()
                
            });
        }); 
 
       
    }
    
    return( 
        <div>
            <IonButton  
                onClick={()=>{printReport()}}
                mode="ios" fill="clear" 
                style={{position:"relative", top:"1rem", right:"1rem"}} 
            >
                <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
                <IonIcon icon={printOutline}  /> 
            </IonButton>
            <IonGrid id="report-content" >
                <IonRow>
                    <IonCol size="12" className="ion-text-center" >
                        <IonTitle className="ion-margin-bottom">{detail.reportName}</IonTitle> 

                        <br />
                        <IonLabel style={{fontSize:'.9em'}}>
                            <IonText className="ion-margin-end" >วันที่</IonText> 
                            <IonText>{moment(detail.start).format("DD MMMM   HH:mm")}</IonText> -
                            <IonText>{moment(detail.stop).format(" DD MMMM  HH:mm")}</IonText>
                        </IonLabel><br/>
                        <IonLabel style={{fontSize:'.9em'}}>
                            <IonText>ทะเบียนรถ​ : {detail?.name} </IonText>
                        </IonLabel>
                    </IonCol>
                </IonRow>
                <br/>
                <IonRow >
                    <IonCol size="12" className="set-center" > 
                       <ReportMap polyline={polyline} /> 
                    </IonCol>
                </IonRow>
                <br/>

                <IonRow className="ion-justify-content-center" style={{fontSize:'.9em'}} >
                    <IonCol size="8" >
                        <IonRow> 
                            <IonCol size="6" ><IonLabel>วันที่ริ่มต้น : {moment(detail?.start).format("DD/MM/YYYY HH:mm")}</IonLabel></IonCol>
                            <IonCol size="6" ><IonLabel>วันที่สิ้นสุด : {moment(detail?.stop).format("DD/MM/YYYY HH:mm")}</IonLabel></IonCol>
                            <IonCol size="6" ><IonLabel>ระยะทางทั้งหมด : {overview?.distance_all} กม.</IonLabel></IonCol>
                            <IonCol size="6" ><IonLabel>ความเร็วสูงสุด : {overview?.speed_max} กม./ชั่วโมง</IonLabel></IonCol>
                            <IonCol size="6" ><IonLabel>เคลื่อนที่ : {overview?.time_all} ชั่วโมง</IonLabel></IonCol>
                            <IonCol size="6" ><IonLabel>ช่วงเวลาที่เดินทางมากที่สุด : {overview?.time_max}</IonLabel></IonCol>
                        </IonRow>
                    </IonCol>

                </IonRow>
            </IonGrid>
           
        </div> 
    )
}

export default RepTravelSum;


const ReportMap=({polyline}:any)=>{

    return(
        <MapContainer 
        id='rep-travelsum-map'
        center={[0,0]}
        zoom={16}
        scrollWheelZoom={true}
        zoomControl={false}
        style={{borderRadius:"10px" }}
        ref={async (comp)=>{  
            comp?.invalidateSize() 
            if(comp){
             comp?.setView(polyline.length>0 ?  polyline[0]:[0.0,0.0]) 
             if(polyline.length > 0){ 
                comp?.fitBounds(polyline)
             }
             comp?.on("zoom",()=>{
                comp?.invalidateSize() 
              })
              setTimeout(()=>{
               comp?.setZoom(12)
              },300)
            } 
        }} 
     >
       <TileLayer
         attribution='© Longdo Map'
         url={`https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`}
     /> 
     {
        polyline.length > 0 &&   <Polyline positions={polyline} />
     } 
     </MapContainer>
    )
}