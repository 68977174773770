import { IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonModal, IonPage, IonRow, IonText, IonTitle, useIonAlert, useIonToast } from "@ionic/react"
import { addCircle, arrowRedo, arrowUndo, closeCircle, informationCircleOutline, reload, share, trash } from "ionicons/icons";
import { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import { useHistory } from "react-router";
import { delRouteSeted, getRouteDetail, getRoutesetTable } from "../actions";
import { faTurnUp, faTurnDown, faUpLong, faCircle, faBridge, faLocationDot, faFerry } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FeatureGroup, MapContainer, Marker, Polyline, Tooltip } from "react-leaflet";
import { Icon, Map } from "leaflet";
import { LayersControlMap } from "../leaflet/LeafletLayers";
import { UpdateMap } from "./TravelRouteAdd";


import "leaflet/dist/leaflet.css";
import "leaflet-rotatedmarker" 
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw'; 
import 'react-leaflet-markercluster/dist/styles.min.css';
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";

interface RouteTable {
     id :    Number 
     name :  String  
     start : String
     end :   String
     count : Number,
     distance : Number
     allDuration :   String
}

interface RouteDetail{
    coordinates: {
        coord: String
        coords:[][]
        distance: Number
        id : Number
        interval : Number
        lineNo : Number
        name : String
        routeAddId : Number
        turn : Number 
    }[]
    route: { 
        name: String
        distance: String
        driveDuration:String
        userId: Number
        beginStationId: Number
        endStationId: Number
        stationCount:Number
        allDurationtion: String
    }
    stations:{
        id: Number
        routeAddId: Number
        userId:Number
        stationId:Number
        dateIn:String
        timeIn: String
        dateOut: String
        timeOut:String
        stayInDuration: String
        timeLeave: null,
        distanceBetween: String
    }[]
}

const polylineColor = ["#3248a8","#24a631","#bf4d0f","#bf4d0f","#cc0a31"]
const TravelRoute=()=>{ 
    const [travelroute , setTravelRoute] = useState<RouteTable[]>([])
    const [ionalert] = useIonAlert();
    const [iontoast,dimisToast] = useIonToast();
    const [loading,setLoading] = useState(false)
    const [show,setShow] = useState(false)
    const [detail , setDetail] = useState<RouteDetail|null>(null)
    const [mapRef ,setMapRef] = useState<Map|null>(null)
    const [tabs,setTabs] = useState(0)

    const getTable =async()=>{
        setLoading(true)
        let tabels = await getRoutesetTable()
        console.log("tabels ",tabels)
        setTravelRoute(tabels)
        setLoading(false)
    }
    useEffect(()=>{
        getTable()
    },[])

    const viewDetail=async (route:RouteTable)=>{  
        setLoading(true)
        const rdetails = await getRouteDetail({routeid: route?.id}) 
        const coordinates = await rdetails.coordinates.map((c:any)=>{
            const coord = c.coord.split("#")
            const coords = coord.map((co:any)=> { return co.split(",") })
            let coordsNum:any[] = coords.map((cor:any)=>{ return [Number(cor[1]) ,Number(cor[0]) ] })
            coordsNum = coordsNum.filter((c)=> !Number.isNaN(c[0]) && !Number.isNaN(c[1]) )
            return { ...c , ...{coords: coordsNum }}
        }) 
        let routedetai = {
            coordinates:coordinates , route: rdetails.route , stations : rdetails.stations
        }
        setDetail(routedetai)
        setShow(true)
        setLoading(false)
    }

    const btnDeleteRow=(route:RouteTable)=>{
        ionalert({
            header: 'ยืนยันที่จะลบเส้นทาง '+route.name,  
            buttons: [
                {
                  text: 'Cancel',
                  role: 'cancel', 
                },
                {
                  text: 'OK',
                  role: 'confirm',
                  handler: async () => { 
                    setLoading(true)
                    const result = await delRouteSeted({routeid: route?.id})
                    if(result){
                        getTable()
                        iontoast({
                            message: 'ลบเส้นทาง '+route.name+' สำเร็จ' ,
                            duration: 3000 ,
                            color :"success" ,
                            position:"top"
                        })
                    }else{
                        iontoast({
                            message: 'ลบเส้นทาง '+route.name+' ไม่สำเร็จ' ,
                            duration: 3000 ,
                            color :"danger" ,
                            position:"top"
                        })
                    }
                    setLoading(false)
                  },
                },
              ], 
          })
        console.log("confirm delete ",route)
    }


    const turncode=(code:any, type:any)=>{
        if(type === 1 ){
            if(code === 0){ return "เลี้ยวซ้าย"}        
            else if(code === 1){ return "เลี้ยวขวา"}
            else if(code === 2){ return "เลี้ยวซ้ายเล็กน้อย"}
            else if(code === 3){ return "เลี้ยวขวาเล็กน้อย"}
            else if(code === 4){ return ""}
            else if(code === 5){ return "ตรงไป"}
            else if(code === 6){ return "เข้าโทลเวย์"}
            else if(code === 9){ return "มาถึง"}
            else if(code === 11){ return "การเดินทางด้วยเรือเฟอร์รี่"}
        }else if(type === 2 ){
            if(code === 0){ return  <FontAwesomeIcon icon={faTurnUp} style={{transform: "rotate(-90deg)"}}/>}        
            else if(code === 1){ return <FontAwesomeIcon icon={faTurnDown} style={{transform: "rotate(-90deg)"}} /> }
            else if(code === 2){ return <FontAwesomeIcon icon={faUpLong} style={{transform: "rotate(-20deg)"}} />}
            else if(code === 3){ return <FontAwesomeIcon icon={faUpLong} style={{transform: "rotate(20deg)"}} />}
            else if(code === 4){ return <FontAwesomeIcon icon={faCircle}  />}
            else if(code === 5){ return <FontAwesomeIcon icon={faUpLong} />}
            else if(code === 6){ return <FontAwesomeIcon icon={faBridge} />}
            else if(code === 9){ return <FontAwesomeIcon icon={faLocationDot} />}
            else if(code === 11){ return <FontAwesomeIcon icon={faFerry} />}
        }
    }

    const boundmap=async(map:Map)=>{
        setMapRef(map)
        let poly :any[] = []
        await detail?.coordinates.map(async(e:any)=>{ 
             e.coords.map((c:any)=>{ 
                poly.push(c)
             })
        }) 
        setTimeout(()=>{  
           map.fitBounds(poly)
        },500)
    }
    return(
    <IonPage> 
        <IonLoading isOpen={loading} />
        <IonContent className="ion-padding" > 
                <IonGrid> 
                        <IonRow>
                            <IonCol size="12" >
                                <TableTralvelRoute 
                                title={"เส้นทางแนะนำ ที่บันทึกไว้"}
                                rowsData={[]}
                                btnDeleteRow={(route:any)=>{btnDeleteRow(route)}}
                                getTable={getTable}
                                viewDetail={(e:any)=>{viewDetail(e)}}
                                /> 
                            </IonCol>
                        </IonRow>
                    </IonGrid>
              
        </IonContent>
        <IonModal className="station-modal" mode="ios" isOpen={show} 
        onIonModalDidDismiss={(e)=>{setShow(false)}} >
        <IonContent className="ion-padding" >
            <IonGrid>
                <IonRow>
                    <IonCol size="12" className="set-center" style={{justifyContent:"space-between",flexDirection:"row"}} > 
                        <IonLabel color={"dark"} > <strong>เส้นทาง {detail?.route?.name}</strong> </IonLabel>
                        <IonButton onClick={()=>setShow(false)} fill="clear" color={"danger"}><IonIcon icon={closeCircle} /> </IonButton> 
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="9" >
                        <MapContainer 
                            id='map-route' 
                            zoom={16}
                            center={[14.340027, 100.586026]}
                            scrollWheelZoom={true}
                            zoomControl={false}
                            style={{borderRadius:"10px",height:'70%' }}
                            ref={async (comp)=>{  
                                comp?.invalidateSize()    
                            }} 
                        >    
                            <UpdateMap setmap={(map:Map)=>{boundmap(map) ; }} />  
                             {
                                detail?.coordinates.map((coord:any,i:any)=>
                                <FeatureGroup  key={i}>  
                                    <Polyline pathOptions={{color:polylineColor[coord.lineNo-1]}} positions={coord?.coords}
                                     ></Polyline>
                                </FeatureGroup>
                                )
                             }
                             {
                                detail?.stations.map((st:any,stIndex:any)=><FeatureGroup>
                                    <Marker position={ st?.latlng.split(",") } icon={new Icon({ 
                                        iconUrl: `../assets/icon-place/map-icon-0.png`,
                                        rotationOrigin: "center",
                                        iconSize:  [30, 30] ,
                                        iconAnchor:  [15, 30] , 
                                        tooltipAnchor: [0, 0],
                                        })} >
                                            <Tooltip className="route-road" permanent={true} direction="bottom"  offset={[0,30]}> 
                                                <div className="ion-padding " > {st.name} </div>
                                            </Tooltip>
                                   </Marker>
                                </FeatureGroup>)
                             }
                            <LayersControlMap  />
                        </MapContainer>
                    </IonCol>

                    <IonCol  sizeXs="12" sizeSm="12" sizeMd="3" >
                        <IonListHeader> เส้นทาง {detail?.route?.name}</IonListHeader>
                        <div style={{width:"100%"}} >
                            <ul> 
                                <li style={{fontSize:".9em"}} > ระยะทาง: { detail?.route?.distance} </li>  
                                <li style={{fontSize:".9em"}} > ช่วงเวลาขับขี่: { detail?.route?.driveDuration} </li> 
                                <li style={{fontSize:".9em"}} > ช่วงเวลาทั้งหมด: { detail?.route?.allDurationtion} </li>   
                                <li style={{fontSize:".9em"}} > สถานีทั้งหมด: { detail?.route?.stationCount.toString()} </li>   
                            </ul>
                        </div>
                        <IonList style={{height:"65vh",overflowY:"scroll"}} > 
                        { detail?.coordinates.map((coord)=>
                             <IonItem style={{fontSize:".8em"}}>
                                <div className="set-center" style={{width:"3rem",height:"3rem",borderRadius:"50%",padding:"5px"}} >
                                    {turncode(coord?.turn , 2 )}
                                </div>
                                <IonLabel className="ion-no-wrap" >{ turncode(coord?.turn,1) }{coord?.name}</IonLabel>
                             </IonItem>
                        )}
                        </IonList>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
        </IonModal>
    </IonPage>
    )
}

export default TravelRoute;

const TableTralvelRoute=({title, rowsData ,btnDeleteRow ,getTable ,viewDetail}:any)=>{
    const history = useHistory() 

    return(
        <div  className="table-travelroute-container" >
            <div className="top-heading"   >
                <IonTitle className="ion-no-padding" style={{fontSize:"2rem", fontWeight:"bold"}}>{title}</IonTitle>
                <div className="table-button-contrtol" >
                    <IonButton fill="clear" color={"dark"} onClick={()=>{getTable()}}> 
                        <IonIcon icon={reload} /> &nbsp;
                        <IonText> โหลดใหม่ </IonText>
                    </IonButton>
                    <IonButton disabled fill="clear" color={"dark"} > 
                        <IonIcon icon={arrowRedo} /> &nbsp;
                        <IonText> นำเข้า </IonText>
                    </IonButton>
                    <IonButton fill="clear" color={"dark"} onClick={()=>{ history.push("/suggestRoute/add")}}>
                        <IonIcon icon={addCircle} /> &nbsp;
                        <IonText > เพิ่ม </IonText>
                    </IonButton>
                    <IonButton  fill="clear" color={"dark"}>
                        <IonIcon icon={trash} /> &nbsp;
                        <IonText> ลบ </IonText>
                    </IonButton>
                    <IonButton fill="clear"  color={"dark"}>
                        <IonIcon icon={share} /> &nbsp;
                        <IonText> ส่งออก </IonText>
                    </IonButton>
                </div>
            </div>
            <table style={{width:"100%"}}>
                <tr className="row-header"   >
                    <th  style={{width:"5%"}}> <IonCheckbox  disabled></IonCheckbox></th>
                    <th  style={{width:"5%"}}>ลำดับ</th>
                    <th  style={{width:"18%"}}>ชื่อเส้นทาง</th>
                    <th style={{width:"13.5%"}} >สถานีต้นทาง</th>
                    <th  style={{width:"13.5%"}}>สถานีปลายทาง</th>
                    <th  style={{width:"12.5%"}}>จำนวนสถานี</th>
                    <th style={{width:"12.5%"}} >ระยทาง (กม.)</th>
                    <th  style={{width:"10%"}}>ระยะเวลารวม</th>
                    <th className="set-center"  style={{width:"auto"}}> ลบ </th> 
                </tr> 
                {
                    rowsData.map((data:RouteTable,index:any)=> 
                    <tr className="row-data"   key={index} > 
                       <td> <IonCheckbox disabled ></IonCheckbox> </td>
                       <td> {index+1} </td>
                       <td> {data?.name} </td>
                       <td> {data?.start} </td>
                       <td> {data?.end} </td>
                       <td> {data?.count.toString()} </td>
                       <td> {data?.distance.toString()} </td>
                       <td> {data?.allDuration} </td>
                       <td className="set-center" style={{flexDirection:"row"}} > 
                            <IonButton  fill="clear" color={"secondary"} onClick={()=>{viewDetail(data)}} > 
                                <IonIcon icon={informationCircleOutline} />
                            </IonButton>    
                            <IonButton fill="clear" color={"danger"} onClick={()=>{btnDeleteRow(data)}} > 
                                <IonIcon icon={trash} /> 
                            </IonButton> 
                        </td>
                    </tr>
                    
                    )
                }
            </table>
        </div>
    )
}