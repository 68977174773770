import { IonCol, IonContent,  IonLoading,  IonPage, IonProgressBar, IonRow, IonSplitPane } from '@ionic/react'; 
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStorage, allDevices, geofences, setStorage, userSetting, allGeometry, menuFilter } from '../actions';
import { DevicesContainer ,DevicesModals } from '../components';  
import { CustomNotify, IconShare } from '../components/AppComponent';   
import { DialogDevice } from '../components/OptionDialogs';
import  { MapStreetView } from '../leaflet/TrackingMap';
import { getIsSignin, getLoading, getMenuOpen, getWindowWidth, setBackupDevices, setDarkMode, setDefaultMap, setDevices, setGoogleEnable, setIsGroupDevice, setLoading, setProfile, setShowLicense, setSignin } from '../store/appSlice';
import { getOpenStreetView, getStreetView, getStreetViewPosition, setDeviceBound, setGeozones } from '../store/mapSlice';
import { useTranslation } from 'react-i18next';  
import toast, { Toaster } from 'react-hot-toast';
import { getContentFilter, setAddress, setCommand, setContentFilter, setEmei, setExpireDate, setIconFilter, setMessage, setName, setNavigation, setPhoneNumber, setQuickTrack, setRealtimeTrack, setSensorBattery, setSensorCardName, setSensorCardNumber, setSensorFuel, setSensorGPS, setSensorGSM, setSensorMile, setSensorPTO, setSensorSpeed, setSensorTemperature, setShare, setStatusName, setToAll, setUpdateTime } from '../store/filterSlice';
import appconf from "../api.config.json"

import '../theme/style.css';  
interface zones { 
    "geoId": number,
    "name":  string,
    "canDelete": boolean,
    "icon": number,
    "position":   { lat: number , lon:number}[]
}

// map.on('moveend, placeMarkersInBounds);

// placeMarkersInBounds();

// function placeMarkersInBounds() {
//     var mapBounds = map.getBounds();
//     for (var i = myMarkers.length -1; i >= 0; i--) {
//         var m = myMarkers[i];
//         var shouldBeVisible = mapBounds.contains(m.getLatLng());
//         if (m._icon && !shouldBeVisible) {
//             map.removeLayer(m);
//         } else if (!m._icon && shouldBeVisible) {
//             map.addLayer(m);
//         }
//     }
// }


const Home: React.FC = () => {
  const dispatch = useDispatch() 
  const loading :any= useSelector(getLoading) 
  const {t,i18n}= useTranslation()
  const streetview :any= useSelector(getStreetView) 
  const steetPosi :any= useSelector(getStreetViewPosition)
  const isStreetView :any= useSelector(getOpenStreetView)
  const menuOpen :any= useSelector(getMenuOpen)  
  const template :any= useSelector(getContentFilter)
  
  const getSetting=async()=>{ 
    /* Setting Lang */
    let locaLang = getStorage('language')  
    if(locaLang !== null && locaLang !== undefined){  
      i18n.changeLanguage(locaLang) 
    }else{ 
      i18n.changeLanguage('th') 
      localStorage.setItem('language','th') 
    }  
  
    const token = getStorage('token')
    if(token){  
      const settings = await userSetting()  
      const showLice =  settings.showName ==="1" ? true :false 
      dispatch(setShowLicense( showLice ))
      dispatch(setIsGroupDevice(settings.groupDevice))
      setStorage('defaultTile',settings.defaultMap)   
      dispatch(setDefaultMap(settings.defaultMap)) 
      dispatch(setGoogleEnable(settings.googleMapEnabled === 1?true:false))
    }else{ 
      dispatch(setShowLicense( false ))
      dispatch(setIsGroupDevice(false))
      setStorage('defaultTile',false)   
      dispatch(setDefaultMap("Longdo")) 
      dispatch(setGoogleEnable(false))
    }
 
  }
 

  const getPtofile=async()=>{
    let prof = await getStorage('profile') 
    dispatch(setProfile(prof)) 
  }
  
  const getDevices=async()=>{
    dispatch(setLoading(true)) 
    let token = await getStorage("token") 
    if(token){ 

      let result = await allDevices({}) //devicesAll({user_id: parseInt(user_id) })
      console.log("result ",result)
      await dispatch(setDevices(result))
      await dispatch(setBackupDevices(result)) 
      let bound:any = []
      await result.filter((device:any)=>device.event_id !== null).map((device:any)=>
        bound = [...bound , [parseFloat(device.latitude),parseFloat(device.longitude)] ]
      )
      dispatch(setDeviceBound(bound))
    }
    dispatch(setLoading(false))
  
  }
 
  const preloader=()=>{
    if (document.readyState === 'complete') {
      dispatch(setLoading(false)) 
    } else { 
      dispatch(setLoading(true)) 
      // Remove the event listener when component unmounts
      return () =>{  dispatch(setLoading(false)) }
    }
    setTimeout(()=>{
      loading!==false ?  dispatch(setLoading(false)) : dispatch(setLoading(false)) 
    },5000)
  }

  const singin=async()=>{
    const isSign = await getStorage("isSignin")
    dispatch(setSignin(isSign))
  }

  const closeTab=()=>{
    const handleTabClose =(event:any) => {
      event.preventDefault(); 
      console.log('beforeunload event triggered'); 
      return   (event.returnValue =   'Are you sure you want to exit?');
    
    } 
  } 

  useEffect(()=>{
    closeTab()
    addMarginMain()
    getPtofile()
    preloader()
    getSetting()
    getDevices()
    dispatch(setLoading(false))  

    const getZone=async()=>{   
      try{ 
        let zones:zones[]  = await allGeometry()   
        let newzones:any = []  
        if(typeof zones === 'object'){ 
        zones?.map((zone:any,index:any)=>{
          let points = zone.position.map((el:any)=> {return [el.lat,el.lon]})
          newzones[index] = {...zone,...{points:points}}
        }) 
        dispatch(setGeozones(newzones))
        }
      }catch(err){
        // console.log("err ",err)
      }
    }
    getZone()
    singin() 
    
  },[streetview,isStreetView,menuOpen,loading])

  const addMarginMain=async()=>{
    // const main = document.getElementById("main")
    // main?.classList.add("margin-main")
  }
 
    
  // const firebaseMessaging = useFirebaseMessaging({
  //   vapidKey:"BE0JWLK75rgXI8GQUV0KPYgD2pqHQFciLa9MkiKdDjZfRcm5fA34mopPZ5oYGzSVgymF6CLFYnslpENsVg7FHAg",
  //   forceSupport:false,
  //   onFcmRegistrationToken: (fcmRegistrationToken: string) => {
  //     console.log("fcmRegistrationToken ",fcmRegistrationToken)
  //   },
  //   onMessage: (message) => {
  //     console.log(`Received foreground message`, message)
  //     const snackMessage = message?.notification?.title 
  //   },
  // })
  const getMenuFilter=async()=>{
    const menufilter = await menuFilter({}); 
    console.log("getMenuFilter ",template ,menufilter )
    menufilter.sort((a:any, b:any) =>  a.type - b.type)  
    let iconc = menufilter.find((e:any)=> e.type ===10 )
    let iconl = menufilter.find((e:any)=> e.type === 110)

    let namec = menufilter.find((e:any)=>  e.type === 11)
    let namel = menufilter.find((e:any)=> e.type ===111 )

    let imeic = menufilter.find((e:any)=> e.type ===12  )
    let imeil = menufilter.find((e:any)=> e.type === 112)

    let addressc = menufilter.find((e:any)=> e.type ===13  )
    let addressl = menufilter.find((e:any)=> e.type === 113)

    let stsNamec = menufilter.find((e:any)=> e.type ===14 )
    let stsNamel = menufilter.find((e:any)=> e.type === 114)

    let updateTimec = menufilter.find((e:any)=> e.type ===15  )
    let updateTimel = menufilter.find((e:any)=> e.type === 115)

    let navc = menufilter.find((e:any)=> e.type ===16  )
    let navl = menufilter.find((e:any)=> e.type === 116)

    let expirec = menufilter.find((e:any)=> e.type ===17  )
    let expirel = menufilter.find((e:any)=> e.type === 117)

    let phonec = menufilter.find((e:any)=> e.type ===18 )
    let phonel = menufilter.find((e:any)=> e.type === 118)

    let fuelc = menufilter.find((e:any)=> e.type ===19 )
    let fuell = menufilter.find((e:any)=> e.type === 119)

    let speedc = menufilter.find((e:any)=> e.type ===20 )
    let speedl = menufilter.find((e:any)=> e.type === 120)

    let batteryc = menufilter.find((e:any)=> e.type ===21  )
    let batteryl = menufilter.find((e:any)=> e.type === 121)

    let gsmc = menufilter.find((e:any)=> e.type ===22  )
    let gsml = menufilter.find((e:any)=> e.type === 122)

    let gpsc = menufilter.find((e:any)=> e.type ===23 )
    let gpsl = menufilter.find((e:any)=> e.type === 123)

    let milec = menufilter.find((e:any)=> e.type ===24 )
    let milel = menufilter.find((e:any)=> e.type === 124)

    let tempc = menufilter.find((e:any)=> e.type ===25 )
    let templ = menufilter.find((e:any)=> e.type === 125)
    let driverMessc = menufilter.find((e:any)=> e.type ===26 )
    let driverMessl = menufilter.find((e:any)=>  e.type === 126)
    let driverIdc = menufilter.find((e:any)=> e.type ===27  )
    let driverIdl = menufilter.find((e:any)=> e.type === 127)
    let quickTrackc = menufilter.find((e:any)=> e.type ===28 )
    let quickTrackl = menufilter.find((e:any)=> e.type === 128)
    let sharec = menufilter.find((e:any)=> e.type ===29 )     
    let sharel = menufilter.find((e:any)=> e.type === 129)
    let messagec = menufilter.find((e:any)=> e.type ===30 )
    let messagel = menufilter.find((e:any)=> e.type ===30 )
    let trackRealtimec = menufilter.find((e:any)=> e.type ===31 )
    let trackRealtimel = menufilter.find((e:any)=> e.type === 131)
    let ptoc = menufilter.find((e:any)=> e.type === 32 )
    let ptol = menufilter.find((e:any)=> e.type === 132)
    let cmdc = menufilter.find((e:any)=> e.type === 33)
    let cmdl = menufilter.find((e:any)=> e.type === 133)
 
    dispatch(setIconFilter({card: iconc.card ,list: iconl.list ,type: template === "card" ? iconc?.type : iconl?.type }))
    dispatch(setName({card: namec.card,list: namel.list , type: template === "card" ? namec?.type : namel?.type})) 
    dispatch(setEmei({card: imeic.card,list: imeil.list ,type:  template === "card" ? imeic?.type : imeil?.type })) 
    dispatch(setAddress({card: addressc.card,list:addressl.list ,type: template === "card" ? addressc?.type : addressl?.type  })) 
    dispatch(setStatusName({card: stsNamec.card,list:stsNamel.list ,type: template === "card" ? stsNamec?.type : stsNamel?.type  })) 
    dispatch(setUpdateTime({card:updateTimec.card,list:updateTimel.list ,type:template === "card" ? updateTimec?.type : updateTimel?.type  })) 
    dispatch(setNavigation({card:navc.card,list:navl.list  ,type: template === "card" ? navc?.type : navl?.type  })) 
    dispatch(setExpireDate({card:expirec.card,list:expirel.list ,type:template === "card" ? expirec?.type : expirel?.type  })) 
    dispatch(setPhoneNumber({card:phonec.card,list:phonel.list ,type: template === "card" ? phonec?.type : phonel?.type  })) 
    // dispatch(setRatePoint({card:e.card,list:updateTime.list})) 
  
    dispatch(setSensorFuel({card: fuelc.card,list: fuell.list ,type: template === "card" ? fuelc?.type : fuell?.type  }))   
    dispatch(setSensorSpeed({card: speedc.card,list: speedl.list ,type:  template === "card" ? speedc?.type :speedl?.type }))  
    dispatch(setSensorBattery({card: batteryc.card,list: batteryl.list ,type:  template === "card" ? batteryc?.type :batteryl?.type }))  
    dispatch(setSensorGSM({card: gsmc.card,list: gsml.list ,type: template === "card" ? gsmc?.type :gsml?.type }))    
    dispatch(setSensorGPS({card: gpsc.card,list: gpsl.list ,type: template === "card" ? gpsc?.type :gpsl?.type }))      
    dispatch(setSensorMile({card: milec.card,list: milel.list ,type:  template === "card" ? milec?.type :milel?.type  }))  
    dispatch(setSensorTemperature({card: tempc.card,list: templ.list ,type:  template === "card" ? tempc?.type :templ?.type  }))    
    dispatch(setSensorCardName({card: driverMessc.card,list: driverMessl.list ,type:  template === "card" ? driverMessc?.type :driverMessl?.type }))  
    dispatch(setSensorCardNumber({card: driverIdc.card,list: driverIdl.list   ,type:   template === "card" ? driverIdc?.type :driverIdl?.type }))   
  
    dispatch(setQuickTrack({card: quickTrackc.card,list: quickTrackl.list  ,type:  template === "card" ? quickTrackc?.type :quickTrackl?.type }))   
    dispatch(setShare({card: sharec.card,list: sharel.list  ,type:  template === "card" ? sharec?.type :sharel?.type }))   
    dispatch(setMessage({card: messagec.card,list: messagel.list   ,type:  template === "card" ? messagec?.type :messagel?.type }))    
    dispatch(setRealtimeTrack({card: trackRealtimec.card,list:trackRealtimel.list  ,type:  template === "card" ? trackRealtimec?.type :trackRealtimel?.type })) 
    dispatch(setSensorPTO({card: ptoc.card,list: ptol.list ,type:  template === "card" ? ptoc?.type :ptol?.type  })) 
    dispatch(setCommand({card: cmdc.cardc,list: cmdl.list   ,type:  template === "card" ? cmdc?.type :cmdl?.type })) 

  }

  useEffect(() => {
    (async () => {   
      let fcmToken = await getStorage("fcm_token")
      // if(!fcmToken){
      //   let token =  await requestForToken(); 
      //   if(token){
      //   setStorage('fcm_token',token)   
      //   firebaseMessaging.init()

        // const regitered =  navigator.serviceWorker.register('/firebase-messaging-sw.js')
        //   .then(function(registration) { 
        //         onMessage(messaging, (payload:any) => {  
        //             var song = new Audio();
        //             song.src = '../../assets/sounds/horn_car_2.wav';
        //             song.play();
        //             toast.custom((t) =>( 
        //               <CustomNotify t={t} title={payload.notification.title} message={payload.notification.body} icon={"https://www.w3schools.com/howto/img_avatar.png"} /> ),
        //               {position:'top-right' ,id: payload.notification.notificationId } 
        //             ); 
        //             //registration.showNotification(payload.data.title, { body: payload.data.body });
        //         });
        //       return navigator.serviceWorker.ready.then(async function(registration:any) {
        //         registration.pushManager.subscribe({userVisibleOnly: true})
        //         .then( (subscription:any)=> {
        //           console.log('Subscribed for push:', subscription.endpoint);
        //         })
        //         .catch( (error:any)=> {
        //           console.log('Subscription failed:', error);
        //         });
        //       });
        //     })
        //     .catch(function(error) {
        //       console.log('Service worker registration failed:', error);
        //     }); 
        //   }
        // }
        getMenuFilter()
        let contFil:any = []
        contFil = await getStorage('content') 
        dispatch(setContentFilter(contFil)) 
    })();

    navigator.geolocation.getCurrentPosition((coord)=>{})
     
   
  }, [ ])

  return (
    <IonPage >  
        <DialogDevice />
        <Toaster />
      <IonContent fullscreen> 
       <IonLoading 
            isOpen={loading}
            onDidDismiss={() => dispatch(setLoading(false))}
            message={'Please wait...'}  
            mode='ios'
       /> 
          <IonRow >    
              <IonCol  style={{padding:'0px'}}>
                <div  className='ion-hide-sm-down' ><DevicesContainer/></div>
                <div  className='ion-hide-sm-up' ><DevicesModals/></div> 
                {/* <TrackingMap /> */}
              </IonCol>
              {streetview&&isStreetView&&steetPosi && <IonCol sizeLg='3' sizeXl='4'  style={{padding:'0px'}} >
                <MapStreetView/>
              </IonCol>}
            
            </IonRow>   
        <IconShare/> 
      </IonContent> 
    </IonPage>
  );
};

export default Home;

const InitializingFirebaseMessaging=()=>{

  return(
    <IonProgressBar type="indeterminate" color="primary"></IonProgressBar>
  )
}

const CopyRegistrationToken=({fcmRegistrationToken}:any)=>{
  console.log("fcmRegistrationToken ",fcmRegistrationToken)
 return(<></>)
}

export const AuthCheck=()=>{
  const dispatch = useDispatch()
  const isSinin :any= useSelector(getIsSignin) 
  // const [cookies, setCookie] = useCookies(['token']);

  const signout=()=>{ 
      dispatch(setSignin(false))
      setTimeout(()=>{ 
          window.location.pathname = 'signin'
      },200) 
  
  } 

  useEffect(()=>{  
    const authenSignin=async()=>{
      let isSign =await getStorage("isSignin")
      let token = await getStorage("token")
      let isSigninParse = JSON.parse(isSign||"false")
      dispatch(setSignin(isSigninParse))

      let appVersion = await getStorage('appVersion')  
 
      if(!token ){ 
        dispatch(setSignin(false))
        setStorage('isSignin', false)
        setTimeout(()=>{ 
            window.location.pathname = 'signin'
        },200) 
      }
      if(appVersion && appVersion != appconf.versionNumber){ 
        localStorage.removeItem("token")
        dispatch(setSignin(false))
        setStorage('isSignin', false)
        setTimeout(()=>{ 
            window.location.pathname = 'signin'
        },200)
      }
    }
    authenSignin()
 
  },[])

  return(<></>)
}
