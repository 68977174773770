import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from "./store";

const initialState = { 
  content: 'list',
  statusCard: [{number: 0 ,status: 0 }],

  iconFilter: {card:true, list:false ,  type:'10'},
  name:       {card:true, list:true ,   type:'11'},
  emei:       {card:true,list:true ,  type:'12'},
  address:     {card:true, list:true ,  type:'13'},
  status_name: {card:true,list: true ,  type:'14'},
  update_time: {card:true,list:true ,   type:'15'}, 
  navigate:   {card:true, list:false ,  type:'16'},
  expired_date: {card:true,list:false , type:'17'},
  phone_number: {card:true,list:false , type:'18'},
  rate_point: {card:true,list:false , type:'rate_point'},

  sensor_fuel: {card:true,list:true   , type:'19'},
  sensor_speed: {card:true,list:true  , type:'20'},
  sensor_battery: {card:true,list:true, type:'21'},
  sensor_GSM: {card:true,list:true  ,   type:'22'},
  sensor_GPS: {card:true,list:true  ,   type:'23'},
  sensor_PTO: {card:true,list:true  ,   type:'32'},
  sensor_mile: {card:true,list:false ,  type:'24'},
  sensor_temperature: {card:true,list:true , type:'25'},
  sensor_cardname: {card:true,list:false  , type:'26'},
  sensor_cardnumber: {card:true,list:false  , type:'27'},
   
  quick_track: {card:true,list:true , type:'28'},
  share: {card:true,list:true ,       type:'29'},
  message: {card:true,list:true ,   type:'30'},
  command: {card:true,list:false ,    type:'33'},
  realtime_track: {card:true,list:true ,type:'31'},

  sensor_cutengine: {card:true,list:false  , type:'34'},  
  streetview: {card:true,list:false  , type:'35'}, 
  notification: {card:true,list:false  , type:'36'}, 
}
 
export const filterSlice = createSlice({
    name: "filteredState",
    initialState,
    reducers: { 
      setContentFilter: (state, action) => {
        state.content = action.payload;
      }, 
      setStatusCard: (state, action) => {
        state.statusCard = action.payload;
      }, 
      setIconFilter: (state, action) => {
        state.iconFilter = action.payload;
      }, 
      setName: (state, action) => {
        state.name = action.payload;
      }, 
      setEmei: (state, action) => {
        state.emei = action.payload;
      },  
      setStatusName: (state, action) => {
        state.status_name = action.payload; 
      }, 
      setUpdateTime: (state, action) => {
        state.update_time = action.payload;
      }, 
      setAddress: (state, action) => {
        state.address = action.payload;
      }, 
      setNavigation: (state, action) => {
        state.navigate = action.payload;
      }, 
      setExpireDate: (state, action) => {
        state.expired_date = action.payload;
      }, 
      setPhoneNumber: (state, action) => {
        state.phone_number = action.payload; 
      },
      setRatePoint: (state, action) => {
        state.rate_point = action.payload; 
      },  
      setSensorFuel: (state, action) => {
        state.sensor_fuel = action.payload;
      }, 
      setSensorSpeed: (state, action) => {
        state.sensor_speed = action.payload;
      }, 
      setSensorBattery: (state, action) => {
        state.sensor_battery = action.payload;
      }, 
      setSensorGSM: (state, action) => {
        state.sensor_GSM = action.payload;
      }, 
      setSensorGPS: (state, action) => {
        state.sensor_GPS = action.payload;
      }, 
      setSensorPTO: (state, action) => {
        state.sensor_PTO = action.payload;
      }, 
      setSensorMile: (state, action) => {
        state.sensor_mile = action.payload;
      },  
      setSensorTemperature: (state, action) => {
        state.sensor_temperature = action.payload;
      },  
      setSensorCardName: (state, action) => {
        state.sensor_cardname = action.payload;
      },  
      setSensorCardNumber: (state, action) => {
        state.sensor_cardnumber = action.payload;
      }, 

      setQuickTrack: (state, action) => {
        state.quick_track = action.payload;
      } , 
      setShare: (state, action) => {
        state.share = action.payload;
      } , 
      setMessage: (state, action) => {
        state.message = action.payload;
        console.log("setMessage ",state.message)
      } , 
      setCommand: (state, action) => {
        state.command = action.payload;
      } ,  
      setRealtimeTrack: (state, action) => {
        state.realtime_track = action.payload;
      } , 
      
      setSensorCutengine: (state, action) => {
        state.sensor_cutengine = action.payload;
      }, 
      setStreetview: (state, action) => {
        state.streetview = action.payload;
      }, 
      setNotification: (state, action) => {
        state.notification = action.payload;
      },
      setToAll: (state, action) => {
        console.log("setToAll state ",action)
        state  =  {...state , ...action.payload}
      },
    }
})
 

export const { setToAll,
  setContentFilter ,setStatusCard , 
  setIconFilter, setName, setRatePoint,
  setEmei , setStatusName, setUpdateTime ,
  setAddress , setNavigation ,setExpireDate ,setPhoneNumber, setSensorFuel ,
  setSensorSpeed, setSensorBattery ,setSensorGSM ,setSensorGPS ,setSensorPTO ,
  setSensorMile , setSensorTemperature ,setSensorCardName ,setSensorCardNumber,
  setQuickTrack ,setShare ,setMessage ,setCommand,setRealtimeTrack ,setSensorCutengine,setStreetview,setNotification
} = filterSlice.actions;


export const getContentFilter = (state:RootState) => state.filteredState.content;
export const getStatusCard = (state:RootState) => state.filteredState.statusCard;
export const getIconFilter = (state:RootState) => state.filteredState.iconFilter;
export const getName = (state:RootState) => state.filteredState.name; 
export const getEmei = (state:RootState) => state.filteredState.emei;  
export const getStatusName = (state:RootState) => state.filteredState.status_name; 
export const getUpdateTime = (state:RootState) => state.filteredState.update_time; 
export const getAddress = (state:RootState) => state.filteredState.address; 
export const getNavigation = (state:RootState) => state.filteredState.navigate; 
export const getExpireDate = (state:RootState) => state.filteredState.expired_date; 
export const getPhoneNumber = (state:RootState) => state.filteredState.phone_number; 
export const getRatePoint = (state:RootState) => state.filteredState.rate_point; 

export const getSensorFuel = (state:RootState) => state.filteredState.sensor_fuel; 
export const getSensorSpeed = (state:RootState) => state.filteredState.sensor_speed; 
export const getSensorBattery = (state:RootState) => state.filteredState.sensor_battery; 
export const getSensorGSM = (state:RootState) => state.filteredState.sensor_GSM; 
export const getSensorGPS = (state:RootState) => state.filteredState.sensor_GPS; 
export const getSensorPTO = (state:RootState) => state.filteredState.sensor_PTO; 
export const getSensorMile = (state:RootState) => state.filteredState.sensor_mile; 
export const getSensorTemperture = (state:RootState) => state.filteredState.sensor_temperature; 
export const getSensorCardName = (state:RootState) => state.filteredState.sensor_cardname; 
export const getSensorCardNumber = (state:RootState) => state.filteredState.sensor_cardnumber; 
   
export const getQuickTrack = (state:RootState) => state.filteredState.quick_track; 
export const getShare = (state:RootState) => state.filteredState.share; 
export const getMessage = (state:RootState) => state.filteredState.message;  
export const getCommand = (state:RootState) => state.filteredState.command;
export const getRealtimeTrack  = (state:RootState) => state.filteredState.realtime_track;
export const getSensorCutengine = (state:RootState) => state.filteredState.sensor_cutengine;
export const getStreetview = (state:RootState) => state.filteredState.streetview;
export const getNotification= (state:RootState) => state.filteredState.notification;

export default filterSlice.reducer;
